import { Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzConfigService } from 'ng-zorro-antd/core/config';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  defaultClasses = { nzClass: 'mobile-toast' };
  constructor(
    private notification: NzNotificationService,
    private configService: NzConfigService
  ) {
    this.configService.set('notification', {
      nzPlacement: 'bottomLeft',
      nzBottom: '60px',
    });
  }

  /**
   * show error popup
   *
   * @private
   * @param {string} [title]
   * @param {string} [error]
   * @memberof NotificationService
   */
  public showError(title?: string, error?: string) {
    this.notification.create(
      'error',
      title || 'System Failure',
      error || 'An error has occurred. Please try again later.',
      { ...this.defaultClasses, nzDuration: 6000 }
    );
  }
  /**
   * show Warning popup
   *
   * @private
   * @param {string} [title]
   * @param {string} [error]
   * @memberof NotificationService
   */
  public showWarning(title?: string, error?: string) {
    this.notification.create(
      'warning',
      title || 'System Failure',
      error || 'An error has occurred. Please try again later.',
      this.defaultClasses
    );
  }

  /**
   * show success popup
   *
   * @private
   * @param {string} [title]
   * @param {string} [success]
   * @memberof NotificationService
   */
  public showSuccess(title?: string, success?: string) {
    this.notification.create(
      'success',
      title || 'System Success',
      success || 'Successfully saved changes.',
      this.defaultClasses
    );
  }

  /**
   * show info popup
   *
   * @private
   * @param {string} [title]
   * @param {string} [success]
   * @memberof NotificationService
   */
  public showInfo(title?: string, success?: string) {
    this.notification.create(
      'info',
      title || 'System info',
      success || 'Successfully saved changes.',
      this.defaultClasses
    );
  }
}
