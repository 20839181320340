import { ChangeAlarm, Counts, AlarmProperty } from '@vfi-ui/models';

export class LoadAlarms {
  public static readonly type = '[Alarms] Add alarms';
}
export class ResetAlarms {
  public static readonly type = '[Alarms] reset alarms';
}
export class LoadMoreAlarms {
  public static readonly type = '[Alarms] Load more alarms';
  constructor(public payload?: { offset: number }) {}
}
export class AlarmTileSelected {
  public static readonly type = '[Alarms] select/unselect Alarms';
  constructor(public payload: ChangeAlarm) {}
}
export class AlarmPriorityChanged {
  public static readonly type = '[Alarms] Alarm priority changed';
  constructor(public payload: ChangeAlarm[]) {}
}
export class AllAlarmPriorityChanged {
  public static readonly type = '[Alarms] All alarm priority changed';
  constructor(public payload: ChangeAlarm) {}
}
export class AlarmTileSelectionPinned {
  public static readonly type = '[Alarms] Pin Alarm instance by selection';
  constructor(public payload: ChangeAlarm) {}
}
export class AlarmTileChecked {
  public static readonly type = '[Alarms] Toggle Alarm instance check box';
  constructor(public payload: ChangeAlarm) {}
}
export class AllAlarmTileChecked {
  public static readonly type = '[Alarms] Toggle All Alarm instance check box';
  constructor(public payload: ChangeAlarm) {}
}
export class CheckNewAlarmTiles {
  public static readonly type =
    '[Alarms] Check new alarm tiles that are loaded';
  constructor(public payload: number) {}
}
export class LiveAlarms {
  public static readonly type = '[Alarms] Socket feed for live alarm';
}
export class CleanupAlarmSockets {
  public static readonly type = '[Alarms] clear all Socket feed for live alarm';
}
export class AddAlarmCounts {
  public static readonly type = '[Alarms] set primary counts for alarms';
  constructor(public payload: Counts) {}
}
export class SelectFirstAlarmTile {
  public static readonly type = '[Alarms] Select first tile';
}
export class SetFirstAlarmTile {
  public static readonly type = '[Alarms] Set first alarm tile';
  constructor(public payload: number) {}
}
export class LoadAlarmStatuses {
  public static readonly type = '[Alarms] Load alarm statuses';
  constructor() {}
}
export class AutoDispatchChanged {
  public static readonly type = '[Alarms] Set auto dispatch for alarm tile';
  constructor(public payload: ChangeAlarm) {}
}
export class SetCustomCounts {
  public static readonly type = '[Alarms] Set custom lens counts';
  constructor(public payload: Counts) {}
}
export class SetAlarmProperties {
  public static readonly type = '[Alarms] Set alarm properties';
  constructor(public payload: AlarmProperty[]) {}
}
export class SetAlarmLimit {
  public static readonly type = '[Alarms] Set alarm limit';
  constructor(public payload: number) {}
}
