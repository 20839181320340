import {
  Component,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { NEW_VALUE_TEXT, SELECT_ALL_VALUE } from '@vfi-ui/models';
import { head, isNil, uniq } from '@vfi-ui/util/helpers';
import { get } from '@vfi-ui/util/helpers';

@Component({
  selector: 'atom-dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  styleUrls: ['./dropdown-menu.component.scss'],
})
export class DropdownMenuComponent implements OnChanges {
  @Input() options;
  @Input() label: string;
  @Input() value;
  @Input() allowClear = false;
  @Input() multiple = false;
  @Input() required = false;
  @Input() disabled = false;
  @Input() showSearch = true;
  @Input() showTooltips = false;
  @Input() width = '229';
  @Input() name: string;
  @Input() showPlaceholder = true;
  @Input() allowNewValues = false;
  @Input() useAllOption = false;
  @Input() selectAllPlaceholder = null;
  @Input() open = false;
  @Input() capitalizePlaceholder = true;
  @Input() revertToValue;
  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Output() optionSearch: EventEmitter<string> = new EventEmitter();
  @Output() newValuesSelected: EventEmitter<string> = new EventEmitter();
  @Output() dropdownStateChanged: EventEmitter<boolean> = new EventEmitter();
  inputValue;
  focused = false;
  newValues = [];

  constructor() {}

  ngOnChanges(change: SimpleChanges) {
    const optionChanged = get(change.options, 'currentValue', false);
    if (change.value && change.value.currentValue) {
      if (this.multiple && !Number.isNaN(Number(head(this.value)))) {
        this.value = this.value.map(Number);
      }
    }
    if (optionChanged) {
      this.addAllOptions(this.name, this.selectAllPlaceholder);
      if (this.allowNewValues) {
        this.options = this.removeDuplicateOptions(
          this.options,
          this.newValues
        );
      }
    }
    // reverts the previously selected value back to its original
    // this is good for selections that need to be confirmed first
    if (
      change.revertToValue?.currentValue &&
      change.revertToValue?.currentValue !== null
    ) {
      this.value = change.revertToValue.currentValue;
    }
  }

  /**
   * emit value of selected option
   *
   * @param {*} value
   * @memberof DropdownMenuComponent
   */
  valueChanged(value) {
    if (this.allowNewValues) {
      const formatted = this.removeNewValueText(
        value,
        this.options,
        this.newValues
      );
      this.options = formatted.options;
      value = formatted.value;
    }
    if (this.multiple && !Number.isNaN(Number(head(value)))) {
      this.inputValue = value.map(String);
      this.valueChange.emit(uniq(this.inputValue));
    } else {
      this.valueChange.emit(value);
    }
  }
  /**
   * emit the state of the dropdown status
   *
   * @param {boolean} value
   * @memberof DropdownMenuComponent
   */
  openChange(value: boolean) {
    this.dropdownStateChanged.emit(value);
  }

  /**
   * emit even to fetch search options
   *
   * @param {string} ev
   * @param {*} options
   * @param {*} newValues
   * @memberof DropdownMenuComponent
   */
  fetchOptions(ev: string, options, newValues) {
    this.optionSearch.emit(ev);
    if (this.allowNewValues) {
      this.options = this.addNewValueText(ev, options, newValues);
    }
  }

  /**
   * remove new value text from selected option
   *
   * @param {string} value
   * @param {*} options
   * @param {*} newValues
   * @returns
   * @memberof DropdownMenuComponent
   */
  removeNewValueText(value: string, options, newValues) {
    if (newValues.length && newValues[0].label === value) {
      const selected = value.replace(` ${NEW_VALUE_TEXT}`, '');
      newValues[0].label = selected;
      newValues[0].value = selected;
      this.newValuesSelected.emit(selected);
      this.newValues = newValues;
      options = this.removeDuplicateOptions(options, newValues);
      const newVal = head(options).value;
      this.value = newVal;
      value = newVal;
    } else {
      this.newValuesSelected.emit('');
    }

    return { options, value };
  }

  /**
   * add new value text to options
   *
   * @param {string} name
   * @param {*} options
   * @param {*} newValues
   * @returns
   * @memberof DropdownMenuComponent
   */
  addNewValueText(name: string, options, newValues) {
    if (options.length && head(options).label.includes(NEW_VALUE_TEXT)) {
      options.shift();
    }
    if (name.length) {
      name = this.trimNewValue(name);
      const newVal = {
        label: `${name} ${NEW_VALUE_TEXT}`,
        value: `${name} ${NEW_VALUE_TEXT}`,
      };
      newValues[0] = newVal;
    }
    this.newValues = newValues;
    return this.removeDuplicateOptions(options, newValues);
  }

  /**
   * set options
   *
   * @param {*} options
   * @param {*} newValues
   * @returns
   * @memberof DropdownMenuComponent
   */
  removeDuplicateOptions(options, newValues) {
    if (newValues.length) {
      const label = head(newValues).label.replace(` ${NEW_VALUE_TEXT}`, '');
      newValues = options.some(
        (r) => r.label.toLowerCase() === label.toLowerCase()
      )
        ? []
        : newValues;
    }
    return [...newValues, ...options];
  }

  /**
   * trim new value to 100 characters
   *
   * @param {string} value
   * @returns
   * @memberof DropdownMenuComponent
   */
  trimNewValue(value: string) {
    return value.length > 100 ? value.substring(0, 100) : value;
  }

  /**
   * check if value is valid
   *
   * @param {*} value
   * @returns
   * @memberof DropdownMenuComponent
   */
  hasValue(value) {
    return !isNil(value);
  }

  /**
   * add select all option
   *
   * @param {string} name
   * @memberof DropdownMenuComponent
   */
  addAllOptions(name: string, selectAllPlaceholder: string) {
    if (
      this.useAllOption &&
      !this.options.some((o) => o.value === SELECT_ALL_VALUE)
    ) {
      const placeholder = selectAllPlaceholder || name;
      this.options.unshift({
        label: `All ${placeholder}s`,
        value: SELECT_ALL_VALUE,
      });
    }
  }
}
