export enum ButtonSize {
  SMALL = 'small',
  DEFAULT = 'default',
  LARGE = 'large',
}

export enum ButtonType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  LINK = 'link',
  DANGER = 'danger',
}

export enum ButtonIconPosition {
  LEFT = 'left',
  RIGHT = 'right',
}
