import { Counts, MenuLens, AlarmViews, Team } from '@vfi-ui/models';

export class SetMobileLens {
  public static readonly type = '[Lens] set mobile lenses';
  constructor(public payload: MenuLens[]) {}
}

export class SetLens {
  public static readonly type = '[Lens] set lenses';
  constructor(public payload: MenuLens[]) {}
}

export class SetCurrentlySelectedLens {
  public static readonly type = '[Lens] set currently selected lens';
  constructor(public payload: string) {}
}
export class NavigateToCurrentlySelectedLens {
  public static readonly type = '[Lens] navigate to currently selected lens';
  constructor() {}
}

export class SetTriageCounts {
  public static readonly type = '[Lens] set counts for triage lenses';
  constructor(public payload: Counts) {}
}

export class SetAlarmView {
  public static readonly type = '[Lens] set alarm view';
  constructor(public payload: AlarmViews) {}
}

export class RemoveLens {
  public static readonly type = '[Lens] remove lens from list';
  constructor(public payload: string) {}
}

export class RemoveMobileLens {
  public static readonly type = '[Lens] remove mobile lens from list';
  constructor(public payload: string) {}
}

export class SetLensTeam {
  public static readonly type = '[Lens] set lens team id';
  constructor(public payload: Partial<Team>) {}
}

export class UpdateLens {
  public static readonly type = '[Lens] update lens';
  constructor(public payload: MenuLens) {}
}
