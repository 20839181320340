import { TableScroll } from '../interfaces/responsive.interface';

export const breakpoints = {
  breakPoints: {
    xs: { max: 575 },
    sm: { min: 576, max: 767 },
    md: { min: 768, max: 991 },
    lg: { min: 992, max: 1200 },
    xl: { min: 1201 },
  },
  debounceTime: 1,
};

export const RESPONSIVE_TABLE_X_SCROLL: TableScroll = {
  x: `${breakpoints.breakPoints.md.max}px`,
};
