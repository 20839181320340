import { MenuService } from '@vfi-ui/data-access/shared';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { filter, tap, take } from 'rxjs/operators';
import { Select, Store } from '@ngxs/store';
import { AuthState, CoreState } from '@vfi-ui/state';
import { Observable } from 'rxjs';
import { head } from '@vfi-ui/util/helpers';
import { AuthRoles } from '@vfi-ui/models';

@Injectable({
  providedIn: 'root',
})
export class LensResolver {
  @Select(AuthState.getAuthRole) authRole$: Observable<AuthRoles>;

  constructor(
    private menuService: MenuService,
    private store: Store
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<string> {
    const app = head(route.url).path;

    return this.authRole$.pipe(
      filter((p) => !!p),
      tap((p) => {
        const isMobile = this.store.selectSnapshot(CoreState.isMobile);
        if (isMobile && app === 'alarms') {
          this.menuService.getMobileMenuItems(p).subscribe();
        } else if (app === 'alarms') {
          this.menuService.getMenuItems().subscribe();
        } else if (app === 'assets') {
          this.menuService.getAssetMenuItems().subscribe();
        } else {
          this.menuService.getReportingMenuItems(p).subscribe();
        }
      }),
      take(1)
    );
  }
}
