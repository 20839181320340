export const DateTimeFormat = {
  DATE_GRT_THAN_24H: 'M/dd/yyyy hh:mm a',
  DATE_LES_THAN_24H: 'hh:mm a',
};

export const AngularDateFormat = {
  DATE_GRT_THAN_24H: 'M/d/y hh:mm aa',
};

export const SevenDaysHours = 168;

export const WEEKDAYS = [
  'SUNDAY',
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
];

export const WEEKDAY_OPTIONS = [
  { label: 'Sunday', value: 'Sunday' },
  { label: 'Monday', value: 'Monday' },
  { label: 'Tuesday', value: 'Tuesday' },
  { label: 'Wednesday', value: 'Wednesday' },
  { label: 'Thursday', value: 'Thursday' },
  { label: 'Friday', value: 'Friday' },
  { label: 'Saturday', value: 'Saturday' },
];

export const FREQUENCIES = [
  { label: 'Instantaneously', value: 0 },
  { label: 'in 1-hour window(s)', value: 1 },
  { label: 'in 2-hour window(s)', value: 2 },
  { label: 'in 3-hour window(s)', value: 3 },
  { label: 'in 4-hour window(s)', value: 4 },
];

export const RANGE_PICKER_OPTIONS = [
  {
    value: 0,
    label: 'Today',
  },
  {
    value: 1,
    label: 'Yesterday',
  },
  {
    value: 7,
    label: 'Last 7 days',
  },
  {
    value: 30,
    label: 'Last 30 days',
  },
  {
    value: 90,
    label: 'Last 90 days',
  },
  {
    value: 180,
    label: 'Last 180 days',
  },
  {
    value: 365,
    label: '1 year',
  },
  {
    value: -1,
    label: 'Custom',
  },
];
