export enum AnalyticsGoalsFormats {
  PERCENTAGE = 'PERCENTAGE',
  DAYS = 'DAYS',
  HOURS = 'HOURS',
  MINUTES = 'MINUTES',
  COUNT = 'COUNT',
}

export enum BulletLiteType {
  PERCENTAGE = '%',
  DAYS = 'd',
  HOURS = 'h',
  MINUTES = 'm',
  COUNT = '',
}

export enum BulletTypeValue {
  PERCENTAGE = 100,
  DAYS = 30,
  HOURS = 24,
  MINUTES = 60,
  COUNT = 100,
}

export enum KpiDashboardMetricTypeValue {
  'ENGAGED_USERS' = 'Engaged Users',
  'REGISTERED_USERS' = 'Registered Users',
  'PRIORITIZED_ALARMS' = ' Prioritized Alarms',
  'CRITICAL_ALARMS_PER_HOUR_PER_ACTIVE_DISPATCHER' = 'Alarms Per Hour',
  'ACTIONED_CRITICAL_ALARMS' = 'Actioned Alarms',
  'CRITICAL_ALARM_DISTRIBUTION' = 'Alarm Priority Distribution',
  'WORK_COVERAGE_CRITICAL_ALARMS' = 'Work Coverage of Alarms',
  'TIME_TO_DISPATCH_CRITICAL_ALARMS' = 'Time To Dispatch',
  'TIME_TO_RESOLUTION_CRITICAL_ALARMS' = 'Time To Resolution',
  'RESOLUTION_EFFECTIVENESS' = 'Resolution Effectiveness',
  'REPEAT_WORK' = 'Repeat Work',
  'OPEN_WORK_INACTIVE_ALARMS' = 'Open Work with Inactive Alarms',
  'DUPLICATE_WORK' = 'Duplicate Work',
}

export const ACCOUNTABILITY_SORT_ORDER = [
  'RESOLUTION_EFFECTIVENESS',
  'REPEAT_WORK',
  'OPEN_WORK_INACTIVE_ALARMS',
  'DUPLICATE_WORK',
];

export const WORK_SORT_ORDER = [
  'WORK_COVERAGE_CRITICAL_ALARMS',
  'TIME_TO_DISPATCH_CRITICAL_ALARMS',
  'TIME_TO_RESOLUTION_CRITICAL_ALARMS',
];

export const AWARENESS_SORT_ORDER = [
  'CRITICAL_ALARMS_PER_HOUR_PER_ACTIVE_DISPATCHER',
  'ACTIONED_CRITICAL_ALARMS',
  'CRITICAL_ALARM_DISTRIBUTION',
];

export const ADOPTION_SORT_ORDER = [
  'ENGAGED_USERS',
  'REGISTERED_USERS',
  'PRIORITIZED_ALARMS',
];

export const KPI_SORT_ORDER_TYPES = {
  adoption: ADOPTION_SORT_ORDER,
  awareness: AWARENESS_SORT_ORDER,
  work: WORK_SORT_ORDER,
  accountability: ACCOUNTABILITY_SORT_ORDER,
};
