import { Apollo } from 'apollo-angular';
import {
  APPLY_FIELD_RELATIONS_TO_EXTERNAL_DETAILS_QUERY,
  APPLY_WORK_ORDER_RULES_QUERY,
  APPLY_WORK_ORDER_TRANSLATION_RULES_QUERY,
  GET_FULL_WORK_ORDER_AUTO_COMPLETE_QUERY,
  POST_WORK_TICKET_QUERY,
} from './../queries/work-order.query';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import {
  MaxAlarmsLimit,
  WorkOrderConfiguration,
  WorkTicketCreateInput,
  BaseAlarmProperty,
  ERROR_SAVE_WORK_TICKETS_RELATIONS,
  ERROR_SAVE_WORK_TICKETS,
  MaxAlarmPropertyLimit,
  WorkTicket,
  CurrentWorkOrderDomainQuery,
} from '@vfi-ui/models';

import { GET_WORK_ORDER_AUTO_COMPLETE_QUERY } from '../queries/work-order.query';
import { catchError, filter, map, tap } from 'rxjs/operators';
import { NotificationService } from './notification.service';
@Injectable({
  providedIn: 'root',
})
export class WorkOrderDataService {
  constructor(
    private apollo: Apollo,
    private notification: NotificationService
  ) {}

  /**
   * query for the work order auto complete options
   *
   * @param {string} field
   * @param {string} filter
   * @returns {Observable<string[]>}
   * @memberof WorkOrderDataService
   */
  getWorkOrderAutoCompleteFields(
    field: string,
    filters: string
  ): Observable<string[]> {
    const limit = MaxAlarmPropertyLimit;
    const offset = 0;
    return this.apollo
      .query<{ fetchWorkOrderDomain: string[] }>({
        fetchPolicy: 'network-only',
        query: GET_FULL_WORK_ORDER_AUTO_COMPLETE_QUERY,
        variables: { field, filter: filters, limit, offset },
      })
      .pipe(
        filter((d) => !!d),
        map((fields) => fields.data.fetchWorkOrderDomain),
        catchError((error) => throwError(error))
      );
  }

  /**
   * query for work order auto complete options with a given configuration
   *
   * @param {{
   *     field: string;
   *     filters: string;
   *     data: WorkOrderConfiguration;
   *     offset: number;
   *   }} {
   *     field,
   *     filters,
   *     data,
   *     offset = 0,
   *   }
   * @returns {Observable<CurrentWorkOrderDomainQuery>}
   * @memberof WorkOrderDataService
   */
  getRelationalWorkOrderAutoCompleteFields({
    field,
    filters,
    data,
    offset = 0,
  }: {
    field: string;
    filters: string;
    data: WorkOrderConfiguration;
    offset: number;
  }): Observable<CurrentWorkOrderDomainQuery> {
    const limit = MaxAlarmsLimit;
    return this.apollo
      .query<CurrentWorkOrderDomainQuery>({
        fetchPolicy: 'network-only',
        query: GET_WORK_ORDER_AUTO_COMPLETE_QUERY,
        variables: { field, data, filter: filters, limit, offset },
      })
      .pipe(
        filter((d) => !!d),
        map((fields) => fields.data),
        catchError((err) => {
          this.notification.showError(ERROR_SAVE_WORK_TICKETS_RELATIONS);
          return throwError(err);
        })
      );
  }

  /**
   * query for relational work order mappings
   *
   * @param {string} field
   * @param {WorkOrderConfiguration} data
   * @returns {Observable<WorkOrderConfiguration>}
   * @memberof WorkOrderDataService
   */
  applyWorkOrderRules(
    field: string,
    data: WorkOrderConfiguration
  ): Observable<WorkOrderConfiguration> {
    return this.apollo
      .query<{ applyWorkOrderRules: WorkOrderConfiguration }>({
        fetchPolicy: 'network-only',
        query: APPLY_WORK_ORDER_RULES_QUERY,
        variables: { field, data },
      })
      .pipe(
        filter((d) => !!d),
        map((fields) => fields.data.applyWorkOrderRules),
        catchError((error) => throwError(error))
      );
  }

  /**
   * Query for relational field values and update
   * the provided external details according to those
   * relationships.
   *
   * @param {Record<string, any>} externalDetails - The cmms field values
   * @returns {Observable<Record<string, any>>}
   * @memberof WorkOrderDataService
   */
  applyCmmsFieldRelationsToValues(
    externalDetails: Record<string, any>,
    externalSystemId: number
  ): Observable<Record<string, any>> {
    return this.apollo
      .query<{ applyFieldRelationsToExternalDetails: WorkOrderConfiguration }>({
        fetchPolicy: 'network-only',
        query: APPLY_FIELD_RELATIONS_TO_EXTERNAL_DETAILS_QUERY,
        variables: { externalDetails, externalSystemId },
      })
      .pipe(
        filter((d) => !!d),
        map((fields) => fields.data.applyFieldRelationsToExternalDetails),
        catchError((error) => throwError(() => error))
      );
  }

  /**
   * query for alarm work order mappings
   *
   * @param {string} id
   * @param {WorkOrderConfiguration} configuration
   * @param {number} [alarmId]
   * @returns {Observable<WorkOrderConfiguration>}
   * @memberof WorkOrderDataService
   */
  applyWorkOrderTranslationRules(
    configuration: BaseAlarmProperty[],
    alarmId?: number
  ): Observable<WorkOrderConfiguration> {
    return this.apollo
      .query<{ applyWorkOrderTranslationRules: WorkOrderConfiguration }>({
        fetchPolicy: 'network-only',
        query: APPLY_WORK_ORDER_TRANSLATION_RULES_QUERY,
        variables: { configuration, alarmId },
      })
      .pipe(
        filter((d) => !!d),
        map((fields) => fields.data.applyWorkOrderTranslationRules),
        catchError((error) => throwError(error))
      );
  }

  /**
   * create/post a work ticket
   *
   * @param {WorkTicketCreateInput} input
   * @param {string} externalSystem
   * @returns
   * @memberof WorkOrderDataService
   */
  postWorkTicket(input: WorkTicketCreateInput, externalSystem: string) {
    return this.apollo
      .mutate<{ createWorkTicket: WorkTicket }>({
        mutation: POST_WORK_TICKET_QUERY,
        variables: { input },
      })
      .pipe(
        filter((d) => !!d),
        catchError((err) => {
          this.notification.showError(ERROR_SAVE_WORK_TICKETS);
          return throwError(err);
        }),
        tap((r) => {
          const wt = `W${r?.data?.createWorkTicket?.id}`;
          const text = input?.isExternalSyncEnabled
            ? `Work Ticket ${wt} was created successfully and is currently syncing with ${externalSystem}.`
            : `Work Ticket ${wt} was created successfully.`;
          this.notification.showSuccess(`${wt} - Work Ticket Created!`, text);
        })
      );
  }
}
