import {
  SetSortFilterType,
  TotalResetGlobalFilters,
  UsersState,
  UsersStateModel,
  SetSortValue,
  ResetUsers,
  SetCoreSortValue,
  SetUsers,
} from '@vfi-ui/state';
import { Users, DefaultUserSort } from '@vfi-ui/models';
import { take, filter, map } from 'rxjs/operators';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { AuthService } from '@vfi-ui/data-access/shared';
import { orderBy } from '@vfi-ui/util/helpers';

@Injectable({
  providedIn: 'root',
})
export class AdminUsersResolver {
  usersState$: Observable<UsersStateModel> = inject(Store).select(
    UsersState.getState
  );

  constructor(
    private store: Store,
    private authService: AuthService
  ) {}

  /**
   * reset users and filters
   *
   * @returns {Observable<Users[]>}
   * @memberof AdminUsersResolver
   */
  resolve(): Observable<Users[]> {
    this.store.dispatch(new SetSortFilterType('user'));
    this.store.dispatch(new ResetUsers());
    this.store.dispatch(new TotalResetGlobalFilters(false));
    this.loadData();

    return this.waitForStateToUpdate();
  }

  /**
   * wait for users state to update
   *
   * @returns {Observable<Users[]>}
   * @memberof AdminUsersResolver
   */
  waitForStateToUpdate(): Observable<Users[]> {
    return this.usersState$.pipe(
      map((res) => res?.items),
      filter((state) => !!state),
      take(1)
    );
  }

  /**
   * load users for admin app
   *
   * @memberof AdminUsersResolver
   */
  loadData(): void {
    this.usersState$.pipe(take(1)).subscribe(() => {
      this.store.dispatch(new SetSortValue(DefaultUserSort));
      this.store.dispatch(new SetCoreSortValue(DefaultUserSort));
      this.authService.getAdminAppUsers().subscribe((users) => {
        const customerUsers = users.filter((user) => !user?.isSuper);
        this.store.dispatch(
          new SetUsers(
            orderBy(customerUsers, ['status', 'displayName'], ['desc', 'asc'])
          )
        );
      });
    });
  }
}
