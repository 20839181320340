import { gql } from 'apollo-angular';

export const GET_MAP_VIEW_BUILDING_ASSETS_QUERY = gql`
  query getMapViewBuildingAssets {
    getMapViewBuildingAssets {
      assetId
      assetName
      name
      address
      geoJson
    }
  }
`;

export const UPDATE_ASSET_GEO_JSON_MUTATION = gql`
  mutation updateAssetGeoJson($entityId: Int!, $geoJSON: Object!) {
    updateAssetGeoJson(entityId: $entityId, geoJSON: $geoJSON)
  }
`;
