import {
  SetSortFilterType,
  TotalResetGlobalFilters,
  ResetOnlyCoreCriterionFilters,
} from '@vfi-ui/state';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { TileType } from '@vfi-ui/models';

@Injectable({
  providedIn: 'root',
})
export class NoDataResolver {
  constructor(private store: Store) {}

  resolve(filterType: TileType) {
    this.store.dispatch(new SetSortFilterType(filterType));
    this.store.dispatch(new TotalResetGlobalFilters(false)).subscribe(() => {
      this.store.dispatch(new ResetOnlyCoreCriterionFilters());
    });
  }
}
