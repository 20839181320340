import { User } from './user.interface';

export enum InventoryType {
  Building = 'BUILDING',
  Room = 'ROOM',
  Equipment = 'EQUIPMENT',
  BasVac = 'BAS_VAC',
}

export interface GlobalCustomerOptions {
  label: string;
  value: number;
}

export interface InventoryAuditLogWhere {
  id?: number;
  type?: InventoryType;
  databaseId?: string;
}

export interface InventoryAuditLog {
  file: string;
  reason: string;
  createdAt: string;
  createdBy: User;
}
