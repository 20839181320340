import { BaseComponent } from '@vfi-ui/feature/core';
import { Component, OnInit } from '@angular/core';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { IDLE_SECONDS, IDLE_TIMEOUT_SECONDS } from '@vfi-ui/models';

@Component({
  selector: 'vfi-admin-app-root',
  template: ` <router-outlet></router-outlet> `,
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends BaseComponent implements OnInit {
  constructor(
    private idle: Idle,
    private router: Router
  ) {
    super();
  }

  ngOnInit() {
    this.setUpIdle();
  }

  /**
   * set up idle tracker
   *
   * @memberof AppComponent
   */
  setUpIdle() {
    this.idle.setIdle(IDLE_SECONDS);
    this.idle.setTimeout(IDLE_TIMEOUT_SECONDS);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idle.watch();
    this.idle.onIdleStart
      .pipe(takeUntil(this.componentDestroyed))
      .subscribe(() => {
        this.router.navigate(['/logout']);
      });
  }
}
