import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { GlobalFiltersService } from './global-filters.service';
import { catchError, tap, map, filter } from 'rxjs/operators';
import {
  CREATE_LENS_QUERY,
  UPDATE_LENS_QUERY,
  DELETE_LENS_QUERY,
  GET_LENS_COUNT_QUERY,
} from '../queries/lens.query';
import {
  LensCreateInput,
  LensType,
  CriterionSelection,
  GlobalFilterSort,
  CoreAlarmsOptions,
  LensCategory,
  LensUpdateInput,
  AlarmProperty,
  ERROR_CREATE_LENS,
  ERROR_UPDATE_LENS,
  ERROR_DELETE_LENS,
  CoreTicketOptions,
  LensCount,
  MenuLens,
  ERROR_SAVE_LENS_TABLE_CONFIG,
  Lens,
  LENS_TYPE_MAP,
  OrderDirection,
} from '@vfi-ui/models';
import { throwError, Observable } from 'rxjs';
import { NotificationService } from './notification.service';
import { groupBy } from '@vfi-ui/util/helpers';

@Injectable({
  providedIn: 'root',
})
export class LensDataService {
  constructor(
    private apollo: Apollo,
    private globalFiltersService: GlobalFiltersService,
    private notification: NotificationService
  ) {}

  /**
   * post new lens creation to backend
   *
   * @param {*} { lens, criterion, category, name, sort }
   * @returns
   * @memberof LensDataService
   */
  createLens({
    criterion,
    category,
    name,
    sort,
    alarmProperties,
    createLensCategory,
    teamId,
  }) {
    const type = LENS_TYPE_MAP.get(createLensCategory);
    let criteria = {};
    if (type === LensType.ALARM) {
      criteria = this.newLensCriteria({
        criterion,
        sort,
        alarmProperties,
        category,
      });
    } else if (type === LensType.WORK) {
      criteria = this.newWorkLensCriteria(criterion, sort);
    } else if (type === LensType.ASSET) {
      criteria = this.newAssetLensCriteria(criterion);
    }

    const options: LensCreateInput = {
      name,
      criteria,
      category,
      type,
      teamId,
    };
    return this.apollo
      .mutate({
        mutation: CREATE_LENS_QUERY,
        variables: { data: options },
      })
      .pipe(
        filter((d) => !!d),
        catchError((error) => {
          this.notification.showError(ERROR_CREATE_LENS);
          return throwError(error);
        }),
        tap(() =>
          this.notification.showSuccess(
            'Your Lens Was Successfully Created',
            `Custom Lens “${name}” has been added to your menu.`
          )
        )
      );
  }

  /**
   * post updates for lens
   *
   * @param {*} { lens, criterion, category, name, sort, id, alarmProperties }
   * @returns
   * @memberof LensDataService
   */
  updateLens({
    criterion,
    category,
    name,
    sort,
    id,
    alarmProperties,
    createLensCategory,
  }) {
    const type = LENS_TYPE_MAP.get(createLensCategory);
    let criteria = {};
    if (type === LensType.ALARM) {
      criteria = this.newLensCriteria({
        criterion,
        sort,
        alarmProperties,
        category,
      });
    } else if (type === LensType.WORK) {
      criteria = this.newWorkLensCriteria(criterion, sort);
    } else if (type === LensType.ASSET) {
      criteria = this.newAssetLensCriteria(criterion);
    }

    const options: Partial<LensUpdateInput> = {
      name,
      criteria,
    };
    return this.apollo
      .mutate({
        mutation: UPDATE_LENS_QUERY,
        variables: { id, data: options },
      })
      .pipe(
        filter((d) => !!d),
        catchError((error) => {
          this.notification.showError(ERROR_UPDATE_LENS);
          return throwError(error);
        }),
        tap(() => {
          this.notification.showSuccess(
            'Your Lens Was Saved',
            `“${name}” was successfully saved and added to your menu.`
          );
        })
      );
  }

  /**
   * update lens table configuration
   *
   * @param {MenuLens} lens
   * @param {*} tableOptions
   * @returns {Observable<Lens>}
   * @memberof LensDataService
   */
  updateLensTableConfiguration(
    lens: MenuLens,
    tableOptions: any
  ): Observable<Lens> {
    return this.apollo
      .mutate<{ lens: Lens }>({
        mutation: UPDATE_LENS_QUERY,
        variables: { id: lens.id, data: { tableOptions } },
      })
      .pipe(
        filter((d) => !!d),
        catchError((error) => {
          this.notification.showError(ERROR_SAVE_LENS_TABLE_CONFIG);
          return throwError(error);
        }),
        filter((d) => !!d),
        map((res) => res?.data?.lens),
        tap(() =>
          this.notification.showSuccess(
            'Your Lens Table Configuration Was Saved',
            `Table configuration for "${lens.name}" was successfully saved.`
          )
        )
      );
  }

  /**
   * post delete event for lens
   *
   * @param {*} {  id, category, name  }
   * @returns
   * @memberof LensDataService
   */
  deleteLens({ id, category, name }) {
    return this.apollo
      .mutate({
        mutation: DELETE_LENS_QUERY,
        variables: { id },
      })
      .pipe(
        filter((d) => !!d),
        catchError((error) => {
          this.notification.showError(ERROR_DELETE_LENS);
          return throwError(error);
        }),
        tap(() => {
          const view =
            category === LensCategory.ACTIVE_ALARMS ? 'Triage' : 'Work';
          this.notification.showSuccess(
            'Your Lens Was Successfully Deleted',
            `Custom Lens “${name}” was deleted from your ${view} menu.`
          );
        })
      );
  }

  /**
   * call api to fetch alarm triage lens counts
   *
   * @param {LensType} [type]
   * @returns {Observable<LensCount[]>}
   * @memberof LensDataService
   */
  getLensCounts(type?: LensType): Observable<LensCount[]> {
    return this.apollo
      .query<{ lensEntityCounts: LensCount[] }>({
        fetchPolicy: 'no-cache',
        query: GET_LENS_COUNT_QUERY,
        variables: type ? { options: { where: { type } } } : {},
      })
      .pipe(
        filter((d) => !!d),
        map((res) => res?.data?.lensEntityCounts)
      );
  }

  /**
   * create CoreAlarmsOptions from sort and filter criterion
   *
   * @private
   * @param {AlarmLens} lens
   * @param {CriterionSelection[]} criterion
   * @param {string[]} lensWhere
   * @param {GlobalFilterSort} sort
   * @returns {CoreAlarmsOptions}
   * @memberof LensDataService
   */
  private newLensCriteria({
    criterion,
    sort,
    alarmProperties,
    category,
  }: {
    criterion: CriterionSelection[];
    sort: GlobalFilterSort;
    alarmProperties: AlarmProperty[];
    category: string;
  }): CoreAlarmsOptions {
    const { where, whereNot } =
      category === LensCategory.ACTIVE_ALARMS
        ? this.globalFiltersService.createFiltersWhere(
            { criterion },
            alarmProperties
          )
        : this.globalFiltersService.createFiltersWhere(
            { criterion },
            alarmProperties
          );
    const sortOrder = !Array.isArray(sort)
      ? sort
      : (this.globalFiltersService.createWorkSortOrderBy(sort) as any);
    return { where, whereNot, offset: 0, limit: 0, order: sortOrder };
  }
  /**
   * create CoreWorkTicketOptions from sort and filter criterion
   *
   * @private
   * @param {CriterionSelection[]} criterion
   * @param {GlobalFilterSort} sort
   * @return {*}  {CoreTicketOptions}
   * @memberof CoreTicketOptions
   */
  private newWorkLensCriteria(
    criterion: CriterionSelection[],
    sort: GlobalFilterSort
  ): CoreTicketOptions {
    const { where, whereNot } =
      this.globalFiltersService.createWorkFiltersWhere({
        criterion,
      });
    const sortOrder = this.globalFiltersService.createWorkSortOrderBy(sort);
    return { where, whereNot, offset: 0, limit: 0, order: sortOrder };
  }

  /**
   * create new asset lens criteria
   *
   * @private
   * @param {CriterionSelection[]} criterion
   * @returns
   * @memberof LensDataService
   */
  private newAssetLensCriteria(criterion: CriterionSelection[]) {
    let where = {};
    let whereNot = {};
    const isExclusion = groupBy(
      criterion,
      (d: CriterionSelection) => d?.detail?.isExclusion || false
    );

    if (isExclusion?.false) {
      where = this.globalFiltersService.generateAssetFilterQueries({
        ...{ criterion },
        criterion: isExclusion?.false,
      });
    }
    if (isExclusion?.true) {
      whereNot = this.globalFiltersService.generateAssetFilterQueries({
        ...{ criterion },
        criterion: isExclusion?.true,
      });
    }

    return {
      where,
      whereNot,
      offset: 0,
      limit: 0,
      order: { field: 'NAME', direction: OrderDirection.ASC },
    };
  }
}
