import { OrderDirection } from './work-ticket.constant';

export enum parserAccuracyDropdownsOptions {
  averageAccuracy = 'Average Total Accuracy',
  averageCnnAccuracy = 'Average CNN Accuracy',
  averageBertAccuracy = 'Average BERT Accuracy',
  averageUntrainedConfidence = 'Untrained Average Confidence',
  trainedAttributePercentage = 'Trained Attributes ( % )',
}

export enum ParserAccuracyGlobalAttributeSort {
  propertyType = 'PROPERTY_TYPE',
  averageAccuracy = 'AVERAGE_ACCURACY',
  averageCnnAccuracy = 'AVERAGE_CNN_ACCURACY',
  averageBertAccuracy = 'AVERAGE_BERT_ACCURACY',
  averageLevenshteinAccuracy = 'AVERAGE_LEVENSHTEIN_ACCURACY',
  averageUntrainedConfidence = 'AVERAGE_UNTRAINED_CONFIDENCE',
  trainedAttributePercentage = 'TRAINED_ATTRIBUTE_PERCENTAGE',
  highestAverageAccuracyPercentage = 'HIGHEST_AVERAGE_ACCURACY_PERCENTAGE',
  lowestAverageAccuracyPercentage = 'LOWEST_AVERAGE_ACCURACY_PERCENTAGE',
}

export enum ParserAccuracyGlobalCustomerSort {
  customerName = 'CUSTOMER_NAME',
  averageAccuracy = 'AVERAGE_ACCURACY',
  averageCnnAccuracy = 'AVERAGE_CNN_ACCURACY',
  averageBertAccuracy = 'AVERAGE_BERT_ACCURACY',
  averageLevenshteinAccuracy = 'AVERAGE_LEVENSHTEIN_ACCURACY',
  averageUntrainedConfidence = 'AVERAGE_UNTRAINED_CONFIDENCE',
  trainedAttributePercentage = 'TRAINED_ATTRIBUTE_PERCENTAGE',
  highestAverageAccuracyPercentage = 'HIGHEST_AVERAGE_ACCURACY_PERCENTAGE',
  lowestAverageAccuracyPercentage = 'LOWEST_AVERAGE_ACCURACY_PERCENTAGE',
  integrationCount = 'INTEGRATION_COUNT',
  uniqueAlarmCount = 'UNIQUE_ALARM_COUNT',
  userInputCount = 'USER_INPUT_COUNT',
  trainingSetUploadCount = 'TRAINING_SET_UPLOAD_COUNT',
}

export enum ParserAccuracyDrilldownSort {
  propertyType = 'PROPERTY_TYPE',
  averageAccuracy = 'AVERAGE_ACCURACY',
  averageCnnAccuracy = 'AVERAGE_CNN_ACCURACY',
  averageBertAccuracy = 'AVERAGE_BERT_ACCURACY',
  averageLevenshteinAccuracy = 'AVERAGE_LEVENSHTEIN_ACCURACY',
  averageUntrainedConfidence = 'AVERAGE_UNTRAINED_CONFIDENCE',
  trainedAttributePercentage = 'TRAINED_ATTRIBUTE_PERCENTAGE',
  blankAttributePercentage = 'BLANK_ATTRIBUTE_PERCENTAGE',
  userInputCount = 'USER_INPUT_COUNT',
  positiveFeedbackCount = 'POSITIVE_FEEDBACK_COUNT',
  negativeFeedbackCount = 'NEGATIVE_FEEDBACK_COUNT',
  uploadedAttributesTrained = 'UPLOADED_ATTRIBUTES_TRAINED',
  createdAt = 'CREATED_AT',
}

export const ParserAccuracyGlobalCustomerDefaultSort = {
  field: ParserAccuracyGlobalCustomerSort.customerName,
  direction: OrderDirection.ASC,
};
export const ParserAccuracyGlobalAttributeDefaultSort = {
  field: ParserAccuracyGlobalAttributeSort.propertyType,
  direction: OrderDirection.ASC,
};

export enum HighestLowestNamePercentPercentage {
  lowestAccuracyNamePercent = 'lowestAverageAccuracyPercentage',
  highestAccuracyNamePercent = 'highestAverageAccuracyPercentage',
}

export const EDIT_INTEGRATION_FIELDS = [
  {
    formName: 'isExternalAlertsEnabled',
    name: 'Enable external status alerts',
    required: false,
  },
  {
    formName: 'hasProcessVariables',
    name: 'Enable process variables in Alarm Instance History',
    required: false,
  },
  {
    formName: 'isAutomationRuleCmmsSyncEnabled',
    name: 'Allow CMMS sync on automation rules',
    required: false,
  },
  {
    formName: 'externalAlertEmails',
    name: 'External Email Alerts',
    required: false,
  },
  {
    formName: 'alarmThroughputEmailThreshold',
    name: 'Alarm Throughput Email Thresholdd',
    required: false,
  },
  {
    formName: 'lastAccessOnSlackThreshold',
    name: 'Last Access on Slack Threshold',
    required: false,
  },
  {
    formName: 'lastAccessOnResolutionThreshold',
    name: 'Last Access on Resolution Threshold',
    required: false,
  },
  {
    formName: 'etlNormalizeVersion',
    name: 'Etl Normalizer Version',
    required: false,
  },
  {
    formName: 'etlIgnorableStates',
    name: 'ETL Ignorable States',
    required: false,
  },
  {
    formName: 'etlTimestampOffset',
    name: 'ETL Timestamp Offset',
    required: false,
  },
  {
    formName: 'cmmsWorkTicketSyncThreshold',
    name: 'Cmms Work Ticket Sync Threshold',
    required: false,
  },
  {
    formName: 'isCloseWorkButtonEnabled',
    name: 'Show Close option on work ticket',
    required: false,
  },
  {
    formName: 'isCmmsPollingBackSyncEnabled',
    name: 'Sync external work changes to internal work tickets',
    required: false,
  },
];
