import { ExternalSystemsState } from './external-systems/external-systems.state';
import { LensState } from './lens/lens.state';
import { AuthState } from './auth/auth.state';
import { CreateWorkState } from './create-work/create-work.state';
import { GlobalFiltersState } from './global-filters/global-filters.state';
import { NgModule } from '@angular/core';
import { NgxsModule, Store } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';

import { CoreState, ResetCore } from './core.state';
import { AlarmsState } from './alarms/alarms.state';
import { UsersState } from './users/users.state';
import { environment } from '@vfi-ui/environments/environment';
import { CustomersState } from './customers/customers.state';
import { WorkTicketsState } from './work-tickets/work-tickets.state';
import { TeamsState } from './teams/teams.state';

@NgModule({
  imports: [
    NgxsModule.forRoot(
      [
        AlarmsState,
        CoreState,
        GlobalFiltersState,
        CreateWorkState,
        UsersState,
        ExternalSystemsState,
        AuthState,
        CustomersState,
        WorkTicketsState,
        LensState,
        TeamsState,
      ],
      {
        developmentMode: !environment.production,
      }
    ),
    NgxsLoggerPluginModule.forRoot({
      disabled: environment.disableLogger,
    }),
    NgxsStoragePluginModule.forRoot({
      keys: ['core'],
    }),
    NgxsRouterPluginModule.forRoot(),
    NgxsFormPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production,
      maxAge: 25, // keep last 25 states
    }),
  ],
})
export class StateModule {
  constructor(private store: Store) {
    // reset Core state so we dont get any dirty state from previous itterations
    this.store.dispatch(ResetCore);
  }
}
