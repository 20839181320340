import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, catchError, filter, map, tap, throwError } from 'rxjs';
import { NotificationService } from './notification.service';
import {
  GET_MAP_VIEW_BUILDING_ASSETS_QUERY,
  UPDATE_ASSET_GEO_JSON_MUTATION,
} from '../queries';
import {
  ERROR_GET_MAP_VIEW_BUILDING_ASSETS,
  MapViewBuildingAsset,
} from '@vfi-ui/models';
import { HttpClient } from '@angular/common/http';
import { environment } from '@vfi-ui/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MapViewDataService {
  constructor(
    private apollo: Apollo,
    private http: HttpClient,
    private notification: NotificationService
  ) {}

  /**
   * Fetches a list of external system commands.
   *
   * @returns An observable that emits the command results
   * @memberof MapViewDataService
   */
  getMapViewBuildingAssets(): Observable<MapViewBuildingAsset[]> {
    return this.apollo
      .query<{
        getMapViewBuildingAssets: MapViewBuildingAsset[];
      }>({
        fetchPolicy: 'no-cache',
        query: GET_MAP_VIEW_BUILDING_ASSETS_QUERY,
        variables: {},
      })
      .pipe(
        filter((d) => !!d),
        catchError((error) => {
          this.notification.showError(ERROR_GET_MAP_VIEW_BUILDING_ASSETS);
          return throwError(() => error);
        }),
        map((res) => res?.data?.getMapViewBuildingAssets)
      );
  }
  /**
   * Updates asset geo json
   *
   * @param {number} entityId
   * @param {object} geoJSON
   * @return {*}
   * @memberof MapViewDataService
   */
  updateAssetGeoJson(entityId: number, geoJSON: object) {
    return this.apollo
      .mutate({
        mutation: UPDATE_ASSET_GEO_JSON_MUTATION,
        variables: { entityId, geoJSON },
      })
      .pipe(
        tap(() => {
          this.notification.showSuccess('Asset', 'Location updated');
        })
      );
  }

  /**
   * Gets geo json given an address
   *
   * @param {string} address
   * @return {*}
   * @memberof MapViewDataService
   */
  getGeoJson(address: string) {
    const url = new URL('https://api.mapbox.com/search/geocode/v6/forward');
    url.searchParams.append('access_token', environment.mapBoxToken);
    url.searchParams.append('q', address);
    url.searchParams.append('country', 'US');
    url.searchParams.append('types', 'address');
    return this.http.get(url.href);
  }
}
