import { tap, catchError, filter } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@vfi-ui/environments/environment';
import { Observable, throwError } from 'rxjs';
import {
  SimulatorMetaContent,
  ERROR_UPLOAD_CSV,
  ERROR_DOWNLOAD_CSV,
} from '@vfi-ui/models';
import { downloadFile } from '@vfi-ui/util/helpers';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root',
})
export class SimulatorDataService {
  constructor(
    private http: HttpClient,
    private notification: NotificationService
  ) {}

  /**
   * get meta details for the simulator
   *
   * @returns {Observable<SimulatorMetaContent>}
   * @memberof SimulatorDataService
   */
  metaDetails(): Observable<SimulatorMetaContent> {
    return this.http.get<SimulatorMetaContent>(
      `${environment.backend}/alarm-simulator/detail`
    );
  }

  /**
   * download last upload simulator csv file
   *
   * @returns
   * @memberof SimulatorDataService
   */
  downloadCSV() {
    return this.http
      .get(`${environment.backend}/alarm-simulator/download`, {
        responseType: 'arraybuffer',
        observe: 'response',
      })
      .pipe(
        filter((d) => !!d),
        tap((res) => {
          let fileName = '';
          try {
            fileName = res.headers
              .get('content-disposition')
              .replace('attachment; filename=', '');
          } catch (error) {
            fileName = 'simulator.csv';
          }

          const file = new Blob([res.body], {
            type: 'application/gzip',
          });
          downloadFile(file, fileName);
        }),
        catchError((error) => {
          this.notification.showError(ERROR_DOWNLOAD_CSV);
          return throwError(error);
        })
      );
  }

  /**
   * upload csv
   *
   * @param {Blob} blob
   * @param {number} customerId
   * @returns
   * @memberof SimulatorDataService
   */
  uploadCSV(blob: File) {
    const formData = new FormData();
    formData.set('file', blob);
    return this.http
      .post<{
        message: string;
        success: boolean;
      }>(`${environment.backend}/alarm-simulator/upload`, formData)
      .pipe(
        filter((d) => !!d),
        tap((msg) => {
          this.notification.showSuccess(
            'Upload Successful',
            msg ? msg.message : `${blob.name} upload successful`
          );
        }),
        catchError((err) => {
          this.notification.showError(ERROR_UPLOAD_CSV);
          return throwError(err);
        })
      );
  }

  /**
   * change the simulator status
   *
   * @param {boolean} status
   * @returns {Observable<SimulatorMetaContent>}
   * @memberof SimulatorDataService
   */
  simStatusChange(status: boolean): Observable<SimulatorMetaContent> {
    return this.http.post<SimulatorMetaContent>(
      `${environment.backend}/alarm-simulator/enabled`,
      {
        enabled: status,
      }
    );
  }
}
