import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
} from '@angular/core';
import { getCurrentYear, isNil } from '@vfi-ui/util/helpers';
import { ICurrentConfig } from 'cordova-plugin-ionic/dist/ngx/IonicCordova';
import { DeployClass } from 'cordova-plugin-ionic';
import { CAPACITOR_DEPLOY_TOKEN } from '@vfi-ui/models';

@Component({
  selector: 'vfi-auth-version-selector',
  templateUrl: './version-selector.component.html',
  styleUrls: ['./version-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class VersionSelectorComponent implements OnInit {
  selectedConfig: ICurrentConfig;
  currentYear = getCurrentYear();
  isNil = isNil;
  channels = ['Dev', 'QA', 'Stage', 'Production'];
  selectedChannel = 'QA';
  downloadStatus: number;
  extractStatus: number;
  keys = Object.keys;

  constructor(
    @Inject(CAPACITOR_DEPLOY_TOKEN) public readonly deploy: DeployClass
  ) {}

  ngOnInit(): void {
    this.fetchConfiguration();
  }
  /**
   * fetch current configuration from ionic deploy
   *
   * @memberof VersionSelectorComponent
   */
  async fetchConfiguration() {
    this.selectedConfig = await this.deploy.getConfiguration();
    this.selectedChannel = this.selectedConfig?.channel;
  }

  /**
   * Invoked when a new channel is selected.
   *
   * @param {ISnapshotInfo} selectedChannel - The selected channel
   * @memberof VersionSelectorComponent
   */
  async selectChannel() {
    try {
      await this.configureDeploy(this.selectedChannel);
      await this.performManualUpdate();
    } catch (error) {
      console.error(error);
    }
  }
  /**
   * perform manual update by downloaing and extracting the files
   *
   * @memberof VersionSelectorComponent
   */
  async performManualUpdate() {
    const update = await this.deploy.checkForUpdate();
    if (update.available) {
      await this.deploy.downloadUpdate((progress) => {
        this.downloadStatus = progress;
      });
      await this.deploy.extractUpdate((progress) => {
        this.extractStatus = progress;
      });
    }
    this.downloadStatus = 0;
    this.extractStatus = 0;
    await this.fetchConfiguration();
    await this.deploy.reloadApp();
  }
  /**
   * change configuration channel of ionic app
   *
   * @param { string } channel
   * @memberof VersionSelectorComponent
   */
  async configureDeploy(channel: string) {
    const config = {
      appId: 'c1bcd07d',
      channel,
    };
    await this.deploy.configure(config);
  }
}
