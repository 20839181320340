import { UntypedFormGroup } from '@angular/forms';
import { EntityWorkTicketInput, WorkTicket, Tile } from '@vfi-ui/models';
export class ToggleCreateWork {
  public static readonly type = '[Create Work] Toggle create work drawer';
  constructor(public payload?: boolean) {}
}

export class SaveCreateWorkDetailsForm {
  public static readonly type = '[Create Work] Save create work details form';
  constructor(public payload: UntypedFormGroup) {}
}
export class AddAssociatedAlarm {
  public static readonly type = '[Create Work] Add an assoicated alarm';
  constructor(public payload: Tile[]) {}
}
export class AddMultiAssociatedAlarm {
  public static readonly type = '[Create Work] Add multiple assoicated alarm';
}
export class AddMultiAssociatedTableAlarm {
  public static readonly type =
    '[Create Work] Add multiple assoicated table alarm';
  constructor(public payload?: number[]) {}
}
export class RemoveAssociatedAlarm {
  public static readonly type = '[Create Work] Remove an assoicated alarm';
  constructor(public payload: string | number) {}
}
export class ResetCreateWork {
  public static readonly type =
    '[Create Work] reset create work ticket to defaults';
  constructor() {}
}
export class ToggleControlCenter {
  public static readonly type = '[Create Work] toggle control center option';
  constructor(public payload: boolean) {}
}
export class AddAssociatedEntities {
  public static readonly type = '[Create Work] Add an associated entity';
  constructor(public payload: EntityWorkTicketInput[]) {}
}
export class ToggleReissueWork {
  public static readonly type = '[Create Work] toggle reissue work';
  constructor(public payload: boolean) {}
}
export class SetReissueWorkTicket {
  public static readonly type = '[Create Work] Set reissue work ticket';
  constructor(public payload: WorkTicket) {}
}
export class ToggleIsWorkCreated {
  public static readonly type = '[Create Work] toggle is work created';
  constructor(public payload: boolean) {}
}
