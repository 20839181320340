import {
  AlarmInstanceReportGroupBySort,
  ALARM_INSTANCE_REPORT_SORT_VALUE,
} from '../interfaces/alarm-instance-report.interface';

export const ALARM_INSTANCE_REPORT_PRIORITY_GROUP_BY_SORT_OPTION = [
  { label: 'Priority', value: AlarmInstanceReportGroupBySort.Priority },
];

export const ALARM_INSTANCE_REPORT_MAX_DAYS_RANGE = 14;
export const ALARM_INSTANCE_REPORT_LIMIT = 50000;
export const ALARM_IMPACT_INFINITE_SCROLL_PERCENT_THRESHOLD = 90;

export const ALARM_INSTANCE_REPORT_WORK_ICON_SORT_MAP = new Map()
  .set('MULTIPLE_WORK', 5)
  .set('NO_WORK', 4)
  .set('OPEN_WORK', 3)
  .set('GOOD_CLOSED_WORK', 2)
  .set('BAD_CLOSED_WORK', 1);

export const ALARM_INSTANCE_REPORT_DYNAMIC_COLUMNS_BLACKLIST = [
  'space_type',
  'equipment',
  'instrument_type',
];

export const ALARM_INSTANCE_REPORT_DEFAULT_COLUMNS = [
  ALARM_INSTANCE_REPORT_SORT_VALUE.alarmTime,
  'building',
  ALARM_INSTANCE_REPORT_SORT_VALUE.contextualName,
  ALARM_INSTANCE_REPORT_SORT_VALUE.endTime,
  ALARM_INSTANCE_REPORT_SORT_VALUE.duration,
  ALARM_INSTANCE_REPORT_SORT_VALUE.associatedWorkTicketIds,
];

export const ALARM_INSTANCE_REPORT_INSTANCE_PER_HOUR_FILTERS = {
  columns: ['isChattering', 'isFleeting'],
  filters: {
    isNuisance: {
      values: ['true'],
      filterType: 'set',
    },
  },
  group: 'alarmId',
};

export const ALARM_INSTANCE_REPORT_INSTANCE_PER_HOUR_TABLE_FILTERS = {
  columns: ['duration'],
  group: 'alarmId',
};

export const ALARM_INSTANCE_REPORT_WORK_COVERAGE_FILTERS = {
  filters: {
    isCovered: {
      values: ['true'],
      filterType: 'set',
    },
  },
  group: null,
};

export const ALARM_INSTANCE_REPORT_CONTEXT_MENU_BLACKLIST = [
  'workResolutionState',
  'bell',
  'priority',
  'alarmTime',
  'endTime',
  'duration',
  'isFleeting',
  'isChattering',
  'isNuisance',
  'isCovered',
  'coveredByName',
  'coveredSource',
  'associatedWorkTickets',
  'isActive',
  'basPriority',
  'ackTime',
  'isServiceImpacting',
  'isStale',
];

export const ALARM_INSTANCE_REPORT_TABLE_COLUMNS = [
  {
    name: 'Alarm Time',
    value: ALARM_INSTANCE_REPORT_SORT_VALUE.alarmTime,
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Alarm Name',
    value: ALARM_INSTANCE_REPORT_SORT_VALUE.contextualName,
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'End Time (RTN)',
    value: ALARM_INSTANCE_REPORT_SORT_VALUE.endTime,
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Time in Alarm',
    value: ALARM_INSTANCE_REPORT_SORT_VALUE.duration,
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Work',
    value: ALARM_INSTANCE_REPORT_SORT_VALUE.associatedWorkTicketIds,
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Alarm ID',
    value: ALARM_INSTANCE_REPORT_SORT_VALUE.alarmId,
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Alarm Source',
    value: ALARM_INSTANCE_REPORT_SORT_VALUE.externalSystemDisplayName,
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Asset Class',
    value: ALARM_INSTANCE_REPORT_SORT_VALUE.assetClass,
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Asset Type',
    value: ALARM_INSTANCE_REPORT_SORT_VALUE.assetType,
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Asset ID',
    value: ALARM_INSTANCE_REPORT_SORT_VALUE.assetId,
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Asset Criticality',
    value: ALARM_INSTANCE_REPORT_SORT_VALUE.assetCriticality,
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Priority',
    value: ALARM_INSTANCE_REPORT_SORT_VALUE.priority,
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },

  {
    name: 'Raw Text',
    value: ALARM_INSTANCE_REPORT_SORT_VALUE.text,
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'End Time Source',
    value: ALARM_INSTANCE_REPORT_SORT_VALUE.endTimeSource,
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Chattering',
    value: ALARM_INSTANCE_REPORT_SORT_VALUE.isChattering,
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Fleeting',
    value: ALARM_INSTANCE_REPORT_SORT_VALUE.isFleeting,
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Nuisance',
    value: ALARM_INSTANCE_REPORT_SORT_VALUE.isNuisance,
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Has Work',
    value: ALARM_INSTANCE_REPORT_SORT_VALUE.isCovered,
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Work Creator',
    value: ALARM_INSTANCE_REPORT_SORT_VALUE.coveredBy,
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Work Source',
    value: ALARM_INSTANCE_REPORT_SORT_VALUE.coveredSource,
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Work Ticket State',
    value: ALARM_INSTANCE_REPORT_SORT_VALUE.workTicketState,
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Work Assignee',
    value: ALARM_INSTANCE_REPORT_SORT_VALUE.workAssignee,
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Work Created Time',
    value: ALARM_INSTANCE_REPORT_SORT_VALUE.workCreatedTime,
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Work Closed Time',
    value: ALARM_INSTANCE_REPORT_SORT_VALUE.workClosedTime,
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Resolution',
    value: ALARM_INSTANCE_REPORT_SORT_VALUE.resolution,
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Alarm Status',
    value: ALARM_INSTANCE_REPORT_SORT_VALUE.isActive,
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'BAS Priority',
    value: ALARM_INSTANCE_REPORT_SORT_VALUE.basPriority,
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Ack Time',
    value: ALARM_INSTANCE_REPORT_SORT_VALUE.ackTime,
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Ack By',
    value: ALARM_INSTANCE_REPORT_SORT_VALUE.ackBy,
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Service Failure Alarm',
    value: ALARM_INSTANCE_REPORT_SORT_VALUE.isServiceImpacting,
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Space Type',
    value: ALARM_INSTANCE_REPORT_SORT_VALUE.spaceType,
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Space Name',
    value: ALARM_INSTANCE_REPORT_SORT_VALUE.spaceName,
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Stale Status',
    value: ALARM_INSTANCE_REPORT_SORT_VALUE.isStale,
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Equipment Name',
    value: ALARM_INSTANCE_REPORT_SORT_VALUE.equipmentName,
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
];
