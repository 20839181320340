import { environment } from '@vfi-ui/environments/environment';
import { adminTabRoutes, Customers } from '@vfi-ui/models';
import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';

@Component({
  selector: 'nuclei-admin-tabs-main',
  templateUrl: './admin-tabs-main.component.html',
  styleUrls: ['./admin-tabs-main.component.scss'],
})
export class AdminTabsMainComponent implements OnChanges {
  @Input() url: string;
  @Input() listRoute: string;
  @Input() dashRoute: string;
  @Input() analyticsGoalsRoute: string;
  @Input() currentCustomer: Customers;
  @Input() showCmmsConfig = false;
  baseUrl: string;
  environment = environment;
  adminRoutes = adminTabRoutes;
  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.currentCustomer && changes.currentCustomer.currentValue) {
      this.baseUrl = `/${this.currentCustomer.name}/${this.currentCustomer.id}`;
    }
  }

  /**
   * show active status based off url
   *
   * @param {string} url
   * @param {string} type
   * @returns {boolean}
   * @memberof AlarmTabsMainComponent
   */
  isActive(url: string, type: string): boolean {
    return url.includes(type);
  }
}
