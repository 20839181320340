import { WorkTicketSortField } from './work-ticket.constant';
export const MaxWorkOrdersLimit = 30;
export const WorkOrderClosedIcon = 'far fa-clipboard-list-check';
export const WorkOrderOpenIcon = 'fas fa-clipboard-list-check';
export const WorkOrderAlamCountIcon = 'fa-bell';
export const DefaultWorkOrderSort = {
  sortValue: 'Created Date',
  sortDbName: WorkTicketSortField.CREATED_AT,
};

export const WorkOrderFields = `
  id
  apiId
  shop
  status
  timeEntered
  timeClosed
  alarms_aggregate {
    aggregate {
      count
    }
  }
`;

export const allWorkOrderFields = `
  description
  building
  unit
  shop
  status
  category
  type
  priority
  timeScheduled
  timeRequired
  phone
  campus
  floor
  room
  equipmentType
  equipment
  maintenance
  notes
  interimMeasure
  billable
  posted
  timeEntered
  timeReceived
  timePosted
  timeStarted
  timeCompleted
  timeClosed
  source
  id
  apiId
  alarmCount
  alarms {
    id
    contextualName
    rooms {
      name
    }
    priority
    floors {
      name
    }
    databaseId
    buildings {
      name
    }
  }
  isClosed`;

export const requiredFieldNames = {
  building: { displayName: 'Building', name: 'building' },
  unit: { displayName: 'Unit', name: 'unit' },
  shop: { displayName: 'Shop', name: 'shop' },
  status: { displayName: 'Work Status', name: 'status' },
  category: { displayName: 'Work Category', name: 'category' },
  type: { displayName: 'Work Type', name: 'type' },
  priority: { displayName: 'Priority', name: 'priority' },
  timeScheduled: { displayName: 'Time Scheduled', name: 'timeScheduled' },
  timeRequired: { displayName: 'Time Required', name: 'timeRequired' },
  phone: { displayName: 'Phone', name: 'phone' },
  description: { displayName: 'Description', name: 'description' },
};

export const WorkOrderRulesFields = `
  description
  building
  unit
  shop
  status
  category
  type
  priority
  timeScheduled
  timeRequired
  phone
  campus
  floor
  room
  equipmentType
  equipment
  maintenance
  notes
  billable
  interimMeasure
`;
