import { Customers } from '@vfi-ui/models';

export class GetAllCustomers {
  public static readonly type = '[Customers] Load customers';
  constructor(public payload?: { customers: Customers[] }) {}
}
export class SetCurrentCustomers {
  public static readonly type = '[Customers] Load current customers';
  constructor(public payload: { customers: Customers }) {}
}
export class UpdateCurrentCustomer {
  public static readonly type = '[Customers] Update current customer';
  constructor(public payload: { customers: Partial<Customers> }) {}
}
export class SetKpiEnabled {
  public static readonly type = '[Customers] Set KPI dashboard enabled';
  constructor(public payload: boolean) {}
}
export class SetUpdateStatusReasonRequired {
  public static readonly type = '[Customers] Set Update Status Reason Required';
  constructor(public payload: boolean) {}
}
