import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import {
  GET_SAVED_VIEWS_QUERY,
  CREATE_SAVED_VIEW_MUTATION,
  DELETE_SAVED_VIEW_MUTATION,
  UPDATE_SAVED_VIEW_MUTATION,
} from '../queries/saved-views.query';
import {
  SavedViewsViewType,
  SavedViewOptions,
  SavedView,
  SavedViewCreateInput,
} from '@vfi-ui/models';
import { Observable, catchError, filter, map, tap, throwError } from 'rxjs';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root',
})
export class SavedViewsDataService {
  constructor(
    private apollo: Apollo,
    private notification: NotificationService
  ) {}

  /**
   * fetches saved views for a given view type
   * @param {SavedViewsViewType} viewType
   * @returns {Observable<SavedView[]>}
   * @memberof SavedViewsDataService
   */
  getSavedViews(viewType: SavedViewsViewType): Observable<SavedView[]> {
    const input: SavedViewOptions = {
      where: {
        viewType,
      },
    };
    return this.apollo
      .query<{ savedViews: SavedView[] }>({
        fetchPolicy: 'no-cache',
        query: GET_SAVED_VIEWS_QUERY,
        variables: {
          input,
        },
      })
      .pipe(
        filter((d) => !!d),
        map((r) => r?.data?.savedViews),
        catchError((error) => throwError(error))
      );
  }

  /**
   * creates a saved view
   * @param {{
   *    name: string;
   *    viewType: SavedViewsViewType;
   *    criteria: string;
   *    isDefault: boolean;
   *  columns: any;
   * }}
   * @returns {Observable<SavedView>}
   * @memberof SavedViewsDataService
   */
  createSavedView({
    name,
    viewType,
    criteria,
    isDefault,
    columns,
  }: {
    name: string;
    viewType: SavedViewsViewType;
    criteria: object;
    isDefault: boolean;
    columns: any;
  }): Observable<SavedView> {
    const input: SavedViewCreateInput = {
      name,
      viewType,
      criteria,
      isDefault,
      columns,
    };
    return this.apollo
      .mutate<{ createSavedView: SavedView }>({
        mutation: CREATE_SAVED_VIEW_MUTATION,
        variables: {
          input,
        },
      })
      .pipe(
        filter((d) => !!d),
        map((r) => r?.data?.createSavedView),
        catchError((error) => throwError(error)),
        tap(() => {
          this.notification.showSuccess(
            `Saved View Created.`,
            `${name} is saved.`
          );
        })
      );
  }

  /**
   * deletes a saved view
   * @param {string} id
   * @returns {Observable<boolean>}
   * @memberof SavedViewsDataService
   */
  deleteSavedView(id: string, name?: string): Observable<boolean> {
    return this.apollo
      .mutate<{ deleteSavedView: boolean }>({
        mutation: DELETE_SAVED_VIEW_MUTATION,
        variables: {
          id,
        },
      })
      .pipe(
        filter((d) => !!d),
        map((r) => r?.data?.deleteSavedView),
        catchError((error) => throwError(error)),
        tap(() => {
          this.notification.showSuccess(
            `Saved View deleted.`,
            `${name} is deleted.`
          );
        })
      );
  }

  /**
   * updates a saved view
   * @param {string} id
   * @param {{
   *   name: string;
   *  viewType: SavedViewsViewType;
   * criteria: string;
   * isDefault: boolean;
   * columns: any;
   * }} opts
   * @returns {Observable<SavedView>}
   * @memberof SavedViewsDataService
   */
  updateSavedView(
    id: string,
    {
      name,
      criteria,
      isDefault,
      columns,
    }: {
      name: string;
      criteria: object;
      isDefault: boolean;
      columns: any;
    }
  ): Observable<SavedView> {
    const input: SavedViewCreateInput = {
      name,
      criteria,
      isDefault,
      columns,
    };
    return this.apollo
      .mutate<{ updateSavedView: SavedView }>({
        mutation: UPDATE_SAVED_VIEW_MUTATION,
        variables: {
          id,
          input,
        },
      })
      .pipe(
        filter((d) => !!d),
        map((r) => r?.data?.updateSavedView),
        catchError((error) => throwError(error)),
        tap(() => {
          this.notification.showSuccess(
            `Saved View updated.`,
            `${name} is updated.`
          );
        })
      );
  }
}
