import { NotificationService } from './notification.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@vfi-ui/environments/environment';
import { catchError, filter } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { ReleaseVersion, ERROR_GET_RELEASE_INFO } from '@vfi-ui/models';

@Injectable({
  providedIn: 'root',
})
export class InfoDataService {
  constructor(
    private readonly http: HttpClient,
    private notification: NotificationService
  ) {}

  /**
   * get release info -version and release date
   *
   * @returns
   * @memberof InfoDataService
   */
  getReleaseInfo(): Observable<ReleaseVersion> {
    return this.http
      .get<ReleaseVersion>(`${environment.backend}/info/version`)
      .pipe(
        filter((d) => !!d),
        catchError((error) => {
          this.notification.showError(ERROR_GET_RELEASE_INFO);
          return throwError(error);
        })
      );
  }
}
