import { BaseComponent } from '@vfi-ui/feature/core';
import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { Entity, SearchEntityResult } from '@vfi-ui/models';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'atom-multi-level-selector',
  templateUrl: './multi-level-selector.component.html',
  styleUrls: ['./multi-level-selector.component.scss'],
})
export class MultiLevelSelectorComponent
  extends BaseComponent
  implements OnInit
{
  @Input() placeholder: string;
  @Input() pointEntity: Entity[];
  @Input() pointEntityValue: SearchEntityResult;
  @Input() value: string;
  @Input() reset: Subject<boolean>;
  @Input() disabled = false;
  @Output() valueChange = new EventEmitter<SearchEntityResult>();
  showMultiLevelSelect = false;

  ngOnInit() {
    if (this.reset) {
      this.reset.pipe(takeUntil(this.componentDestroyed)).subscribe(() => {
        this.pointEntityValue = null;
        this.pointEntity = null;
      });
    }
  }

  /**
   * emit changes to value
   * @memberof MultiLevelSelectorComponent
   */
  onValueChange() {
    this.valueChange.emit(this.pointEntityValue);
  }
}
