import { Apollo } from 'apollo-angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AdminNavigationComponent } from './admin-navigation/admin-navigation.component';
import { AdminLayoutComponent } from './admin-layout/admin-layout.component';

import { DragulaModule } from 'ng2-dragula';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NzDropDownModule,
    DragulaModule.forRoot(),
  ],
  declarations: [AdminNavigationComponent, AdminLayoutComponent],
  exports: [AdminNavigationComponent, AdminLayoutComponent],
  providers: [Apollo, { provide: NZ_I18N, useValue: en_US }],
})
export class FeatureAdminDashboardModule {}
