export const AG_GRID_CONTEXT_MENU_COLUMNS = [
  'building',
  'floor',
  'alarmAssetName',
  'openWorkTickets',
  'contextualName',
  'id',
  'componentName',
  'equipmentName',
  'text',
  'spaceName',
  'systemName',
  'name',
  'room',
  'roomName',
  'roomNumber',
];

// AG-Grid duration filter constants
export enum AgGridDuration {
  GREATER_THAN = 'Greater than',
  LESS_THAN = 'Less than',
}
export const AG_GRID_DURATION_OPTIONS = [
  AgGridDuration.GREATER_THAN,
  AgGridDuration.LESS_THAN,
];
export enum AgGridDurationUnit {
  HOURS = 'Hours',
  MINUTES = 'Minutes',
}
export const AG_GRID_DURATION_UNIT_OPTIONS = [
  AgGridDurationUnit.HOURS,
  AgGridDurationUnit.MINUTES,
];

// Work Table
export const DEFAULT_WORK_TABLE_COLUMNS = [
  'id',
  'createdAt',
  'objective',
  'dueAt',
  'assignee',
  'cmmsSyncStatus',
  'isResolved',
];
