import { catchError, filter, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { ASSIGN_ROLE_QUERY, GET_ROLES_QUERY } from '../queries';
import { AuthRoles, ERROR_ASSIGN_ROLES, Roles } from '@vfi-ui/models';
import { NotificationService } from './notification.service';
import { orderBy } from '@vfi-ui/util/helpers';

@Injectable({
  providedIn: 'root',
})
export class RolesDataService {
  constructor(
    private apollo: Apollo,
    private notificationService: NotificationService
  ) {}

  /**
   * fetch all roles
   *
   * @returns {Observable<Roles[]>}
   * @memberof RolesDataService
   */
  getRoles(): Observable<Roles[]> {
    return this.apollo
      .query<{ getRoles: Roles[] }>({
        fetchPolicy: 'no-cache',
        query: GET_ROLES_QUERY,
      })
      .pipe(
        filter((d) => !!d),
        map((r) => {
          const roles = r?.data?.getRoles;
          const customRoles = orderBy(
            roles.filter((res) => res.role === AuthRoles.custom),
            ['name'],
            ['asc']
          );
          const systemRoles = roles.filter(
            (res) => res.role !== AuthRoles.custom
          );
          return [...systemRoles, ...customRoles];
        }),
        catchError((error) => throwError(error))
      );
  }

  /**
   * assign roles
   *
   * @param {string[]} userIds
   * @param {AuthRoles} role
   * @returns
   * @memberof RolesDataService
   */
  assignRoles(userIds: string[], role: Roles) {
    return this.apollo
      .mutate({
        mutation: ASSIGN_ROLE_QUERY,
        variables: {
          userIds,
          role: role.role,
          customRoleId: role.customRoleId,
        },
      })
      .pipe(
        filter((d) => !!d),
        tap(() => this.notificationService.showSuccess('Assigned roles')),
        catchError((error) => {
          this.notificationService.showError(ERROR_ASSIGN_ROLES);
          return throwError(error);
        })
      );
  }
}
