import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Select, Store } from '@ngxs/store';
import {
  CriterionSelection,
  FastWorkTicket,
  LensParent,
  LensType,
  MenuLens,
  tabRoutes,
  TileType,
  WORK_FILTERS_CRITERION,
} from '@vfi-ui/models';
import {
  GlobalFiltersState,
  LensState,
  LoadWorkTickets,
  ResetOnlyCoreCriterionFilters,
  ResetWorkTickets,
  SetCoreGlobalFilters,
  SetCoreSortValue,
  SetCurrentlySelectedLens,
  SetGlobalCurrentLens,
  SetSort,
  SetSortFilterType,
  TotalResetGlobalFilters,
  WorkTicketsState,
  WorkTicketsStateModel,
} from '@vfi-ui/state';
import {
  fastParse,
  fetchLensTeamName,
  head,
  isEmpty,
  isNil,
  set,
} from '@vfi-ui/util/helpers';
import { Observable } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { get } from '@vfi-ui/util/helpers';

@Injectable({
  providedIn: 'root',
})
export class WorkResolver {
  @Select(WorkTicketsState.getState)
  workTicketState$: Observable<WorkTicketsStateModel>;
  @Select(LensState.getLenses) lenses$: Observable<MenuLens[]>;

  constructor(
    private store: Store,
    private router: Router
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    lensParent: LensParent,
    isMobile: boolean
  ): Observable<FastWorkTicket[]> {
    const allWorkSort = state.url.includes(tabRoutes.ALL_WORK);
    const workType: TileType = allWorkSort ? 'allWork' : 'activeWork';
    this.store.dispatch(new SetSortFilterType(workType));
    const lens = route.paramMap.get('lens');
    const lensId = route.paramMap.get('lensId');
    const parent = head(route.url).path;
    const team = route.paramMap.get('team');

    return this.waitforLensToUpdate({
      lensName: parent,
      lensId,
      lensParent,
      team,
      isMobile,
    }).pipe(
      tap((len) => {
        if (len) {
          this.store.dispatch([
            new TotalResetGlobalFilters(false),
            new ResetOnlyCoreCriterionFilters(),
            new ResetWorkTickets(),
          ]);
          this.store.dispatch(new SetCurrentlySelectedLens(len.id));
          this.store.dispatch(
            new SetGlobalCurrentLens({
              parent: parent,
              child: lens,
              parentId: len.id,
              type: workType,
              childId: '',
              category: len.category,
            })
          );

          const order = Array.isArray(len.criteria.order)
            ? len.criteria.order
            : [len.criteria.order];
          this.store.dispatch(new SetSort(order));

          this.store.dispatch(
            new SetCoreSortValue({
              ...len.displayOptions.sort,
              sortDbName: len.displayOptions.sort.sortDbName,
              sortValue: len.displayOptions.sort.sortValue,
              sortType: len.displayOptions.sort.sortType,
            })
          );

          this.store
            .dispatch(new SetCoreGlobalFilters(this.addDefaultsCriterion(len)))
            .subscribe(() => {
              if (isMobile) {
                this.loadData();
              }
            });
        } else {
          this.router.navigate(['/work']);
        }
      }),
      switchMap(() => this.waitForStateToUpdate())
    );
  }

  waitforLensToUpdate({
    lensName,
    lensId,
    lensParent,
    team,
    isMobile,
  }: {
    lensName: string;
    lensId: string;
    lensParent: LensParent;
    team: string;
    isMobile: boolean;
  }): Observable<MenuLens> {
    const currentLens = isMobile ? lensParent : lensName;
    return this.lenses$.pipe(
      filter(
        (lenses) =>
          lenses.length > 0 &&
          !isEmpty(
            lenses.find(
              (l) => l.name === currentLens && l.type === LensType.WORK
            )
          )
      ),
      map((lenses) =>
        isMobile
          ? lenses.find((x) => x.id === lensId) ||
            lenses.find((y) => y.name === currentLens)
          : lenses.find(
              (y) => y.name === currentLens && fetchLensTeamName(y) === team
            )
      ),
      take(1)
    );
  }

  waitForStateToUpdate(): Observable<FastWorkTicket[]> {
    return this.workTicketState$.pipe(
      map((res) => res?.workTickets),
      filter((state) => !!state),
      take(1)
    );
  }

  loadData(): void {
    this.workTicketState$
      .pipe(
        filter((workState) => {
          const { child } = this.store.selectSnapshot(
            GlobalFiltersState.getLens
          );
          return !workState.tiles.length && !isNil(child);
        }),
        take(1)
      )
      .subscribe(() => {
        this.store.dispatch(new LoadWorkTickets());
      });
  }

  /**
   * add default options for a criterion
   *
   * @private
   * @param {CriterionSelection[]} criterion
   * @returns {CriterionSelection[]}
   * @memberof WorkResolver
   */
  private addDefaultsCriterion(lens: MenuLens): CriterionSelection[] {
    let len = fastParse(lens);
    len = this.setAssigneeCriterion(len);
    return len.displayOptions.criterion.map((criteria) => {
      const values = WORK_FILTERS_CRITERION.find(
        (c) => c.dbName === get(criteria, 'detail.dbName')
      );

      if (values) {
        const details = { ...values, ...criteria.detail };
        return { ...criteria, detail: details };
      }

      return criteria;
    });
  }

  /**
   * set assignee criterion
   *
   * @private
   * @param {MenuLens} len
   * @returns
   * @memberof WorkResolver
   */
  private setAssigneeCriterion(len: MenuLens): MenuLens {
    if (len.name === 'My Work') {
      const index = len?.displayOptions?.criterion.findIndex(
        (r) => r.model === 'assignee'
      );
      const assignee = get(len, 'criteria.where.assignee', '');
      set(len, `displayOptions.criterion[${index}].value`, assignee);
      set(
        len,
        `displayOptions.criterion[${index}].detail.selection.value`,
        assignee
      );
    }
    return len;
  }
}
