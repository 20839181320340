import {
  CriterionSelection,
  TileType,
  AlarmLens,
  filterModelMode,
  GlobalFilterSort,
  LensCategory,
  Sort,
} from '@vfi-ui/models';
export class GlobalFiltersSearch {
  public static readonly type = '[GlobalFilters] search by text';
  constructor(public payload: string) {}
}
export class SetSort {
  public static readonly type = '[GlobalFilters] set sort';
  constructor(public payload: GlobalFilterSort[] | Sort[]) {}
}
export class SetSortValue {
  public static readonly type = '[GlobalFilters] set sort value';
  constructor(public payload: GlobalFilterSort) {}
}
export class SetCoreSortValue {
  public static readonly type = '[GlobalFilters] set default sort value';
  constructor(public payload: GlobalFilterSort) {}
}
export class SetGlobalCurrentLens {
  public static readonly type =
    '[SetGlobalCurrentLens] Set current alarm route';
  constructor(public payload: Partial<AlarmLens>) {}
}
export class SetSortFilterType {
  public static readonly type =
    '[GlobalFilters] set global filters and sort type';
  constructor(public payload: TileType) {}
}

export class ResetGlobalFilters {
  public static readonly type = '[GlobalFilters] reset global filters state';
  constructor(public reset?: boolean) {}
}
export class SetResetFilters {
  public static readonly type =
    '[GlobalFilters] set reset state for global filters';
  constructor(public reset?: boolean) {}
}
export class SetGlobalFilters {
  public static readonly type = '[GlobalFilters] set global fitlers';
  constructor(public payload: CriterionSelection[]) {}
}
export class SetCoreGlobalFilters {
  public static readonly type = '[GlobalFilters] set Core global filters';
  constructor(public payload: CriterionSelection[]) {}
}
export class RemoveCoreGlobalFilters {
  public static readonly type = '[GlobalFilters] remove Core global filters';
  constructor(public payload: CriterionSelection) {}
}

export class RemoveGlobalFilterCriterion {
  public static readonly type =
    '[GlobalFilters] remove single global fitlers criterion';
  constructor(public payload: CriterionSelection) {}
}
export class ToggleFilterModal {
  public static readonly type = '[GlobalFilters] toggle filter model state';
  constructor(public payload: boolean) {}
}
export class ToggleFilterMode {
  public static readonly type =
    '[GlobalFilters] toggle create lens filter mode between lens and filters';
  constructor(public payload: filterModelMode) {}
}
export class ToggleCreateLensFilterMode {
  public static readonly type =
    '[GlobalFilters] toggle create lens filter mode';
  constructor(
    public payload: { mode: filterModelMode; category: LensCategory }
  ) {}
}
export class ToggleSystemLensModal {
  public static readonly type =
    '[GlobalFilters] toggle edit system lens model state';
  constructor(public payload: boolean) {}
}
export class ToggleAlarmMobileActions {
  public static readonly type =
    '[GlobalFilters] toggle Alarm Mobile Actions menu state';
  constructor(public payload: boolean) {}
}

export class TotalResetGlobalFilters {
  public static readonly type =
    '[GlobalFilters] total reset global filters state';
  constructor(public reset?: boolean) {}
}
export class ResetOnlyCriterionFilters {
  public static readonly type =
    '[GlobalFilters] total reset only criterion global filters state';
  constructor() {}
}

export class ResetOnlyCoreCriterionFilters {
  public static readonly type =
    '[GlobalFilters] total reset only core criterion global filters state';
  constructor() {}
}
