import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonIconPosition, ButtonSize, ButtonType } from '@vfi-ui/models';

@Component({
  selector: 'atom-vfi-button',
  templateUrl: './vfi-button.component.html',
  styleUrls: ['./vfi-button.component.scss'],
})
export class VfiButtonComponent {
  @Input() size = ButtonSize.DEFAULT;
  @Input() ariaLabel: string;
  @Input() type = ButtonType.PRIMARY;
  @Input() loading = false;
  @Input() text = '';
  @Input() icon: string;
  @Input() iconStack: string[];
  @Input() iconPosition = ButtonIconPosition.LEFT;
  @Input() disabled = false;
  @Input() classes: string;
  @Output()
  clicked: EventEmitter<boolean> = new EventEmitter();
  buttonIconPosition = ButtonIconPosition;

  constructor() {}
}
