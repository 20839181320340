import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { map, switchMap } from 'rxjs/operators';
import { ZENDESK_BRAND_ID, ZENDESK_Url } from '@vfi-ui/models';

@Injectable({
  providedIn: 'root',
})
export class SsoGuard {
  constructor(private auth: AuthService) {}
  /**
   * determines if user wanted to access zendesk
   * If yes, redirect to zendesk since user is logged in
   *
   * @returns {Observable<boolean>}
   * @memberof SsoGuard
   */
  canActivate(): Observable<boolean | UrlTree> {
    return this.loadUser();
  }

  /**
   * determines if user wanted to access zendesk
   * If yes, redirect to zendesk after logging in
   *
   * @returns {Observable<boolean>}
   * @memberof SsoGuard
   */
  canDeactivate(): Observable<boolean | UrlTree> {
    return this.loadUser();
  }
  /**
   * determines if user wanted to access zendesk
   * If yes, redirect to zendesk after logging in
   *
   * @returns {Observable<boolean>}
   * @memberof SsoGuard
   */
  canActivateChild(): Observable<boolean | UrlTree> {
    return this.loadUser();
  }
  /**
   * call authservice go get zendesk token and redirect user to it
   *
   * @private
   * @returns {Observable<boolean>}
   * @memberof SsoGuard
   */
  private loadUser(): Observable<boolean> {
    return this.auth.getFirebaseUser().pipe(
      switchMap((res) => {
        const hasZendeskID = window.location.search.includes(
          `brand_id=${ZENDESK_BRAND_ID}`
        );
        if (res && hasZendeskID) {
          return this.zendeskSso();
        } else {
          return of(true);
        }
      })
    );
  }
  /**
   * call authservice go get zendesk token and redirect user to it
   *
   * @private
   * @returns {Observable<boolean>}
   * @memberof SsoGuard
   */
  private zendeskSso(): Observable<boolean> {
    return this.auth.getZendeskToken().pipe(
      map((d) => {
        window.location.assign(`${ZENDESK_Url}?jwt=${d}`);
        return false;
      })
    );
  }
}
