import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'integer',
})
export class IntegerPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value === '0' && args) {
      const valueArray = String(args).split('.');
      valueArray[1] = valueArray[1].substring(0, 2);
      const newVal = valueArray.join('.');

      if (newVal === '0.00') {
        return newVal;
      }
      return value;
    }
    return value;
  }
}
