import { Injectable } from '@angular/core';
import {
  Asset,
  AssetPropertyQuery,
  AssetQuery,
  Entity,
  EntityCreateInput,
  EntityUpdateInput,
  ERROR_ASSETS,
  OrderDirection,
} from '@vfi-ui/models';
import { Apollo } from 'apollo-angular';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  CHECK_DUPLICATE_ASSETS_QUERY,
  GET_ASSETS_QUERY,
  GET_ASSET_OPTIONS_QUERY,
  GET_ASSET_PROPERTY_OPTIONS_QUERY,
} from '../queries';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root',
})
export class AssetsDataService {
  constructor(
    private notification: NotificationService,
    private apollo: Apollo
  ) {}

  /**
   * fetch assets
   *
   * @param {*} input
   * @returns {Observable<Asset[]>}
   * @memberof AssetsDataService
   */
  public getAssets(input): Observable<Asset[]> {
    return this.apollo
      .query<{ assets: Asset[] }>({
        fetchPolicy: 'network-only',
        query: GET_ASSETS_QUERY,
        variables: { input },
      })
      .pipe(
        map((res) =>
          res?.data?.assets.map((asset) => ({
            ...asset,
            associatedWorkTickets: asset.associatedWorkTickets?.map(
              (workTicket) =>
                ({
                  displayId: `W${workTicket}`,
                  id: workTicket,
                  isResolved: null,
                  state: 'OPEN',
                  status: 'OPEN',
                }) as any
            ),
          }))
        ),
        catchError((err) => {
          this.notification.showError(ERROR_ASSETS);
          return throwError(err);
        })
      );
  }

  /**
   * fetch asset options
   *
   * @param {string} [nameLike='']
   * @param {number} [offset=0]
   * @param {string} type
   * @returns {Observable<AssetQuery>}
   * @memberof AssetsDataService
   */
  public getAssetOptions(
    nameLike = '',
    offset = 0,
    type: string
  ): Observable<AssetQuery> {
    const where = { nameLike: `%${nameLike}%` };
    if (type) {
      Object.assign(where, { type: [type] });
    }
    return this.apollo
      .query<AssetQuery>({
        fetchPolicy: 'network-only',
        query: GET_ASSET_OPTIONS_QUERY,
        variables: {
          input: {
            limit: 50,
            offset,
            order: { field: 'ID', direction: OrderDirection.ASC },
            where,
            isUniqueNamesOnly: true,
          },
        },
      })
      .pipe(
        map((res) => res?.data),
        catchError((err) => {
          this.notification.showError(ERROR_ASSETS);
          return throwError(err);
        })
      );
  }
  /**
   * Gets the asset property options
   *
   * @param {string} [nameLike='']
   * @param {number} [offset=0]
   * @param {string} type
   * @return {*}  {Observable<AssetQuery>}
   * @memberof AssetsDataService
   */
  public getAssetPropertyOptions(
    valueLike = '',
    offset = 0,
    type: string
  ): Observable<AssetPropertyQuery> {
    const where = { valueLike: `%${valueLike}%`, type, isUniqueValue: true };
    return this.apollo
      .query<AssetPropertyQuery>({
        fetchPolicy: 'network-only',
        query: GET_ASSET_PROPERTY_OPTIONS_QUERY,
        variables: {
          input: {
            limit: 50,
            offset,
            order: { field: 'VALUE', direction: OrderDirection.ASC },
            where,
          },
        },
      })
      .pipe(
        map((res) => res?.data),
        catchError((err) => {
          this.notification.showError(ERROR_ASSETS);
          return throwError(err);
        })
      );
  }

  /**
   * fetch all assets
   *
   * @returns {Observable<Asset[]>}
   * @memberof AssetsDataService
   */
  public getAllAssets(): Observable<Asset[]> {
    return this.apollo
      .query<{ assets: Asset[] }>({
        fetchPolicy: 'network-only',
        query: GET_ASSETS_QUERY,
      })
      .pipe(
        map((res) => res?.data?.assets),
        catchError((err) => {
          this.notification.showError(ERROR_ASSETS);
          return throwError(err);
        })
      );
  }

  /**
   * check for duplicate assets
   *
   * @param {{ entityId?: number, createInput?: Partial<EntityCreateInput>, updateInput?: Partial<EntityUpdateInput> }} { entityId, createInput, updateInput }
   * @returns {Observable<Entity>}
   * @memberof AssetsDataService
   */
  public checkForDuplicateAssets({
    entityId,
    createInput,
    updateInput,
  }: {
    entityId?: number;
    createInput?: Partial<EntityCreateInput>;
    updateInput?: Partial<EntityUpdateInput>;
  }): Observable<Entity> {
    return this.apollo
      .query<{ checkEntityDuplicate: Entity }>({
        fetchPolicy: 'network-only',
        query: CHECK_DUPLICATE_ASSETS_QUERY,
        variables: { entityId, createInput, updateInput },
      })
      .pipe(map((res) => res?.data?.checkEntityDuplicate));
  }
}
