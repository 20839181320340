<div class="multi-level-select">
  <button
    class="dropdown-button"
    aria-haspopup="listbox"
    aria-labelledby="dropdown-label"
    nz-popover
    [(nzPopoverVisible)]="showMultiLevelSelect"
    [nzPopoverPlacement]="['bottom', 'top']"
    nzPopoverTrigger="click"
    [nzPopoverContent]="multiLevelDropdown"
    [nzPopoverOverlayClassName]="'multi-level-select-popover'"
    [disabled]="disabled"
    [class.igray-8-bg]="disabled"
  >
    <div class="dropdown-label-placeholder" *ngIf="!pointEntityValue && !value">
      {{ placeholder }}
    </div>
    <!-- TODO: remove when new styles are implmented -->
    <div class="value w-100" *ngIf="pointEntityValue || value">
      <label>{{ placeholder }}</label>
      <div class="dropdown-label d-flex align-items-start">
        {{ pointEntityValue?.name || value }}
      </div>
    </div>
    <!-- TODO: Add back when new styles are implmented -->
    <!-- <i class="fas fa-chevron-down" *ngIf="!dropdownOpen"></i>
          <i class="fas fa-chevron-up" *ngIf="dropdownOpen"></i> -->
  </button>
</div>
<ng-template #multiLevelDropdown>
  <atom-multi-level-select-input
    [pointEntity]="pointEntity"
    (currentValueChange)="
      pointEntityValue = $event; onValueChange(); showMultiLevelSelect = false
    "
  ></atom-multi-level-select-input>
</ng-template>
