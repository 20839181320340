import { gql } from 'apollo-angular';
import {
  allWorkTicketActivityFields,
  allWorkTicketFields,
} from '@vfi-ui/models';

export const RETRY_WORK_TICKET_CMMS_SYNC = gql`
  mutation retryWorkTicketCmmsSync($id: Int!) {
    retryWorkTicketCmmsSync(id: $id) {
      ${allWorkTicketFields}
    }
  }
`;

export const GET_EXISTING_WORK_TICKETS_QUERY = gql`
  query workTicketsAndCount($options: WorkTicketOptions!) {
    workTicketsAndCount(options: $options) {
      items {
        id
        displayId
        objective
        createdAt
        status
        state
        isResolved
        assignee {
          displayName
        }
        alarms {
          id
        }
      }
    }
  }
`;

export const DISMISS_WORK_TICKET_CMMS_SYNC = gql`
  mutation dismissWorkTicketCmmsSyncFailure($id: Int!) {
    dismissWorkTicketCmmsSyncFailure(id: $id) {
      ${allWorkTicketFields}
    }
  }
`;

export const GET_WORK_TICKET_IDS_QUERY = gql`
  query workTicketsAndCount($options: WorkTicketOptions!) {
    workTicketsAndCount(options: $options) {
      items {
        id
        displayId
      }
      count
    }
  }
`;

export const GET_WORK_TICKET_QUERY = gql`
  query workTicketsAndCount($options: WorkTicketOptions!) {
    workTicketsAndCount(options: $options) {
      items {${allWorkTicketFields}},
      count
    }
  }
`;

export const GET_WORK_TICKETS_ACTIVITY_QUERY = gql`
  query workTicketsAndCount($options: WorkTicketActivityOptions!) {
     workTicketActivityAndCount(options: $options) {
      items {${allWorkTicketActivityFields}},
      count
    }
    }
`;

export const GET_CLOSED_WORK_STATISTICS_QUERY = gql`
  query workTicketsAndCount(
    $options: WorkTicketOptions!
    $PreAlarmReoccurencesRange: Int
    $PostAlarmReoccurencesRange: Int
    $PreWorkReoccurencesRange: Int
    $PostWorkReoccurencesRange: Int
  ) {
    workTicketsAndCount(options: $options) {
      items {
        alarmInstanceReoccurenceFirst {
          alarmTime
        }
        workReoccurenceFirst {
          createdAt
        }
        preAlarmReoccurences: alarmReoccurences(
          input: { daysSinceLastClosed: $PreAlarmReoccurencesRange }
        )
        postAlarmReoccurences: alarmReoccurences(
          input: { daysSinceLastClosed: $PostAlarmReoccurencesRange }
        )
        preWorkReoccurences: workReoccurences(
          input: { daysSinceLastClosed: $PreWorkReoccurencesRange }
        )
        postWorkReoccurences: workReoccurences(
          input: { daysSinceLastClosed: $PostWorkReoccurencesRange }
        )
      }
      count
    }
  }
`;

export const GET_WORK_TICKETS_COUNTS_QUERY = gql`
  query workTicketsAndCount($options: WorkTicketOptions!) {
    workTicketsAndCount(options: $options) {
      count
    }
  }
`;

export const CHANGE_WORK_TICKET_STATE_QUERY = gql`
  mutation changeWorkTicketState(
    $id: Int!
    $state: WorkTicketState
    $survey: WorkTicketSurveyInput
  ) {
    changeWorkTicketState(id: $id, state: $state, survey: $survey) {
      ${allWorkTicketFields}
    }
  }
`;

export const UPDATE_WORK_TICKET_QUERY = gql`
  mutation updateWorkTicket($id: Int!, $data: WorkTicketUpdateInput!) {
    updateWorkTicket(id: $id, data: $data) {
      ${allWorkTicketFields}
    }
  }
`;

export const CREATE_WORK_TICKET_ACTIVITY = gql`
  mutation createWorkTicketActivity($workTicketId: Int!, $input: WorkTicketActivityInput!) {
    createWorkTicketActivity(workTicketId: $workTicketId, input: $input) {
      ${allWorkTicketActivityFields}
    }
  }
`;

export const DELETE_WORK_TICKET_ACTIVITY = gql`
  mutation deleteWorkTicketActivity($id: ID!) {
    deleteWorkTicketActivity(id: $id) {
      ${allWorkTicketActivityFields}
    }
  }
`;

export const GET_WORK_HISTORY_QUERY = gql`
  query workTicketsAndCount(
    $options: WorkTicketOptions!
    $PreAlarmReoccurencesRange: Int
    $PostAlarmReoccurencesRange: Int
    $PreWorkReoccurencesRange: Int
    $PostWorkReoccurencesRange: Int
  ) {
    workTicketsAndCount(options: $options) {
      items {
        id
        displayId
        assignee {
          displayName
        }
        status
        state
        createdAt
        closedAt
        alarmCount
        objective
        isResolved
        preAlarmReoccurences: alarmReoccurences(
          input: { daysSinceLastClosed: $PreAlarmReoccurencesRange }
        )
        postAlarmReoccurences: alarmReoccurences(
          input: { daysSinceLastClosed: $PostAlarmReoccurencesRange }
        )
        preWorkReoccurences: workReoccurences(
          input: { daysSinceLastClosed: $PreWorkReoccurencesRange }
        )
        postWorkReoccurences: workReoccurences(
          input: { daysSinceLastClosed: $PostWorkReoccurencesRange }
        )
      }
      count
    }
  }
`;

export const GET_WORK_EQUIPMENT_HISTORY_QUERY = gql`
  query workTicketsAndCount(
    $options: WorkTicketOptions!
    $PreAlarmReoccurencesRange: Int
    $PostAlarmReoccurencesRange: Int
    $PreWorkReoccurencesRange: Int
    $PostWorkReoccurencesRange: Int
  ) {
    workTicketsAndCount(options: $options) {
      items {
        id
        displayId
        assignee {
          displayName
        }
        status
        state
        createdAt
        closedAt
        alarmCount
        objective
        isResolved
        preAlarmReoccurences: alarmReoccurences(
          input: { daysSinceLastClosed: $PreAlarmReoccurencesRange }
        )
        postAlarmReoccurences: alarmReoccurences(
          input: { daysSinceLastClosed: $PostAlarmReoccurencesRange }
        )
        preWorkReoccurences: workReoccurences(
          input: { daysSinceLastClosed: $PreWorkReoccurencesRange }
        )
        postWorkReoccurences: workReoccurences(
          input: { daysSinceLastClosed: $PostWorkReoccurencesRange }
        )
      }
      count
    }
  }
`;

export const FETCH_EXTERNAL_CMMS_FIELDS_QUERY = gql`
  query externalCmmsFields($input: ExternalCmmsFieldOptions!) {
    externalCmmsFields(input: $input) {
      id
      name
      dataType
      defaultValue
      defaultName
      title
      isRequired
      isVisible
      isReadOnly
      hasDomain
      displayOrder
      displayTemplate
      isAutomationRuleAlarmAttribute
    }
  }
`;

export const FETCH_EXTERNAL_CMMS_FIELDS_OPTIONS_QUERY = gql`
  query externalCmmsDomainValues(
    $input: ExternalCmmsDomainValueOptions!
    $externalDetails: Object
  ) {
    externalCmmsDomainValues(input: $input, externalDetails: $externalDetails) {
      name
      value
      field {
        id
        name
      }
    }
    externalCmmsDomainValueCount(
      input: $input
      externalDetails: $externalDetails
    )
  }
`;

export const TRANSLATE_CMMS_VALUE_QUERY = gql`
  query translateInternalToExternalCmmsValues(
    $alarmId: Int
    $entityId: Int
    $externalSystemId: Int
  ) {
    translateInternalToExternalCmmsValues(
      alarmId: $alarmId
      entityId: $entityId
      externalSystemId: $externalSystemId
    ) {
      field {
        id
        name
        title
      }
      value
      name
      isInherited
      defaultedBy
    }
  }
`;

export const GET_FAST_WORK_TICKETS_QUERY = gql`
  query fastWorkTickets($options: WorkTicketOptions!) {
    fastWorkTickets(options: $options)
  }
`;

export const GET_ASSET_FAST_WORK_TICKETS_QUERY = gql`
  query fastWorkTickets(
    $openOptions: WorkTicketOptions!
    $recentlyClosedOptions: WorkTicketOptions!
  ) {
    opened: fastWorkTickets(options: $openOptions)
    recentlyClosed: fastWorkTickets(options: $recentlyClosedOptions)
  }
`;

export const GET_FAST_WORK_TICKETS_WITH_COUNTS_QUERY = gql`
  query fastWorkTickets($options: WorkTicketOptions!) {
    fastWorkTickets(options: $options)
    workTicketsAndCount(options: $options) {
      count
    }
  }
`;

export const GET_CMMS_ASSIGNEE_QUERY = gql`
  query cmmsAssignee($input: CmmsAssigneeOptions!) {
    cmmsAssignee(input: $input)
    cmmsAssigneeCount(input: $input)
  }
`;
