import {
  fastParse,
  omit,
  orderBy,
  startCase,
  countBy,
  result,
  processAlarmProperties,
  MapFastAlarmToTile,
} from '@vfi-ui/util/helpers';
import {
  MaxAlarmsLimit,
  Tile,
  AlarmAutoDispatchIcon,
  AlarmPinnedIcon,
  AlarmPriorityColors,
  SocketAction,
  Counts,
  CriterionSelection,
  LensEntityEvent,
  LensEventType,
  AlarmProperty,
  ALARM_SORTS_CRITERION,
  ALARM_FILTERS_CRITERION,
  CoreAlarmsWhere,
  filterCategoryType,
  CRITERIA_BUILDER_CRITERION,
  ALARMS_TABLE_PROPERTIES_BLACKLIST,
  ALARM_SORT_PROPERTIES_BLACKLIST,
  FastAlarm,
} from '@vfi-ui/models';
import {
  State,
  Action,
  Selector,
  StateContext,
  Store,
  Actions,
  StateOperator,
  ofActionCompleted,
} from '@ngxs/store';
import { GlobalFiltersState } from './../global-filters/global-filters.state';
import {
  LoadAlarms,
  LoadMoreAlarms,
  AlarmTileChecked,
  AllAlarmTileChecked,
  AlarmTileSelected,
  AlarmPriorityChanged,
  AlarmTileSelectionPinned,
  ResetAlarms,
  LiveAlarms,
  CleanupAlarmSockets,
  AddAlarmCounts,
  SelectFirstAlarmTile,
  SetFirstAlarmTile,
  AutoDispatchChanged,
  SetCustomCounts,
  SetAlarmProperties,
  SetAlarmLimit,
  AllAlarmPriorityChanged,
  LoadAlarmStatuses,
  CheckNewAlarmTiles,
} from './alarms.actions';
import {
  patch,
  append,
  updateItem,
  removeItem,
  insertItem,
} from '@ngxs/store/operators';
import { AlarmsDataService, SocketsService } from '@vfi-ui/data-access/shared';
import { takeUntil, skip, take, skipWhile, filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { LensState } from '../lens/lens.state';
import { Injectable } from '@angular/core';

export interface AlarmsStateModel {
  items: FastAlarm[];
  tiles: Tile[];
  loading: boolean;
  offset: number;
  limit: number;
  counts: Counts;
  customCounts: Counts;
  totalCount: number;
  selectAll: boolean;
  properties: AlarmProperty[];
  alarmStatuses: string[];
  alarmDetailsForm;
  alarmQueryWhere: CoreAlarmsWhere;
}
export const AlarmStateDefaults: AlarmsStateModel = {
  items: [],
  tiles: [],
  loading: false,
  offset: 0,
  limit: MaxAlarmsLimit,
  counts: {},
  customCounts: {},
  totalCount: 0,
  selectAll: false,
  properties: [],
  alarmStatuses: [],
  alarmDetailsForm: {
    model: undefined,
    dirty: false,
    status: '',
    errors: {},
  },
  alarmQueryWhere: null,
};

@State<AlarmsStateModel>({
  name: 'alarms',
  defaults: AlarmStateDefaults,
})
@Injectable({
  providedIn: 'root',
})
export class AlarmsState {
  alarmAddRemoveEvent$ = this.socketService.onMessage<
    LensEntityEvent<FastAlarm>
  >(SocketAction.LENS_EVENT);

  liveAlarmUpdated$ = this.socketService.onMessage<FastAlarm>(
    SocketAction.ALARM_UPDATED
  );

  private alarmSocketSubscription = new Subscription();

  constructor(
    private alarmsDataService: AlarmsDataService,
    private store: Store,
    private actions$: Actions,
    private socketService: SocketsService
  ) {}

  /**
   * check and return if there are any filtered applied
   *
   * @readonly
   * @private
   * @type {boolean}
   * @memberof AlarmsState
   */
  private get isfiltered(): boolean {
    return this.store.selectSnapshot(GlobalFiltersState.isCriterionSet);
  }

  @Selector()
  static getState(state: AlarmsStateModel) {
    return state;
  }

  @Selector()
  static getAlarms(state: AlarmsStateModel) {
    return state.items;
  }

  @Selector()
  static getTiles(state: AlarmsStateModel) {
    return state.tiles;
  }

  @Selector()
  static getCheckedTiles(state: AlarmsStateModel) {
    return state.tiles.filter((tile) => tile.checked);
  }

  @Selector()
  static getTileById(state: AlarmsStateModel) {
    return (id: string | number) =>
      state.tiles.find((tile) => tile.tileID === id);
  }

  @Selector()
  static getLoading(state: AlarmsStateModel) {
    return state.loading;
  }

  @Selector()
  static getOffset(state: AlarmsStateModel) {
    return state.offset;
  }

  @Selector()
  static getlimit(state: AlarmsStateModel) {
    return state.limit;
  }

  @Selector()
  static getAlarmCounts(state: AlarmsStateModel) {
    return state.counts;
  }
  @Selector()
  static getCustomLensCounts(state: AlarmsStateModel) {
    return state.customCounts;
  }
  @Selector()
  static getAlarmTotalCount(state: AlarmsStateModel) {
    return state.totalCount;
  }
  @Selector()
  static getAlarmProperties(state: AlarmsStateModel) {
    return state.properties;
  }
  @Selector()
  static getAlarmQueryWhere(state: AlarmsStateModel) {
    return state.alarmQueryWhere;
  }

  @Selector()
  static getAlarmStatuses(state: AlarmsStateModel) {
    return state.alarmStatuses;
  }

  @Selector()
  static getObjectControlProperties(state: AlarmsStateModel) {
    const properties = state.properties;
    return properties.map((props) => props.name);
  }
  @Selector()
  static isAlarmDetailsDirty(state: AlarmsStateModel) {
    return state.alarmDetailsForm.dirty;
  }

  @Selector([AlarmsState])
  static getAlarmSortsCriterion(state: AlarmsStateModel) {
    const defaultSorts = ALARM_SORTS_CRITERION;
    const propertySort = state.properties
      .filter((prop) => !ALARM_SORT_PROPERTIES_BLACKLIST.includes(prop?.name))
      .map((prop) => processAlarmProperties(prop));

    return orderBy([...propertySort, ...defaultSorts], ['name'], ['asc']);
  }

  @Selector()
  static getAlarmFilterCriterion(state: AlarmsStateModel) {
    const defaultFilters = ALARM_FILTERS_CRITERION;
    const propertyFilters = state.properties
      .filter((prop) => !ALARMS_TABLE_PROPERTIES_BLACKLIST.includes(prop?.name))
      .map((prop) => ({
        name: startCase(prop.name),
        show: true,
        type: 'select',
        blanks: true,
        dbName: prop.name,
        dbProperty: 'name',
        category: filterCategoryType.ALARMS,
      }));

    return orderBy([...propertyFilters, ...defaultFilters], ['name'], ['asc']);
  }

  @Selector()
  static getCriteriaBuilderAlarmsFilters(state: AlarmsStateModel) {
    const defaultFilters = CRITERIA_BUILDER_CRITERION;
    const propertyFilters = state.properties.map((prop) => ({
      name: startCase(prop.name),
      show: true,
      type: 'select',
      blanks: true,
      dbName: prop.name,
      dbProperty: 'name',
      category: filterCategoryType.ALARMS,
    }));

    return orderBy([...propertyFilters, ...defaultFilters], ['name'], ['asc']);
  }

  @Selector()
  static getDynamicPropertyFilters(state: AlarmsStateModel) {
    const propertyFilters = state.properties.map((prop) => ({
      name: startCase(prop.name),
      show: true,
      type: 'select',
      blanks: true,
      dbName: prop.name,
      dbProperty: 'name',
      category: filterCategoryType.ALARMS,
    }));

    return orderBy([...propertyFilters], ['name'], ['asc']);
  }

  @Selector()
  static getCheckedAlarms(state: AlarmsStateModel) {
    const checked = state.tiles.map((tile) => {
      if (tile.checked) {
        return tile.tileID;
      }
    });
    return state.items.filter((alarm) => checked.includes(alarm.id));
  }

  @Selector()
  static getCheckedAlarmIds(state: AlarmsStateModel) {
    return state.tiles.filter((tile) => tile.checked).map((t) => t.tileID);
  }

  @Selector()
  static getExcludedAlarmIds(state: AlarmsStateModel) {
    const unSelected = countBy(state.tiles, 'checked').false;
    return state.selectAll && unSelected
      ? state.tiles.filter((tile) => !tile.checked).map((tile) => tile.tileID)
      : [];
  }

  @Selector()
  static isAllAlarmsSelected(state: AlarmsStateModel) {
    return state.selectAll;
  }

  @Selector()
  static getSelectedTileCount(state: AlarmsStateModel) {
    const intermediate = state.tiles.some((tile) => !tile.checked);
    const checkCount = countBy(state.tiles, 'checked');
    let count = checkCount.true || 0;
    if (state.selectAll && intermediate) {
      count = state.totalCount - checkCount.false;
    }
    if (state.selectAll && !intermediate) {
      count = state.totalCount;
    }
    return count;
  }

  @Action(ResetAlarms)
  public resetState(ctx: StateContext<AlarmsStateModel>) {
    const state = ctx.getState();
    ctx.setState({
      ...AlarmStateDefaults,
      counts: state.counts,
      customCounts: state.customCounts,
      properties: state.properties,
      limit: state.limit,
      alarmStatuses: state.alarmStatuses,
    });
  }

  @Action(LoadAlarms)
  public add(ctx: StateContext<AlarmsStateModel>) {
    const state = ctx.getState();
    const searchTerm = this.store.selectSnapshot(
      GlobalFiltersState.getSearchTerm
    );
    const criterion = this.store.selectSnapshot(
      GlobalFiltersState.getCriterion
    );
    const coreCriterion = this.store.selectSnapshot(
      GlobalFiltersState.getCoreCriterion
    );
    const allCriterion: CriterionSelection[] = [...coreCriterion, ...criterion];
    const sort = this.store.selectSnapshot(GlobalFiltersState.getSort);
    const lens = this.store.selectSnapshot(GlobalFiltersState.getLens);
    const alarmProperties = this.store.selectSnapshot(
      AlarmsState.getAlarmProperties
    );

    ctx.setState({
      ...state,
      loading: true,
    });

    this.alarmsDataService
      .getFastAlarmsAndCount({
        ...state,
        searchTerm,
        criterion: allCriterion,
        sort,
        lens,
        alarmProperties,
      })
      .pipe(
        takeUntil(
          this.actions$.pipe(
            ofActionCompleted(LoadAlarms),
            skip(1),
            take(1),
            filter((d) => !!d)
          )
        )
      )
      .subscribe(({ Alarm, loading, count, alarmQueryWhere }) => {
        ctx.setState(
          patch({
            items: append(Alarm),
            tiles: append(this.mapAlarmTiles(Alarm)),
            loading: loading,
            totalCount: count,
            alarmQueryWhere,
          })
        );

        if (state.selectAll) {
          ctx.dispatch(new CheckNewAlarmTiles(state.tiles.length - 1));
        }
      });
  }

  @Action(LoadAlarmStatuses)
  public loadAlarmStatuses(ctx: StateContext<AlarmsStateModel>) {
    this.alarmsDataService.getAlarmStatuses().subscribe((d) => {
      ctx.setState(
        patch({
          alarmStatuses: d,
        })
      );
    });
  }

  @Action(LoadMoreAlarms)
  public updateOffset(ctx: StateContext<AlarmsStateModel>, { payload }) {
    const state = ctx.getState();
    ctx.setState(
      patch({
        offset: result(payload, 'offset', state.offset + state.limit),
      })
    );
    return ctx.dispatch(new LoadAlarms());
  }

  @Action(AlarmTileChecked)
  public alarmTileChecked(ctx: StateContext<AlarmsStateModel>, { payload }) {
    ctx.setState(
      patch({
        tiles: updateItem(
          (tile: Tile) => tile.tileID === payload.id,
          patch({
            checked: payload.status,
          })
        ),
      })
    );
  }

  @Action(AlarmTileSelected)
  public selectAlarmTile(ctx: StateContext<AlarmsStateModel>, { payload }) {
    const state = ctx.getState();
    const tiles = state.tiles.map((tile) => {
      if (tile.selected) {
        tile = { ...tile, selected: false };
      }
      if (tile.tileID === payload.id) {
        tile = { ...tile, selected: true };
      }
      return tile;
    });
    ctx.patchState({
      tiles,
    });
  }

  @Action(SelectFirstAlarmTile)
  public selectFirstAlarmTile(ctx: StateContext<AlarmsStateModel>) {
    const state = ctx.getState();
    const firstTileID = state.tiles[0].tileID;
    ctx.setState({
      ...state,
      tiles: state.tiles.map((alarm) => ({
        ...alarm,
        selected: alarm.tileID === firstTileID ? true : false,
      })),
    });
  }

  @Action(SetFirstAlarmTile)
  public setFirstAlarmTile(ctx: StateContext<AlarmsStateModel>, payload) {
    this.alarmsDataService
      .getAlarm(payload.payload)
      .pipe(take(1))
      .subscribe(({ Alarm }) => {
        ctx.setState(this.insertItem(0, Alarm[0]));
        return ctx.dispatch(new SelectFirstAlarmTile());
      });
  }

  @Action(AllAlarmTileChecked)
  public allAlarmTileChecked(ctx: StateContext<AlarmsStateModel>, { payload }) {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      selectAll: payload.status,
      tiles: state.tiles.map((alarm) => ({
        ...alarm,
        checked: payload.status,
      })),
    });
  }

  @Action(CheckNewAlarmTiles)
  public checkNewAlarmTiles(ctx: StateContext<AlarmsStateModel>, { payload }) {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      selectAll: true,
      tiles: state.tiles.map((alarm, index) => ({
        ...alarm,
        checked: index <= payload ? alarm.checked : true,
      })),
    });
  }

  @Action(AlarmPriorityChanged)
  public alarmPriorityChanged(
    ctx: StateContext<AlarmsStateModel>,
    { payload }
  ) {
    payload.forEach((alarm) => {
      ctx.setState(
        patch({
          tiles: updateItem(
            (tile: Tile) => tile.tileID === alarm.id,
            patch({
              priorityIconColor: result(AlarmPriorityColors, `${alarm.status}`),
            })
          ),
        })
      );
    });
  }

  @Action(AllAlarmPriorityChanged)
  public allAlarmPriorityChanged(
    ctx: StateContext<AlarmsStateModel>,
    { payload }
  ) {
    const state = ctx.getState();
    const unfreeze = fastParse(state);
    if (payload.excludeIds) {
      ctx.setState(
        patch({
          tiles: unfreeze.tiles.map((tile) => {
            if (!payload.excludeIds.includes(tile.tileID)) {
              tile.priorityIconColor = result(
                AlarmPriorityColors,
                `${payload.status}`
              );
            }
            return tile;
          }),
        })
      );
    } else {
      ctx.setState(
        patch({
          tiles: unfreeze.tiles.map((tile) => {
            tile.priorityIconColor = result(
              AlarmPriorityColors,
              `${payload.status}`
            );
            return tile;
          }),
        })
      );
    }
  }

  @Action(AlarmTileSelectionPinned)
  public alarmTileSelectionPinned(ctx: StateContext<AlarmsStateModel>) {
    const state = ctx.getState();
    const unfreeze = fastParse(state);
    const checked = state.tiles.filter((tile) => tile.checked);
    const allPinned = checked.every((tile) => tile.pinned);
    const someUnpined = checked.some((tile) => tile.pinned === false);
    const ids = checked.map((alarm) => alarm.tileID);
    const isChecked = someUnpined ? true : !allPinned;
    this.alarmsDataService.postPinnedAlarms(ids, isChecked).subscribe(() => {
      ctx.setState({
        ...state,
        tiles: unfreeze.tiles.map((tile) => {
          if (tile.checked) {
            tile.pinned = isChecked;
            tile.level2Icon = tile.pinned ? AlarmPinnedIcon : '';
          }
          return tile;
        }),
      });
    });
  }

  @Action(AddAlarmCounts)
  addPrimaryCounts(ctx: StateContext<AlarmsStateModel>, { payload }) {
    ctx.setState(
      patch({
        counts: payload,
      })
    );
  }

  @Action(SetCustomCounts)
  addCustomLensCounts(ctx: StateContext<AlarmsStateModel>, { payload }) {
    const state = ctx.getState();
    ctx.setState(
      patch({
        customCounts: { ...state.customCounts, ...payload },
      })
    );
  }

  @Action(AutoDispatchChanged)
  public alarmAutoDispatchChange(
    ctx: StateContext<AlarmsStateModel>,
    { payload }
  ) {
    ctx.setState(
      patch({
        tiles: updateItem(
          (tile: Tile) => tile.tileID === payload.id,
          patch({
            top1Icon: payload.status ? AlarmAutoDispatchIcon : '',
          })
        ),
      })
    );
  }

  @Action(SetAlarmProperties)
  addAlarmProperties(ctx: StateContext<AlarmsStateModel>, { payload }) {
    ctx.setState(
      patch({
        properties: payload,
      })
    );
  }

  @Action(SetAlarmLimit)
  setAlarmLimit(ctx: StateContext<AlarmsStateModel>, { payload }) {
    ctx.setState(
      patch({
        limit: payload,
      })
    );
  }

  @Action(CleanupAlarmSockets)
  public cleanupSockets() {
    this.alarmSocketSubscription.unsubscribe();
    this.alarmSocketSubscription = new Subscription();
  }

  @Action(LiveAlarms)
  public liveAlarms(ctx: StateContext<AlarmsStateModel>) {
    const liveAlarmAddRemovedSub = this.alarmAddRemoveEvent$
      .pipe(skipWhile(() => this.isfiltered))
      .subscribe((event) => {
        const lens = this.store.selectSnapshot(
          LensState.getCurrentlySelectedLens
        );
        if (lens.id === event.lens.id) {
          const alarm = event.data.entity;
          if (event.data.type === LensEventType.IngressEvent) {
            const alarms = ctx.getState().items || [];
            const existingAlarm = alarms.find((a) => a.id === alarm.id);
            if (!existingAlarm) {
              ctx.setState(this.insertItem(0, alarm));
            }
          } else if (event.data.type === LensEventType.EgressEvent) {
            ctx.setState(this.removeAlarmById(alarm.id));
          }
        }
      });

    const liveAlarmUpdatedSub = this.liveAlarmUpdated$
      .pipe(skipWhile(() => this.isfiltered))
      .subscribe((alarm) => {
        ctx.setState(this.updateAlarmProperties(alarm));
      });

    this.alarmSocketSubscription.add(liveAlarmAddRemovedSub);
    this.alarmSocketSubscription.add(liveAlarmUpdatedSub);
  }

  /**
   * remove alarm by id
   *
   * @private
   * @param {AlarmInstanceCreated} alarm
   * @returns
   * @memberof AlarmsState
   */
  private insertItem(index = 0, alarm): StateOperator<AlarmsStateModel> {
    return patch({
      tiles: insertItem(this.mapAlarmTiles([alarm])[0], index),
      items: insertItem(alarm, index),
    });
  }

  /**
   * remove alarm by id
   *
   * @private
   * @param {AlarmInstanceCreated} alarm
   * @returns
   * @memberof AlarmsState
   */
  private removeAlarmById(
    id: string | number
  ): StateOperator<AlarmsStateModel> {
    return patch({
      tiles: removeItem((tile: Tile) => tile.tileID === id),
      items: removeItem((item: FastAlarm) => item.id === id),
    });
  }

  /**
   * update alarm properties other then time
   *
   * @private
   * @param {FastAlarm} alarm
   * @returns
   * @memberof AlarmsState
   */
  private updateAlarmProperties(
    alarm: FastAlarm
  ): StateOperator<AlarmsStateModel> {
    const tiles = this.store.selectSnapshot(AlarmsState.getTiles);
    const stateTile = tiles.find((t) => t.tileID === alarm.id);
    return patch({
      tiles: updateItem(
        (tile) => tile['tileID'] === alarm.id,
        patch(this.mapAlarmTile(alarm, stateTile))
      ),
      items: updateItem(
        (item: FastAlarm) => item.id === alarm.id,
        patch({
          text: alarm.text,
          isPinned: alarm.isPinned,
          priority: alarm.priority,
          status: alarm.status,
          latestAlarmTime: alarm.latestAlarmTime,
        })
      ),
    });
  }

  /**
   * map alarm tiles by core alarm
   *
   * @private
   * @param {FastAlarm[]} alarms
   * @returns {Tile[]}
   * @memberof AlarmsState
   */
  private mapAlarmTiles(alarms: FastAlarm[]): Tile[] {
    return alarms.map((alarm) => MapFastAlarmToTile(alarm));
  }

  /**
   * map alarm tile by core alarm
   *
   * @private
   * @param {FastAlarm} alarm
   * @returns {Tile}
   * @memberof AlarmsState
   */
  private mapAlarmTile(alarm: FastAlarm, tile: Tile): Tile {
    const updatedTile = MapFastAlarmToTile(alarm, tile);
    return omit(updatedTile, ['selected']);
  }
}
