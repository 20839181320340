import { gql } from 'apollo-angular';

export const PULL_EXTERNAL_CMMS_FIELDS_QUERY = gql`
  mutation getCmmsFields($externalSystemId: Int!) {
    getCmmsFields(externalSystemId: $externalSystemId)
  }
`;

export const PULL_EXTERNAL_CMMS_DOMAIN_VALUE_QUERY = gql`
  mutation getCmmsFields($cmmsFieldId: String!) {
    getCmmsDomain(cmmsFieldId: $cmmsFieldId)
  }
`;

export const EXTENRAL_CMMS_FIELDS_QUERY = gql`
  query externalCmmsFields($options: ExternalCmmsFieldOptions!) {
    externalCmmsFieldCount(input: $options)
    externalCmmsFields(input: $options) {
      id
      name
      defaultName
      defaultValue
      title
      description
      maxLength
      dataType
      isCreateWorkField
      isRequired
      isVisible
      isReadOnly
      isDeleted
      displayOrder
      displayTemplate
      isTranslateValueByNameEnabled
      isTranslateValueByValueEnabled
      isAutomationRuleAlarmAttribute
      isAutoSyncValuesEnabled
      relatedParentField {
        id
        name
      }
    }
  }
`;

export const EXTENRAL_CMMS_DOMAIN_VALUE_QUERY = gql`
  query externalCmmsDomainValues($options: ExternalCmmsDomainValueOptions!) {
    externalCmmsDomainValueCount(input: $options)
    externalCmmsDomainValues(input: $options) {
      id
      name
      value
      externalId
      parentExternalId
      isDeleted
      isManuallyAdded
    }
  }
`;

export const CREATE_EXTERNAL_CMMS_QUERY = gql`
  mutation createExternalCmmsField(
    $input: ExternalCmmsExternalFieldCreateInput!
  ) {
    createExternalCmmsField(input: $input) {
      id
    }
  }
`;

export const DELETE_CMMS_FIELD_VALUES_MUTATION = gql`
  mutation deleteCmmsFieldValues($cmmsFieldId: String!) {
    deleteCmmsFieldValues(cmmsFieldId: $cmmsFieldId)
  }
`;

export const DELETE_CMMS_FIELD_TRANSLATIONS_MUTATION = gql`
  mutation deleteExternalCmmsValueTranslations($cmmsFieldId: String!) {
    deleteExternalCmmsValueTranslations(cmmsFieldId: $cmmsFieldId)
  }
`;

export const CREATE_CMMS_DOMAIN_VALUE_QUERY = gql`
  mutation createExternalCmmsDomainValue(
    $input: ExternalCmmsDomainValueCreateInput!
  ) {
    createExternalCmmsDomainValue(input: $input) {
      id
    }
  }
`;

export const UPDATE_EXTERNAL_CMMS_QUERY = gql`
  mutation updateExternalCmmsField(
    $id: String!
    $input: ExternalCmmsExternalFieldUpdateInput!
  ) {
    updateExternalCmmsField(id: $id, input: $input) {
      id
      name
      defaultName
      defaultValue
      title
      description
      maxLength
      dataType
      isCreateWorkField
      isRequired
      isVisible
      isReadOnly
      displayOrder
      displayTemplate
      isTranslateValueByNameEnabled
      isTranslateValueByValueEnabled
      isAutomationRuleAlarmAttribute
      isAutoSyncValuesEnabled
    }
  }
`;

export const UPDATE_CMMS_DOMAIN_VALUE_QUERY = gql`
  mutation updateExternalCmmsDomainValue(
    $id: String!
    $input: ExternalCmmsDomainValueUpdateInput!
  ) {
    updateExternalCmmsDomainValue(id: $id, input: $input) {
      id
    }
  }
`;

export const EXTENRAL_CMMS_FIELD_MAPPIPNG_QUERY = gql`
  query externalCmmsFieldMappings($input: ExternalCmmsFieldMappingOptions!) {
    externalCmmsFieldMappings(input: $input) {
      externalField {
        id
        name
        defaultName
        defaultValue
        title
        description
        maxLength
        dataType
        isCreateWorkField
        isRequired
        isVisible
        isReadOnly
        displayOrder
        displayTemplate
      }
      internalField {
        id
        name
      }
    }
  }
`;

export const CREATE_EXTERNAL_CMMS_FIELD_MAPPING_QUERY = gql`
  mutation createExternalCmmsFieldMapping(
    $input: ExternalCmmsFieldMappingCreateInput!
  ) {
    createExternalCmmsFieldMapping(input: $input) {
      externalField {
        id
        name
        defaultName
        defaultValue
        title
        description
        maxLength
        dataType
        isCreateWorkField
        isRequired
        isVisible
        isReadOnly
        displayOrder
        displayTemplate
      }
      internalField {
        id
        name
      }
    }
  }
`;

export const GET_EXTERNAL_CMMS_FIELD_MAPPINGS_QUERY = gql`
  query externalCmmsExternalFieldMaps($externalSystemId: Int) {
    externalCmmsExternalFieldMaps(externalSystemId: $externalSystemId) {
      field {
        id
        name
        title
      }
      relationships {
        id
        name
        title
      }
    }
  }
`;

export const DELETE_EXTERNAL_CMMS_FIELD_MAPPING_QUERY = gql`
  mutation deleteExternalCmmsFieldMapping(
    $externalId: String!
    $internalId: String!
  ) {
    deleteExternalCmmsFieldMapping(
      externalId: $externalId
      internalId: $internalId
    )
  }
`;

export const EXTENRAL_CMMS_VALUE_TRANSLATIONS_QUERY = gql`
  query externalCmmsValueTranslations(
    $input: ExternalCmmsValueTranslationOptions!
  ) {
    externalCmmsValueTranslations(input: $input) {
      id
      externalValue
      internalValue
      translationDirection
    }
    externalCmmsValueTranslationCount(input: $input)
  }
`;

export const CREATE_EXTERNAL_CMMS_VALUE_TRANSLATION_QUERY = gql`
  mutation createExternalCmmsValueTranslation(
    $input: ExternalCmmsValueTranslationCreateInput!
  ) {
    createExternalCmmsValueTranslation(input: $input) {
      id
      externalValue
      internalValue
      translationDirection
    }
  }
`;

export const UPDATE_EXTERNAL_CMMS_VALUE_TRANSLATION_QUERY = gql`
  mutation updateExternalCmmsValueTranslation(
    $id: String!
    $input: ExternalCmmsValueTranslationUpdateInput!
  ) {
    updateExternalCmmsValueTranslation(id: $id, input: $input) {
      id
      externalValue
      internalValue
      translationDirection
    }
  }
`;

export const DELETE_EXTERNAL_CMMS_VALUE_TRANSLATION_QUERY = gql`
  mutation deleteExternalCmmsValueTranslation($id: String!) {
    deleteExternalCmmsValueTranslation(id: $id)
  }
`;

export const CREATE_EXTERNAL_CMMS_FIELD_RELATION_QUERY = gql`
  mutation addRelatedParentExternalCmmsField(
    $childId: String!
    $parentId: String!
  ) {
    addRelatedParentExternalCmmsField(childId: $childId, parentId: $parentId)
  }
`;

export const DELETE_EXTERNAL_CMMS_FIELD_RELATION_QUERY = gql`
  mutation removeRelatedParentExternalCmmsField(
    $childId: String!
    $parentId: String!
  ) {
    removeRelatedParentExternalCmmsField(childId: $childId, parentId: $parentId)
  }
`;

export const UPDATE_CMMS_CONFIG_QUERY = gql`
  mutation updateCmmsConfig(
    $alarmId: Int
    $entityId: Int
    $externalSystemId: Int!
    $cmmsConfig: Object!
  ) {
    updateCmmsConfig(
      alarmId: $alarmId
      entityId: $entityId
      externalSystemId: $externalSystemId
      cmmsConfig: $cmmsConfig
    )
  }
`;
