import { HttpClient } from '@angular/common/http';
import {
  CREATE_AUTOMATION_RULES_QUERY,
  UPDATE_AUTOMATION_RULES_QUERY,
  FETCH_AUTOMATION_RULES_QUERY,
  UPDATE_AUTOMATION_RULE_EXECUTION_ORDER_QUERY,
  DELETE_AUTOMATION_RULE_QUERY,
  FETCH_AUTOMATION_ALARM_COUNT_QUERY,
  FETCH_AUTOMATION_ALARMS_QUERY,
  CLONE_AUTOMATION_RULE_QUERY,
} from './../queries/automation-rules.query';
import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import {
  AutomationRuleCreateInput,
  ERROR_CREATE_AUTOMATION,
  ERROR_UPDATE_AUTOMATION,
  AutomationRule,
  AutomationRuleOrderInput,
  ERROR_UPDATE_AUTOMATION_RULES_EXECUTION_ORDER,
  OrderDirection,
  ERROR_DELETE_AUTOMATION,
  ERROR_DOWNLOAD_CSV,
  GroupCriteriaInput,
  CoreAlarm,
  GroupCriteriaClause,
  ERROR_CLONE_AUTOMATION_RULE,
} from '@vfi-ui/models';
import { Observable, throwError } from 'rxjs';
import { map, filter, catchError, tap } from 'rxjs/operators';
import { downloadFile, get, isNil } from '@vfi-ui/util/helpers';
import { NotificationService } from './notification.service';
import { environment } from '@vfi-ui/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AutomationRulesDataService {
  constructor(
    private apollo: Apollo,
    private notification: NotificationService,
    private http: HttpClient
  ) {}

  /**
   * calls api to fetch automation rules
   *
   * @returns {Observable<AutomationRule[]>}
   * @memberof AutomationRulesDataService
   */
  fetchAutomationRules(): Observable<AutomationRule[]> {
    const order = { field: 'EXECUTION_ORDER', direction: OrderDirection.ASC };
    return this.apollo
      .query<AutomationRule[]>({
        fetchPolicy: 'no-cache',
        query: FETCH_AUTOMATION_RULES_QUERY,
        variables: {
          input: { order },
        },
      })
      .pipe(
        filter((d) => !!d),
        map((res) => get(res, 'data.automationRules'))
      );
  }
  /**
   * fetch rule by given ids
   *
   * @param {number} id
   * @return {*}  {Observable<AutomationRule[]>}
   * @memberof AutomationRulesDataService
   */
  fetchAutomationRuleByIds(ids: number[]): Observable<AutomationRule[]> {
    return this.apollo
      .query<AutomationRule[]>({
        fetchPolicy: 'network-only',
        query: FETCH_AUTOMATION_RULES_QUERY,
        variables: {
          input: { where: { ids } },
        },
      })
      .pipe(
        filter((d) => !!d),
        map((res) => get(res, 'data.automationRules'))
      );
  }

  /**
   * call api to update automation rules execution order
   *
   * @param {AutomationRuleOrderInput[]} input
   * @returns
   * @memberof AutomationRulesDataService
   */
  updateAutomationExecutionOrder(input: AutomationRuleOrderInput[]) {
    return this.apollo
      .mutate({
        mutation: UPDATE_AUTOMATION_RULE_EXECUTION_ORDER_QUERY,
        variables: {
          input,
        },
      })
      .pipe(
        filter((d) => !!d),
        catchError((err) => {
          this.notification.showError(
            ERROR_UPDATE_AUTOMATION_RULES_EXECUTION_ORDER
          );
          return throwError(err);
        }),
        tap(() => {
          this.notification.showSuccess(
            'Automation Rules',
            `Execution order updated`
          );
        })
      );
  }

  /**
   * create an automation rule by call the api
   *
   * @param {AutomationRuleCreateInput} input
   * @param {*} [excludeIds=[]]
   * @returns
   * @memberof AutomationRulesDataService
   */
  createAutomationRule(input: AutomationRuleCreateInput) {
    return this.apollo
      .mutate({
        mutation: CREATE_AUTOMATION_RULES_QUERY,
        variables: { input },
      })
      .pipe(
        filter((d) => !!d),
        map((res) => get(res, 'data.createAutomationRule')),
        catchError((err) => {
          this.notification.showError(ERROR_CREATE_AUTOMATION);
          return throwError(err);
        }),
        tap(() => {
          this.notification.showSuccess(
            'Automation Rules',
            `Automation rule created`
          );
        })
      );
  }

  /**
   * update an automation rule by calling the api
   *
   * @param {number} id
   * @param {AutomationRuleCreateInput} input
   * @param {*} [excludeIds=[]]
   * @returns
   * @memberof AutomationRulesDataService
   */
  updateAutomationRule(id: number, input: AutomationRuleCreateInput) {
    return this.apollo
      .mutate({
        mutation: UPDATE_AUTOMATION_RULES_QUERY,
        variables: { id, input: input },
      })
      .pipe(
        filter((d) => !!d),
        map((res) => get(res, 'data.updateAutomationRule')),
        catchError((err) => {
          this.notification.showError(ERROR_UPDATE_AUTOMATION);
          return throwError(err);
        }),
        tap(() => {
          this.notification.showSuccess(
            'Automation Rules',
            `Automation rule updated`
          );
        })
      );
  }

  /**
   * delete automation rule by ID
   *
   * @param {number} id
   * @returns
   * @memberof AutomationRulesDataService
   */
  deleteAutomationRule(id: number) {
    return this.apollo
      .mutate({
        mutation: DELETE_AUTOMATION_RULE_QUERY,
        variables: { id },
      })
      .pipe(
        filter((d) => !!d),
        map((res) => get(res, 'data.deleteAutomationRule')),
        catchError((err) => {
          this.notification.showError(ERROR_DELETE_AUTOMATION);
          return throwError(err);
        }),
        tap(() => {
          this.notification.showSuccess(
            'Automation Rules',
            `Automation rule deleted`
          );
        })
      );
  }
  /**
   * download audit log csv file
   *
   * @param {number} [automationRuleId]
   * @return {*}
   * @memberof AutomationRulesDataService
   */
  downloadAuditLogCSV(automationRuleId?: number) {
    return this.http
      .post(
        environment.backend + '/automation-rule/download_audit',
        {
          automationRuleId,
        },
        { responseType: 'arraybuffer', observe: 'response' }
      )
      .pipe(
        filter((d) => !!d),
        catchError((err) => {
          this.notification.showError(ERROR_DOWNLOAD_CSV);
          return throwError(err);
        }),
        tap((res) => {
          let fileName = '';
          try {
            fileName = res.headers
              .get('content-disposition')
              .replace('attachment; filename=', '');
          } catch (error) {
            fileName = 'audit-log.csv';
          }

          const file = new Blob([res.body], {
            type: 'application/gzip',
          });
          downloadFile(file, fileName);
        })
      );
  }

  /**
   * fetch automation alarm counts
   *
   * @param {GroupCriteriaInput} criteria
   * @returns
   * @memberof AutomationRulesDataService
   */
  fetchAutomationAlarmCount(criteria: GroupCriteriaInput, excludeIds = []) {
    const variables = criteria ? { input: criteria } : {};
    return this.apollo
      .query<number>({
        fetchPolicy: 'no-cache',
        query: FETCH_AUTOMATION_ALARM_COUNT_QUERY,
        variables: { ...variables, excludeIds },
      })
      .pipe(
        filter((d) => !!d),
        map((res) => get(res, 'data.alarmCount'))
      );
  }

  /**
   * fetch automation preview alarms
   *
   * @param {GroupCriteriaInput} criteria
   * @param {number} offset
   * @param {*} [search=null]
   * @returns
   * @memberof AutomationRulesDataService
   */
  fetchAutomationAlarms(
    criteria: GroupCriteriaInput,
    offset: number,
    search = null,
    excludeIds = []
  ) {
    let crit = criteria;
    if (!isNil(search) && search.length > 0) {
      const searchWhere = {
        clause: GroupCriteriaClause.WHERE,
        where: { pattern: `%${search}%` },
      };
      crit = {
        clause: GroupCriteriaClause.AND,
        data: [criteria, searchWhere],
      };
    }
    return this.apollo
      .query<{ alarms: CoreAlarm[] }>({
        fetchPolicy: 'no-cache',
        query: FETCH_AUTOMATION_ALARMS_QUERY,
        variables: {
          input: {
            criteria: crit,
            limit: 20,
            offset,
            order: { field: 'ID', direction: OrderDirection.DESC },
          },
          excludeIds,
        },
      })
      .pipe(
        filter((d) => !!d),
        map((res) => res?.data?.alarms)
      );
  }

  /**
   * clone automation rule
   *
   * @param {number} id
   * @returns {Observable<AutomationRule>}
   * @memberof AutomationRulesDataService
   */
  cloneAutomationRule(id: number): Observable<AutomationRule> {
    return this.apollo
      .mutate<{ cloneAutomationRule: AutomationRule }>({
        mutation: CLONE_AUTOMATION_RULE_QUERY,
        variables: {
          id,
        },
      })
      .pipe(
        filter((d) => !!d),
        map((res) => res?.data?.cloneAutomationRule),
        catchError((err) => {
          this.notification.showError(ERROR_CLONE_AUTOMATION_RULE);
          return throwError(err);
        }),
        tap(() => {
          this.notification.showSuccess(
            'Automation Rules',
            `Automation rule R${id} cloned`
          );
        })
      );
  }
}
