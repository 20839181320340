import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'any' })
export class HttpCancelDataService {
  private pendingHTTPRequests$ = new Subject<void>();

  constructor() {}
  /**
   * trigger next observable cancel pending requests
   *
   * @memberof HttpCancelDataService
   */
  public cancelPendingRequests() {
    this.pendingHTTPRequests$.next();
  }
  /**
   * return obserable for pending http requests
   *
   * @return {*}
   * @memberof HttpCancelDataService
   */
  public onCancelPendingRequests() {
    return this.pendingHTTPRequests$.asObservable();
  }
}
