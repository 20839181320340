import { take, filter } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Select } from '@ngxs/store';
import { CoreState } from '@vfi-ui/state';

@Injectable({
  providedIn: 'root',
})
export class GraphQlResolver {
  @Select(CoreState.graphQLReady) gqlReady$: Observable<boolean>;

  constructor() {}

  resolve(): Observable<boolean> {
    return this.graphQLReady$();
  }

  graphQLReady$(): Observable<boolean> {
    return this.gqlReady$.pipe(
      filter((graphQl) => !!graphQl),
      take(1)
    );
  }
}
