import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import {
  AlarmInstanceReportExportInput,
  ERROR_GENERATE_EXPORT,
  LookerViewType,
  OrderDirection,
  ReportExport,
} from '@vfi-ui/models';
import { map, catchError, filter, tap } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import { NotificationService } from './notification.service';
import {
  GENERATE_ALARM_REPORT_EXPORT,
  GET_REPORT_EXPORTS,
  GET_REPORT_QUERY,
} from '../queries/report-export.query';
import { HttpClient } from '@angular/common/http';
import { environment } from '@vfi-ui/environments/environment';
import { downloadFile } from '@vfi-ui/util/helpers';

@Injectable({
  providedIn: 'root',
})
export class ReportExportDataService {
  constructor(
    private apollo: Apollo,
    private notification: NotificationService,
    private http: HttpClient
  ) {}

  /**
   * calls api to fetch report exports
   *
   * @returns {Observable<ReportExport[]>}
   * @memberof ReportExportDataService
   */
  getReportExports(): Observable<ReportExport[]> {
    const input = {
      order: { field: 'CREATED_AT', direction: OrderDirection.DESC },
    };
    return this.apollo
      .query<{ reportExports: ReportExport[] }>({
        fetchPolicy: 'network-only',
        query: GET_REPORT_EXPORTS,
        variables: { input },
      })
      .pipe(
        map((reports) => reports?.data?.reportExports),
        catchError((error) => throwError(error))
      );
  }

  /**
   * call api to download report export
   *
   * @param {string} fileUrl
   * @param {string} fileName
   * @returns
   * @memberof ReportExportDataService
   */
  downloadReportExport(fileUrl: string, fileName: string) {
    return this.http
      .post(
        environment.backend + '/download/report',
        {
          route: fileUrl,
          type: 'REPORT_EXPORTS',
        },
        { responseType: 'arraybuffer', observe: 'response' }
      )
      .pipe(
        filter((d) => !!d),
        catchError((err) => {
          this.notification.showError(ERROR_GENERATE_EXPORT);
          return throwError(err);
        }),
        tap((res) => {
          const file = new Blob([res.body], {
            type: 'application/gzip',
          });
          downloadFile(file, fileName);
        }),
        map((res) => res)
      );
  }

  /**
   * call api to start report export
   *
   * @param {Partial<AlarmInstanceReportExportInput>} input
   * @param {number} totalCount
   * @returns {Observable<Partial<ReportExport>>}
   * @memberof ReportExportDataService
   */
  generateAlarmReportExport(
    input: Partial<AlarmInstanceReportExportInput>,
    totalCount: number
  ): Observable<Partial<ReportExport>> {
    return this.apollo
      .mutate<{ createAlarmInstanceReportExport: Partial<ReportExport> }>({
        mutation: GENERATE_ALARM_REPORT_EXPORT,
        variables: { input },
      })
      .pipe(
        map((res) => res?.data?.createAlarmInstanceReportExport),
        tap(() => {
          const warning =
            totalCount > 10000
              ? 'Only first 10,000 records will be exported.'
              : '';
          this.notification.showSuccess(
            'Export Report',
            `${warning} Report will automatically download when it is done processing.`
          );
        }),
        catchError((error) => {
          this.notification.showError(ERROR_GENERATE_EXPORT);
          return throwError(error);
        })
      );
  }

  /**
   * Gets the dashboard url
   *
   * @param {LookerViewType} type
   * @return {*}  {Observable<string>}
   * @memberof ReportExportDataService
   */
  getDashboard(type: LookerViewType): Observable<string> {
    return this.apollo
      .query<{
        getReportUrl: string;
      }>({
        fetchPolicy: 'no-cache',
        query: GET_REPORT_QUERY,
        variables: { type },
      })
      .pipe(
        filter((d) => !!d),
        map((res) => res?.data?.getReportUrl),
        catchError((error) => throwError(error))
      );
  }
}
