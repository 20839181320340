<footer id="footer" class="d-flex align-items-center pt-2 pb-2 w-100 pr-8">
  <div class="logo flex-shrink-0">
    <a href="#"
      ><img src="/assets/virtual_facility_logo.png" alt="Virtual Facility"
    /></a>
  </div>
  <ul class="fnav list-unstyled d-flex flex-shrink-0">
    <li *ngIf="showUsers">
      <a class="btn-circle" [routerLink]="'/users'" routerLinkActive="active"
        ><i class="fas fa-hospital-user"></i
      ></a>
    </li>
    <li *ngIf="environment.showSalesSimulator">
      <a
        class="btn-circle"
        [routerLink]="'/simulator'"
        routerLinkActive="active"
      >
        <i class="fas fa-user-astronaut"></i
      ></a>
    </li>
    <li>
      <a
        class="btn-circle"
        [routerLink]="'/alarm-throughput'"
        routerLinkActive="active"
      >
        <i class="fas fa-retweet"></i
      ></a>
    </li>
  </ul>
  <div class="flex-grow-1"></div>
  <ul class="fnav list-unstyled d-flex flex-shrink-0 pr-3">
    <li>
      <button
        class="btn-circle"
        nzSize="large"
        nz-button
        nz-dropdown
        nzTrigger="click"
        [nzDropdownMenu]="userMenu"
      >
        <i class="fas fa-user"></i>
      </button>
      <nz-dropdown-menu #userMenu="nzDropdownMenu">
        <ul nz-menu class="user-menu">
          <li
            class="t-border-9 ipt-1 ipb-1 ipr-2"
            nz-menu-item
            (click)="signOut()"
          >
            <span class="font-semibold regular-14 neutral-1">Log Out</span>
          </li>
        </ul>
      </nz-dropdown-menu>
    </li>
  </ul>
</footer>
