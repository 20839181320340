import {
  EventEmitter,
  Component,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MenuLens, AlarmViews } from '@vfi-ui/models';

@Component({
  selector: 'atom-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss'],
})
export class TabComponent implements OnChanges {
  @Input() url: string;
  @Input() listRoute: string;
  @Input() dashRoute: string;
  @Input() tableRoute: string;
  @Input() lens: MenuLens;
  @Input() listDisabled = false;
  @Input() dashDisabled = false;
  @Input() alarmView: AlarmViews;
  @Output() tabChanged = new EventEmitter<string>();

  stateUrl: string;
  alarmViews = AlarmViews;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.lens && changes.lens.currentValue) {
      this.stateUrl = `${this.lens.category}/${this.lens.name}/${this.lens.id}`;
    }
  }

  /**
   * show active status based off url
   *
   * @param {string} url
   * @param {string} type
   * @returns {boolean}
   * @memberof TabComponent
   */
  isActive(url: string, type: string): boolean {
    return url?.includes(type) || false;
  }
  /**
   * fire tab changed event
   *
   * @param {string} ev
   * @memberof TabComponent
   */
  change(ev: string) {
    this.tabChanged.emit(ev);
  }
}
