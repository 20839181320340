export const GET_PRODUCTS_FEATURES = `
  query productsAndCount {
    productsAndCount {
      items {
        id
        name
        features {
          id
          name
        }
      }
    }
  }
`;

export const UPDATE_PRODUCTS = `
  mutation updateProducts($customerId: Int!, $productIds: [Int!]!) {
    updateProducts(customerId: $customerId, productIds: $productIds)
  }
`;

export const UPDATE_SITUATIONAL_AWARENESS_TIMERS = `
  mutation updateSituationalDashboardThresholds($input: [SituationalDashboardThresholdsInput!]!) {
    updateSituationalDashboardThresholds(input: $input) {
      timeToImpact
      timeToEscalation
      criticality
    }
  }
`;
