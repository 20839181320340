import { DeviceDetectorService } from 'ngx-device-detector';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { map, take, filter } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AuthRoles } from '@vfi-ui/models';
import { AuthState } from '@vfi-ui/state';

@Injectable({
  providedIn: 'root',
})
export class RestrictedMobileResolver {
  @Select(AuthState.getAuthRole) authRole$: Observable<AuthRoles>;

  constructor(
    private router: Router,
    private deviceDetectorService: DeviceDetectorService
  ) {}

  resolve(): Observable<boolean> {
    return this.getPermissionAndDevice();
  }

  /**
   * determines the permission and device of the user
   *
   * @returns {Observable<boolean>}
   * @memberof RestrictedMobileResolver
   */
  getPermissionAndDevice(): Observable<boolean> {
    const isMobile = this.deviceDetectorService.isMobile();
    return this.authRole$.pipe(
      filter((authRole) => !!authRole),
      map((authRole) => {
        if (
          isMobile &&
          (authRole === AuthRoles.none ||
            localStorage.getItem('vfi-deepLink-params'))
        ) {
          this.router.navigate(['/501']);
        }
        localStorage.removeItem('vfi-deepLink-params');
        return true;
      }),
      take(1)
    );
  }
}
