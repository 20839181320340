import { Inject, Injectable } from '@angular/core';
import { firstValueFrom, map, Observable, of } from 'rxjs';
import { CapacitorGlobal } from '@capacitor/core';
import { GuardResult, RedirectCommand, Router } from '@angular/router';
import { AuthService, MfaService } from '../services';
import { CAPACITOR_CORE_TOKEN, CUSTOMER_ID_HEADER } from '@vfi-ui/models';
import { multiFactor } from 'firebase/auth';

@Injectable({
  providedIn: 'root',
})
export class MfaGuard {
  constructor(
    private mfaService: MfaService,
    private authService: AuthService,
    private router: Router,
    @Inject(CAPACITOR_CORE_TOKEN) private capacitor: CapacitorGlobal
  ) {}

  /**
   * determines if user has access to mfa pages.
   * If no, redirect to login page
   *
   * @returns {Observable<boolean>}
   * @memberof LoginGuard
   */
  canActivate(): Observable<GuardResult> {
    if (this.capacitor.isNativePlatform()) {
      return of(true);
    }
    if (this.mfaService.factorId) {
      return of(true);
    }

    return this.authService.getFirebaseUser().pipe(
      map((user) => {
        if (user) {
          return true;
        }
        return new RedirectCommand(this.router.parseUrl('login'));
      })
    );
  }

  async canRedirect(): Promise<GuardResult> {
    const firebaseUser = await firstValueFrom(
      this.authService.getFirebaseUser()
    );
    if (!firebaseUser) {
      return false;
    }

    const customerId = localStorage.getItem(CUSTOMER_ID_HEADER);
    if (!customerId) {
      return true;
    }
    const customer = await firstValueFrom(
      this.authService.getCustomerById([+customerId])
    );
    const multiFactorUser = multiFactor(firebaseUser);
    const isFirebaseMfaActive = !!multiFactorUser.enrolledFactors.length;
    const isSsoEnabled = !!firebaseUser.tenantId;
    const shouldEnrollInMFA =
      customer?.isMFAEnabled && !isFirebaseMfaActive && !isSsoEnabled;

    if (shouldEnrollInMFA) {
      this.mfaService.showSecure = true;
      this.mfaService.showVerification = false;
      this.mfaService.showMFASuccessToast = true;

      return new RedirectCommand(this.router.parseUrl('mfa'));
    }
    return true;
  }
}
