import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import {
  ExternalCmmsField,
  ExternalCmmsDomainValueCreateInput,
  MODAL_Z_INDEX,
} from '@vfi-ui/models';
import { get } from '@vfi-ui/util/helpers';

@Component({
  selector: 'nuclei-create-cmms-domain-value',
  templateUrl: './create-cmms-domain-value.component.html',
  styleUrls: ['./create-cmms-domain-value.component.scss'],
})
export class CreateCmmsDomainValueComponent implements OnInit, OnChanges {
  @Input() isVisible = false;
  @Input() disableAddValueButton = false;
  @Input() selectedCmmsField: ExternalCmmsField;
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter();
  @Output()
  createCmmsDomainValue: EventEmitter<ExternalCmmsDomainValueCreateInput> =
    new EventEmitter();
  form: UntypedFormGroup;
  modalZIndex = MODAL_Z_INDEX;

  constructor(private readonly formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      name: [null, Validators.required],
      value: [null, Validators.required],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (get(changes.isVisible, 'currentValue', false)) {
      this.form.reset();
    }
  }

  /**
   * Emit event to create cmms value.
   *
   * @memberof CreateCmmsDomainValueComponent
   */
  handleCreateCmmsDomainValue() {
    this.createCmmsDomainValue.emit(this.form.value);
  }

  /**
   * Invoked when the cancel button is selected. Closes
   * the modal and resets the form.
   *
   * @memberof CreateCmmsDomainValueComponent
   */
  handleCancel() {
    this.form.reset();
    this.closeModal.emit(false);
  }
}
