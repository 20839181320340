<div class="dropdown-list" role="listbox" aria-expanded="false">
  <div
    class="back"
    *ngIf="
      (parent.length === 1 && type) ||
      (baseEntity?.id ? parent[0] : parent[parent?.length - 1])
    "
    (click)="back()"
  >
    <i class="fas fa-chevron-left"></i>
    <span>{{ parent[parent?.length - 1]?.name }}</span>
  </div>

  <form [formGroup]="formGroup">
    <input
      type="text"
      class="dropdown-search"
      placeholder="Search"
      formControlName="search"
      (keyup)="search($event.target.value)"
    />
  </form>

  <div class="alert" *ngIf="loading">Loading...</div>
  <div class="alert" *ngIf="options?.length === 0 && !loading">No Results</div>

  <div class="list-container" *ngIf="showTypeSelector && !type">
    <atom-dropdown-item
      *ngFor="let o of entityTypeOptions"
      [showCheckbox]="!showTypeSelector"
      [item]="o"
      [title]="o?.name"
      [showBadge]="formGroup.get('search').value"
      [badgeCount]="o?.name === 'Space' ? spaceTypeCount : assetTypeCount"
      [showRightIcon]="true"
      (drillDownChange)="pickEntityType($event)"
    ></atom-dropdown-item>
  </div>

  <div
    class="list-container"
    *ngIf="!loading && type"
    #listContainer
    infiniteScroll
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="50"
    [infiniteScrollContainer]="listContainer"
    (scrolled)="scroll()"
  >
    <atom-dropdown-item
      *ngFor="let o of options"
      [item]="o"
      [title]="type === 'Asset' ? formatAssetName(o) : o?.name"
      [selected]="formGroup.get('selected').value === o"
      [showBadge]="formGroup.get('search').value"
      [showRightIcon]="o?.count > 0"
      (selectChange)="formGroup.get('selected').setValue($event)"
      (drillDownChange)="drillDown($event)"
    ></atom-dropdown-item>
  </div>

  <button
    class="select-btn"
    [disabled]="formGroup.get('selected').invalid"
    *ngIf="options?.length && !loading && type"
    (click)="select(formGroup.get('selected').value)"
  >
    {{ buttonLabel }}
  </button>
</div>
