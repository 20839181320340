import { Component, Input } from '@angular/core';

@Component({
  selector: 'atom-badge-performance-metric',
  templateUrl: './badge-performance-metric.component.html',
  styleUrls: ['./badge-performance-metric.component.scss'],
})
export class BadgePerformanceMetricComponent {
  @Input() percentage: number;
  @Input() delta: number;
  @Input() show = false;
  isFinite = Number.isFinite;
  abs = Math.abs;

  constructor() {}
}
