import { Injectable } from '@angular/core';
import { GuardResult, RedirectCommand, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class DesktopRedirectGuard {
  constructor(private router: Router) {}

  /**
   * navigate to alarms table by default
   *
   * @returns {GuardResult}
   * @memberof DesktopRedirectGuard
   */
  canActivate(): GuardResult {
    return new RedirectCommand(
      this.router.parseUrl('alarms/Standard Lenses/Active Alarms')
    );
  }
}
