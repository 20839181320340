import { GET_WORK_RESOLUTION_WORK_TICKETS_QUERY } from './../queries/work-resolution.query';
import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import {
  ERROR_GET_WORK_TICKETS,
  WorkResolutionWorkTicket,
  WORK_TICKETS_REPORT_MAX_DAYS_RANGE,
} from '@vfi-ui/models';
import { catchError, filter, map } from 'rxjs/operators';
import { NotificationService } from './notification.service';
import { startOfDay, subDays } from 'date-fns';
@Injectable({
  providedIn: 'root',
})
export class WorkTicketsDataService {
  constructor(
    private apollo: Apollo,
    private notification: NotificationService
  ) {}

  /**
   * call api to fetch work history tickets
   *
   * @returns {Observable<WorkResolutionWorkTicket[]>}
   * @memberof WorkTicketsDataService
   */
  getWorkResolutionWorkTickets(): Observable<WorkResolutionWorkTicket[]> {
    return this.apollo
      .query<{ workResolutionWorkTickets: WorkResolutionWorkTicket[] }>({
        fetchPolicy: 'no-cache',
        query: GET_WORK_RESOLUTION_WORK_TICKETS_QUERY,
        variables: {
          input: {
            workTicketInput: {
              createdAt: {
                from: startOfDay(
                  subDays(new Date(), WORK_TICKETS_REPORT_MAX_DAYS_RANGE)
                ),
                to: new Date(),
              },
            },
          },
        },
      })
      .pipe(
        filter((d) => !!d),
        map((activity) => activity?.data?.workResolutionWorkTickets),
        catchError((error) => {
          this.notification.showError(ERROR_GET_WORK_TICKETS);
          return throwError(error);
        })
      );
  }
}
