<div class="d-flex align-items-center justify-content-between">
  <ul class="d-flex v-tab-list mb-0">
    <li
      class="v-tab regular-16 mr-1 pt-1 cursor"
      [routerLink]="
        alarmView === alarmViews.table
          ? tableRoute + lens.name
          : listRoute + stateUrl
      "
      [ngClass]="{
        active: isActive(url, '/list/') || isActive(url, '/table/'),
        'tab-disabled': listDisabled
      }"
      [attr.aria-label]="lens?.type"
      (click)="change(lens.type)"
    >
      <span class="neutral-1">{{ lens.type | titlecase }}</span>
    </li>
    <li
      class="v-tab regular-16 pt-1"
      [routerLink]="dashRoute + stateUrl"
      [ngClass]="{
        active: isActive(url, '/dash/'),
        'tab-disabled': dashDisabled
      }"
      aria-label="dashboard"
      (click)="change('Dashboard')"
    >
      <span class="neutral-1">Dashboard</span>
    </li>
  </ul>
</div>
