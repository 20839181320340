/**
 * Checks if `value` is `null` or `undefined`.
 *
 * @param {*} value The value to check.
 * @returns {boolean} Returns `true` if `value` is nullish, else `false`.
 * @example
 *
 * _.isNil(null);
 * // => true
 *
 * _.isNil(void 0);
 * // => true
 *
 * _.isNil(NaN);
 * // => false
 */
export function isNil(value: any): boolean {
  return value == null || value === undefined;
}

/**
 * Checks if `value` is `null`.
 *
 * @param {*} value The value to check.
 * @returns {boolean} Returns `true` if `value` is `null`, else `false`.
 * @example
 *
 * _.isNull(null);
 * // => true
 *
 * _.isNull(void 0);
 * // => false
 */
export function isNull(value: any): boolean {
  return value === null;
}

/**
 * Checks if `value` is `undefined`.
 *
 * @param {*} value The value to check.
 * @returns {boolean} Returns `true` if `value` is `undefined`, else `false`.
 * @example
 *
 * _.isUndefined(void 0);
 * // => true
 *
 * _.isUndefined(null);
 * // => false
 */
export function isUndefined(value: any): boolean {
  return value === undefined;
}

/**
 * Checks if `value` is an empty object, collection, map, or set.
 *
 * Objects are considered empty if they have no own enumerable string keyed
 * properties.
 *
 * Array-like values such as `arguments` objects, arrays, buffers, strings, or
 * Similarly, maps and sets are considered empty if they have a `size` of `0`.
 *
 * @param {*} value The value to check.
 * @returns {boolean} Returns `true` if `value` is empty, else `false`.
 * @example
 *
 * isEmpty(null)
 * // => true
 *
 * isEmpty(true)
 * // => true
 *
 * isEmpty(1)
 * // => true
 *
 * isEmpty([1, 2, 3])
 * // => false
 *
 * isEmpty('abc')
 * // => false
 *
 * isEmpty({ 'a': 1 })
 * // => false
 */
export function isEmpty(value: any): boolean {
  return (
    [Object, Array].includes((value || {}).constructor) &&
    !Object.entries(value || {}).length
  );
}

/**
 * Checks if `value` is classified as a boolean primitive.
 *
 * @param {*} value The value to check.
 * @returns {boolean} Returns `true` if `value` is a boolean, else `false`.
 * @example
 *
 * _.isBoolean(false);
 * // => true
 *
 * _.isBoolean(null);
 * // => false
 */
export function isBoolean(value: any): boolean {
  return typeof value === 'boolean';
}

/**
 * Checks if `value` is classified as a `Date` object.
 *
 * @param {*} value The value to check.
 * @returns {boolean} Returns `true` if `value` is a date object, else `false`.
 * @example
 *
 * _.isDate(new Date);
 * // => true
 *
 * _.isDate('Mon April 23 2012');
 * // => false
 */
export function isDate(value: any): boolean {
  return Object.prototype.toString.call(value) === '[object Date]';
}

/**
 * Gets the value at `path` of `object`. If the resolved value is
 * `undefined`, the `defaultValue` is returned in its place.
 *
 * @param {Object} object The object to query.
 * @param {Array|string} path The path of the property to get.
 * @param {*} [defaultValue] The value returned for `undefined` resolved values.
 * @returns {*} Returns the resolved value.
 * @example
 *
 * const object = { 'a': [{ 'b': { 'c': 3 } }] }
 *
 * get(object, 'a[0].b.c')
 * // => 3
 *
 * get(object, ['a', '0', 'b', 'c'])
 * // => 3
 *
 * get(object, 'a.b.c', 'default')
 * // => 'default'
 */
export function get(obj, path, defaultValue = undefined) {
  const travel = (regexp: RegExp) =>
    String.prototype.split
      .call(path, regexp)
      .filter(Boolean)
      .reduce(
        (res, key) => (res !== null && res !== undefined ? res[key] : res),
        obj
      );
  const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/);
  return result === undefined || result === obj ? defaultValue : result;
}

/**
 * Gets the first element of `array`.
 *
 * @param {*} array
 * @returns {*}
 * @example
 *
 * _.head([1, 2, 3]);
 * // => 1
 *
 * _.head([]);
 * // => undefined
 */
export function head(array: any[]): any {
  if (array && array.length) {
    const [head] = array;
    return head;
  }
  return undefined;
}

/**
 * Gets the last element of `array`.
 *
 * @param {any[]} array
 * @returns {*}
 * @example
 *
 * _.last([1, 2, 3]);
 * // => 3
 */
export function last(array: any[]): any {
  var length = array == null ? 0 : array.length;
  return length ? array[length - 1] : undefined;
}

/**
 * Creates a duplicate-free version of an array, using
 * [`SameValueZero`](http://ecma-international.org/ecma-262/7.0/#sec-samevaluezero)
 * for equality comparisons, in which only the first occurrence of each element
 * is kept. The order of result values is determined by the order they occur
 * in the array.
 *
 * @param {any[]} array
 * @returns {any[]}
 * @example
 *
 * _.uniq([2, 1, 2]);
 * // => [2, 1]
 */
export function uniq(array: any[]): any[] {
  return Array.from(new Set(array));
}

/**
 * Converts the first character of `string` to upper case.
 *
 * @param {string} [string=''] The string to convert.
 * @returns {string} Returns the converted string.
 * @example
 *
 * _.upperFirst('fred');
 * // => 'Fred'
 *
 * _.upperFirst('FRED');
 * // => 'FRED'
 */
export function upperFirst(string: string): string {
  if (!string) {
    return string;
  }
  return string?.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Converts the first character of `string` to lower case.
 *
 * @param {string} [string=''] The string to convert.
 * @returns {string} Returns the converted string.
 * @example
 *
 * _.lowerFirst('Fred');
 * // => 'fred'
 *
 * _.lowerFirst('FRED');
 * // => 'fRED'
 */
export function lowerFirst(string: string): string {
  return string.charAt(0).toLowerCase() + string.slice(1);
}

/**
 * Creates an array of numbers (positive) progressing from start up to, but not including, end.
 *
 * @export
 * @param {number} length
 * @returns {number[]}
 * @example
 *
 * range(4);
 * // => [0, 1, 2, 3]
 */
export function range(length: number): number[] {
  return Array.from({ length }, (_, i) => i);
}

/**
 * Creates an object composed of the picked object properties.
 *
 * @export
 * @param {*} object
 * @param {string[]} keys
 * @returns {*}
 * @example
 *
 * var object = { 'a': 1, 'b': '2', 'c': 3 };
 *
 * pick(object, ['a', 'c']);
 * // => { 'a': 1, 'c': 3 }
 */
export function pick(object: any, keys: string[]): any {
  return keys.reduce((obj, key) => {
    if (object && object.hasOwnProperty(key)) {
      obj[key] = object[key];
    }
    return obj;
  }, {});
}

/**
 * Computes the sum of the values in `array`.
 * It accepts `iteratee` which is
 * invoked for each element in `array` to generate the value to be summed.
 * The iteratee is invoked with one argument: (value).
 *
 * @export
 * @param {any[]} arr
 * @param {string} value
 * @returns {number}
 * @example
 *
 * var objects = [{ 'n': 4 }, { 'n': 2 }, { 'n': 8 }, { 'n': 6 }];
 *
 * _.sumBy(objects, function(o) { return o.n; });
 * // => 20
 *
 * // The `_.property` iteratee shorthand.
 * _.sumBy(objects, 'n');
 * // => 20
 */
export function sumBy(arr: any[], value: string): number {
  return arr.reduce((acc, item) => acc + item[value], 0);
}

/**
 * Checks if `path` is a direct property of `object`.
 *
 * @export
 * @param {*} obj
 * @param {*} path
 * @returns {boolean}
 * @example
 *
 * var object = { 'a': { 'b': 2 } };
 * var other = _.create({ 'a': _.create({ 'b': 2 }) });
 *
 * _.has(object, 'a');
 * // => true
 *
 * _.has(object, 'a.b');
 * // => true
 *
 * _.has(object, ['a', 'b']);
 * // => true
 *
 * _.has(other, 'a');
 * // => false
 */
export function has(obj: any, path: any): boolean {
  const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g);
  return !!pathArray.reduce((prevObj, key) => prevObj && prevObj[key], obj);
}

/**
 * Creates an array of unique values, in order, from all given arrays using
 * [`SameValueZero`](http://ecma-international.org/ecma-262/7.0/#sec-samevaluezero)
 * for equality comparisons.
 *
 * @export
 * @param {*} arr
 * @param {*} args
 * @returns {any[]}
 * @example
 *
 * _.union([2], [1, 2]);
 * // => [2, 1]
 */
export function union(arr: any, ...args): any[] {
  return Array.from(new Set(arr.concat(...args)));
}

/**
 * Removes all elements from `array` that `predicate` returns truthy for
 * and returns an array of the removed elements. The predicate is invoked
 * with three arguments: (value, index, array).
 *
 * **Note:** Unlike `_.filter`, this method mutates `array`. Use `_.pull`
 * to pull elements from an array by value.
 *
 * @export
 * @param {any[]} array
 * @param {*} iteratee
 * @returns {any[]}
 * @example
 *
 * var array = [1, 2, 3, 4];
 * var evens = _.remove(array, function(n) {
 *   return n % 2 == 0;
 * });
 *
 * console.log(array);
 * // => [1, 3]
 *
 * console.log(evens);
 * // => [2, 4]
 */
export function remove(array: any[], iteratee): any[] {
  // in order to not mutate the original array until the very end
  // we want to cache the indexes to remove while preparing the result to return
  const toRemove = [];
  const result = array.filter((item, i) => iteratee(item) && toRemove.push(i));

  // just before returning, we can then remove the items, making sure we start
  // from the higher indexes: otherwise they would shift at each removal
  toRemove.reverse().forEach((i) => array.splice(i, 1));
  return result;
}

/**
 * Sets the value at `path` of `object`. If a portion of `path` doesn't exist,
 * it's created. Arrays are created for missing index properties while objects
 * are created for all other missing properties. Use `_.setWith` to customize
 * `path` creation.
 *
 * **Note:** This method mutates `object`.
 *
 * @export
 * @param {*} obj
 * @param {*} path
 * @param {*} value
 * @example
 *
 * var object = { 'a': [{ 'b': { 'c': 3 } }] };
 *
 * _.set(object, 'a[0].b.c', 4);
 * console.log(object.a[0].b.c);
 * // => 4
 *
 * _.set(object, ['x', '0', 'y', 'z'], 5);
 * console.log(object.x[0].y.z);
 * // => 5
 */
export function set(obj: any, path: any, value: any) {
  // Regex explained: https://regexr.com/58j0k
  const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g);

  pathArray.reduce((acc, key, i) => {
    if (acc[key] === undefined) acc[key] = {};
    if (i === pathArray.length - 1) acc[key] = value;
    return acc[key];
  }, obj);
}
/**
 * This method creates an object composed of the
 * own and inherited enumerable property paths of `object` that are not omitted.
 *
 * @export
 * @param {*} obj
 * @param {string[]} props
 * @returns {*}
 * @example
 *
 * var object = { 'a': 1, 'b': '2', 'c': 3 };
 *
 * _.omit(object, ['a', 'c']);
 * // => { 'b': '2' }
 */
export function omit(obj: any, props: string[]): any {
  obj = { ...obj };
  props.forEach((prop) => delete obj[prop]);
  return obj;
}

/**
 * Computes number rounded to precision.
 *
 * @export
 * @param {number} num
 * @param {number} precision
 * @returns {number}
 * @example
 *
 * Math.round(4.006)
 * // => 4
 *
 * round(4.006, 2)
 * // => 4.01
 *
 * round(4060, -2)
 * // => 4100
 */
export function round(num: number, precision: number): number {
  const modifier = 10 ** precision;
  return Math.round(num * modifier) / modifier;
}

/**
 * Converts `string` to
 * [start case](https://en.wikipedia.org/wiki/Letter_case#Stylistic_or_specialised_usage).
 *
 * @export
 * @param {string} value
 * @returns {string}
 * @example
 *
 * _.startCase('__FOO_BAR__');
 * // => 'FOO BAR'
 *
 * _.startCase('--foo-bar--');
 * // => 'Foo Bar'
 */
export function startCase(value: string): string {
  return value
    .replace(/[^a-zA-Z0-9]/g, ' ')
    .split(' ')
    .map(upperFirst)
    .join(' ');
}
/**
 * Converts `string` to
 * [start case](https://en.wikipedia.org/wiki/Letter_case#Stylistic_or_specialised_usage).
 *
 * @export
 * @param {string} value
 * @returns {string}
 * @example
 *
 * _.startCase('__FOO_BAR__');
 * // => 'Foo Bar'
 *
 * _.startCase('--foo-bar--');
 * // => 'Foo Bar'
 */
export function startCaseCap(value: string): string {
  return value
    .replace(/[^a-zA-Z0-9]/g, ' ')
    .split(' ')
    .map((st) => st.toLowerCase())
    .map(upperFirst)
    .join(' ');
}

/**
 * Creates an array excluding all given values using
 * [`SameValueZero`](http://ecma-international.org/ecma-262/7.0/#sec-samevaluezero)
 * for equality comparisons.
 *
 * **Note:** Unlike `_.pull`, this method returns a new array.
 *
 * @export
 * @param {any[]} arr
 * @param {...any} args
 * @returns {any[]}
 * @example
 *
 * _.without([2, 1, 2, 3], 1, 2);
 * // => [3]
 */
export function without(arr: any[], ...args: any): any[] {
  return arr.filter((item) => !args.includes(item));
}

/**
 * Creates an array of values corresponding to `paths` of `object`.
 *
 * @export
 * @param {*} obj
 * @param {string[]} paths
 * @returns {any[]}
 * @example
 *
 * var object = { 'a': [{ 'b': { 'c': 3 } }, 4] };
 *
 * _.at(object, ['a[0].b.c', 'a[1]']);
 * // => [3, 4]
 */
export function at(obj: any, paths: string[]): any[] {
  const values = paths.map((p) => {
    return get(obj, p);
  });
  return values;
}

/**
 * Creates an object composed of keys generated from the results of running
 * each element of `collection` thru `iteratee`. The corresponding value of
 * each key is the last element responsible for generating the key. The
 * iteratee is invoked with one argument: (value).
 *
 * @export
 * @param {*} array
 * @param {*} key
 * @returns {*}
 * @example
 *
 * var array = [
 *   { 'dir': 'left', 'code': 97 },
 *   { 'dir': 'right', 'code': 100 }
 * ];
 *
 * _.keyBy(array, function(o) {
 *   return String.fromCharCode(o.code);
 * });
 * // => { 'a': { 'dir': 'left', 'code': 97 }, 'd': { 'dir': 'right', 'code': 100 } }
 *
 * _.keyBy(array, 'dir');
 * // => { 'left': { 'dir': 'left', 'code': 97 }, 'right': { 'dir': 'right', 'code': 100 } }
 */
export function keyBy(array, key): any {
  return (array || []).reduce((r, x) => ({ ...r, [key ? x[key] : x]: x }), {});
}

/**
 * This method is like `sortBy` except that it allows specifying the sort
 * orders of the iteratees to sort by. If `orders` is unspecified, all values
 * are sorted in ascending order. Otherwise, specify an order of "desc" for
 * descending or "asc" for ascending sort order of corresponding values.
 *
 * @export
 * @param {any[]} arr
 * @param {any[]} props
 * @param {string[]} orders
 * @returns {any[]}
 * @example
 *
 * var users = [
 *   { 'user': 'fred',   'age': 48 },
 *   { 'user': 'barney', 'age': 34 },
 *   { 'user': 'fred',   'age': 40 },
 *   { 'user': 'barney', 'age': 36 }
 * ];
 *
 * // Sort by `user` in ascending order and by `age` in descending order.
 * _.orderBy(users, ['user', 'age'], ['asc', 'desc']);
 * // => objects for [['barney', 36], ['barney', 34], ['fred', 48], ['fred', 40]]
 */
export function orderBy(arr: any[], props: any[], orders: string[]): any[] {
  if (arr) {
    return [...arr].sort((a, b) =>
      props.reduce((acc, prop, i) => {
        if (acc === 0) {
          const [p1, p2] =
            orders && (orders[i] === 'desc' || orders[i] === 'DESC')
              ? [b[prop], a[prop]]
              : [a[prop], b[prop]];
          acc = p1 > p2 ? 1 : p1 < p2 ? -1 : 0;
        }
        return acc;
      }, 0)
    );
  }
  return [];
}

/**
 * Converts `string` to
 * [snake case](https://en.wikipedia.org/wiki/Snake_case).
 *
 * @export
 * @param {string} str
 * @returns {string}
 * @example
 *
 * _.snakeCase('Foo Bar');
 * // => 'foo_bar'
 *
 * _.snakeCase('fooBar');
 * // => 'foo_bar'
 *
 * _.snakeCase('--FOO-BAR--');
 * // => 'foo_bar'
 */
export function snakeCase(str: string): string {
  return (
    str &&
    str
      .match(
        /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
      )
      .map((x) => x.toLowerCase())
      .join('_')
  );
}

/**
 * Creates an object composed of keys generated from the results of running
 * each element of `collection` thru `iteratee`. The order of grouped values
 * is determined by the order they occur in `collection`. The corresponding
 * value of each key is an array of elements responsible for generating the
 * key. The iteratee is invoked with one argument: (value).
 *
 *
 * @export
 * @param {*} arr
 * @param {*} fn
 * @returns {any}
 * @example
 *
 * _.groupBy([6.1, 4.2, 6.3], Math.floor);
 * // => { '4': [4.2], '6': [6.1, 6.3] }
 *
 * // The `_.property` iteratee shorthand.
 * _.groupBy(['one', 'two', 'three'], 'length');
 * // => { '3': ['one', 'two'], '5': ['three'] }
 */
export function groupBy(arr: any, fn: any): any {
  return arr
    ?.map(typeof fn === 'function' ? fn : (val) => val[fn])
    .reduce((acc, val, i) => {
      acc[val] = (acc[val] || []).concat(arr[i]);
      return acc;
    }, {});
}

/**
 * Creates an object composed of keys generated from the results of running
 * each element of `collection` thru `iteratee`. The corresponding value of
 * each key is the number of times the key was returned by `iteratee`. The
 * iteratee is invoked with one argument: (value).
 *
 * @export
 * @param {*} arr
 * @param {*} fn
 * @returns {*}
 * @example
 *
 * _.countBy([6.1, 4.2, 6.3], Math.floor);
 * // => { '4': 1, '6': 2 }
 *
 * // The `_.property` iteratee shorthand.
 * _.countBy(['one', 'two', 'three'], 'length');
 * // => { '3': 2, '5': 1 }
 */
export function countBy(arr: any, fn: any): any {
  return arr
    .map(typeof fn === 'function' ? fn : (val) => val[fn])
    .reduce((acc, val) => {
      acc[val] = (acc[val] || 0) + 1;
      return acc;
    }, {});
}

/**
 * This method is like `_.get` except that if the resolved value is a
 * function it's invoked with the `this` binding of its parent object and
 * its result is returned.
 *
 * @export
 * @param {Object} object The object to query.
 * @param {Array|string} path The path of the property to resolve.
 * @param {*} [defaultValue] The value returned for `undefined` resolved values.
 * @returns {*} Returns the resolved value.
 * @example
 *
 * var object = { 'a': [{ 'b': { 'c1': 3, 'c2': _.constant(4) } }] };
 *
 * _.result(object, 'a[0].b.c1');
 * // => 3
 *
 * _.result(object, 'a[0].b.c2');
 * // => 4
 *
 * _.result(object, 'a[0].b.c3', 'default');
 * // => 'default'
 *
 * _.result(object, 'a[0].b.c3', _.constant('default'));
 * // => 'default'
 */
export function result(
  obj: any,
  path: string,
  defaultValue: any = undefined
): any {
  const val = get(obj, path);
  return isNil(val) ? defaultValue : val;
}

/**
 * This method is like `_.uniq` except that it accepts `iteratee` which is
 * invoked for each element in `array` to generate the criterion by which
 * uniqueness is computed. The order of result values is determined by the
 * order they occur in the array. The iteratee is invoked with one argument:
 * (value).
 *
 * @export
 * @param {*} arr
 * @param {*} fn
 * @param {*} [set=new Set]
 * @returns {any[]}
 * @example
 *
 * _.uniqBy([2.1, 1.2, 2.3], Math.floor);
 * // => [2.1, 1.2]
 *
 * // The `_.property` iteratee shorthand.
 * _.uniqBy([{ 'x': 1 }, { 'x': 2 }, { 'x': 1 }], 'x');
 * // => [{ 'x': 1 }, { 'x': 2 }]
 */
export function uniqBy(arr: any, fn: any, set = new Set()): any[] {
  return arr.filter((el) =>
    ((v) => !set.has(v) && set.add(v))(
      typeof fn === 'function' ? fn(el) : el[fn]
    )
  );
}

/**
 * chunk arrary into smaller portions defined by size
 *
 * @export
 * @template T
 * @param {T[]} input
 * @param {number} size
 * @return {*}  {T[][]}
 */
export function chunk<T>(input: T[], size: number): T[][] {
  return input.reduce((arr, item, idx) => {
    return idx % size === 0
      ? [...arr, [item]]
      : [...arr.slice(0, -1), [...arr.slice(-1)[0], item]];
  }, []);
}

/**
 * checks if given string is a valid url
 *
 * @export
 * @param {string} str
 * @returns
 */
export function isURL(str: string) {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !!pattern.test(str);
}

/**
 * Flatten a multidimensional object
 *
 * For example:
 *   flattenObject({ a: 1, b: { c: 2 } })
 * Returns:
 *   { a: 1, c: 2}
 */
export function flattenObject(obj) {
  const flattened = {};

  keys(obj).forEach((key) => {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      Object.assign(flattened, flattenObject(obj[key]));
    } else {
      flattened[key] = obj[key];
    }
  });

  return flattened;
}

/**
 * Creates an array of the own enumerable property names of `object`.
 *
 * **Note:** Non-object values are coerced to objects. See the
 * [ES spec](http://ecma-international.org/ecma-262/7.0/#sec-object.keys)
 * for more details.
 *
 * @category Object
 * @param {Object} object The object to query.
 * @returns {Array} Returns the array of property names.
 * @example
 *
 * function Foo() {
 *   this.a = 1;
 *   this.b = 2;
 * }
 *
 * Foo.prototype.c = 3;
 *
 * keys(new Foo);
 * // => ['a', 'b'] (iteration order is not guaranteed)
 *
 * keys('hi');
 * // => ['0', '1']
 */
export function keys(obj) {
  if (!isNil(obj)) {
    return Object.keys(obj);
  }
  return [];
}

/**
 * Removes the property at `path` of `object`.
 *
 * **Note:** This method mutates `object`.
 *
 * @static
 * @memberOf _
 * @since 4.0.0
 * @category Object
 * @param {Object} object The object to modify.
 * @param {Array|string} path The path of the property to unset.
 * @returns {boolean} Returns `true` if the property is deleted, else `false`.
 * @example
 *
 * var object = { 'a': [{ 'b': { 'c': 7 } }] };
 * _.unset(object, 'a[0].b.c');
 * // => true
 *
 * console.log(object);
 * // => { 'a': [{ 'b': {} }] };
 *
 * _.unset(object, ['a', '0', 'b', 'c']);
 * // => true
 *
 * console.log(object);
 * // => { 'a': [{ 'b': {} }] };
 */
export function unset(obj, path) {
  // Regex explained: https://regexr.com/58j0k
  const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g);

  pathArray.reduce((acc, key, i) => {
    if (i === pathArray.length - 1) delete acc[key];
    return acc[key];
  }, obj);
}

/**
 * converts string to title case
 *
 * @export
 * @param {string} str
 * @returns {string}
 */
export function titleCase(str: string): string {
  if (!str) {
    return '';
  }
  return str
    .toLowerCase()
    .replace('_', ' ')
    .split(' ')
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
}

/**
 * checks if arrays are equal
 *
 * const a = [1, 2, 3];
 * const b = [1, 2, 3];
 * const str = 'a';
 * const strObj = new String('a');
 *
 * equals(a, b); // true
 * equals([str], [strObj]); // false
 * equals([null], [undefined]); // false
 *
 * @export
 * @param {any[]} a
 * @param {any[]} b
 * @returns
 */
export function arrayIsEqual(a: any[], b: any[]) {
  return a?.length === b?.length && a?.every((v, i) => v === b[i]);
}

/**
 * Creates an array of elements split into two groups
 * the first of which contains elements predicate returns truthy for
 * the second of which contains elements predicate returns falsey for
 * The predicate is invoked with one argument: (value).
 *
 * const [lessThan5, greaterThanEqual5] = partition([0,1,4,3,5,7,9,2,4,6,8,9,0,1,2,4,6], e => e < 5);
 * lessThan5 = [0, 1, 4, 3, 2, 4, 0, 1, 2, 4]
 * greaterThanEqual5 = [5, 7, 9, 6, 8, 9, 6]
 *
 * @export
 * @param {any[]} array
 * @param {*} filter
 * @returns
 */
export function partition(array: any[], filter: any) {
  let pass = [],
    fail = [];
  array.forEach((e, idx, arr) => (filter(e, idx, arr) ? pass : fail).push(e));
  return [pass, fail];
}

/**
 * Converts a string to camelcase.
 * Use String.prototype.match() to break the string into words using an appropriate regexp.
 * Use Array.prototype.map(), Array.prototype.slice(), Array.prototype.join(), String.prototype.toLowerCase() and String.prototype.toUpperCase() to combine them, capitalizing the first letter of each one.
 * toCamelCase('some_database_field_name') -> someDatabaseFieldName
 * toCamelCase('Some label that needs to be camelized') -> someLabelThatNeedsToBeCamelized
 * toCamelCase('some-javascript-property') -> someJavascriptProperty
 * toCamelCase('some-mixed_string with spaces_underscores-and-hyphens') -> someMixedStringWithSpacesUnderscoresAndHyphens
 *
 * @export
 * @param {*} str
 * @returns
 */
export function camelCase(str) {
  return str
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
}

/**
 * Adds space between capital letters
 * upperCaseWithSpace('HelloGoodbye') -> 'Hello Goodbye'
 *
 * @export
 * @param {string} str
 * @returns
 */
export function upperCaseWithSpace(str: string) {
  return (
    str.charAt(0).toUpperCase() +
    str
      .slice(1)
      .replace(/([A-Z])/g, ' $1')
      .trim()
  );
}
/**
 * This method supports comparing arrays, array buffers, booleans, date objects, error objects, maps, numbers, Object objects, regexes, sets, strings, symbols, and typed arrays.
 *
 * @export
 * @param {*} first
 * @param {*} second
 * @returns {boolean}
 */
export function isEqual(first: any, second: any): boolean {
  if (first === second) {
    return true;
  }
  if (
    (first === undefined ||
      second === undefined ||
      first === null ||
      second === null) &&
    (first || second)
  ) {
    return false;
  }
  const firstType = first?.constructor.name;
  const secondType = second?.constructor.name;
  if (firstType !== secondType) {
    return false;
  }
  if (firstType === 'Array') {
    if (first.length !== second.length) {
      return false;
    }
    let equal = true;
    for (let i = 0; i < first.length; i++) {
      if (!isEqual(first[i], second[i])) {
        equal = false;
        break;
      }
    }
    return equal;
  }
  if (firstType === 'Object') {
    let equal = true;
    const fKeys = Object.keys(first);
    const sKeys = Object.keys(second);
    if (fKeys.length !== sKeys.length) {
      return false;
    }
    for (let i = 0; i < fKeys.length; i++) {
      if (first[fKeys[i]] && second[fKeys[i]]) {
        if (first[fKeys[i]] === second[fKeys[i]]) {
          continue; // eslint-disable-line
        }
        if (
          first[fKeys[i]] &&
          (first[fKeys[i]].constructor.name === 'Array' ||
            first[fKeys[i]].constructor.name === 'Object')
        ) {
          equal = isEqual(first[fKeys[i]], second[fKeys[i]]);
          if (!equal) {
            break;
          }
        } else if (first[fKeys[i]] !== second[fKeys[i]]) {
          equal = false;
          break;
        }
      } else if (
        (first[fKeys[i]] && !second[fKeys[i]]) ||
        (!first[fKeys[i]] && second[fKeys[i]])
      ) {
        equal = false;
        break;
      }
    }
    return equal;
  }
  return first === second;
}
