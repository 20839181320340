<button
  class="vfi-button-defaults vfi-button-dark-mode {{ type }}-btn mr-1 {{
    size
  }} {{ classes }}"
  [disabled]="disabled || loading"
  [ngClass]="{ disabledBtn: disabled || loading, 'icon-only': !text?.length }"
  [attr.aria-label]="ariaLabel"
  nz-tooltip
  (click)="clicked.emit(true)"
>
  <span
    class="d-flex align-items-center justify-content-center"
    [ngClass]="{
      'flex-row-reverse': iconPosition === buttonIconPosition.RIGHT
    }"
  >
    <i
      *ngIf="icon && !loading"
      [ngClass]="[icon, iconPosition]"
      [class.iconOnly]="!text?.length"
    ></i>
    <span
      *ngIf="iconStack && !loading"
      class="left fa-stack regular-10"
      [class.iconOnly]="!text?.length"
    >
      <i *ngFor="let iconClass of iconStack" [ngClass]="[iconClass]"></i>
    </span>
    <i
      *ngIf="loading"
      class="left fas fa-spinner-third fa-spin"
      [class.iconOnly]="!text?.length"
    ></i>
    <span *ngIf="text?.length">{{ text }}</span>
  </span>
</button>
