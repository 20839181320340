import { Injectable } from '@angular/core';
import { ListControlEvents, SocketAction } from '@vfi-ui/models';
import { Observable, zip } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { NotificationService } from './notification.service';
import { SocketsService } from './sockets.service';

@Injectable({
  providedIn: 'root',
})
export class ObjectControlDataService {
  constructor(
    private notification: NotificationService,
    private socketService: SocketsService
  ) {}

  /**
   * listen for socket events and return when start and finish match
   *
   * @param {string} successMsg
   * @returns
   * @memberof ObjectControlDataService
   */
  public listenToSocketEvents({
    successMsg,
    initiator$,
  }: {
    successMsg?: string;
    initiator$?: Observable<any>;
  }) {
    const created$ = this.socketService.onMessage<ListControlEvents>(
      SocketAction.BULK_JOB_CREATED
    );
    const completed$ = this.socketService.onMessage<ListControlEvents>(
      SocketAction.BULK_JOB_COMPLETED
    );
    return zip(created$, initiator$, completed$).pipe(
      filter((d) => !!d),
      filter(([created, , completed]) => created.taskId === completed.taskId),
      tap(() => this.notification.showSuccess(successMsg || 'List Control'))
    );
  }
}
