import { InjectionToken, Injectable, Inject } from '@angular/core';

export interface CoreConfig {
  production: boolean;
  // apiKey: string
}

export const CoreConfigService = new InjectionToken<CoreConfig>('CoreConfig');

@Injectable({
  providedIn: 'root',
})
export class CoreService {
  constructor(@Inject(CoreConfigService) private config) {
    console.log('CoreService config', this.config);
  }

  public get isProduction() {
    return this.config.production;
  }

  public get isDevelopment() {
    return !this.config.production;
  }
}
