import { AuthRoles } from './roles.constants';
import { OrderDirection } from './work-ticket.constant';

export const DefaultUserSort = {
  sortValue: 'Display Name',
  sortDbName: 'displayName',
  sortType: 'asc' as OrderDirection,
};

export const DefaultUsersSort = {
  name: 'Display Name',
  dbName: 'displayName',
};

export const DisableDictionary = {
  true: 'disabled',
  false: 'active',
};

export const AuthRoleOptions = [
  {
    label: 'Admin',
    value: AuthRoles.admin,
  },
  {
    label: 'Manager',
    value: AuthRoles.manager,
  },
  {
    label: 'User',
    value: AuthRoles.standard,
  },
  {
    label: 'Vendor',
    value: AuthRoles.vendor,
  },
  {
    label: 'Occupant',
    value: AuthRoles.occupant,
  },
];

export const DefaultLandingPageUrls = {
  TRIAGE: '/alarms',
  TRIAGE_TABLE_VIEW: '/alarms/Standard%20Lenses/Active%20Alarms',
  // TODO: update when landing page in db changes
  EXPLORER: '/work',
  ANALYTICS: '/analytics',
  COMMAND_CENTER: '/command-center',
  ASSETS: '/assets',
};

export const LandingPageUrls = {
  TRIAGE: '/alarms',
  TRIAGE_TABLE_VIEW: '/alarms',
  // TODO: update when database landing page changes
  EXPLORER: '/work',
  ANALYTICS: '/analytics',
  COMMAND_CENTER: '/command-center',
  ASSETS: '/assets',
};
