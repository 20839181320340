import { GeoJSON } from '@vfi-ui/models';
import { Position, Point } from 'geojson';
import { LngLatLike, Map, GeoJSONFeature } from 'mapbox-gl';
import { booleanContains } from '@turf/boolean-contains';

/**
 * Gets coordinates from geojson
 *
 * @export
 * @param {GeoJSON} geoJSON
 * @return {[number, number]}
 */
export function getCoordinates(geoJSON: GeoJSON): [number, number] {
  let coords: Position;
  switch (geoJSON?.type) {
    case 'FeatureCollection':
      coords = (geoJSON?.features || [])[0]?.geometry?.coordinates || [];
      break;
    case 'Feature':
      coords = geoJSON?.geometry?.coordinates || [];
      break;
  }
  return coords as [number, number];
}

/**
 * Returns `true` if the given point coordinates
 * are within the given feature.
 *
 * @export
 * @param {Position} coords - The target coordinates
 * @param {GeoJSONFeature} feature - The feature to check
 * @return {boolean} `true` if the point is within the feature
 * bounds otherwise false
 */
export function isPointWithinFeature(
  coordinates: Position,
  feature: GeoJSONFeature
): boolean {
  const point = { type: 'Point', coordinates } as Point;
  const type = feature.geometry.type;
  let isPointWithin = false;
  if (type === 'Polygon') {
    isPointWithin = booleanContains(feature.geometry, point);
  }
  return isPointWithin;
}
/**
 * Gets the entity feature from the map given coords
 *
 * @export
 * @param {LngLatLike} coords
 * @param {Map} map
 * @return {GeoJSONFeature}
 */
export function getEntityFeature(
  coords: [number, number],
  map: Map
): GeoJSONFeature {
  if (coords?.length) {
    const point = map.project(coords as LngLatLike);
    const features = map.queryRenderedFeatures(point, {});
    for (const feature of features) {
      if (isPointWithinFeature(coords as Position, feature)) {
        return feature;
      }
    }
  }
}
