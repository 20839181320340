import {
  CmmsDataType,
  CmmsDisplayTemplate,
} from '../interfaces/cmms.interface';

export const CMMS_DATA_TYPE_OPTIONS = [
  {
    label: 'List',
    value: CmmsDataType.List,
  },
  {
    label: 'Boolean',
    value: CmmsDataType.Boolean,
  },
  {
    label: 'Number',
    value: CmmsDataType.Number,
  },
  {
    label: 'String',
    value: CmmsDataType.String,
  },
  {
    label: 'Date Time',
    value: CmmsDataType.DateTime,
  },
  {
    label: 'Text Area',
    value: CmmsDataType.TextArea,
  },
];

export const CMMS_DISPLAY_TEMPLATE_OPTIONS = [
  {
    label: 'None',
    value: 'null',
  },
  {
    label: 'Key Value',
    value: CmmsDisplayTemplate.KeyValue,
  },
  {
    label: 'Value',
    value: CmmsDisplayTemplate.Value,
  },
];
