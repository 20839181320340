import { gql } from 'apollo-angular';

import { WorkOrderRulesFields, allWorkOrderFields } from '@vfi-ui/models';

export const GET_ALL_WORK_ORDER_FIELDS_QUERY = gql`
  query work_order_view($where: work_order_view_bool_exp) {
    work_order_view(where: $where) {
      ${allWorkOrderFields}
    }
  }
`;

export const GET_FULL_WORK_ORDER_AUTO_COMPLETE_QUERY = gql`
  query fetchWorkOrderDomain(
    $field: String!
    $filter: String!
    $limit: Int!
    $offset: Int!
  ) {
    fetchWorkOrderDomain(
      field: $field
      filter: $filter
      limit: $limit
      offset: $offset
    )
  }
`;

export const GET_WORK_ORDER_AUTO_COMPLETE_QUERY = gql`
  query currentWorkOrderDomain(
    $field: String!
    $data: WorkOrderConfigurationCreateInput!
    $filter: String!
    $limit: Int!
    $offset: Int!
  ) {
    currentWorkOrderDomain(
      field: $field
      data: $data
      filter: $filter
      limit: $limit
      offset: $offset
    )
    currentWorkOrderDomainCount(field: $field, data: $data, filter: $filter)
  }
`;

export const APPLY_WORK_ORDER_RULES_QUERY = gql`
  query applyWorkOrderRules($field: String!, $data: WorkOrderConfigurationCreateInput!) {
    applyWorkOrderRules(field: $field, data: $data) {
      ${WorkOrderRulesFields}
    }
  }
`;

export const APPLY_WORK_ORDER_TRANSLATION_RULES_QUERY = gql`
  query applyWorkOrderTranslationRules($configuration: [AlarmPropertyConfigurationInput!]!, $alarmId: Int) {
    applyWorkOrderTranslationRules(configuration: $configuration, alarmId: $alarmId) {
      ${WorkOrderRulesFields}
    }
  }
`;

export const POST_WORK_TICKET_QUERY = gql`
  mutation createWorkTicket($input: WorkTicketCreateInput!) {
    createWorkTicket(input: $input) {
      id
    }
  }
`;

export const APPLY_FIELD_RELATIONS_TO_EXTERNAL_DETAILS_QUERY = gql`
  query applyFieldRelationsToExternalDetails(
    $externalDetails: Object!
    $externalSystemId: Int
  ) {
    applyFieldRelationsToExternalDetails(
      externalDetails: $externalDetails
      externalSystemId: $externalSystemId
    )
  }
`;
