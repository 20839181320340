import {
  State,
  Selector,
  Action,
  StateContext,
  createSelector,
} from '@ngxs/store';
import { Team } from '@vfi-ui/models';
import { Injectable } from '@angular/core';
import { SetTeams } from './teams.actions';

export interface TeamsStateModel {
  teams: Team[];
}

export const TeamssStateDefaults: TeamsStateModel = {
  teams: [],
};

@State<TeamsStateModel>({
  name: 'teams',
  defaults: TeamssStateDefaults,
})
@Injectable({
  providedIn: 'root',
})
export class TeamsState {
  static team(id?: string) {
    return createSelector([TeamsState], (state: TeamsStateModel) =>
      state.teams.find((s) => s.id.toString() === id)
    );
  }

  @Selector()
  public static getState(state: TeamsStateModel) {
    return state;
  }

  @Selector()
  public static getTeams(state: TeamsStateModel) {
    return state.teams;
  }

  @Selector()
  public static getTeamsOptions(state: TeamsStateModel) {
    return state.teams.map((team) => ({ value: team?.id, label: team.name }));
  }

  @Action(SetTeams)
  public getAllCustomers(ctx: StateContext<TeamsStateModel>, { payload }) {
    const state = ctx.getState();
    ctx.setState({ ...state, teams: payload });
  }
}
