import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'atom-vfi-checkbox',
  templateUrl: './vfi-checkbox.component.html',
  styleUrl: './vfi-checkbox.component.scss',
})
export class VfiCheckboxComponent {
  @Input() label: string;
  @Input() labelCheck = false;
  @Input() value: boolean;
  @Input() disabled = false;
  @Output() valueChanged: EventEmitter<boolean> = new EventEmitter();

  /**
   * handle input changed event
   *
   * @param {string} value
   * @memberof VfiCheckboxComponent
   */
  changed(value: boolean) {
    this.value = value;
    this.valueChanged.emit(this.value);
  }
}
