import { Component } from '@angular/core';

@Component({
  selector: 'vfi-admin-dashboard-admin-layout',
  templateUrl: './admin-layout.component.html',
  styles: [],
})
export class AdminLayoutComponent {
  constructor() {}
}
