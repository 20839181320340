export const ERROR_UPDATE_ALARM_DETAILS = 'Failed to update alarm details.';
export const ERROR_FETCH_ALARM_COUNT_REPORT = 'Failed to fetch report.';
export const ERROR_GET_ALARM_TILES = 'Failed to get alarms.';
export const ERROR_PIN_ALARMS = 'Failed to pin alarm(s).';
export const ERROR_CHANGE_STATUS = 'Failed to change status.';
export const ERROR_CHANGE_PRIORITY = 'Failed to change priority.';
export const ERROR_CHANGE_ALARM_DETAILS_PROPERTY_CONFIGURATION =
  'Failed to apply alarm configuration changes.';
export const ERROR_ADD_ALARM_DETAILS_PROPERTY_CONFIGURATION =
  'Failed to update alarm configuration changes.';
export const ERROR_UPDATE_ALARM_NOTES = 'Failed to update alarm notes.';
export const ERROR_RESET_PASSWORD = 'Failed to reset password.';
export const ERROR_CREATE_USERS = 'Failed to create user.';
export const ERROR_UPDATE_PROFILE = 'Failed to update user profile.';
export const ERROR_UPDATE_PROFILE_PHOTO = 'falied to update profile photo.';
export const ERROR_GET_USER_TILES = 'Failed to load user tiles.';
export const ERROR_GET_USER_DETAILS = 'Failed to load user details.';
export const ERROR_LOGIN = 'Login Failed.';
export const ERROR_SIGNOUT = 'Logout failed.';
export const ERROR_CREATE_CUSTOMER = 'Failed to create customer.';
export const ERROR_UPDATE_CUSTOMER = 'Failed to update customer.';
export const ERROR_CHANGE_PASSWORD = 'Failed to change password.';
export const ERROR_ADD_DEVICE_REGISTRATION_TOKEN =
  'Failed to add device registraton token.';
export const ERROR_CHANGE_CUSTOMER = 'Failed to change customer.';
export const ERROR_INVITE = 'Failed to invite user.';
export const ERROR_RESEND_INVITE = 'Failed to resend invite.';
export const ERROR_LOGIN_AUTH = 'Incorrect username or password.';
export const ERROR_ACTIVATE_USER = 'Failed to activate user.';
export const ERROR_UPDATE_USER_ACCESS = 'Failed to update user access.';
export const ERROR_GET_FOLLOWUP_WORK_REPORT = 'Failed to fetch report.';
export const ERROR_GET_RELEASE_INFO = 'Failed to fetch release info.';
export const ERROR_SAVE_USER_FEEDBACK = 'Failed to send feedback.';
export const ERROR_CREATE_LENS = 'Failed to create lens.';
export const ERROR_UPDATE_LENS = 'Failed to update lens.';
export const ERROR_DELETE_LENS = 'Failed to delete lens.';
export const ERROR_SAVE_LENS_TABLE_CONFIG =
  'Failed to save table configuration.';
export const ERROR_CREATE_NOTIFICATION = 'Failed to create notification.';
export const ERROR_DELETE_NOTIFICATION = 'Failed to delete notification.';
export const ERROR_UPDATE_NOTIFICATION = 'Failed to update notification.';
export const ERROR_GET_NOTIFICATION = 'Failed to get notification.';
export const ERROR_UNSUBSCRIBE_NOTIFICATION =
  'Lens unavailable. The link is expired or unavailable.';
export const ERROR_PREPARSE_RUN = 'Failed to create ML generic preparser job .';
export const ERROR_PREPARSE_DOWNLOAD = 'Failed to download preparser csv .';
export const ERROR_DOWNLOAD_CSV = 'Failed to download CSV file.';
export const ERROR_UPLOAD_CSV = 'Failed to upload CSV file.';
export const ERROR_CREATE_EXTERNAL_SYSTEM = 'Failed to create integration.';
export const ERROR_UPDATE_EXTERNAL_SYSTEM = 'Failed to update integration.';
export const ERROR_REPARSE_ALARMS_FOR_EXTERNAL_SYSTEM =
  'Failed to initiate reparse';
export const ERROR_REPLAY_ALARMS_FOR_EXTERNAL_SYSTEM =
  'Failed to initiate alarm replay';
export const ERROR_ISSUE_KILL_COMMAND = 'Failed to issue kill command.';
export const ERROR_UPLOAD_SIMULATOR_CSV = 'Failed to upload file.';
export const ERROR_SAVE_WORK_TICKETS_RELATIONS =
  'Failed to get work ticket Configuration.';
export const ERROR_SAVE_WORK_TICKETS = 'Failed to create work ticket.';
export const ERROR_GET_WORK_TICKET = 'Failed to get work ticket.';
export const ERROR_GET_WORK_TICKETS = 'Failed to get work tickets.';
export const ERROR_UPDATE_WORK_TICKET = 'Failed to update work ticket.';
export const ERROR_CHANGE_WORK_TICKET_STATE =
  'Failed to change work ticket state.';
export const ERROR_GET_ALARM_DETAILS = (id) =>
  `Failed to get details for alarm: ${id}.`;
export const ERROR_GET_ALARM_RAW_ATTRIBUTES = `Failed to get alarm source info`;
export const ERROR_GET_ORGANIZATIONS = 'Failed to get organizations.';
export const ERROR_CREATE_ORGANIZATIONS = 'Failed to add new organization.';
export const ERROR_DELETE_USER_OPEN_WORK = (openWork) =>
  `This user has ${openWork} active work tickets assigned to them. You must reassign these tickets in order to delete this user.`;
export const ERROR_CREATE_EXISTING_EMAIL = `Email already exists`;
export const ERROR_CREATE_EXISTING_USER =
  'User was already registered in the system. Please contact VFI Support.';
export const ERROR_UPDATE_AUTOMATION_RULES_EXECUTION_ORDER =
  'Failed to update automation rules execution order.';
export const ERROR_CLONE_AUTOMATION_RULE = 'Failed to clone automation rule.';
export const ERROR_UPDATE_AUTOMATION = 'Failed to update automation rule.';
export const ERROR_CREATE_AUTOMATION = 'Failed to create automation rule.';
export const ERROR_DELETE_AUTOMATION = 'Failed to delete automation rule.';
export const ERROR_FETCH_LOCATION_HIERARCHY_BUILDINGS =
  'Failed to fetch buildings';
export const ERROR_UPDATE_CUSTOMER_PARENT_LOCATION_NAME =
  'Failed to update customer parent group name.';
export const ERROR_FETCH_LOCATION_HIERARCHY_GROUPS =
  'Failed to fetch locations';
export const ERROR_ADD_LOCATION_HIERARCHY_GROUPS = 'Failed to create new group';
export const ERROR_EDIT_LOCATION_HIERARCHY_GROUPS = 'Failed to update group';
export const ERROR_DELETE_LOCATION_GROUP = 'Failed to delete location group';
export const ERROR_CREATE_TEAM = 'Failed to create team';
export const ERROR_UPDATE_TEAM = 'Failed to update team';
export const ERROR_DELETE_TEAM = 'Failed to delete team';
export const ERROR_BULK_UPDATE_ALARMS = 'Failed update alarms';
export const ERROR_MFA_UPDATE = 'Failed to update multi-factor authentication.';
export const ERROR_MFA_AUTH =
  'Failed to update multi-factor authentication. Please log in and try again.';
export const ERROR_ASSIGN_ROLES =
  'Failed to assign roles. Please log in and try again.';
export const ERROR_SNOOZE_ENTITY = 'Failed to snooze entity. Please try again.';
export const ERROR_UNSNOOZE_ENTITY =
  'Failed to unsnooze entity. Please try again.';
export const ERROR_CREATE_SITUATIONAL_AWARENESS_VIEW =
  'Failed to create command center view. Please try again.';
export const ERROR_UPDATE_SITUATIONAL_AWARENESS_VIEW =
  'Failed to update command center view. Please try again.';
export const ERROR_DELETE_SITUATIONAL_AWARENESS_VIEW =
  'Failed to delete command center view. Please try again.';
export const ERROR_PULLING_CMMS_FIELD = 'Failed to pull cmms fields.';
export const ERROR_PULLING_CMMS_DOMAIN_VALUE =
  'Failed to pull cmms domain values.';
export const ERROR_CREATE_CMMS_FIELD = 'Failed to create cmms field.';
export const ERROR_DELETE_CMMS_FIELD_VALUES =
  'Failed to delete cmms field values.';
export const ERROR_DELETE_CMMS_FIELD_TRANSLATIONS =
  'Failed to delete cmms field translations.';
export const ERROR_UPDATE_CMMS_FIELD = 'Failed to update cmms field.';
export const ERROR_CREATE_CMMS_DOMAIN_VALUE =
  'Failed to create cmms domain value.';
export const ERROR_UPDATE_CMMS_DOMAIN_VALUE =
  'Failed to update cmms domain value.';
export const ERROR_CREATE_CMMS_FIELD_MAPPING =
  'Failed to create cmms field mapping.';
export const ERROR_DELETE_CMMS_FIELD_MAPPING =
  'Failed to delete cmms field mapping.';
export const ERROR_CREATE_CMMS_FIELD_RELATION =
  'Failed to create cmms field relation.';
export const ERROR_DELETE_CMMS_FIELD_RELATION =
  'Failed to delete cmms field relation.';
export const ERROR_CREATE_CMMS_VALUE_TRANSLATION =
  'Failed to create cmms value translation.';
export const ERROR_UPDATE_CMMS_VALUE_TRANSLATION =
  'Failed to update cmms value translation.';
export const ERROR_DELETE_CMMS_VALUE_TRANSLATION =
  'Failed to delete cmms value translation.';
export const ERROR_UPDATE_CMMS_CONFIG = 'Failed to update cmms configuration';
export const ERROR_DELETE_ENTITY_RELATIONSHIP =
  'Failed to delete entity relationship.';
export const ERROR_CREATE_ENTITY_RELATIONSHIP =
  'Failed to create entity relationship.';
export const ERROR_UPDATE_SPACE_TYPE = 'Failed to update space type.';
export const ERROR_DELETE_SPACE_TYPE = 'Failed to delete space type.';
export const ERROR_MERGE_SPACE_TYPE = 'Failed to merge space type.';
export const ERROR_UPDATE_ENTITY = 'Failed to update entity.';
export const ERROR_DELETE_ENTITY = 'Failed to delete entity.';
export const ERROR_CREATE_ENTITY = 'Failed to create asset.';
export const ERROR_UPDATE_ENTITY_CRITICALITY =
  'Failed to update entity criticality.';
export const ERROR_GET_ALARM_INSTANCE = 'Failed to get alarm instances.';
export const ERROR_UPDATE_KPI_CONFIG = 'Failed to update KPI configuration.';
export const ERROR_UPDATE_KPI_NOTES = 'Failed to update notes.';
export const ERROR_GENERATE_EXPORT = 'Failed to export report';
export const ERROR_CUSTOMER_ALARM_CONFIG_UPDATE =
  'Failed to update customer alarm config.';
export const ERROR_GET_EXTERNAL_SYSTEM_COMMANDS =
  'Failed to fetch external system commands';
export const ERROR_RETRY_EXTERNAL_SYSTEM_COMMAND =
  'Failed to retry external system command';
export const ERROR_UPLOAD_INVENTORY = `Failed to upload inventory`;
export const ERROR_GET_GLOBAL_CUSTOMERS = `Failed to fetch list of global customers`;
export const ERROR_CREATE_GLOBAL_CUSTOMER = `Failed to create global customer`;
export const ERROR_CREATE_GLOBAL_ENVIRONMENT_CUSTOMER = `Failed to reassign global customer`;
export const ERROR_ASSETS = 'Failed to fetch assets';
export const ERROR_SET_DEFAULT_LENS = 'Failed to set default lens';
export const ERROR_GET_MAP_VIEW_BUILDING_ASSETS =
  'Failed to fetch map view building assets';
export const ERROR_UPDATE_CONTEXTUAL_NAME = 'Failed to update contextual name';
export const ERROR_WORK_TICKET_CMMS_SYNC = 'Failed to sync work ticket to CMMS';
export const ERROR_POINTS = 'Failed to get points';
export const ERROR_GET_EXTERNAL_SYSTEM_SOURCE_MAPPINGS =
  'Failed to fetch external system source mappings';
