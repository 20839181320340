import {
  State,
  Selector,
  Action,
  StateContext,
  createSelector,
} from '@ngxs/store';
import { Customers } from '@vfi-ui/models';
import {
  GetAllCustomers,
  SetCurrentCustomers,
  SetKpiEnabled,
  SetUpdateStatusReasonRequired,
  UpdateCurrentCustomer,
} from './customers.actions';
import { Injectable } from '@angular/core';
import { patch } from '@ngxs/store/operators';

export interface CustomersStateModel {
  customers: Customers[];
  currentCustomers: Customers | null;
  isKpiEnabled: boolean;
  isUpdateStatusReasonRequired: boolean;
}

export const CustomersStateDefaults: CustomersStateModel = {
  customers: [],
  currentCustomers: null,
  isKpiEnabled: false,
  isUpdateStatusReasonRequired: false,
};

@State<CustomersStateModel>({
  name: 'customers',
  defaults: CustomersStateDefaults,
})
@Injectable({
  providedIn: 'root',
})
export class CustomersState {
  static customer(id?: string) {
    return createSelector([CustomersState], (state: CustomersStateModel) =>
      state.customers.find((s) => s.id.toString() === id)
    );
  }

  @Selector()
  public static getState(state: CustomersStateModel) {
    return state;
  }

  @Selector()
  public static getCustomers(state: CustomersStateModel) {
    return state.customers;
  }

  @Selector()
  public static getCurrentCustomers(state: CustomersStateModel) {
    return state.currentCustomers;
  }

  @Selector()
  public static getCurrentCustomerTimezone(state: CustomersStateModel) {
    return state.currentCustomers.defaultTimezone;
  }

  @Selector()
  public static isBASPriorityEnabled(state: CustomersStateModel) {
    return state.currentCustomers?.isBASPriorityEnabled;
  }

  @Selector()
  public static isMFAEnabled(state: CustomersStateModel) {
    return state.currentCustomers?.isMFAEnabled;
  }

  @Selector()
  public static isRawTextEnabled(state: CustomersStateModel) {
    return state.currentCustomers?.isDisplayRawAlarmTextEnabled;
  }

  @Selector()
  public static isCmmsSyncedEnabled(state: CustomersStateModel) {
    return state.currentCustomers?.isCmmsSyncEnabled;
  }

  @Selector()
  public static getCustomerLocationName(state: CustomersStateModel) {
    return state.currentCustomers?.parentLocationName;
  }

  @Selector()
  public static isKpiEnabled(state: CustomersStateModel) {
    return state.isKpiEnabled;
  }

  @Selector()
  public static isUpdateStatusReasonRequired(state: CustomersStateModel) {
    return state.isUpdateStatusReasonRequired;
  }

  @Selector()
  public static getCustomerName(state: CustomersStateModel) {
    return state.currentCustomers.name;
  }

  @Action(GetAllCustomers)
  public getAllCustomers(
    ctx: StateContext<CustomersStateModel>,
    { payload: { customers } }
  ) {
    const state = ctx.getState();
    ctx.setState({ ...state, customers });
  }

  @Action(SetCurrentCustomers)
  public setCurrentCustomers(
    ctx: StateContext<CustomersStateModel>,
    { payload: { customers } }
  ) {
    ctx.setState(
      patch({
        currentCustomers: customers,
      })
    );
  }

  @Action(UpdateCurrentCustomer)
  public updateCurrentCustomer(
    ctx: StateContext<CustomersStateModel>,
    { payload: { customers } }
  ) {
    const state = ctx.getState();
    ctx.setState(
      patch({
        currentCustomers: { ...state.currentCustomers, ...customers },
      })
    );
  }

  @Action(SetKpiEnabled)
  public setKpiEnabled(
    ctx: StateContext<CustomersStateModel>,
    payload: { payload }
  ) {
    ctx.setState(
      patch({
        isKpiEnabled: payload.payload,
      })
    );
  }

  @Action(SetUpdateStatusReasonRequired)
  public setUpdateStatusReasonRequired(
    ctx: StateContext<CustomersStateModel>,
    payload: { payload }
  ) {
    ctx.setState(
      patch({
        isUpdateStatusReasonRequired: payload.payload,
      })
    );
  }
}
