<label
  nz-checkbox
  [ngModel]="value"
  [nzDisabled]="disabled"
  (ngModelChange)="changed($event)"
  >{{ label }}
  <i
    *ngIf="labelCheck"
    class="ml-1 fa-solid fa-check check-green iregular-12"
  ></i
></label>
