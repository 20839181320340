import { Injectable } from '@angular/core';
import { ScriptModel } from '@vfi-ui/models';
import { Observable, Observer } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScriptLoaderService {
  private scripts: ScriptModel[] = [];

  public load(
    script: ScriptModel,
    nocache: boolean = false
  ): Observable<ScriptModel> {
    return new Observable<ScriptModel>((observer: Observer<ScriptModel>) => {
      const existingScript: ScriptModel = this.scripts.find(
        (s) => s.name === script.name
      );
      // Complete if the script is already loaded
      if (existingScript && existingScript.loaded) {
        observer.next(existingScript);
        observer.complete();
      } else {
        // Add the script to scripts array
        this.scripts = [...this.scripts, script];
        // Create the script
        const scriptElement: HTMLScriptElement =
          document.createElement('script');
        scriptElement.type = 'text/javascript';
        scriptElement.src = nocache
          ? `${script.src}?v=${Date.now()}`
          : script.src;
        scriptElement.onload = () => {
          script.loaded = true;
          observer.next(script);
          observer.complete();
        };
        scriptElement.onerror = (error: string | Event) => {
          console.error(`Couldn't load script ${script.src}`);
          observer.error(error);
        };
        // Load the script
        document.getElementsByTagName('body')[0].appendChild(scriptElement);
      }
    });
  }
}
