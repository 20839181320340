import {
  GET_INTEGRATIONS_QUERY,
  GET_EXTERNAL_SYSTEM_JSON_SCHEMA_QUERY,
  CREATE_EXTERNAL_SYSTEM_QUERY,
  EXTERNAL_SYSTEM_AND_COUNTS_QUERY,
  GENERATE_BAS_LICENSE_KEY_QUERY,
  UPDATE_EXTERNAL_SYSTEM_QUERY,
  GET_ALL_INTEGRATIONS_QUERY,
  GET_EXTERNAL_SYSTEMS_OPTIONS_QUERY,
  GENERATE_RAW_EMAIL_CSV_QUERY,
  ISSUE_KILL_COMMAND,
  REPARSE_ALARMS_FOR_EXTERNAL_SYSTEM,
  REPLAY_ALARMS_FOR_EXTERNAL_SYSTEM,
  INITIATE_EXTERNAL_SYSTEM_GET_ASSETS_COMMAND,
  INITIATE_EXTERNAL_SYSTEM_HISTORICAL_COMMAND,
  GET_EXTERNAL_SYSTEM_COMMAND_TYPES_QUERY,
} from './../queries/external-system.query';
import { environment } from '@vfi-ui/environments/environment';
import { NotificationService } from './notification.service';
import { tap, catchError, map, filter } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import {
  Integrations,
  ERROR_DOWNLOAD_CSV,
  ERROR_UPLOAD_CSV,
  ERROR_CREATE_EXTERNAL_SYSTEM,
  ERROR_UPDATE_EXTERNAL_SYSTEM,
  CUSTOMER_ID_HEADER,
  ERROR_ISSUE_KILL_COMMAND,
  ERROR_REPARSE_ALARMS_FOR_EXTERNAL_SYSTEM,
  ExternalSystemReplayAlarmsInput,
  ERROR_REPLAY_ALARMS_FOR_EXTERNAL_SYSTEM,
  ExternalSystemCommandType,
} from '@vfi-ui/models';
import { catchUndisclosedErrors } from '@vfi-ui/util/helpers';
import { get } from '@vfi-ui/util/helpers';
import { Apollo } from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class ParserDataService {
  constructor(
    private apollo: Apollo,
    private readonly http: HttpClient,
    private notification: NotificationService
  ) {}
  /**
   * download vf file
   *
   * @param {string} route
   * @param {number} customerId
   * @return {*}
   * @memberof ParserDataService
   */
  downloadVF(route: string, customerId: number) {
    const headers = new HttpHeaders()
      .set(CUSTOMER_ID_HEADER, String(customerId))
      .set('Content-Type', 'application/json')
      .set('Cache-Control', 'no-cache, no-store');

    return this.http
      .post(
        environment.backend + '/download/vf',
        {
          route,
        },
        { responseType: 'arraybuffer', observe: 'response', headers }
      )
      .pipe(
        filter((d) => !!d),
        catchError((err) => {
          this.notification.showError(ERROR_DOWNLOAD_CSV);
          return throwError(err);
        }),
        tap((res) => {
          const filename =
            res.headers
              .get('content-disposition')
              .replace('attachment; filename=', '') || 'ml.gzip';
          const file = new Blob([res.body], {
            type: 'application/gzip',
          });
          const objectUrl = URL.createObjectURL(file);
          const element = document.createElement('a');
          element.href = objectUrl;
          element.download = filename;
          document.body.appendChild(element);
          element.click();
          element.remove();
        })
      );
  }

  /**
   * fetch parser integrations
   *
   * @param {string} [customerId]
   * @returns {Observable<Integrations[]>}
   * @memberof ParserDataService
   */
  getIntegrations(customerId?: string): Observable<Integrations[]> {
    const customerIdHeader = customerId
      ? {
          [CUSTOMER_ID_HEADER]: customerId,
        }
      : {};
    return this.http
      .request('POST', environment.backend, {
        headers: {
          'Cache-Control': 'no-cache, no-store',
          'Content-Type': 'application/json',
          ...customerIdHeader,
        },
        body: JSON.stringify({
          query: GET_INTEGRATIONS_QUERY,
          variables: {
            options: {},
          },
        }),
      })
      .pipe(
        filter((d) => !!d),
        map((res) =>
          get(res, 'data.externalSystemsAndCount.items', []).sort((a, b) =>
            a.displayName.localeCompare(b.displayName)
          )
        )
      );
  }

  /**
   * fetch all customer integrations
   *
   * @returns {Observable<Integrations[]>}
   * @memberof ParserDataService
   */
  getAllIntegrations(customerId: number): Observable<Integrations[]> {
    const headers = new HttpHeaders()
      .set(CUSTOMER_ID_HEADER, String(customerId))
      .set('Content-Type', 'application/json')
      .set('Cache-Control', 'no-cache, no-store');
    return this.http
      .request('POST', environment.backend, {
        headers,
        body: JSON.stringify({
          query: GET_ALL_INTEGRATIONS_QUERY,
          variables: {
            options: {},
          },
        }),
      })
      .pipe(
        filter((d) => !!d),
        map((res) => get(res, 'data.externalSystemsAndCount.items', []))
      );
  }

  /**
   * fetch integration json schema
   *
   * @param {string} formType
   * @returns
   * @memberof ParserDataService
   */
  getIntegrationSchema(formType: string) {
    return this.http
      .request('POST', environment.backend, {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: GET_EXTERNAL_SYSTEM_JSON_SCHEMA_QUERY,
          variables: {
            formType,
          },
        }),
      })
      .pipe(
        filter((d) => !!d),
        map((res) => get(res, 'data.getExternalSystemJsonSchema', []))
      );
  }

  /**
   * create external system
   *
   * @param {Integrations} data
   * @returns
   * @memberof ParserDataService
   */
  createExternalSystem(data: Integrations) {
    return this.http
      .request<{
        data: { createExternalSystem };
      }>('POST', environment.backend, {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: CREATE_EXTERNAL_SYSTEM_QUERY,
          variables: { data },
        }),
      })
      .pipe(
        filter((d) => !!d),
        map((res) => res.data.createExternalSystem),
        tap(() => {
          this.notification.showSuccess('Add Integration', 'Integration added');
        }),
        catchError((error) => {
          this.notification.showError(ERROR_CREATE_EXTERNAL_SYSTEM);
          return throwError(error);
        })
      );
  }

  /**
   * update external system
   *
   * @param {number} id
   * @param {*} data
   * @returns
   * @memberof ParserDataService
   */
  updateExternalSystem(id: number, data) {
    return this.http
      .request<{
        data: { updateExternalSystem };
      }>('POST', environment.backend, {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: UPDATE_EXTERNAL_SYSTEM_QUERY,
          variables: { id, data },
        }),
      })
      .pipe(
        filter((d) => !!d),
        map((res) => get(res, 'data.updateExternalSystem', [])),
        tap(() => {
          this.notification.showSuccess(
            'Update Integration',
            'Integration updated'
          );
        }),
        catchError((error) => {
          this.notification.showError(ERROR_UPDATE_EXTERNAL_SYSTEM);
          return throwError(error);
        })
      );
  }

  /**
   * fetch integration json schema values
   *
   * @param {number} id
   * @returns
   * @memberof ParserDataService
   */
  getIntegrationSchemaValues(id: number) {
    return this.http
      .request('POST', environment.backend, {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: EXTERNAL_SYSTEM_AND_COUNTS_QUERY,
          variables: {
            options: { where: { ids: [id] } },
          },
        }),
      })
      .pipe(
        filter((d) => !!d),
        map((res) => get(res, 'data.externalSystemsAndCount.items[0]', []))
      );
  }

  /**
   *
   * generate BAS license key
   *
   * @param {number} id
   * @returns
   * @memberof ParserDataService
   */
  generateBASLicenseKey(id: number) {
    return this.http
      .request('POST', environment.backend, {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: GENERATE_BAS_LICENSE_KEY_QUERY,
          variables: {
            id,
          },
        }),
      })
      .pipe(
        filter((d) => !!d),
        map((res) => get(res, 'data.getLicenseKeyForBAS', ''))
      );
  }

  /**
   * generate raw email csv
   *
   * @param {number} id
   * @returns
   * @memberof ParserDataService
   */
  generateRawEmailCSV(id: number) {
    return this.http
      .request('POST', environment.backend, {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: GENERATE_RAW_EMAIL_CSV_QUERY,
          variables: {
            id,
          },
        }),
      })
      .pipe(
        filter((d) => !!d),
        map(catchUndisclosedErrors),
        catchError((err) => {
          this.notification.showError(ERROR_UPLOAD_CSV);
          return throwError(err);
        })
      );
  }

  /**
   * fetch external system options
   *
   * @param {string} type
   * @returns
   * @memberof ParserDataService
   */
  fetchExternalSystemOptions(type: string) {
    return this.http
      .request('POST', environment.backend, {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: GET_EXTERNAL_SYSTEMS_OPTIONS_QUERY,
          variables: { type },
        }),
      })
      .pipe(
        filter((d) => !!d),
        map((res) => get(res, 'data.getExternalSystemOptions', ''))
      );
  }
  /**
   * Submits kill command to restart the datapump
   *
   * @param {number} externalSystemId
   * @returns {Observable<boolean>}
   * @memberof ParserDataService
   */
  submitKillCommand(externalSystemId: number): Observable<any> {
    return this.http
      .request('POST', environment.backend, {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: ISSUE_KILL_COMMAND,
          variables: { id: externalSystemId },
        }),
      })
      .pipe(
        tap(() => {
          this.notification.showSuccess(
            'Kill Command Issued',
            'Command Issued'
          );
        }),
        catchError((error) => {
          this.notification.showError(ERROR_ISSUE_KILL_COMMAND);
          return throwError(error);
        })
      );
  }

  /**
   * Initiates a reparse for a given external system
   *
   * @param {number} externalSystemId
   * @returns {Observable<any>}
   * @memberof ParserDataService
   */
  reparseAlarmsForExternalSystem(externalSystemId: number): Observable<any> {
    return this.http
      .request('POST', environment.backend, {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: REPARSE_ALARMS_FOR_EXTERNAL_SYSTEM,
          variables: { id: externalSystemId },
        }),
      })
      .pipe(
        tap(() => {
          this.notification.showSuccess(
            'Reparse Initiated',
            'All alarms for external system will be reparsed'
          );
        }),
        catchError((error) => {
          this.notification.showError(ERROR_REPARSE_ALARMS_FOR_EXTERNAL_SYSTEM);
          return throwError(error);
        })
      );
  }

  /**
   * Initiates a reparse for a given external system
   *
   * @param {number} externalSystemId - The external system id
   * @param {ExternalSystemReplayAlarmsInput} input - The external system id
   * @returns {Observable<any>}
   * @memberof ParserDataService
   */
  replayAlarmsForExternalSystem(
    externalSystemId: number,
    input: ExternalSystemReplayAlarmsInput
  ): Observable<any> {
    return this.http
      .request('POST', environment.backend, {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: REPLAY_ALARMS_FOR_EXTERNAL_SYSTEM,
          variables: { id: externalSystemId, input },
        }),
      })
      .pipe(
        tap(() => {
          this.notification.showSuccess(
            'Replay Initiated',
            'Depending on the volumne of alarms this could take awhile'
          );
        }),
        catchError((error) => {
          this.notification.showError(ERROR_REPLAY_ALARMS_FOR_EXTERNAL_SYSTEM);
          return throwError(error);
        })
      );
  }

  /**
   * Initiates a command for external system
   * @param id
   * @param commandType
   * @returns
   * @memberof ParserDataService
   */
  initiateExternalSystemGetAssetsCommand(
    id: number,
    commandType: ExternalSystemCommandType
  ) {
    return this.apollo
      .query({
        fetchPolicy: 'no-cache',
        query: INITIATE_EXTERNAL_SYSTEM_GET_ASSETS_COMMAND,
        variables: {
          id,
          commandType,
        },
      })
      .pipe(
        tap(() => {
          this.notification.showSuccess(
            'Command Issued',
            'Depending on the volumne of alarms this could take a while'
          );
        }),
        catchError((error) => {
          this.notification.showError('Error running command');
          return throwError(error);
        })
      );
  }

  /**
   * Initiates historical command for external system
   * @param id
   * @param commandType
   * @returns
   * @memberof ParserDataService
   */
  initiateExternalSystemHistoricalCommand(id: number, dateRange: Date[]) {
    const range = {
      from: dateRange[0],
      to: dateRange[1],
    };
    return this.apollo
      .query({
        fetchPolicy: 'no-cache',
        query: INITIATE_EXTERNAL_SYSTEM_HISTORICAL_COMMAND,
        variables: {
          id,
          data: { range },
        },
      })
      .pipe(
        tap(() => {
          this.notification.showSuccess(
            'Command Issued',
            'Depending on the volumne of alarms this could take a while'
          );
        }),
        catchError((error) => {
          this.notification.showError('Error running command');
          return throwError(error);
        })
      );
  }

  /**
   * Gets external system command types
   * @param id
   * @returns
   * @memberof ParserDataService
   */
  getExternalSystemCommandTypes(id: number) {
    return this.apollo
      .query<ExternalSystemCommandType>({
        fetchPolicy: 'no-cache',
        query: GET_EXTERNAL_SYSTEM_COMMAND_TYPES_QUERY,
        variables: {
          id,
        },
      })
      .pipe(map((res) => get(res, 'data.getExternalSystemCommandTypes', [])));
  }
}
