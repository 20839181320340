import {
  Action,
  createSelector,
  Selector,
  State,
  StateContext,
} from '@ngxs/store';
import { Logout, SignIn, LoadUser, UpdateUser } from './auth.actions';
import {
  User,
  Field,
  CUSTOMER_ID_HEADER,
  AuthRoles,
  UIPermission,
  Users,
  LandingPage,
  LandingPageUrls,
  ToggleProducts,
  DefaultLandingPageUrls,
} from '@vfi-ui/models';
import { AuthService, SocketsService } from '@vfi-ui/data-access/shared';
import { Navigate } from '@ngxs/router-plugin';
import { patch } from '@ngxs/store/operators';
import { SetRequiredWorkTicketFields } from '../work-tickets/work-tickets.actions';
import { Injectable } from '@angular/core';
import { isNil, result } from '@vfi-ui/util/helpers';
import { DeviceDetectorService } from 'ngx-device-detector';

export class AuthStateModel {
  loading: boolean;
  loggedIn: boolean;
  user: User;
  workTicketSystem: string | null;
}

@State<AuthStateModel>({
  name: 'auth',
})
@Injectable({
  providedIn: 'root',
})
export class AuthState {
  constructor(
    private authService: AuthService,
    private readonly socketService: SocketsService,
    private deviceDetectorService: DeviceDetectorService
  ) {}
  static checkPermission(
    parent: string,
    child: string,
    permission: UIPermission
  ) {
    return createSelector(
      [AuthState],
      (state: AuthStateModel) =>
        state?.user?.permissions[parent][child].includes(permission) ||
        state?.user?.isSuper
    );
  }
  @Selector()
  static getState(state: AuthStateModel) {
    return state;
  }
  @Selector()
  static user(state: AuthStateModel) {
    return state.user;
  }
  @Selector()
  static userPermissions(state: AuthStateModel) {
    return state.user.permissions;
  }
  @Selector()
  static getId(state: AuthStateModel) {
    return state.user.id;
  }
  @Selector()
  static getUid(state: AuthStateModel) {
    return state.user.uid;
  }
  @Selector()
  static getAuthRole(state: AuthStateModel) {
    return state.user?.authRole;
  }
  @Selector()
  static getPermissions(state: AuthStateModel) {
    return state.user.permissions;
  }
  @Selector()
  static isSuper(state: AuthStateModel) {
    return state.user.isSuper;
  }
  @Selector()
  static products(state: AuthStateModel) {
    return state.user.products['products'];
  }
  @Selector()
  static getCutomerId(state: AuthStateModel) {
    return state.user.hasuraClaims['x-hasura-org-id'];
  }
  @Selector()
  static getWorkTicketSystem(state: AuthStateModel) {
    return state.workTicketSystem;
  }
  @Selector()
  static hasWorkProduct(state: AuthStateModel) {
    return !isNil(
      state?.user?.products?.products?.find((r) => r.name === 'work')
    );
  }
  @Selector()
  static getUserTeams(state: AuthStateModel) {
    return state.user.teamMembers;
  }
  @Selector()
  static hasWorkPermissions(state: AuthStateModel) {
    return (
      state?.user?.permissions?.work?.myWork.includes(UIPermission.view) ||
      state?.user?.permissions?.work?.teamWork.includes(UIPermission.view) ||
      state?.user?.isSuper
    );
  }
  @Selector()
  static hasSavedViewPermissions(state: AuthStateModel) {
    return (
      state?.user?.permissions?.savedViews?.savedViews.length > 0 ||
      state?.user?.isSuper
    );
  }
  @Selector()
  static hasAdvancedAnalyticsPermissions(state: AuthStateModel) {
    return (
      state?.user?.permissions?.analytics?.advancedAnalytics.includes(
        UIPermission.view
      ) || state?.user?.isSuper
    );
  }
  @Selector()
  static hasTeam(state: AuthStateModel) {
    const isSuperUser = state?.user?.isSuper;
    const isAdmin = state.user.authRole === AuthRoles.admin;
    const hasTeamMembers = state.user.teamMembers.length > 0;
    return isSuperUser || isAdmin || hasTeamMembers;
  }

  @Selector()
  static getLandingPage(state: AuthStateModel) {
    return state?.user?.landingPage;
  }

  @Selector()
  public static isLandingPage(landingPage: LandingPage) {
    return createSelector(
      [AuthState],
      (state) => state?.auth?.user?.landingPage === landingPage
    );
  }

  @Selector()
  static getAnalyticsLandingPage(state: AuthStateModel) {
    return state?.user?.analyticsPage;
  }

  @Action(SignIn)
  async signIn(patchState: StateContext<AuthStateModel>, { payload }) {
    const state = patchState.getState();
    patchState.setState({
      ...state,
      loading: true,
      loggedIn: false,
    });

    patchState.setState({
      ...state,
      loading: false,
      loggedIn: true,
      user: payload,
    });

    return this.authService.getUsersByUids([payload.uid]).subscribe(([usr]) => {
      localStorage.setItem(
        CUSTOMER_ID_HEADER,
        payload.hasuraClaims['x-hasura-org-id']
      );

      if (usr.isSuper) {
        return patchState.dispatch(new Navigate(['/select']));
      } else {
        return this.navigateLandingPage(usr);
      }
    });
  }

  @Action(LoadUser, { cancelUncompleted: true })
  async loadUser(patchState: StateContext<AuthStateModel>, { payload }) {
    const state = patchState.getState();
    return this.authService.getProducts().subscribe((products) => {
      const fields = result(products, 'workTicketSystem.fields', [])
        .filter((field: Field) => field.required)
        .map((field: Field) => field.name);
      patchState.dispatch(new SetRequiredWorkTicketFields(fields));
      patchState.setState({
        ...state,
        loading: false,
        loggedIn: true,
        user: { ...payload, products },
        workTicketSystem: result(products, 'workTicketSystem.name', null),
      });
    });
  }

  @Action(UpdateUser)
  async updateUser(patchState: StateContext<AuthStateModel>, { payload }) {
    patchState.setState(
      patch({
        user: patch(payload),
      })
    );
  }

  @Action(Logout)
  logout(patchState: StateContext<AuthStateModel>) {
    patchState.setState({
      loggedIn: false,
      loading: false,
      user: null,
      workTicketSystem: null,
    });
    localStorage.clear();
    this.socketService.cleanupSockets();
    this.authService.signOut().then(() => {
      window.location.href = '/login';
    });
  }

  /**
   * redirect user on login
   *
   * @private
   * @param {Users} user
   * @returns
   * @memberof AuthState
   */
  private navigateLandingPage(user: Users) {
    const deepLink = localStorage.getItem('vfi-deepLink');
    if (deepLink) {
      localStorage.removeItem('vfi-deepLink');
      return (window.location.href = deepLink);
    } else {
      if (!user.isSuper) {
        const isDesktop = this.deviceDetectorService.isDesktop();
        if (isDesktop) {
          const customerId = localStorage.getItem(CUSTOMER_ID_HEADER);
          this.authService
            .getCustomerById([+customerId])
            .subscribe((customer) => {
              const hasSA = customer?.products?.some(
                (p) => p?.name === ToggleProducts.commandCenter
              );
              const lp =
                user.landingPage === LandingPage.CommandCenter && !hasSA
                  ? LandingPage.Triage
                  : user.landingPage;
              let url = LandingPageUrls[lp];

              url = DefaultLandingPageUrls[lp];

              return (window.location.href = url);
            });
        } else {
          return (window.location.href = '/');
        }
      } else {
        return (window.location.href = '/');
      }
    }
  }
}
