import { ChangeUser, Users } from '@vfi-ui/models';

export class LoadUsers {
  public static readonly type = '[Users] Load users';
}
export class ResetUsers {
  public static readonly type = '[Users] reset users';
}

export class SetUsers {
  public static readonly type = '[Users] set users';
  constructor(public payload: Users[]) {}
}

export class LoadMoreUsers {
  public static readonly type = '[Users] Load more users';
  constructor(public payload?: { offset: number }) {}
}

export class UserDisplayNameChanged {
  public static readonly type = '[Users] Display name changed';
  constructor(public payload: ChangeUser) {}
}

export class UserAvatarChanged {
  public static readonly type = '[Users] Avatar changed';
  constructor(public payload: ChangeUser) {}
}

export class UserRoleChanged {
  public static readonly type = '[Users] Role changed';
  constructor(public payload: ChangeUser) {}
}

export class UserCompanyChanged {
  public static readonly type = '[Users] Company changed';
  constructor(public payload: ChangeUser) {}
}

export class UserStatusChanged {
  public static readonly type = '[Users] Status changed';
  constructor(public payload: ChangeUser) {}
}

export class UserAccessChanged {
  public static readonly type = '[Users] User access changed';
  constructor(public payload: ChangeUser) {}
}

export class RemoveUser {
  public static readonly type = '[Users] remove users';
  constructor(public payload: string) {}
}

export class DeletingUser {
  public static readonly type = '[Users] set deleting user';
  constructor(public payload: boolean) {}
}
