import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { map, take, filter } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AuthRoles, permission, Permissions } from '@vfi-ui/models';
import { AuthState, SetKpiEnabled } from '@vfi-ui/state';
import { KpiDashboardDataService } from '@vfi-ui/data-access/shared';

@Injectable({
  providedIn: 'root',
})
export class PermissionResolver {
  @Select(AuthState.getAuthRole) authRole$: Observable<AuthRoles>;

  constructor(
    private kpiDashboardService: KpiDashboardDataService,
    private store: Store
  ) {}

  resolve(): Observable<Permissions> {
    return this.getPermissions();
  }

  /**
   * set roles and permissions
   *
   * @returns {Observable<Permissions>}
   * @memberof PermissionResolver
   */
  getPermissions(): Observable<Permissions> {
    this.kpiDashboardService
      .getKpiConfig()
      .subscribe((kpiEnabled) =>
        this.store.dispatch(new SetKpiEnabled(kpiEnabled))
      );
    return this.authRole$.pipe(
      filter((p) => !!p),
      map((p) => permission[p]),
      take(1)
    );
  }
}
