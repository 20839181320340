import { AlarmsReportSort } from '../interfaces/alarms-report.interface';
import { AlarmPriorityValues } from './alarms.constant';
import { OrderDirection } from './work-ticket.constant';

export enum AlarmsReportSortField {
  Id = 'ID',
  Priority = 'PRIORITY',
  AlarmState = 'ALARM_STATE',
  Status = 'STATUS',
  Attributes = 'ATTRIBUTES',
  Text = 'TEXT',
  ContextualName = 'CONTEXTUAL_NAME',
  BasPriority = 'BAS_PRIORITY',
  ChatteringCount = 'CHATTERING_COUNT',
  FleetingCount = 'FLEETING_COUNT',
  NuisanceCount = 'NUISANCE_COUNT',
  Notes = 'NOTES',
  LastAlarmTime = 'LAST_ALARM_TIME',
  LastEndTime = 'LAST_END_TIME',
  LastDuration = 'LAST_DURATION',
  AverageDuration = 'AVERAGE_DURATION',
  InstanceCount = 'INSTANCE_COUNT',
  LastAckTime = 'LAST_ACK_TIME',
  LastAckBy = 'LAST_ACK_BY',
  OpenWorkIds = 'OPEN_WORK_IDS',
  RecentlyClosedWorkIds = 'RECENTLY_CLOSED_WORK_IDS',
  LastCreatedWorkId = 'LAST_CREATED_WORK_ID',
  LastClosedWorkId = 'LAST_CLOSED_WORK_ID',
  LastWorkCreatedAt = 'LAST_WORK_CREATED_AT',
  LastWorkClosedAt = 'LAST_WORK_CLOSED_AT',
  LastWorkAssignee = 'LAST_WORK_ASSIGNEE',
  LastWorkAssigneeName = 'LAST_WORK_ASSIGNEE_NAME',
  LastWorkResolution = 'LAST_WORK_RESOLUTION',
  OpenWorkCount = 'OPEN_WORK_COUNT',
  WorkCount = 'WORK_COUNT',
  TotalWorkDuration = 'TOTAL_WORK_DURATION',
  ExternalSystemDisplayName = 'EXTERNAL_SYSTEM_DISPLAY_NAME',
  HasPointEntity = 'HAS_POINT_ENTITY',
}

export const ALARMS_REPORT_SORT_VALUE = {
  id: AlarmsReportSortField.Id,
  priority: AlarmsReportSortField.Priority,
  alarmState: AlarmsReportSortField.AlarmState,
  status: AlarmsReportSortField.Status,
  attributes: AlarmsReportSortField.Attributes,
  text: AlarmsReportSortField.Text,
  contextualName: AlarmsReportSortField.ContextualName,
  basPriority: AlarmsReportSortField.BasPriority,
  chatteringCount: AlarmsReportSortField.ChatteringCount,
  fleetingCount: AlarmsReportSortField.FleetingCount,
  nuisanceCount: AlarmsReportSortField.NuisanceCount,
  notes: AlarmsReportSortField.Notes,
  lastAlarmTime: AlarmsReportSortField.LastAlarmTime,
  lastEndTime: AlarmsReportSortField.LastEndTime,
  lastDuration: AlarmsReportSortField.LastDuration,
  averageDuration: AlarmsReportSortField.AverageDuration,
  instanceCount: AlarmsReportSortField.InstanceCount,
  lastAckTime: AlarmsReportSortField.LastAckTime,
  lastAckBy: AlarmsReportSortField.LastAckBy,
  openWorkIds: AlarmsReportSortField.OpenWorkIds,
  recentlyClosedWorkIds: AlarmsReportSortField.RecentlyClosedWorkIds,
  lastCreatedWorkId: AlarmsReportSortField.LastCreatedWorkId,
  lastClosedWorkId: AlarmsReportSortField.LastClosedWorkId,
  lastWorkCreatedAt: AlarmsReportSortField.LastWorkCreatedAt,
  lastWorkClosedAt: AlarmsReportSortField.LastWorkClosedAt,
  lastWorkAssignee: AlarmsReportSortField.LastWorkAssignee,
  lastWorkAssigneeName: AlarmsReportSortField.LastWorkAssigneeName,
  lastWorkResolution: AlarmsReportSortField.LastWorkResolution,
  openWorkCount: AlarmsReportSortField.OpenWorkCount,
  workCount: AlarmsReportSortField.WorkCount,
  totalWorkDuration: AlarmsReportSortField.TotalWorkDuration,
  externalSystemDisplayName: AlarmsReportSortField.ExternalSystemDisplayName,
  // Time values used to format duration
  lastDurationTimeValues: AlarmsReportSortField.LastDuration,
  averageDurationTimeValues: AlarmsReportSortField.AverageDuration,
  totalWorkDurationTimeValues: AlarmsReportSortField.TotalWorkDuration,
  hasPointEntity: AlarmsReportSortField.HasPointEntity,
};

export const ALARMS_REPORT_DEFAULT_SORT: AlarmsReportSort = {
  field: ALARMS_REPORT_SORT_VALUE.lastAlarmTime,
  field2: null,
  field3: null,
  direction: OrderDirection.DESC,
};

export const ALARMS_REPORT_DEFAULT_COLUMNS = [
  ALARMS_REPORT_SORT_VALUE.id,
  ALARMS_REPORT_SORT_VALUE.priority,
  ALARMS_REPORT_SORT_VALUE.lastAlarmTime,
  ALARMS_REPORT_SORT_VALUE.text,
  ALARMS_REPORT_SORT_VALUE.contextualName,
  'building',
  ALARMS_REPORT_SORT_VALUE.openWorkIds,
];

export const ALARMS_REPORT_TABLE_COLUMNS = [
  {
    name: 'Alarm ID',
    value: ALARMS_REPORT_SORT_VALUE.id,
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Alarm State',
    value: ALARMS_REPORT_SORT_VALUE.alarmState,
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Alarm Status',
    value: ALARMS_REPORT_SORT_VALUE.status,
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Alarm Priority',
    value: ALARMS_REPORT_SORT_VALUE.priority,
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Raw Text',
    value: ALARMS_REPORT_SORT_VALUE.text,
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Contextual Name',
    value: ALARMS_REPORT_SORT_VALUE.contextualName,
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'BAS Priority',
    value: ALARMS_REPORT_SORT_VALUE.basPriority,
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Chattering Count',
    value: ALARMS_REPORT_SORT_VALUE.chatteringCount,
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Nuisance Count',
    value: ALARMS_REPORT_SORT_VALUE.nuisanceCount,
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Fleeting Count',
    value: ALARMS_REPORT_SORT_VALUE.fleetingCount,
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Alarm Notes',
    value: ALARMS_REPORT_SORT_VALUE.notes,
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Alarm Source',
    value: ALARMS_REPORT_SORT_VALUE.externalSystemDisplayName,
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Last Alarm Time',
    value: ALARMS_REPORT_SORT_VALUE.lastAlarmTime,
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Last End Time',
    value: ALARMS_REPORT_SORT_VALUE.lastEndTime,
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Last Duration',
    value: ALARMS_REPORT_SORT_VALUE.lastDuration,
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Average Duration',
    value: ALARMS_REPORT_SORT_VALUE.averageDuration,
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Instance Count',
    value: ALARMS_REPORT_SORT_VALUE.instanceCount,
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Last Ack Time',
    value: ALARMS_REPORT_SORT_VALUE.lastAckTime,
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Last Ack User',
    value: ALARMS_REPORT_SORT_VALUE.lastAckBy,
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Open Work',
    value: ALARMS_REPORT_SORT_VALUE.openWorkIds,
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: 'Work Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Recently Closed Work',
    value: ALARMS_REPORT_SORT_VALUE.recentlyClosedWorkIds,
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: 'Work Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Last Created Work',
    value: ALARMS_REPORT_SORT_VALUE.lastCreatedWorkId,
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: 'Work Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Last Closed Work',
    value: ALARMS_REPORT_SORT_VALUE.lastClosedWorkId,
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: 'Work Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Last Work Creation Time',
    value: ALARMS_REPORT_SORT_VALUE.lastWorkCreatedAt,
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: 'Work Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Last Work Closed Time',
    value: ALARMS_REPORT_SORT_VALUE.lastWorkClosedAt,
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: 'Work Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Last Work Assignee',
    value: ALARMS_REPORT_SORT_VALUE.lastWorkAssigneeName,
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: 'Work Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Last Work Resolution',
    value: ALARMS_REPORT_SORT_VALUE.lastWorkResolution,
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: 'Work Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Open Work Count',
    value: ALARMS_REPORT_SORT_VALUE.openWorkCount,
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: 'Work Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Work Ticket Count',
    value: ALARMS_REPORT_SORT_VALUE.workCount,
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: 'Work Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Total Work Duration',
    value: ALARMS_REPORT_SORT_VALUE.totalWorkDuration,
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: 'Work Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Has Alarm Point Entity',
    value: ALARMS_REPORT_SORT_VALUE.hasPointEntity,
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
];

export const ALARMS_REPORT_DEFUALT_GROUP_BY_OPTIONS = [
  'None',
  'Alarm State',
  'Last Work Assignee',
  'Last Work Resolution',
  'Priority',
  'Alarm Status',
];

export enum AlarmsReportGroupValues {
  'Alarm State' = AlarmsReportSortField.AlarmState,
  'Last Work Assignee' = AlarmsReportSortField.LastWorkAssignee,
  'Last Work Resolution' = AlarmsReportSortField.LastWorkResolution,
  'Priority' = AlarmsReportSortField.Priority,
  'Alarm Status' = AlarmsReportSortField.Status,
}

export const ALARM_REPORT_PRIORITY_OPTIONS = [
  {
    label: 'Highest',
    value: AlarmPriorityValues.Highest,
  },
  {
    label: 'High',
    value: AlarmPriorityValues.High,
  },
  {
    label: 'Medium',
    value: AlarmPriorityValues.Medium,
  },
  {
    label: 'Low',
    value: AlarmPriorityValues.Low,
  },
  {
    label: 'Lowest',
    value: AlarmPriorityValues.Lowest,
  },
  {
    label: 'No Priority',
    value: AlarmPriorityValues['No priority'],
  },
];

export const ALARM_STATE_OPTIONS = [
  {
    label: 'Active',
    value: 'ACTIVE',
  },
  {
    label: 'Inactive',
    value: 'INACTIVE',
  },
];

export const ATTRIBUTE_PROPS = [
  'building',
  'floor',
  'space_type',
  'room_number',
  'room_name',
  'flow_direction',
  'parameter_prefix',
  'parameter',
  'condition',
  'equipment',
  'equipment_type',
  'medium',
  'instrument_type',
  'category',
  'shop',
];

export const BLACKLISTED_FILTERS = [
  'Pinned',
  'Alarm Source',
  'BAS Priority',
  'Keyword',
  'Nuisance Behavior',
];
