import { OrderDirection } from '@vfi-ui/models';
import { Granularity, DatetimeRange, CoreAlarmsFiltersWhere } from '..';

export interface AlarmImpactAlarmOptions {
  alarmInput?: CoreAlarmsFiltersWhere;
  workTicketInput?: AlarmImpactWorkTicketWhere;
  granularity: Granularity;
  timezone: string;
}

export interface AlarmImpactAlarm {
  period: string;
  count: number;
}

export enum AlarmImpactWorkTicketOrderField {
  Id = 'ID',
  ExternalId = 'EXTERNAL_ID',
  Resolution = 'RESOLUTION',
  CreatedAt = 'CREATED_AT',
  ClosedAt = 'CLOSED_AT',
  Assignee = 'ASSIGNEE',
  InstanceCount = 'INSTANCE_COUNT',
  InstanceChange = 'INSTANCE_CHANGE',
}

export interface AlarmImpactWorkTicketOrder {
  field: AlarmImpactWorkTicketOrderField;
  direction: OrderDirection;
}

export interface AlarmImpactWorkTicketOptions {
  alarmInput?: CoreAlarmsFiltersWhere;
  workTicketInput?: AlarmImpactWorkTicketWhere;
  granularity: Granularity;
  isOpen: boolean;
  timezone: string;
  order?: AlarmImpactWorkTicketOrder;
  limit?: number;
  offset?: number;
  currentPeriod?: DatetimeRange;
  previousPeriod?: DatetimeRange;
}

export interface AlarmImpactWorkTicketWhere {
  createdAt?: DatetimeRange;
  closedAt?: DatetimeRange;
  assignee?: string[];
  createdBy?: string[];
  ageDaysMin?: number;
  ageDaysMax?: number;
  isResolved?: boolean;
}

export interface AlarmImpactWorkTicketChart {
  resolvedCount: number;
  openedCount: number;
  unresolvedCount: number;
  period: string;
}

export interface AlarmImpactWorkTicket {
  id: number;
  externalId?: string;
  resolution?: string;
  createdAt: string;
  closedAt?: string;
  assignee: string;
  previousInstanceCount: number;
  currentInstanceCount: number;
}

export interface AlarmImpactTooltipCounts {
  count: AlarmImpactLookbackTooltipCounts;
  resolvedCount?: AlarmImpactLookbackTooltipCounts;
  unresolvedCount?: AlarmImpactLookbackTooltipCounts;
  openedCount?: AlarmImpactLookbackTooltipCounts;
}

export interface AlarmImpactLookbackTooltipCounts {
  previous: number;
  current: number;
}

export interface AlarmImpactMetricsQuery {
  unresolvedWorkDurationInput: number;
  unresolvedWorkInput: number;
  workAssociatedAlarmsCount: number;
}

export interface AlarmImpactMetrics {
  workAssociatedAlarms: number;
  workAssociatedAlarmsChange: number;
  unresolvedWork: number;
  unresolvedWorkChange: number;
  unresolvedDuration: number;
  unresolvedDurationChange: number;
  repeatTickets: number;
  repeatTicketsChange: number;
}
