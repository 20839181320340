import { gql } from 'apollo-angular';

export const GET_ENTITY_LIST_QUERY = gql`
  query entities($options: EntityOptions!) {
    entities(options: $options) {
      id
      name
      assetClass
      class
      classId
      rawEntityClass
      criticality
      criticalityPath
      properties
      alarmIds
      location
      roomName
      roomNumber
      isPaid
      spaceType {
        id
        name
      }
      locationEntities {
        id
        name
        class
      }
    }
    entityCount(options: $options)
  }
`;

export const DELETE_ENTITY_RELATIONSHIP_QUERY = gql`
  mutation deleteEntityRelation($entityRelationshipId: Int!) {
    deleteEntityRelation(entityRelationshipId: $entityRelationshipId)
  }
`;

export const SEARCH_ENTITY_COUNT_QUERY = gql`
  query searchEntityCount($options: SearchEntityOptions!) {
    searchEntityCount(options: $options)
  }
`;

export const SEARCH_ENTITIES_QUERY = gql`
  query searchEntities($options: SearchEntityOptions!) {
    searchEntities(options: $options) {
      id
      name
      count
      building
      class
    }
  }
`;

export const GET_ENTITIES_OPTIONS_QUERY = gql`
  query searchEntities($options: SearchEntityOptions!) {
    searchEntities(options: $options) {
      id
      name
      count
      building
      class
    }
    searchEntityCount(options: $options)
  }
`;

export const CREATE_ENTITY_RELATIONSHIP_QUERY = gql`
  mutation createEntityRelation(
    $baseEntityId: Int!
    $relationshipType: BrickRelationshipType!
    $relationEntityId: [Int!]!
  ) {
    createEntityRelation(
      baseEntityId: $baseEntityId
      relationshipType: $relationshipType
      relationEntityId: $relationEntityId
    )
  }
`;

export const GET_ENTITY_CLASSES_QUERY = gql`
  query entityClasses($options: EntityClassOptions!) {
    entityClasses(options: $options) {
      id
      name
    }
    entityClassCount(options: $options)
  }
`;

export const GET_ENTITY_CLASS_OPTIONS_QUERY = gql`
  query entityClasses($options: EntityClassOptions!) {
    entityClasses(options: $options) {
      id
      name
    }
    entityClassCount(options: $options)
  }
`;

export const GET_MERGED_ENTITIES_QUERY = gql`
  query entities($options: EntityOptions!) {
    entities(options: $options) {
      name
    }
  }
`;

export const UPDATE_ENTITY_CRITICALITY_QUERY = gql`
  mutation updateEntityCriticality($entityId: Int!, $criticality: Int!) {
    updateEntityCriticality(entityId: $entityId, criticality: $criticality)
  }
`;

export const UPDATE_ENTITY_QUERY = gql`
  mutation updateEntity($entityId: Int!, $input: EntityUpdateInput!) {
    updateEntity(entityId: $entityId, input: $input) {
      alarmIds
      class
      criticality
      id
      location
      name
      rawEntityClass
    }
  }
`;

export const DELETE_ENTITY_QUERY = gql`
  mutation deleteEntity($entityId: Int!) {
    deleteEntity(entityId: $entityId)
  }
`;

export const GET_CREATE_ENTITY_SCHEMA_QUERY = gql`
  query createEntitySchema($class: EntityClass!) {
    createEntitySchema(class: $class)
  }
`;

export const GET_ENTITY_ACTIVITY_QUERY = gql`
  query entityActivity(
    $options: EntityActivityOptions!
    $countOptions: EntityActivityOptions!
  ) {
    entityActivity(options: $options) {
      id
      entity {
        name
        id
      }
      entityId
      alarmId
      activityType
      property
      propertyRevValue
      propertyPrevValue
      createdAt
      createdBy {
        firstName
        lastName
      }
      snoozeReason
      snoozeDuration
      workTicket {
        externalType
        isClosedExternally
        displayId
        closedBy {
          firstName
          lastName
        }
        automationRule {
          id
        }
      }
      relatedEntity {
        name
      }
      relationshipDirection
    }
    entityActivityCount(options: $countOptions)
  }
`;

export const GET_ASSET_ACTIVITY_COUNT_QUERY = gql`
  query assetActivity($input: AssetActivityOptions!) {
    assetActivity(input: $input) {
      field
      type
      createdAt
      alarm {
        id
        alarmName
      }
      createdBy {
        firstName
        lastName
      }
      metadata
      entity {
        id
        name
      }
      workTicket {
        id
        problemDetails
        displayId
      }
    }
    assetActivityCount(input: $input)
  }
`;

export const CREATE_ENTITY_QUERY = gql`
  mutation createEntity($input: EntityCreateInput!) {
    createEntity(input: $input) {
      id
      name
    }
  }
`;

export const GET_ENTITY_RELATIONSHIPS_QUERY = gql`
  query assetRelationships($entityId: Int!, $includeUpstream: Boolean) {
    assetRelationships(entityId: $entityId, includeUpstream: $includeUpstream) {
      id
      relationshipId
      building
      floor
      name
      type
      spaceType
      relationshipType
      status
      relationshipDirection
      isReadOnly
      class
      stateChangedAt
    }
  }
`;
