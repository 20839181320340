import { gql } from 'apollo-angular';

export const INVENTORY_AUDIT_LOG_QUERY = gql`
  query inventoryAuditLogs($input: InventoryAuditLogOptions!) {
    inventoryAuditLogs(input: $input) {
      id
      file
      reason
      createdAt
      createdBy {
        displayName
      }
    }
  }
`;
