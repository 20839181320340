export enum SituationalDashboardEntityState {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export enum SituationalDashboardEntityWorkCreator {
  User = 'USER',
  Automation = 'AUTOMATION',
}

export enum SituationalDashboardEntityClass {
  Room = 'ROOM',
  Equipment = 'EQUIPMENT',
}

export enum SituationalDashboardEntityType {
  ROOM = 'Room',
  EQUIPMENT = 'Equipment',
}

export enum SituationalDashboardEntitySortField {
  StateChangedAt = 'STATE_CHANGED_AT',
  IsEscalated = 'IS_ESCALATED',
  LastActiveWorkTicketCreatedBy = 'LAST_ACTIVE_WORK_TICKET_CREATED_BY',
  BuildingName = 'BUILDING_NAME',
  IsCovered = 'IS_COVERED',
  State = 'STATE',
}

export const SituationalDashboardSnoozeValues = {
  15: '15 minutes',
  30: '30 minutes',
  60: '1 hour',
};

export enum SituationalAwarenessEntityActivityTypeValues {
  SNOOZE_ENDED = 'Ended',
  SNOOZE_CANCELLED = 'Cancelled',
  SNOOZE_STARTED = 'Started',
  WORK_OPENED = 'Opened',
  WORK_CLOSED = 'Closed',
}

export enum SituationalAwarenessViewEntitySortOrder {
  OldestFirst = 'OLDEST_FIRST',
  LatestFirst = 'LATEST_FIRST',
}

export enum SituationalDashboardAlarmSortField {
  IsActive = 'IS_ACTIVE',
  LatestAlarmTime = 'LATEST_ALARM_TIME',
  HasWork = 'HAS_WORK',
}

export const SituationalAwarenessSortOptions = [
  {
    label: 'Oldest on Top',
    value: SituationalAwarenessViewEntitySortOrder.OldestFirst,
  },
  {
    label: 'Newest on Top',
    value: SituationalAwarenessViewEntitySortOrder.LatestFirst,
  },
];

export const SituationalAwarenessCriticality = {
  Highest: 5,
  High: 4,
  Medium: 3,
  Low: 2,
  Lowest: 1,
  None: 0,
};

export const SituationalAwarenessCriticalityValues = [
  {
    label: '0',
    value: SituationalAwarenessCriticality.None,
  },
  {
    label: '1',
    value: SituationalAwarenessCriticality.Lowest,
  },
  {
    label: '2',
    value: SituationalAwarenessCriticality.Low,
  },
  {
    label: '3',
    value: SituationalAwarenessCriticality.Medium,
  },
  {
    label: '4',
    value: SituationalAwarenessCriticality.High,
  },
  {
    label: '5',
    value: SituationalAwarenessCriticality.Highest,
  },
];

export const ClosedWorkTicketsSortOptions = [
  {
    name: 'Created',
    show: true,
    type: 'dateTime',
    dbName: 'CREATED_AT',
    category: 'Work Tickets',
  },
  {
    name: 'Assignee',
    show: true,
    type: 'string',
    dbName: 'ASSIGNEE',
    category: 'Work Tickets',
  },
  {
    name: 'ID',
    show: true,
    type: 'string',
    dbName: 'ID',
    category: 'Work Tickets',
  },
  {
    name: 'Resolution',
    show: true,
    type: 'string',
    dbName: 'IS_RESOLVED',
    category: 'Work Tickets',
  },
];

export const WorkTicketsSortOptions = [
  {
    name: 'Created',
    show: true,
    type: 'dateTime',
    dbName: 'CREATED_AT',
    category: 'Work Tickets',
  },
  {
    name: 'Due Date',
    show: true,
    type: 'dateTime',
    dbName: 'DUE_AT',
    category: 'Work Tickets',
  },
  {
    name: 'Time to Impact',
    show: true,
    type: 'dateTime',
    dbName: 'CLOSEST_ENTITY_IMPACT_AT',
    category: 'Work Tickets',
  },
  {
    name: 'Assignee',
    show: true,
    type: 'string',
    dbName: 'ASSIGNEE',
    category: 'Work Tickets',
  },
  {
    name: 'ID',
    show: true,
    type: 'string',
    dbName: 'ID',
    category: 'Work Tickets',
  },
];

export const WorkTicketsDefaultSorts = {
  name: 'Created',
  show: true,
  type: 'dateTime',
  dbName: 'CREATED_AT',
  category: 'Work Tickets',
};

export const ClosedWorkTicketsDefaultSorts = {
  name: 'Created',
  show: true,
  type: 'dateTime',
  dbName: 'CREATED_AT',
  category: 'Work Tickets',
};

export const STATE_VALUE = new Map()
  .set(SituationalDashboardEntityState.Active, 1)
  .set(SituationalDashboardEntityState.Inactive, 0);
