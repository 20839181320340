<div class="menu">
  <ul nz-menu nzMode="inline">
    <ng-container *ngFor="let lens of lenses; let first = first">
      <li
        nz-submenu
        class="inline-menu-header"
        [nzOpen]="menuOpenState[lens.name] || false"
        (nzOpenChange)="menuOpenState[lens.name] = $event"
      >
        <span title class="iregular-16 ellipsis accordion-title">{{
          lens.name
        }}</span>
        <ul>
          <ng-container *ngFor="let child of lens.childLens">
            <li
              nz-menu-item
              class="pl-5 submenu-border-spacer"
              *ngIf="child.type !== lensType.CREATE"
              [attr.aria-label]="child.name"
              [ngClass]="{
                activeLink: currentSelectedLens?.id === child.id,
              }"
            >
              <span title class="collapse-icon d-flex align-items-center w-100">
                <i
                  class="fa-solid fa-star regular-10 mr-1 cursor-pointer"
                  [class.fa-bounce]="
                    showStarAnimation && defaultLensId === child.id
                  "
                  [class.disabled-star]="defaultLensId !== child.id"
                  [class.yellow-6]="defaultLensId === child.id"
                  (click)="setDefaultLens(child.id)"
                ></i>

                <div
                  class="d-flex align-items-center"
                  (click)="routeChanged(child)"
                >
                  <a
                    class="mb-0 mr-1 lenName ellipsis"
                    [attr.aria-label]="child.name"
                    nz-tooltip
                    nzTooltipPlacement="right"
                    [nzTooltipTitle]="
                      child.name.length >= 16 ? child.name : null
                    "
                  >
                    {{ child.name }}
                  </a>
                </div>
                <div
                  class="d-flex justify-content-end w-100"
                  id="badge-container"
                >
                  <i
                    *ngIf="canEditLens(child)"
                    class="fas fa-pencil show-on-hover cursor-pointer"
                    (click)="updateLens(child.category, child.isCustom)"
                  ></i>

                  <nz-badge
                    nzNoAnimation
                    [class.hide-on-hover]="canEditLens(child)"
                    [nzOverflowCount]="999999"
                    [nzShowZero]="true"
                    *ngIf="
                      (triageCounts$ | async)[child.id] &&
                      showCounts(child?.category)
                    "
                    [nzCount]="(triageCounts$ | async)[child.id]"
                  ></nz-badge>
                </div>
              </span>
            </li>
            <li
              nz-menu-item
              *ngIf="lens?.isCustom && canCreateLens(lens, child)"
              (click)="openLensModel(child.category, lens)"
              class="create-lens-item"
            >
              <span title class="collapse-icon d-flex align-items-center w-100">
                <div class="transparent-border"></div>
                <div
                  class="d-flex align-items-center justify-content-end w-100"
                >
                  <i
                    class="fas fa-plus-circle blue-4 regular-12"
                    nz-tooltip
                    nzTooltipTitle="Create lens"
                  ></i>
                </div>
              </span>
            </li>
          </ng-container>
        </ul>
      </li>
    </ng-container>
  </ul>
</div>
