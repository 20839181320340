import { Apollo, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache, ApolloLink } from '@apollo/client/core';
import { Store } from '@ngxs/store';
import { NgModule } from '@angular/core';
import { filter, take } from 'rxjs/operators';
import { User, CUSTOMER_ID_HEADER } from '@vfi-ui/models';
import { environment } from '@vfi-ui/environments/environment';
import { GraphQLReady } from '@vfi-ui/state';

@NgModule({
  imports: [ApolloModule],
})
export class GraphQLModule {
  constructor(
    private apollo: Apollo,
    private store: Store,
    private httpLink: HttpLink
  ) {
    const httpLinkDef = this.httpLink.create({
      uri: environment.backend,
    });
    this.apollo.createDefault({
      link: ApolloLink.from([httpLinkDef]),
      cache: new InMemoryCache({
        addTypename: false,
      }),
    });
    this.store
      .select((state) => state.auth)
      .pipe(
        filter((auth) => !!auth && !!auth.user && !!auth.user.products),
        take(1)
      )
      .subscribe(({ user }) => {
        this.setupApolloApi(user);
        this.store.dispatch(new GraphQLReady());
      });
  }

  /**
   * set up apollo link for backend api
   *
   * @param {User} user
   * @memberof GraphQLModule
   */
  setupApolloApi(user: User): void {
    const middleware = new ApolloLink((operation, forward) => {
      // add the authorization to the headers
      const token = user.token;
      const customerId = localStorage.getItem(CUSTOMER_ID_HEADER);
      let headers = {
        Authorization: `${token}`,
      };
      if (customerId) {
        headers[CUSTOMER_ID_HEADER] = customerId;
      }
      const operationalContext = operation.getContext();
      if (operationalContext?.headers) {
        headers = { ...headers, ...operationalContext.headers };
      }
      operation.setContext({
        headers,
      });
      return forward ? forward(operation) : null;
    });
    const httpLinkDef = this.httpLink.create({
      uri: environment.backend,
    });
    this.apollo.removeClient();
    this.apollo.createDefault({
      link: ApolloLink.from([middleware, httpLinkDef]),
      cache: new InMemoryCache({
        addTypename: false,
      }),
    });
  }
}
