import { Subject } from 'rxjs';
import { Component, OnDestroy } from '@angular/core';

@Component({
  selector: 'vfi-core-base-component',
  template: '<div></div>',
})
export abstract class BaseComponent implements OnDestroy {
  protected componentDestroyed: Subject<void> = new Subject<void>();
  protected destroyed$: Subject<boolean> = new Subject();

  ngOnDestroy(): void {
    this.componentDestroyed.next();
  }
}
