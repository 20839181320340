import { gql } from 'apollo-angular';

export const GET_ASSETS_QUERY = gql`
  query assets($input: AssetOptions) {
    assets(input: $input)
  }
`;

export const GET_ASSET_OPTIONS_QUERY = gql`
  query assets($input: AssetOptions) {
    assets(input: $input)
    assetCount(input: $input)
  }
`;

export const GET_ASSET_PROPERTY_OPTIONS_QUERY = gql`
  query assetProperties($input: AssetPropertyOptions) {
    assetProperties(input: $input) {
      type
      value
    }
    assetPropertyCount(input: $input)
  }
`;

export const CHECK_DUPLICATE_ASSETS_QUERY = gql`
  query checkEntityDuplicate(
    $entityId: Int
    $createInput: EntityCreateInput
    $updateInput: EntityUpdateInput
  ) {
    checkEntityDuplicate(
      entityId: $entityId
      createInput: $createInput
      updateInput: $updateInput
    ) {
      name
      assetClass
      location
    }
  }
`;
