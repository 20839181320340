import { gql } from 'apollo-angular';

export const GET_REPORT_EXPORTS = gql`
  query reportExports($input: ReportExportOptions!) {
    reportExports(input: $input) {
      id
      exportType
      status
      fileUrl
      error
      fileName
      completedAt
    }
  }
`;

export const GENERATE_ALARM_REPORT_EXPORT = gql`
  mutation createAlarmInstanceReportExport(
    $input: AlarmInstanceReportExportInput!
  ) {
    createAlarmInstanceReportExport(input: $input) {
      id
      fileName
    }
  }
`;

export const GET_REPORT_QUERY = gql`
  query getReportUrl($type: LookerViewType!) {
    getReportUrl(type: $type)
  }
`;
