import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'nuclei-popup-container',
  templateUrl: './popup-container.component.html',
  styleUrls: ['./popup-container.component.scss'],
})
export class PopupContainerComponent {
  @Output() togglePopup = new EventEmitter<void>();
  @Input() title: string;

  /**
   * toggle close event
   * @param event
   * @memberof PopupContainerComponent
   */
  onTogglePopup(event) {
    this.togglePopup.emit(event);
  }
}
