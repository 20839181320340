import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  UntypedFormControl,
} from '@angular/forms';
import { PasswordValidator } from '@vfi-ui/util/validators';

@Component({
  selector: 'nuclei-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SetPasswordComponent implements OnInit {
  @Input() passwordForm: UntypedFormGroup;
  @Input() changePassword = false;
  @Input() oldPasswordError = '';
  @Output() formValid: EventEmitter<boolean> = new EventEmitter();

  showForm = false;
  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.passwordForm = this.passwordForm
      ? this.passwordForm
      : this.formBuilder.group({});
    this.passwordForm.addControl('password', new UntypedFormControl(null));
    this.passwordForm.addControl(
      'confirmPassword',
      this.formBuilder.control(null)
    );
    if (this.changePassword) {
      this.passwordForm.addControl(
        'oldPassword',
        this.formBuilder.control(null, Validators.required)
      );
    }

    const validators = this.getPasswordValidators();

    this.passwordForm.controls['password'].setValidators(validators);

    this.passwordForm.controls['confirmPassword'].setValidators(
      Validators.required
    );

    this.showForm = true;

    this.passwordForm.statusChanges.subscribe((d) => {
      this.formValid.emit(d === 'VALID');
    });
  }

  /**
   * return validators for password
   *
   * @private
   * @returns
   * @memberof SetPasswordComponent
   */
  private getPasswordValidators() {
    return [
      Validators.compose([
        Validators.required,
        // check whether the entered password has a number
        PasswordValidator.patternValidator(/\d/, {
          hasNumber: true,
        }),
        // check whether the entered password has upper case letter
        PasswordValidator.patternValidator(/[A-Z]/, {
          hasCapitalCase: true,
        }),
        // check whether the entered password has a lower case letter
        PasswordValidator.patternValidator(/[a-z]/, {
          hasSmallCase: true,
        }),
        // check whether the entered password has a special character
        PasswordValidator.patternValidator(
          /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/,
          {
            hasSpecialCharacters: true,
          }
        ),
        Validators.minLength(8),
      ]),
    ];
  }
}
