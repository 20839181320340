import {
  Integrations,
  ExternalSystemSchema,
  ExternalSystemTypes,
} from '@vfi-ui/models';
import { State, Selector, Action, StateContext } from '@ngxs/store';
import { SetExternalSystemIntegrations } from './external-systems.actions';
import { patch } from '@ngxs/store/operators';
import { Injectable } from '@angular/core';

export interface ExternalSystemsStateModel {
  items: Integrations[];
  cmms: Integrations[];
  activeCmms: Integrations;
}
export const externalSystemsStateModelDefaults: ExternalSystemsStateModel = {
  items: [],
  cmms: [],
  activeCmms: null,
};

@State<ExternalSystemsStateModel>({
  name: 'externalSystems',
  defaults: externalSystemsStateModelDefaults,
})
@Injectable({
  providedIn: 'root',
})
export class ExternalSystemsState {
  @Selector()
  public static getState(state: ExternalSystemsStateModel) {
    return state;
  }
  @Selector()
  public static getIntegrations(state: ExternalSystemsStateModel) {
    return state.items;
  }

  @Selector()
  public static getIntegrationOptions(state: ExternalSystemsStateModel) {
    return state?.items?.map((item) => ({
      label: item.displayName,
      value: item?.id,
    }));
  }

  @Selector()
  public static getCmms(state: ExternalSystemsStateModel) {
    return state.cmms;
  }
  @Selector()
  public static getActiveCmms(state: ExternalSystemsStateModel) {
    return state.activeCmms;
  }
  @Selector()
  public static hasMetasys(state: ExternalSystemsStateModel) {
    return !!state.items
      .filter((d) => d.isActive)
      .find((d) => d.schema === ExternalSystemSchema.METASYS);
  }

  @Selector()
  public static hasWorkTicketSystem(state: ExternalSystemsStateModel) {
    return !!state.activeCmms;
  }

  @Selector()
  public static hasWebTMA(state: ExternalSystemsStateModel) {
    return (
      state.items
        .filter((d) => d.isActive)
        .findIndex((i) => i.schema === ExternalSystemSchema.WEBTMA) !== -1
    );
  }

  @Action(SetExternalSystemIntegrations)
  setExternalSystemIntegrations(
    ctx: StateContext<ExternalSystemsStateModel>,
    { payload }: { payload: Integrations[] }
  ) {
    const cmms = payload.filter((d) => d?.type === ExternalSystemTypes.WORK);
    const activeCmms = cmms
      .sort((a, b) => a.createdAt.localeCompare(b.createdAt))
      .find((d) => d.isActive);
    ctx.setState(
      patch({
        items: payload,
        cmms,
        activeCmms,
      })
    );
  }
}
