import { LensCategory, LensType } from '../interfaces/lens-interface';
import { LandingPageUrls } from './users.constant';

export const DupeLensError = 'Lens with name already exists';

export const ALARM_LENS_CATEGORY_OPTIONS = [
  { label: 'Active Alarms', value: LensCategory.ACTIVE_ALARMS },
  { label: 'All Alarms', value: LensCategory.ALL_ALARMS },
];

export const WORK_LENS_CATEGORY_OPTIONS = [
  { label: 'Open Work', value: LensCategory.ACTIVE_WORK },
  { label: 'All Work', value: LensCategory.ALL_WORK },
];

export const ASSET_LENS_CATEGORY_OPTIONS = [
  { label: 'Active Assets', value: LensCategory.ACTIVE_ASSETS },
  { label: 'All Assets', value: LensCategory.ALL_ASSETS },
];

export const CUSTOM_LENS_TABLE_CONGIF_WARNING_MESSAGE =
  'Active columns, column order, sort order, and group by settings will be updated. Are you sure you want to save these changes?';
export const TEAM_LENS_TABLE_CONFIG_WARNING_MESSAGE =
  'This is a team lens. Active columns, column order, sort order, and group by settings will be updated. Configuration updates will apply to all members of the team. Are you sure you want to save these changes?';

export const LENS_TYPE_MAP = new Map<LensCategory, LensType>()
  .set(LensCategory.ACTIVE_WORK, LensType.WORK)
  .set(LensCategory.ALL_WORK, LensType.WORK)
  .set(LensCategory.ACTIVE_ALARMS, LensType.ALARM)
  .set(LensCategory.ALL_ALARMS, LensType.ALARM)
  .set(LensCategory.ACTIVE_ASSETS, LensType.ASSET)
  .set(LensCategory.ALL_ASSETS, LensType.ASSET);

export const LENS_TYPE_URL_MAP = new Map<LensType, string>()
  .set(LensType.ALARM, LandingPageUrls.TRIAGE_TABLE_VIEW)
  .set(LensType.WORK, LandingPageUrls.EXPLORER)
  .set(LensType.ASSET, LandingPageUrls.ASSETS);

export const LENS_TYPE_DEFAULT_LENS = new Map<LensType, string>()
  .set(LensType.ALARM, 'Active Alarms')
  .set(LensType.WORK, 'My Work')
  .set(LensType.ASSET, 'All Assets');
