import { ChangeAlarm, Counts } from '@vfi-ui/models';

export class LoadWorkTickets {
  public static readonly type = '[Work tickets] Load work tickets';
}

export class LoadMoreWorkTickets {
  public static readonly type = '[Work tickets] Load more work tickets';
}
export class WorkTileSelected {
  public static readonly type = '[Work tickets] select work ticket';
  constructor(public payload: ChangeAlarm) {}
}
export class ResetWorkTickets {
  public static readonly type = '[Work tickets] reset work ticket state';
}
export class ToggleSubmitReivewModal {
  public static readonly type =
    '[Work tickets] toggle submit for review model state';
  constructor(public payload: boolean) {}
}
export class ToggleFeedbackModal {
  public static readonly type = '[Work tickets] toggle feedback model state';
  constructor(public payload: boolean) {}
}
export class RemoveWorkTicket {
  public static readonly type = '[Work tickets] remove a work ticket';
  constructor(public payload: number) {}
}

export class AddWorkTicketCounts {
  public static readonly type =
    '[Work tickets] set primary counts for work tickets';
  constructor(public payload: Counts) {}
}

export class LiveWorkTickets {
  public static readonly type =
    '[Work tickets] Socket feed for live work tickets';
}

export class CleanupWorkTicketSockets {
  public static readonly type =
    '[Work tickets] clear all Socket feed for live tickets';
}

export class SetRequiredWorkTicketFields {
  public static readonly type =
    '[Work tickets] Set required work ticket fields';
  constructor(public payload: string[]) {}
}
