import { Injectable } from '@angular/core';
import { LensType } from '@vfi-ui/models';
import { MenuService } from '@vfi-ui/data-access/shared';
import { RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DefaultLensGuard {
  constructor(private menuService: MenuService) {}

  canActivate(
    state: RouterStateSnapshot,
    lensType: LensType
  ): Observable<boolean> {
    return this.menuService.navigateToDefaultLens(lensType, state.url);
  }
}
