import { SetTeams, TeamsState, TeamsStateModel } from '@vfi-ui/state';
import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { TeamService } from '@vfi-ui/data-access/shared';
import { Observable, map, take } from 'rxjs';
import { Team } from '@vfi-ui/models';

@Injectable({
  providedIn: 'root',
})
export class TeamsResolver {
  @Select(TeamsState.getState) teamsState$: Observable<TeamsStateModel>;

  constructor(
    private store: Store,
    private teamsService: TeamService
  ) {}

  resolve() {
    this.teamsService
      .getAllTeams()
      .subscribe((teams) => this.store.dispatch(new SetTeams(teams || [])));
    return this.waitForStateToUpdate();
  }

  /**
   * wait for state to have stored value before returning data
   *
   * @returns {Observable<Team[]>}
   * @memberof TeamsResolver
   */
  waitForStateToUpdate(): Observable<Team[]> {
    return this.teamsState$.pipe(
      map((res) => res.teams),
      take(1)
    );
  }
}
