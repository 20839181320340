<div class="login w-100 d-flex align-content-center justify-content-center">
  <div class="align-self-center">
    <div class="login_wrapper">
      <div class="login_form">
        <div class="login_box">
          <section
            class="login_content d-flex flex-column align-items-center justify-content-between h-100"
          >
            <div
              class="form_wrapper d-flex flex-column align-items-center w-100"
            >
              <img
                class="vfi-logo mb-3"
                src="/assets/vfi-logo.svg"
                alt="vfi-logo"
              />
              <form [formGroup]="resetForm" class="w-100">
                <input
                  nz-input
                  type="email"
                  placeholder="Email"
                  formControlName="email"
                />
                <div
                  *ngIf="submitted && !!resetFormControls.email.errors"
                  class="red"
                >
                  <div
                    class="mt-2"
                    *ngIf="!!resetFormControls.email.errors.required"
                  >
                    Email is required
                  </div>
                  <div
                    class="mt-2"
                    *ngIf="!!resetFormControls.email.errors.email"
                  >
                    Email must be a valid email address
                  </div>
                </div>
                <div class="mt-2">
                  <atom-vfi-button
                    class="mt-2"
                    [text]="'Reset Password'"
                    [ariaLabel]=""
                    [type]="'secondary'"
                    [classes]="'block-button reset-btn'"
                    (clicked)="resetPassword(resetForm.value)"
                  ></atom-vfi-button>
                </div>
                <a routerLink="/login" class="underline regular-16"
                  >Return To Log In</a
                >
              </form>
            </div>
            <div class="mt-4">
              <p class="copyright gray-1 regular-12">
                &copy; {{ currentYear }} Virtual Facility, Inc. All Rights
                Reserved.
              </p>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</div>
