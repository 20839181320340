import {
  NotificationService,
  AlarmsDataService,
} from '@vfi-ui/data-access/shared';
import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import {
  ToggleCreateWork,
  SaveCreateWorkDetailsForm,
  AddAssociatedAlarm,
  RemoveAssociatedAlarm,
  AddMultiAssociatedAlarm,
  ResetCreateWork,
  ToggleControlCenter,
  AddAssociatedEntities,
  ToggleReissueWork,
  SetReissueWorkTicket,
  ToggleIsWorkCreated,
  AddMultiAssociatedTableAlarm,
} from './create-work.actions';
import { patch, append, removeItem } from '@ngxs/store/operators';
import {
  EntityWorkTicketInput,
  WorkTicket,
  Tile,
  WorkTicketCreateInput,
} from '@vfi-ui/models';
import { AlarmsState } from './../alarms/alarms.state';
import { MAX_ASSOCIATED_ALARMS } from '@vfi-ui/models';
import { Injectable } from '@angular/core';
import { isBoolean, isNil, MapAlarmToTile } from '@vfi-ui/util/helpers';

export interface CreateWorkStateModel {
  isOpen: boolean;
  isWorkCreated: boolean;
  createWorkDetailsForm;
  additionalWorkDetailsForm;
  cmmsForm;
  associatedAlarms: Tile[];
  associatedEntities: EntityWorkTicketInput[];
  isControlCenter: boolean;
  isReissue: boolean;
  reissueWorkTicket: WorkTicket;
}
export const CreateWorkStateDefaults: CreateWorkStateModel = {
  isOpen: false,
  isWorkCreated: false,
  createWorkDetailsForm: {
    model: undefined,
    dirty: false,
    status: '',
    errors: {},
  },
  additionalWorkDetailsForm: {
    model: undefined,
    dirty: false,
    status: '',
    errors: {},
  },
  cmmsForm: {
    model: undefined,
    dirty: false,
    status: '',
    errors: {},
  },
  associatedAlarms: [],
  associatedEntities: [],
  isControlCenter: false,
  isReissue: false,
  reissueWorkTicket: null,
};

@State<CreateWorkStateModel>({
  name: 'createWork',
  defaults: CreateWorkStateDefaults,
})
@Injectable({
  providedIn: 'root',
})
export class CreateWorkState {
  constructor(
    private store: Store,
    private alarmsService: AlarmsDataService,
    private notificationService: NotificationService
  ) {}

  @Selector()
  public static getState(state: CreateWorkStateModel) {
    return state;
  }

  @Selector()
  public static getAssociatedAlarms(state: CreateWorkStateModel) {
    return state.associatedAlarms;
  }

  @Selector()
  public static getIsOpen(state: CreateWorkStateModel) {
    return state.isOpen;
  }

  @Selector()
  public static isControlCenter(state: CreateWorkStateModel) {
    return state.isControlCenter;
  }

  @Selector()
  public static getAssociatedEntities(state: CreateWorkStateModel) {
    return state.associatedEntities;
  }

  @Selector()
  public static getReissueWorkTicket(state: CreateWorkStateModel) {
    return state.reissueWorkTicket;
  }

  @Selector()
  public static isReissue(state: CreateWorkStateModel) {
    return state.isReissue;
  }

  @Selector()
  public static isWorkCreated(state: CreateWorkStateModel) {
    return state.isWorkCreated;
  }

  @Selector()
  public static isFormsDirty(state: CreateWorkStateModel) {
    return (
      state.additionalWorkDetailsForm.dirty ||
      state.createWorkDetailsForm.dirty ||
      state.cmmsForm.dirty
    );
  }

  @Selector()
  public static hasWork(state: CreateWorkStateModel) {
    return (
      state.associatedAlarms?.length > 0 ||
      Object.values(state?.createWorkDetailsForm?.model || {}).some(
        (n) => !isNil(n)
      ) ||
      Object.values(state?.additionalWorkDetailsForm?.model || {}).some(
        (n) => !isNil(n) && n !== false
      ) ||
      Object.values(state?.cmmsForm?.model || {}).some((n) => !isNil(n))
    );
  }
  @Selector()
  public static createTicketPost(state: CreateWorkStateModel) {
    return this.formatCreateTicketValues(state);
  }

  @Action(ResetCreateWork)
  public resetState(ctx: StateContext<CreateWorkStateModel>) {
    const state = ctx.getState();
    ctx.setState({
      ...CreateWorkStateDefaults,
      isControlCenter: state.isControlCenter,
      isWorkCreated: state.isWorkCreated,
    });
  }

  @Action(ToggleCreateWork)
  public toggleCreateWork(
    ctx: StateContext<CreateWorkStateModel>,
    { payload }: { payload: boolean }
  ) {
    ctx.setState(
      patch({
        isOpen: isBoolean(payload) ? payload : !ctx.getState().isOpen,
      })
    );
  }

  @Action(AddAssociatedAlarm)
  public associatedAlarm(
    ctx: StateContext<CreateWorkStateModel>,
    { payload }: { payload: Tile[] }
  ) {
    const state = ctx.getState();
    payload = this.checkForDupes(payload, state.associatedAlarms);
    const maxedOut = this.isMaxedOut(state, payload, MAX_ASSOCIATED_ALARMS);
    if (maxedOut) {
      this.showMaxError();
      return;
    }
    ctx.setState(
      patch({
        associatedAlarms: append(payload),
      })
    );
  }

  @Action(AddMultiAssociatedAlarm)
  public addMultiAssoicatedAlarm(ctx: StateContext<CreateWorkStateModel>) {
    let tiles = this.store.selectSnapshot(AlarmsState.getCheckedTiles);
    const state = ctx.getState();
    tiles = this.checkForDupes(tiles, state.associatedAlarms);
    const maxedOut = this.isMaxedOut(state, tiles, MAX_ASSOCIATED_ALARMS);
    if (maxedOut) {
      this.showMaxError();
      return;
    }
    ctx.setState(
      patch({
        associatedAlarms: append(tiles),
      })
    );
  }

  @Action(AddMultiAssociatedTableAlarm)
  public addMultiAssoicatedTableAlarm(
    ctx: StateContext<CreateWorkStateModel>,
    { payload }: { payload: number[] }
  ) {
    this.alarmsService.getAlarmsByIds(payload).subscribe((r) => {
      let tiles = r.map((alarm) => MapAlarmToTile(alarm));
      const state = ctx.getState();
      tiles = this.checkForDupes(tiles, state.associatedAlarms);
      const maxedOut = this.isMaxedOut(state, tiles, MAX_ASSOCIATED_ALARMS);
      if (maxedOut) {
        this.showMaxError();
        return;
      }
      ctx.setState(
        patch({
          associatedAlarms: append(tiles),
        })
      );
    });
  }

  @Action(RemoveAssociatedAlarm)
  public removeAssociatedAlarm(
    ctx: StateContext<CreateWorkStateModel>,
    { payload }: { payload: string }
  ) {
    ctx.setState(
      patch({
        associatedAlarms: removeItem((tile: Tile) => tile.tileID === payload),
      })
    );
  }

  @Action(SaveCreateWorkDetailsForm)
  public saveDetailsForm(ctx: StateContext<CreateWorkStateModel>, payload) {
    ctx.setState(
      patch({
        createWorkDetailsForm: payload.payload,
      })
    );
  }

  @Action(ToggleControlCenter)
  public toggleControlCenter(
    ctx: StateContext<CreateWorkStateModel>,
    { payload }: { payload: boolean }
  ) {
    ctx.setState(
      patch({
        isControlCenter: payload,
      })
    );
  }

  @Action(AddAssociatedEntities)
  public associatedEntity(
    ctx: StateContext<CreateWorkStateModel>,
    { payload }: { payload: EntityWorkTicketInput[] }
  ) {
    const state = ctx.getState();
    payload = this.checkForEntityDupes(payload, state.associatedEntities);
    const maxedOut = this.isEntityMaxedOut(
      state,
      payload,
      MAX_ASSOCIATED_ALARMS
    );
    if (maxedOut) {
      this.showMaxError();
      return;
    }
    ctx.setState(
      patch({
        associatedEntities: append(payload),
      })
    );
  }

  @Action(ToggleReissueWork)
  public toggleReissueWork(
    ctx: StateContext<CreateWorkStateModel>,
    { payload }: { payload: boolean }
  ) {
    ctx.setState(
      patch({
        isReissue: payload,
      })
    );
  }

  @Action(SetReissueWorkTicket)
  public setReissueWorkTicket(
    ctx: StateContext<CreateWorkStateModel>,
    { payload }: { payload: WorkTicket }
  ) {
    ctx.setState(
      patch({
        reissueWorkTicket: payload,
      })
    );
  }

  @Action(ToggleIsWorkCreated)
  public toggleIsWorkCreated(
    ctx: StateContext<CreateWorkStateModel>,
    { payload }: { payload: boolean }
  ) {
    ctx.setState(
      patch({
        isWorkCreated: payload,
      })
    );
  }

  /**
   * check if an alarm has already been added to a ticket
   *
   * @private
   * @param {Tile[]} payload
   * @param {Tile[]} associatedAlarms
   * @returns {Tile[]}
   * @memberof CreateWorkState
   */
  private checkForDupes(payload: Tile[], associatedAlarms: Tile[]): Tile[] {
    const dupes = [];
    const notDupes = payload.filter((load) => {
      const exists = associatedAlarms.find(
        (alarm) => alarm.tileID === load.tileID
      );
      if (exists) {
        dupes.push(exists);
      }
      return !exists;
    });

    if (dupes.length) {
      this.showDupeError();
    }

    return notDupes;
  }

  /**
   * check if an alarm has already been added to a ticket
   *
   * @private
   * @param {Tile[]} payload
   * @param {Tile[]} associatedAlarms
   * @returns {Tile[]}
   * @memberof CreateWorkState
   */
  private checkForEntityDupes(
    payload: EntityWorkTicketInput[],
    associatedEntities: EntityWorkTicketInput[]
  ): EntityWorkTicketInput[] {
    const dupes = [];
    const notDupes = payload.filter((load) => {
      const exists = associatedEntities.find(
        (entity) =>
          entity.alarmId === load.alarmId && entity.entityId === load.entityId
      );
      if (exists) {
        dupes.push(exists);
      }
      return !exists;
    });

    if (dupes.length) {
      this.showDupeError();
    }

    return notDupes;
  }

  /**
   * show dupe alarms added error
   *
   * @private
   * @param {(string | number)} id
   * @memberof CreateWorkState
   */
  private showDupeError(): void {
    this.notificationService.showError(
      'Alarm already attached',
      `Duplicate alarms cannot be added to a work ticket.`
    );
  }

  /**
   * show max associated alarm reached error
   *
   * @private
   * @memberof CreateWorkState
   */
  private showMaxError(): void {
    this.notificationService.showError(
      `Alarm limit reached`,
      `You can add up to ${MAX_ASSOCIATED_ALARMS} alarms to this ticket. Please remove an alarm before adding another.`
    );
  }

  /**
   * check if the associated entities are more than max
   *
   * @private
   * @param {CreateWorkStateModel} state
   * @param {EntityWorkTicketInput[]} payload
   * @param {number} max
   * @returns {boolean}
   * @memberof CreateWorkState
   */
  private isEntityMaxedOut(
    state: CreateWorkStateModel,
    payload: EntityWorkTicketInput[],
    max: number
  ): boolean {
    return state.associatedEntities.length + payload.length > max;
  }

  /**
   * check if the associated alarms are more then max
   *
   * @private
   * @param {CreateWorkStateModel} state
   * @param {Tile[]} payload
   * @param {number} max
   * @returns {boolean}
   * @memberof CreateWorkState
   */
  private isMaxedOut(
    state: CreateWorkStateModel,
    payload: Tile[],
    max: number
  ): boolean {
    return state.associatedAlarms.length + payload.length > max;
  }

  /**
   * create post values for creating a work ticket
   *
   * @private
   * @param {CreateWorkStateModel} state
   * @memberof CreateWorkState
   */
  // eslint-disable-next-line @typescript-eslint/member-ordering
  private static formatCreateTicketValues(
    state: CreateWorkStateModel
  ): WorkTicketCreateInput {
    return {
      details: state.createWorkDetailsForm.model,
      associatedAlarms: state.associatedAlarms.map((alarm) => alarm.tileID),
      additionalDetails: state.additionalWorkDetailsForm.model,
    };
  }
}
