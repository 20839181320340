import {
  UPDATE_PRODUCTS,
  UPDATE_SITUATIONAL_AWARENESS_TIMERS,
} from './../queries/products.query';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { GET_PRODUCTS_FEATURES } from '../queries/products.query';
import { catchError, filter, map, tap } from 'rxjs/operators';
import { environment } from '@vfi-ui/environments/environment';
import { HttpClient } from '@angular/common/http';
import {
  ProductsAndFeatures,
  SituationalAwarenessTimers,
} from '@vfi-ui/models';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root',
})
export class ProductsDataService {
  constructor(
    private http: HttpClient,
    private notification: NotificationService
  ) {}

  /**
   * fetch products and features
   *
   * @returns {Observable<ProductsAndFeatures[]>}
   * @memberof ProductsDataService
   */
  getProductsAndFeatures(): Observable<ProductsAndFeatures[]> {
    return this.http
      .request<{
        data: { productsAndCount: { items: ProductsAndFeatures[] } };
      }>('POST', environment.backend, {
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          query: GET_PRODUCTS_FEATURES,
        }),
      })
      .pipe(
        filter((d) => !!d),
        map((products) => products.data.productsAndCount.items)
      );
  }

  /**
   * update customer products
   *
   * @param {number} customerId
   * @param {number[]} productsId
   * @returns
   * @memberof ProductsDataService
   */
  updateProducts({ customerId, productIds }) {
    return this.http
      .request<{
        data: { productsAndCount: { items: ProductsAndFeatures[] } };
      }>('POST', environment.backend, {
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          query: UPDATE_PRODUCTS,
          variables: { customerId, productIds },
        }),
      })
      .pipe(
        filter((d) => !!d),
        tap(() => {
          this.notification.showSuccess(
            'Products',
            'Successfully saved product changes.'
          );
        }),
        catchError((err) => {
          this.notification.showError(
            'Products',
            'Failed to save product settings. Please try again later.'
          );
          return throwError(err);
        })
      );
  }

  /**
   * update situational awareness timers
   *
   * @param {SituationalAwarenessTimers} input
   * @returns
   * @memberof ProductsDataService
   */
  updateSituationalAwarenessTimers(input: SituationalAwarenessTimers[]) {
    return this.http
      .request('POST', environment.backend, {
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          query: UPDATE_SITUATIONAL_AWARENESS_TIMERS,
          variables: { input },
        }),
      })
      .pipe(
        filter((d) => !!d),
        tap(() => {
          this.notification.showSuccess(
            'Products',
            'Successfully saved product changes.'
          );
        }),
        catchError((err) => {
          this.notification.showError(
            'Products',
            'Failed to save product settings. Please try again later.'
          );
          return throwError(err);
        })
      );
  }
}
