import { Status } from '../interfaces/user.interface';
import { AuthRoles } from './roles.constants';

export const INVITE_USER_DEFAULTS = {
  id: `invite-users`,
  uid: `invite-users`,
  phone: {
    number: ' ',
    isVerified: false,
    isSubscribed: false,
  },
  email: {
    address: ' ',
    isVerified: false,
    isSubscribed: true,
  },
  displayName: 'User Name ',
  firstName: null,
  lastName: null,
  role: 'User Title',
  authRole: AuthRoles.none,
  lastSignInTime: null,
  lastInviteTime: null,
  teams: [],
  avatarUrl: null,
  company: 'User Company',
  status: Status.none,
  selected: true,
};

export const forceShowWork = true;
