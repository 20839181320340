<div class="login w-100 d-flex align-content-center justify-content-center">
  <div class="align-self-center">
    <div>
      <div class="login_box">
        <section
          class="login_content d-flex flex-column justify-content-between h-100"
        >
          <div class="form_wrapper d-flex flex-column align-items-center">
            <img
              class="flex-1 vfi-logo mb-3"
              src="/assets/vfi-logo.svg"
              alt="vfi-logo"
            />
            <div
              class="flex-1 flex-grow-1 ng-star-inserted d-flex flex-column justify-content-center w-100 mt-5"
              *ngIf="customers$ | async as customers"
            >
              <p class="regular-20 font-light neutral-1 mb-3">
                Select Customer
              </p>
              <div
                class="input-wrapper caret-wrapper"
                [class.input-active]="!isNil(selectedCustomer)"
              >
                <nz-select
                  style="min-width: 100%"
                  [ngModel]="1000"
                  (ngModelChange)="select($event)"
                  [nzSize]="'large'"
                  aria-label="customer-dropdown"
                >
                  <nz-option
                    *ngFor="let option of customers"
                    [nzValue]="option.id"
                    [nzLabel]="option.name"
                  ></nz-option>
                </nz-select>
                <label>Select Customer</label>
              </div>
              <br />
              <br />
              <atom-vfi-button
                [text]="'Log In as Customer'"
                [ariaLabel]="'customerSelector'"
                [type]="'secondary'"
                [classes]="'w-100'"
                (clicked)="login(selectedCustomer)"
              ></atom-vfi-button>
            </div>
          </div>
          <br />
          <div class="mt-4 flex-1">
            <p class="gray-1 regular-12">
              &copy; {{ currentYear }} Virtual Facility, Inc. All Rights
              Reserved.
            </p>
          </div>
        </section>
      </div>
    </div>
  </div>
</div>
