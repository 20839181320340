import { gql } from 'apollo-angular';

export const GET_ALL_BUILDINGS_QUERY = gql`
  query list_control_query($options: AlarmPropertyOptions!) {
    alarmPropertiesAndCount(options: $options) {
      items {
        type
        value
        id
        hasLocations
      }
      count
    }
  }
`;

export const UPDATE_PARENT_LOCATION_NAME_QUERY = `
  mutation updateCustomer($id: Int!, $data: CustomerUpdateInput!) {
    updateCustomer(id: $id, data: $data) {
      parentLocationName
    }
  }
`;
export const GET_ALL_LOCATION_GROUPS_QUERY = gql`
  query locations($input: LocationOptions!) {
    locations(input: $input) {
      id
      name
      alarmProperties {
        id
        type
        value
        hasLocations
      }
    }
  }
`;
export const ADD_LOCATION_GROUP_QUERY = gql`
  mutation addLocation($input: LocationCreateInput!) {
    addLocation(input: $input) {
      id
      name
      alarmProperties {
        id
        type
        value
        hasLocations
      }
    }
  }
`;
export const EDIT_LOCATION_GROUP_QUERY = gql`
  mutation updateLocation($id: String!, $input: LocationUpdateInput!) {
    updateLocation(id: $id, input: $input) {
      id
      name
      alarmProperties {
        id
        type
        value
        hasLocations
      }
    }
  }
`;

export const DELETE_LOCATION_GROUP_MUTATION = gql`
  mutation deletetLocation($id: String!) {
    deleteLocation(id: $id)
  }
`;
