import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { NotificationService } from './notification.service';
import { ERROR_SAVE_USER_FEEDBACK, SupportTicketInput } from '@vfi-ui/models';

import { CREATE_SUPPORT_TICKET } from '../queries';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserFeedbackService {
  constructor(
    private readonly apollo: Apollo,
    private notification: NotificationService
  ) {}

  /**
   * Save features to jira project
   *
   * @param {UntypedFormGroup} form
   * @returns
   * @memberof UserFeedbackService
   */
  saveFeedback(input: SupportTicketInput): Promise<void> {
    return firstValueFrom(
      this.apollo.mutate({
        mutation: CREATE_SUPPORT_TICKET,
        variables: { input },
      })
    )
      .then(() => {
        this.notification.showSuccess(input.subject, 'Successfully sent');
      })
      .catch((err) => {
        console.error(err);
        this.notification.showError(ERROR_SAVE_USER_FEEDBACK);
      });
  }
}
