import { gql } from 'apollo-angular';

import {
  AllAlarmFields,
  ListAlarmFields,
  AlarmRawAttributes,
} from '@vfi-ui/models';

export const ALARMS_QUERY = gql`
  query alarmsAndCount($options: AlarmsOptions) {
    alarmsAndCount(options: $options) {
      count
      Alarm: items {
        ${AllAlarmFields}
      }
    }
  }
`;

export const ALARMS_QUERY_FOR_TILE_LIST = gql`
  query alarmsAndCount($options: AlarmsOptions) {
    alarmsAndCount(options: $options) {
      count
      Alarm: items {
        ${ListAlarmFields}
      }
    }
  }
`;

export const FAST_ALARM_QUERY_FOR_TILE_LIST = gql`
  query alarmsAndCount($options: AlarmsOptions, $countOptions: AlarmsOptions) {
    fastAlarms(options: $options)
    alarmsAndCount(options: $countOptions) {
      count
    }
  }
`;

export const ALARMS_QUERY_COUNTS = gql`
  query alarmsAndCount($options: AlarmsOptions) {
    alarmsAndCount(options: $options) {
      count
      alarms: items {
        id
      }
    }
  }
`;

export const FAST_ALARMS_QUERY = gql`
  query fastAlarms($options: AlarmsOptions) {
    fastAlarms(options: $options)
  }
`;

export const ALARM_RAW_ATTRIBUTES_QUERY = gql`
  query alarmsAndCount($options: AlarmsOptions) {
    alarmsAndCount(options: $options) {
      count
      Alarm: items {
        ${AlarmRawAttributes}
      }
    }
  }
`;

export const CONTROL_CENTER_ALARMS_QUERY = gql`
  query alarmsAndCount($options: AlarmsOptions) {
    alarmsAndCount(options: $options) {
      count
      items {
        id
        pointEntityId
        entities {
          name
        }
      }
    }
  }
`;

export const GET_INSTANCE_HISTORY_QUERY = gql`
  query alarm_instance_by_id($options: AlarmInstanceOptions!) {
    alarmInstancesAndCount(options: $options) {
      items {
        id
        alarmId
        ackBy
        ackTime
        alarmTime
        duration
        endTime
        endTimeSource
        processVariableValue {
          value
          unit
        }
      }
      count
    }
  }
`;

export const GET_ALARM_ACTIVITY_QUERY = gql`
  query alarmActivityAndCount($options: AlarmActivityOptions) {
    alarmActivityAndCount(options: $options) {
      items {
        field
        revValue
        prevValue
        createdAt
        user {
          displayName
          isSuper
          firstName
          lastName
        }
        automationRule {
          id
        }
        metadata {
          statusUpdateReason
          snoozeReason
          snoozeDuration
        }
      }
      count
    }
  }
`;
export const GET_AUTO_COMPLETE_QUERY_DATABASEID = gql`
  query alarm_activity_by_id(
    $order_by: [Alarm_order_by!]
    $where: Alarm_bool_exp
    $distinct_on: [Alarm_select_column!]
    $limit: Int
  ) {
    Alarm(
      where: $where
      order_by: $order_by
      distinct_on: $distinct_on
      limit: $limit
    ) {
      databaseId
    }
  }
`;

export const GET_AUTO_COMPLETE_QUERY = gql`
  query alarm_auto_complete($options: AlarmPropertyOptions!) {
    alarmPropertiesAndCount(options: $options) {
      items {
        type
        value
        id
      }
      count
    }
  }
`;

export const GET_ENTITIES = gql`
  query entities($options: EntityOptions!) {
    entities(options: $options) {
      id
      name
      isPaid
    }
    entityCount(options: $options)
  }
`;

export const GET_ALARM_OCCURRENCES_QUERY = gql`
  query alarmInstanceDaily(
    $alarmId: Int!
    $range: DatetimeRange!
    $timezone: String!
  ) {
    alarmInstanceDaily(alarmId: $alarmId, range: $range, timezone: $timezone) {
      time
      count
    }
  }
`;

export const PIN_ALARMS_QUERY = gql`
  mutation pinAlarms($ids: [Int!]!, $pin: Boolean!) {
    pinAlarms(ids: $ids, pin: $pin)
  }
`;

export const MOVE_ALARMS_QUERY = gql`
  mutation moveAlarms($input: UpdateAlarmStatusInput!) {
    moveAlarms(input: $input)
  }
`;

export const SAVE_ALARM_CONFIG_QUERY = gql`
  mutation updateAlarmPropertyConfiguration(
    $id: Int!
    $configuration: [AlarmPropertyConfigurationInput!]!
  ) {
    updateAlarmPropertyConfiguration(id: $id, configuration: $configuration) {
      ${AllAlarmFields}
    }
  }
`;

export const ADD_ALARM_PROPERTY = gql`
  mutation addAlarmProperties($type: String!, $values: [String!]) {
    addAlarmProperties(type: $type, values: $values)
  }
`;

export const GET_ALARM_STATUSE_QUERY = gql`
  {
    alarmStatuses
  }
`;

export const GET_ALARM_PROPERTIES_QUERY = gql`
  query alarm_properties($options: AlarmPropertyTypeOptions) {
    alarmPropertyTypesAndCount(options: $options) {
      items {
        id
        name
      }
    }
  }
`;

export const UPDATE_ALARM_PRIORITY_QUERY = gql`
  mutation updateAlarmPriority($ids: [Int!]!, $priority: Int!) {
    updateAlarmPriority(ids: $ids, priority: $priority)
  }
`;

export const UPDATE_ALARM_PROPERTY_QUERY = gql`
  mutation updateAlarmProperty($id: String!, $data: AlarmPropertyUpdateInput!) {
    updateAlarmProperty(id: $id, data: $data)
  }
`;

export const MERGE_ALARM_PROPERTY_QUERY = gql`
  mutation mergeAlarmProperty($fromId: String!, $toId: String!) {
    mergeAlarmProperty(fromId: $fromId, toId: $toId)
  }
`;

export const BULK_UPDATE_ALARMS_QUERY = gql`
  mutation updateAlarms($input: UpdateAlarmsInput!) {
    updateAlarms(input: $input)
  }
`;

export const WORK_TIMELINE_ALARMS_QUERY = gql`
  query workTimeline($options: AlarmInstanceOptions!) {
    alarmInstancesAndCount(options: $options) {
      items {
        id
        alarmId
        alarmTime
        endTime
        alarm {
          priority
        }
      }
    }
  }
`;

export const GET_CUSTOM_STATUSES = `
  {
    alarmStatusesCustom
  }
`;

export const CREATE_ALARM_STATUS = `
  mutation createAlarmStatus($status: String!) {
    createAlarmStatus(status: $status)
  }
`;

export const DELETE_ALARM_STATUS = `
  mutation removeAlarmStatus($status: String!) {
    removeAlarmStatus(status: $status)
  }
`;

export const UPDATE_ALARM_STATUS = `
  mutation updateAlarmStatus($currentStatus: String!, $newStatus: String!) {
    updateAlarmStatus(currentStatus: $currentStatus, newStatus: $newStatus)
  }
`;

export const UPDATE_ALARM_NOTES_QUERY = gql`
  mutation updateAlarmNotes($id: Int!, $notes: String) {
    updateAlarmNotes(id: $id, notes: $notes) {
      id
    }
  }
`;

export const GET_ALARM_ENTITIES = gql`
  query alarmEntities($options: EntityOptions!) {
    alarmEntities: entities(options: $options) {
      id
      name
      alarmIds
      location
      class
      rawEntityClass
      criticality
    }
  }
`;

export const UPDATE_ALARM_POINT_ENTITY_MUTATION = gql`
  mutation updateAlarmPointEntity($id: Int!, $entityId: Int!) {
    updateAlarmPointEntity(id: $id, entityId: $entityId)
  }
`;

export const UPDATE_ALARM_DETAILS_MUTATION = gql`
  mutation updateAlarmDetails($id: Int!, $input: AlarmDetailsInput!) {
    updateAlarmDetails(id: $id, input: $input) {
      id
    }
  }
`;

export const SNOOZE_ALARM_MUTATION = gql`
  mutation snoozeAlarm(
    $id: Int!
    $snoozeExpiresAt: DateTime!
    $reason: String
  ) {
    snoozeAlarm(id: $id, snoozeExpiresAt: $snoozeExpiresAt, reason: $reason)
  }
`;

export const UNSNOOZE_ALARM_MUTATION = gql`
  mutation unsnoozeAlarm($id: Int!) {
    unsnoozeAlarm(id: $id)
  }
`;

export const UPDATE_ALARM_CONTEXTUAL_NAME_MUTATION = gql`
  mutation updateAlarmContextualName($id: Int, $contextualName: String) {
    updateAlarmContextualName(id: $id, contextualName: $contextualName)
  }
`;

export const GET_POINTS_QUERY = gql`
  query points($input: PointOptions) {
    points(input: $input)
    pointCount(input: $input)
  }
`;
