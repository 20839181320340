import { gql } from 'apollo-angular';

export const GET_ROLES_QUERY = gql`
  query getRoles {
    getRoles {
      role
      name
      description
      customRoleId
      permissions
    }
  }
`;

export const ASSIGN_ROLE_QUERY = gql`
  mutation assignRole(
    $userIds: [String!]!
    $role: RoleEnum!
    $customRoleId: String
  ) {
    assignRole(userIds: $userIds, role: $role, customRoleId: $customRoleId)
  }
`;
