import { AuthRoles } from '../constants/roles.constants';
import { SavedViewsViewType } from '../constants/saved-views.constants';
import { MenuLens } from './menu.interface';
import { TeamMember } from './team.interface';

export interface VFITeam {
  id: string;
  permissions: object;
}

export interface VFICustomer {
  id: number;
  disabled?: boolean;
  teams?: VFITeam[];
}
export interface VFIClaims {
  admin: boolean;
  customers: VFICustomer[];
}
export interface HasuraClaims {
  // Array of roles ['user', 'admin'], etc
  'x-hasura-allowed-roles'?: string[];
  // "user"
  'x-hasura-default-role'?: string;
  // firebase id
  'x-hasura-user-id'?: string;
  // Organization id
  'x-hasura-org-id'?: string;
  // Custom header
  'x-hasura-custom'?: string;
}

export interface User {
  id: string;
  uid?: string;
  username: string;
  phone: string;
  email: string;
  token?: string;
  last_login?: Date;
  active: boolean;
  expiration_date?: Date;
  hasuraClaims?: HasuraClaims;
  displayName?: string;
  products?: Products;
  role?: string;
  pendingInvite?: string;
  lastSignInTime?: string;
  isSuper?: boolean;
  verifiedPhone?: boolean;
  verifiedEmail?: boolean;
  avatarUrl?: string;
  isPushNotificationEnabled?: boolean;
  isMFAEnabled?: boolean;
  isIntegrationStatusAlertEmailsEnabled?: boolean;
  teamMembers?: TeamMember[];
  authRole?: AuthRoles;
  permissions?: { [key: string]: any };
  landingPage?: LandingPage;
  landingPageLens?: MenuLens;
  analyticsPage?: SavedViewsViewType;
}
export type ChangeUser = Partial<Users>;

// VFI users model
export interface Users {
  id: number | string;
  uid: string;
  phone: Phone;
  email: Email;
  displayName: string;
  firstName: string;
  lastName: string;
  role: string;
  lastSignInTime: string;
  lastInviteTime: string;
  inviteNotSent?: boolean;
  avatarUrl: string;
  company: string;
  status: Status;
  selected?: boolean;
  emailRecipientUser?: Users;
  isSuper?: boolean;
  isPushNotificationEnabled?: boolean;
  isMFAEnabled?: boolean;
  isIntegrationStatusAlertEmailsEnabled?: boolean;
  lastActiveAt?: string;
  teamMembers?: TeamMember[];
  teams?: string[];
  authRole?: AuthRoles;
  authRoleId?: string;
  permissions?: { [key: string]: any };
  isSso?: boolean;
  landingPage?: LandingPage;
  landingPageLens?: MenuLens;
  analyticsPage?: SavedViewsViewType;
}

export interface Email {
  address: string;
  isVerified: boolean;
  isSubscribed: boolean;
  forwarding?: EmailForwarding;
}

export interface EmailForwarding {
  isEnabled: boolean;
  recipient: string;
  until: string;
}

export interface Pages {
  pages: { [key: string]: boolean };
}

export interface Phone {
  number: string;
  isVerified: boolean;
  isSubscribed: boolean;
}

export enum Status {
  active = 'active',
  disabled = 'disabled',
  pending = 'pending',
  none = 'none',
}

export interface Credentials {
  email: string;
  password: string;
}

export interface Products {
  url: string;
  workTicketSystem: WorkTicketSystem;
  products: Product[];
  domains: string[];
}

export interface WorkTicketSystem {
  name: string;
  fields: Field[];
}

export interface Field {
  name: string;
  required: boolean;
}
export interface Customers {
  id: number;
  name: string;
  defaultTimezone: string;
  onboardingStatus?: CustomerOnboardingStatus;
  products?: Product[];
  isSSOEnabled: boolean;
  pilotDateEnd: string;
  isPilot: boolean;
  domains: string[];
  isCloseWorkRulesBypassEnabled?: boolean;
  isCmmsSyncEnabled?: boolean;
  isBASPriorityEnabled?: boolean;
  isMFAEnabled?: boolean;
  isDisplayRawAlarmTextEnabled?: boolean;
  parentLocationName?: string;
  userSessionExpiryMins?: number;
  smsNotificationCount?: number;
  emailNotificationCount?: number;
  maxNotificationLimit?: number;
  globalCustomerId?: number;
}
export interface CreateCustomers {
  name?: string;
  domains: string[];
  pilotDateEnd: string;
  isSSOEnabled: boolean;
  isPilot: boolean;
  isCloseWorkRulesBypassEnabled?: boolean;
  isCmmsSyncEnabled?: boolean;
  isDisplayRawAlarmTextEnabled?: boolean;
  isBASPriorityEnabled?: boolean;
  isMFAEnabled?: boolean;
  defaultTimezone?: string;
  userSessionExpiryMins?: number;
  maxNotificationLimit?: number;
}

export interface Product {
  name: string;
  id?: number;
}

export interface ValidateUser {
  displayName: string;
  emailAddress: string;
}

export interface Features {
  id: string;
  name: string;
}

export interface ProductsAndFeatures {
  id: string;
  name: string;
  features: Features[];
}

export enum CustomerOnboardingStatus {
  PENDING = 'PENDING',
  COMPLETE = 'COMPLETE',
}

export interface OrganizationOptions {
  label: string;
  value: string;
}

export enum UIPermission {
  create = 'CREATE',
  edit = 'EDIT',
  view = 'VIEW',
  delete = 'DELETE',
  reassign = 'REASSIGN',
  close = 'CLOSE',
}

export enum LandingPage {
  Triage = 'TRIAGE',
  TriageTableView = 'TRIAGE_TABLE_VIEW',
  Explorer = 'EXPLORER',
  Analytics = 'ANALYTICS',
  CommandCenter = 'COMMAND_CENTER',
  Assets = 'ASSETS',
}

export enum AnalyticsPage {
  AlarmHistory = 'ALARM_HISTORY',
  BadActorsReport = 'BAD_ACTORS_REPORT',
  WorkHistory = 'WORK_HISTORY',
  WorkResolution = 'WORK_RESOLUTION',
  CurrentStatusReport = 'CURRENT_STATUS_REPORT',
  SpaceImpactSummary = 'SPACE_IMPACT_SUMMARY',
  EquipmentFailureSummary = 'EQUIPMENT_FAILURE_SUMMARY',
  KpiDashboard = 'KPI_DASHBOARD',
}

export interface CustomerAlarmConfig {
  isUpdateStatusReasonRequired: boolean;
}
