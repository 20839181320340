import { NotificationService } from './notification.service';
import { Injectable } from '@angular/core';
import {
  AlarmProperty,
  ERROR_FETCH_LOCATION_HIERARCHY_BUILDINGS,
  ERROR_UPDATE_CUSTOMER_PARENT_LOCATION_NAME,
  OrderDirection,
  ERROR_FETCH_LOCATION_HIERARCHY_GROUPS,
  Location,
  ERROR_ADD_LOCATION_HIERARCHY_GROUPS,
  ERROR_EDIT_LOCATION_HIERARCHY_GROUPS,
  ERROR_DELETE_LOCATION_GROUP,
} from '@vfi-ui/models';
import { Apollo } from 'apollo-angular';
import { filter, map, catchError, tap } from 'rxjs/operators';

import {
  ADD_LOCATION_GROUP_QUERY,
  EDIT_LOCATION_GROUP_QUERY,
  GET_ALL_BUILDINGS_QUERY,
  GET_ALL_LOCATION_GROUPS_QUERY,
  UPDATE_PARENT_LOCATION_NAME_QUERY,
  DELETE_LOCATION_GROUP_MUTATION,
} from '../queries';
import { Observable, throwError } from 'rxjs';
import { get } from '@vfi-ui/util/helpers';
import { HttpClient } from '@angular/common/http';
import { environment } from '@vfi-ui/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LocationHierarchyDataService {
  constructor(
    private apollo: Apollo,
    private notification: NotificationService,
    private http: HttpClient
  ) {}

  /**
   * call api to fetch buildings data
   *
   * @return {*}  {Observable<{ items: AlarmProperty[]; count: number }>}
   * @memberof LocationHierarchyDataService
   */
  getAllBuildings(): Observable<{ items: AlarmProperty[]; count: number }> {
    const type = { types: ['building'] };
    const where = { ...type };
    return this.apollo
      .query<{
        alarmPropertiesAndCount: { items: AlarmProperty[]; count: number };
      }>({
        fetchPolicy: 'no-cache',
        query: GET_ALL_BUILDINGS_QUERY,
        variables: {
          options: {
            where,
            order: { field: 'VALUE', direction: OrderDirection.ASC },
          },
        },
      })
      .pipe(
        filter((d) => !!d),
        map((activity) => get(activity, 'data.alarmPropertiesAndCount', [])),
        catchError((error) => {
          this.notification.showError(ERROR_FETCH_LOCATION_HIERARCHY_BUILDINGS);
          return throwError(error);
        })
      );
  }

  /**
   * calls API to update customer parent location name
   *
   * @param {string} parentLocationName
   * @param {number} id
   * @returns
   * @memberof LocationHierarchyDataService
   */
  updateParentLocationName(parentLocationName: string, id: number) {
    const data = {
      parentLocationName,
    };
    return this.http
      .request<{
        updateCustomer: { parentLocationName: string };
      }>('POST', environment.backend, {
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          query: UPDATE_PARENT_LOCATION_NAME_QUERY,
          variables: { id, data },
        }),
      })
      .pipe(
        filter((d) => !!d),
        map((activity) =>
          get(activity, 'data.updateCustomer.parentLocationName', '')
        ),
        tap(() => {
          this.notification.showSuccess(
            'Location Hierarchy',
            'Parent group name was successfully updated'
          );
        }),
        catchError((error) => {
          this.notification.showError(
            ERROR_UPDATE_CUSTOMER_PARENT_LOCATION_NAME
          );
          return throwError(error);
        })
      );
  }
  /**
   * call api to fetch groups location data
   *
   * @return {*}  {Observable<Location[]>}
   * @memberof LocationHierarchyDataService
   */
  getAllLocations(): Observable<Location[]> {
    return this.apollo
      .query<{
        locations: Location[];
      }>({
        fetchPolicy: 'no-cache',
        query: GET_ALL_LOCATION_GROUPS_QUERY,
        variables: {
          input: {
            where: { isSingleTeam: false },
            order: { field: 'NAME', direction: OrderDirection.ASC },
          },
        },
      })
      .pipe(
        filter((d) => !!d),
        map((activity) => get(activity, 'data.locations', [])),
        catchError((error) => {
          this.notification.showError(ERROR_FETCH_LOCATION_HIERARCHY_GROUPS);
          return throwError(error);
        })
      );
  }
  /**
   * call api to fetch groups location data by id
   *
   * @param {string} id
   * @return {*}  {Observable<Location[]>}
   * @memberof LocationHierarchyDataService
   */
  getLocationById(id: string): Observable<Location[]> {
    return this.apollo
      .query<{
        locations: Location[];
      }>({
        fetchPolicy: 'no-cache',
        query: GET_ALL_LOCATION_GROUPS_QUERY,
        variables: {
          input: {
            where: { ids: [id] },
            order: { field: 'NAME', direction: OrderDirection.ASC },
          },
        },
      })
      .pipe(
        filter((d) => !!d),
        map((activity) => get(activity, 'data.locations', [])),
        catchError((error) => {
          this.notification.showError(ERROR_FETCH_LOCATION_HIERARCHY_GROUPS);
          return throwError(error);
        })
      );
  }
  /**
   * add new group for location
   *
   * @param {string} name
   * @return {*}  {Observable<Location>}
   * @memberof LocationHierarchyDataService
   */
  addLocationGroup(name: string): Observable<Location> {
    return this.apollo
      .mutate<{
        addLocation: Location;
      }>({
        fetchPolicy: 'no-cache',
        mutation: ADD_LOCATION_GROUP_QUERY,
        variables: {
          input: {
            name,
          },
        },
      })
      .pipe(
        filter((d) => !!d),
        map((activity) => get(activity, 'data.addLocation', {})),
        catchError((error) => {
          this.notification.showError(ERROR_ADD_LOCATION_HIERARCHY_GROUPS);
          return throwError(error);
        })
      );
  }
  /**
   * update name of an existing group
   *
   * @param {string} id
   * @param {string} name
   * @return {*}  {Observable<Location>}
   * @memberof LocationHierarchyDataService
   */
  editLocationGroupName(id: string, name: string): Observable<Location> {
    return this.apollo
      .mutate<{
        addLocation: Location;
      }>({
        fetchPolicy: 'no-cache',
        mutation: EDIT_LOCATION_GROUP_QUERY,
        variables: {
          id,
          input: {
            name,
          },
        },
      })
      .pipe(
        filter((d) => !!d),
        map((activity) => get(activity, 'data.updateLocation', {})),
        tap(() => {
          this.notification.showSuccess(
            'Location Hierarchy',
            'Group name was successfully updated'
          );
        }),
        catchError((error) => {
          this.notification.showError(ERROR_EDIT_LOCATION_HIERARCHY_GROUPS);
          return throwError(error);
        })
      );
  }
  /**
   * edit location for buildings
   *
   * @param {string} id
   * @param {AlarmProperty[]} buildings
   * @return {*}  {Observable<Location>}
   * @memberof LocationHierarchyDataService
   */
  editLocationGroupBuildings(
    id: string,
    buildings: AlarmProperty[]
  ): Observable<Location> {
    const alarmProperties = {
      type: 'building',
      values: buildings.map((build) => build.value),
    };
    return this.apollo
      .mutate<{
        addLocation: Location;
      }>({
        fetchPolicy: 'no-cache',
        mutation: EDIT_LOCATION_GROUP_QUERY,
        variables: {
          id,
          input: {
            alarmProperties,
          },
        },
      })
      .pipe(
        filter((d) => !!d),
        map((activity) => get(activity, 'data.updateLocation', {})),
        tap(() => {
          this.notification.showSuccess(
            'Location Hierarchy',
            'Group associated building was successfully updated'
          );
        }),
        catchError((error) => {
          this.notification.showError(ERROR_EDIT_LOCATION_HIERARCHY_GROUPS);
          return throwError(error);
        })
      );
  }

  /**
   * Remove an existing location group.
   *
   * @param {string} id - The id of the location group to remove
   * @return {*}  {Observable<boolean>}
   * @memberof LocationHierarchyDataService
   */
  deleteLocationGroup(id: string): Observable<boolean> {
    return this.apollo
      .mutate({
        mutation: DELETE_LOCATION_GROUP_MUTATION,
        variables: { id },
      })
      .pipe(
        filter((d) => !!d),
        map((res) => get(res, 'data.deleteLocationGroup')),
        catchError((err) => {
          this.notification.showError(ERROR_DELETE_LOCATION_GROUP);
          return throwError(err);
        }),
        tap(() => {
          this.notification.showSuccess(
            'Location Groups',
            `Location group was successfully deleted`
          );
        })
      );
  }
}
