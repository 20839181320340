import { VfiInputComponent } from './vfi-input/vfi-input.component';
import { DropdownItemComponent } from './dropdown-item/dropdown-item.component';
import { MultiLevelSelectInputComponent } from './multi-level-select-input/multi-level-select-input.component';
import { AutoGrowDirective } from './auto-grow.directive.ts/auto-grow.directive';
import { TabComponent } from './tab/tab.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeatureCoreModule } from '@vfi-ui/feature/core';
import { PaginationComponent } from './pagination/pagination.component';
import { DropdownMenuComponent } from './dropdown-menu/dropdown-menu.component';
import { Indicator401Component } from './indicator-401/indicator-401.component';
import { Indicator404Component } from './indicator-404/indicator-404.component';
import { BadgePerformanceMetricComponent } from './badge-performance-metric/badge-performance-metric.component';
import { IconComponent } from './icon/icon.component';
import { DropdownMenuIconLeftComponent } from './dropdown-menu-icon-left/dropdown-menu-icon-left.component';
import { SelectSingleComponent } from './select-single/select-single.component';
import { SelectMultipleComponent } from './select-multiple/select-multiple.component';
import { BadgeWorkComponent } from './badge-work/badge-work.component';
import { DisabledInputComponent } from './disabled-input/disabled-input.component';
import { LongPressDirective } from './long-press/long-press.directive';
import { RateOfChangeComponent } from './rate-of-change/rate-of-change.component';
import { RateOfTimeChangeComponent } from './rate-of-time-change/rate-of-time-change.component';
import { AndOrComponent } from './and-or/and-or.component';
import { VfiButtonComponent } from './vfi-button/vfi-button.component';
import { RateOfChangeDirective } from './rate-of-change-directive/rate-of-change.directive';
import { MenuListComponent } from './menu-list/menu-list.component';
import { TabsComponent } from './tabs/tabs.component';
import { VfiAccordionComponent } from './vfi-accordion/vfi-accordion.component';
import { MultiLevelSelectorComponent } from './multi-level-selector/multi-level-selector.component';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { AssigneeSelectorComponent } from './assignee-selector/assignee-selector.component';
import { PointEntitySelectorComponent } from './point-entity-selector/point-entity-selector.component';
import { MultiLevelInputComponent } from './multi-level-input/multi-level-input.component';
import { VfiDropdownComponent } from './vfi-dropdown/vfi-dropdown.component';
import { ReportExportComponent } from './report-export/report-export.component';
import { SavedViewsSelectorComponent } from './saved-views-selector/saved-views-selector.component';
import { VfiSelectComponent } from './vfi-select/vfi-select.component';
import { AlarmFieldsComponent } from './alarm-fields/alarm-fields.component';
import { FilterPillsComponent } from './filter-pills/filter-pills.component';
import { CAPACITOR_CORE_PROVIDER } from '@vfi-ui/models';
import { ActionsMenuComponent } from './actions-menu/actions-menu.component';
import { VfiMultiSelectComponent } from './vfi-multi-select/vfi-multi-select.component';
import { PreventSpecialCharacterDirective } from './special-character/special-character.directive';
import { VfiSelectSingleComponent } from './vfi-select-single/vfi-select-single.component';
import { VfiTextareaComponent } from './vfi-textarea/vfi-textarea.component';
import { VfiAssigneeSelectorComponent } from './vfi-assignee-selector/vfi-assignee-selector.component';
import { VfiDatePickerComponent } from './vfi-date-picker/vfi-date-picker.component';
import { VfiCmmsSelectSingleComponent } from './vfi-cmms-select-single/vfi-cmms-select-single.component';
import { VfiSelectMultipleComponent } from './vfi-select-multiple/vfi-select-multiple.component';
import { VfiSelectPopupComponent } from './vfi-select-popup/vfi-select-popup.component';
import { VfiCheckboxComponent } from './vfi-checkbox/vfi-checkbox.component';

@NgModule({
  imports: [CommonModule, FeatureCoreModule, InfiniteScrollDirective],
  declarations: [
    BadgePerformanceMetricComponent,
    BadgeWorkComponent,
    DropdownMenuComponent,
    DropdownMenuIconLeftComponent,
    IconComponent,
    Indicator401Component,
    Indicator404Component,
    PaginationComponent,
    SelectMultipleComponent,
    SelectSingleComponent,
    TabComponent,
    AutoGrowDirective,
    DisabledInputComponent,
    LongPressDirective,
    RateOfChangeComponent,
    RateOfTimeChangeComponent,
    AndOrComponent,
    VfiButtonComponent,
    RateOfChangeDirective,
    VfiAccordionComponent,
    MenuListComponent,
    TabsComponent,
    MultiLevelSelectInputComponent,
    DropdownItemComponent,
    MultiLevelSelectorComponent,
    AssigneeSelectorComponent,
    PointEntitySelectorComponent,
    MultiLevelInputComponent,
    VfiDropdownComponent,
    VfiInputComponent,
    VfiSelectComponent,
    VfiMultiSelectComponent,
    ReportExportComponent,
    SavedViewsSelectorComponent,
    AlarmFieldsComponent,
    FilterPillsComponent,
    ActionsMenuComponent,
    PreventSpecialCharacterDirective,
    VfiSelectSingleComponent,
    VfiTextareaComponent,
    VfiAssigneeSelectorComponent,
    VfiDatePickerComponent,
    VfiCmmsSelectSingleComponent,
    VfiSelectMultipleComponent,
    VfiSelectPopupComponent,
    VfiCheckboxComponent,
  ],
  exports: [
    BadgePerformanceMetricComponent,
    BadgeWorkComponent,
    DropdownMenuComponent,
    DropdownMenuIconLeftComponent,
    IconComponent,
    Indicator401Component,
    Indicator404Component,
    PaginationComponent,
    SelectMultipleComponent,
    SelectSingleComponent,
    TabComponent,
    AutoGrowDirective,
    DisabledInputComponent,
    LongPressDirective,
    RateOfChangeComponent,
    RateOfTimeChangeComponent,
    AndOrComponent,
    VfiButtonComponent,
    RateOfChangeDirective,
    VfiAccordionComponent,
    MenuListComponent,
    TabsComponent,
    MultiLevelSelectInputComponent,
    DropdownItemComponent,
    MultiLevelSelectorComponent,
    AssigneeSelectorComponent,
    MultiLevelInputComponent,
    PointEntitySelectorComponent,
    VfiDropdownComponent,
    VfiInputComponent,
    VfiSelectComponent,
    VfiMultiSelectComponent,
    ReportExportComponent,
    SavedViewsSelectorComponent,
    AlarmFieldsComponent,
    FilterPillsComponent,
    ActionsMenuComponent,
    PreventSpecialCharacterDirective,
    VfiSelectSingleComponent,
    VfiTextareaComponent,
    VfiAssigneeSelectorComponent,
    VfiDatePickerComponent,
    VfiCmmsSelectSingleComponent,
    VfiSelectMultipleComponent,
    VfiSelectPopupComponent,
    VfiCheckboxComponent,
  ],
  providers: [CAPACITOR_CORE_PROVIDER],
})
export class UiAtomsModule {}
