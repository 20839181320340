import { AutomationRuleAssetCriteria } from './automation-rules.interface';
import { WorkTicket } from './work-order.interface';

export interface Asset {
  id: number;
  name: string;
  type: string;
  class: AssetClass;
  building: string;
  floor: string;
  room: string;
  associatedAlarms: number[];
  associatedServiceImpactingAlarms: number[];
  associatedWorkTickets: WorkTicket[];
  criticality: number;
  spaceType: string;
  roomName: string;
  roomNumber: string;
  status: AssetStatus;
  hasServiceImpactingAlarms: boolean;
  isPaid: boolean;
  snoozeReason: string;
  snoozedAt: string;
  snoozeExpiresAt: string;
  snoozedBy: string;
  source: string;
  typeId: number;
  isActive: boolean;
  assetCriteria: AutomationRuleAssetCriteria;
}

export interface AssetWhere {
  ids: number[];
  name: string[];
  type: string[];
  class: AssetClass[];
  building: string[];
  floor: string[];
  room: string[];
  criticality: number[];
  spaceType: string[];
  roomName: string[];
  roomNumber: string[];
  status: AssetStatus[];
  hasServiceImpactingAlarms: boolean;
  isPaid: boolean;
  source: string[];
  isActive: boolean;
  nameLike: string;
  label: string[];
}

export interface AssetProperty {
  type: string;
  value: string;
}

export interface AssetQuery {
  assets: Asset[];
  assetCount: number;
}

export interface AssetPropertyQuery {
  assetProperties: AssetProperty[];
  assetPropertyCount: number;
}

export enum AssetClass {
  Equipment = 'EQUIPMENT',
  System = 'SYSTEM',
  Space = 'SPACE',
}

export enum AssetStatus {
  Red = 'RED',
  Yellow = 'YELLOW',
  Green = 'GREEN',
}

export enum AssetSource {
  Alarm = 'ALARM',
  Inventory = 'INVENTORY',
}

export enum AssetType {
  BUILDING = 'Building',
  FLOOR = 'Floor',
  ROOM = 'Room',
}

export enum AssetActivityType {
  Alarm = 'ALARM',
  WorkTicket = 'WORK_TICKET',
  Asset = 'ASSET',
}
