import {
  AlarmState,
  VfiWarnState,
  AlarmAssociatedWorkStatus,
} from './alarms.constant';
import {
  GlobalSortAndFiltersCriterion,
  CriterionSelection,
} from './../interfaces/global-filters.interface';
import { DefaultNuisanceSort } from './nuisance.constant';
import { WorkTicketProcessState } from './work-ticket.constant';
import { CoreAlarmsOrderSortField } from '../interfaces/alarm-instance.interface';
import { environment } from '@vfi-ui/environments/environment';
import { AlarmInstanceCoveredSource } from '../interfaces/alarm-instance-report.interface';
import { AssetClassFilterOptions } from './alarm-details.constants';
import {
  AssetClassOptions,
  AssetCriticalityOptions,
  AssetSourceOptions,
  AssetStatusOptions,
} from './assets.constants';

export enum nuisanceType {
  CHATTERING = 'Chattering',
  FLEETING = 'Fleeting',
  CHATTERING_AND_FLEETING = 'Chattering & Fleeting',
  CHATTERING_OR_FLEETING = 'Chattering or Fleeting',
  NONE = 'None',
}

export enum StaleStatus {
  STALE = 'Stale',
  NOT_STALE = 'Not Stale',
}

export enum filterModelMode {
  CREATE_LENS = 'create lens filter',
  EDIT_LENS = 'edit an lens filter',
  FILTERS = 'filters',
}
export enum filterCategoryType {
  ALARMS = 'Alarm Filters',
  WORK = 'Work Ticket Filters',
  ASSETS = 'Asset Filters',
  USERS = 'Users Filters',
  ENTITY = 'Entity Filters',
}

export enum criterionSelectionType {
  NOTIFICATION_EXCLUDES = 'notification excludes',
}

export enum buttonSizes {
  DEFAULT = 'default',
  SMALL = 'small',
  LARGE = 'large',
}

export const DurationKeys = [
  'minDays',
  'minHrs',
  'minMins',
  'maxDays',
  'maxHrs',
  'maxMins',
  'max',
  'min',
];

export const LENS_FILTER_BLACKLIST = ['instrument_type', 'isPinned'];

export const BasFilters = [
  'POINT_DESCRIPTION',
  'ALARM_NAME',
  'ALARM_DESCRIPTION',
  'ALARM_MESSAGE',
  'ALARM_CATEGORY',
  'PRIORITY',
];

export const INCLUDES_EXCLUDES_OPTIONS = [
  { label: 'includes', value: 'includes' },
  { label: 'excludes', value: 'excludes' },
];

export enum WORK_RESOLUTION_VALUES {
  Resolved = 'true',
  Unresolved = 'false',
}

export const ResolutionOptions = [
  {
    label: 'Resolved',
    value: WORK_RESOLUTION_VALUES.Resolved,
  },
  {
    label: 'Unresolved',
    value: WORK_RESOLUTION_VALUES.Unresolved,
  },
];

export const ALARM_ID_FILTER_CRITERION: GlobalSortAndFiltersCriterion[] = [
  {
    name: 'Alarm ID',
    dbName: 'ids',
    query: 'alarmId',
    show: true,
    type: 'select',
    blanks: false,
    category: filterCategoryType.ALARMS,
  },
];

export const SERVICE_FAILURE_ALARM_FILTER_CRITERION: GlobalSortAndFiltersCriterion[] =
  [
    {
      name: 'Service Failure Alarm',
      show: true,
      type: 'bool',
      dbName: 'isServiceImpacting',
      blanks: false,
      category: filterCategoryType.ALARMS,
    },
  ];

export const ASSET_TYPE_FILTER_CRITERION: GlobalSortAndFiltersCriterion[] = [
  {
    name: 'Asset Class',
    show: true,
    type: 'customValueSelect',
    blanks: false,
    multi: true,
    dbName: 'alarmTypes',
    category: filterCategoryType.ALARMS,
    dbProperty: 'alarmTypes',
    selectValues: AssetClassFilterOptions,
  },
];

export const ASSET_CRITICALITY_FILTER_CRITERION: GlobalSortAndFiltersCriterion[] =
  [
    {
      name: 'Asset Criticality',
      show: true,
      type: 'customValueSelect',
      blanks: false,
      multi: true,
      dbName: 'assetCriticality',
      category: filterCategoryType.ALARMS,
      selectValues: AssetCriticalityOptions,
    },
  ];

export const CRITERIA_BUILDER_CRITERION: GlobalSortAndFiltersCriterion[] = [
  ...ASSET_CRITICALITY_FILTER_CRITERION,
  {
    name: 'Alarm ID',
    dbName: 'ids',
    query: 'alarmId',
    show: true,
    type: 'alarmId',
    blanks: false,
    category: filterCategoryType.ALARMS,
  },
  {
    name: 'Alarm Source',
    dbName: 'externalSystemId',
    query: 'systemSource',
    show: true,
    type: 'alarmSource',
    blanks: false,
    category: filterCategoryType.ALARMS,
  },
  {
    name: 'Keyword',
    show: true,
    type: 'text',
    blanks: false,
    dbName: 'text',
    category: filterCategoryType.ALARMS,
    dbProperty: 'name',
  },
  {
    name: 'Nuisance Status',
    show: true,
    type: 'customValueSelect',
    blanks: false,
    dbName: 'isNuisance',
    category: filterCategoryType.ALARMS,
    dbProperty: 'name',
    selectValues: [
      {
        label: nuisanceType.CHATTERING,
        value: nuisanceType.CHATTERING,
      },
      {
        label: nuisanceType.FLEETING,
        value: nuisanceType.FLEETING,
      },
      {
        label: nuisanceType.CHATTERING_AND_FLEETING,
        value: nuisanceType.CHATTERING_AND_FLEETING,
      },
      {
        label: nuisanceType.CHATTERING_OR_FLEETING,
        value: nuisanceType.CHATTERING_OR_FLEETING,
      },
    ],
  },
  {
    name: 'Alarm Priority',
    show: true,
    type: 'priority',
    blanks: false,
    dbName: 'priorities',
    category: filterCategoryType.ALARMS,
    min: 0,
    max: 5,
  },
  {
    name: 'Pinned',
    show: true,
    type: 'customValueSelect',
    blanks: false,
    dbName: 'isPinned',
    category: filterCategoryType.ALARMS,
    selectValues: [
      {
        label: 'True',
        value: true,
      },
      {
        label: 'False',
        value: false,
      },
    ],
  },
  {
    name: 'Stale Status',
    show: true,
    type: 'customValueSelect',
    blanks: false,
    dbName: 'isStale',
    category: filterCategoryType.ALARMS,
    dbProperty: 'isStale',
    selectValues: [
      {
        label: StaleStatus.STALE,
        value: true,
      },
      {
        label: StaleStatus.NOT_STALE,
        value: false,
      },
    ],
  },
  {
    name: 'Status',
    show: true,
    type: 'status',
    blanks: false,
    dbName: 'statuses',
    category: filterCategoryType.ALARMS,
    dbProperty: 'statuses',
  },
  {
    name: 'Source Alarm Point Like',
    show: true,
    type: 'text',
    blanks: false,
    dbName: 'pointLike',
    category: filterCategoryType.ALARMS,
    dbProperty: 'name',
  },
  {
    name: 'Source Alarm Point',
    dbName: 'POINT',
    show: true,
    multi: true,
    type: 'select',
    blanks: false,
    category: filterCategoryType.ALARMS,
    newValues: true,
  },
  {
    name: 'Alarm Asset Name',
    show: true,
    type: 'select',
    blanks: false,
    dbName: 'alarmAssetNames',
    dbProperty: 'name',
    category: filterCategoryType.ALARMS,
  },
  {
    name: 'Asset Type',
    show: true,
    type: 'select',
    blanks: false,
    dbName: 'assetTypes',
    category: filterCategoryType.ALARMS,
    dbProperty: 'assetTypes',
    multi: true,
  },
];

export const ALARM_FILTERS_CRITERION: GlobalSortAndFiltersCriterion[] = [
  ...ASSET_CRITICALITY_FILTER_CRITERION,
  {
    name: 'Alarm Source',
    dbName: 'externalSystemId',
    query: 'systemSource',
    show: true,
    type: 'alarmSource',
    blanks: false,
    category: filterCategoryType.ALARMS,
  },
  {
    name: 'Alarm Time',
    show: true,
    type: 'dateTime',
    blanks: false,
    dbName: 'alarmTime',
    category: filterCategoryType.ALARMS,
  },
  {
    name: 'Active Work',
    show: true,
    type: 'customValueSelect',
    blanks: false,
    dbName: 'activeWork',
    category: filterCategoryType.ALARMS,
    selectValues: [
      {
        label: 'True',
        value: '1',
      },
      {
        label: 'False',
        value: '0',
      },
    ],
  },
  {
    name: 'Keyword',
    show: true,
    type: 'text',
    blanks: false,
    dbName: 'text',
    category: filterCategoryType.ALARMS,
    dbProperty: 'name',
  },
  {
    name: 'Source Alarm Point Like',
    show: true,
    type: 'text',
    blanks: false,
    dbName: 'pointLike',
    category: filterCategoryType.ALARMS,
    dbProperty: 'name',
  },
  {
    name: 'Source Alarm Point',
    dbName: 'POINT',
    show: true,
    multi: true,
    type: 'select',
    blanks: false,
    category: filterCategoryType.ALARMS,
    newValues: true,
  },
  {
    name: 'Nuisance Behavior',
    show: true,
    type: 'customValueSelect',
    blanks: false,
    multi: true,
    dbName: 'nuisanceBehavior',
    category: filterCategoryType.ALARMS,
    dbProperty: 'name',
    selectValues: [
      {
        label: nuisanceType.CHATTERING,
        value: 'CHATTERING',
      },
      {
        label: nuisanceType.FLEETING,
        value: 'FLEETING',
      },
      {
        label: nuisanceType.CHATTERING_AND_FLEETING,
        value: 'CHATTERING_AND_FLEETING',
      },
      {
        label: nuisanceType.NONE,
        value: 'NONE',
      },
    ],
  },
  {
    name: 'Alarm Priority',
    show: true,
    type: 'priority',
    blanks: false,
    dbName: 'priority',
    category: filterCategoryType.ALARMS,
    min: 0,
    max: 5,
  },
  {
    name: 'Pinned',
    show: true,
    type: 'bool',
    blanks: false,
    dbName: 'isPinned',
    category: filterCategoryType.ALARMS,
  },
  {
    name: 'Alarm State',
    show: true,
    type: 'customValueSelect',
    blanks: false,
    dbName: 'isActive',
    category: filterCategoryType.ALARMS,
    selectValues: [
      {
        label: 'Active',
        value: true,
      },
      {
        label: 'Inactive',
        value: false,
      },
    ],
  },
  {
    name: 'Stale Status',
    show: true,
    type: 'customValueSelect',
    blanks: false,
    dbName: 'isStale',
    category: filterCategoryType.ALARMS,
    dbProperty: 'isStale',
    selectValues: [
      {
        label: StaleStatus.STALE,
        value: StaleStatus.STALE,
      },
      {
        label: StaleStatus.NOT_STALE,
        value: StaleStatus.NOT_STALE,
      },
    ],
  },
  {
    name: 'Status',
    show: true,
    type: 'status',
    blanks: false,
    dbName: 'statuses',
    category: filterCategoryType.ALARMS,
    dbProperty: 'statuses',
  },
  {
    name: 'Time in Alarm',
    show: true,
    type: 'durationMinMax',
    dbName: 'duration',
    category: filterCategoryType.ALARMS,
  },
];

export const BAS_FILTER_CRITERION: GlobalSortAndFiltersCriterion[] = [
  {
    name: 'Source Point Description',
    show: true,
    type: 'select',
    blanks: false,
    dbName: 'POINT_DESCRIPTION',
    dbProperty: 'name',
    category: filterCategoryType.ALARMS,
    newValues: true,
  },
  {
    name: 'Source Alarm Name',
    show: true,
    type: 'select',
    blanks: false,
    dbName: 'ALARM_NAME',
    dbProperty: 'name',
    category: filterCategoryType.ALARMS,
    newValues: true,
  },
  {
    name: 'Source Alarm Description',
    show: true,
    type: 'select',
    blanks: false,
    dbName: 'ALARM_DESCRIPTION',
    dbProperty: 'name',
    category: filterCategoryType.ALARMS,
    newValues: true,
  },
  {
    name: 'Source Alarm Message',
    show: true,
    type: 'select',
    blanks: false,
    dbName: 'ALARM_MESSAGE',
    dbProperty: 'name',
    category: filterCategoryType.ALARMS,
    newValues: true,
  },
  {
    name: 'Source Alarm Category',
    show: true,
    type: 'select',
    blanks: false,
    dbName: 'ALARM_CATEGORY',
    dbProperty: 'name',
    category: filterCategoryType.ALARMS,
    newValues: true,
  },
  {
    name: 'Source Priority',
    show: true,
    type: 'select',
    blanks: false,
    dbName: 'PRIORITY',
    dbProperty: 'name',
    category: filterCategoryType.ALARMS,
    newValues: true,
  },
];

export const TRIAGE_FILTER_CRITERION: GlobalSortAndFiltersCriterion[] = [
  ...ALARM_ID_FILTER_CRITERION,
  ...SERVICE_FAILURE_ALARM_FILTER_CRITERION,
  ...ASSET_TYPE_FILTER_CRITERION,
  ...BAS_FILTER_CRITERION,
  {
    name: 'Alarm Asset Name',
    show: true,
    type: 'select',
    blanks: false,
    dbName: 'alarmAssetNames',
    dbProperty: 'name',
    category: filterCategoryType.ALARMS,
  },
  {
    name: 'Asset Type',
    show: true,
    type: 'select',
    blanks: false,
    dbName: 'assetTypes',
    dbProperty: 'name',
    category: filterCategoryType.ALARMS,
  },
  {
    name: 'Space Type',
    show: true,
    type: 'select',
    blanks: true,
    dbName: 'spaceTypes',
    dbProperty: 'name',
    category: filterCategoryType.ALARMS,
  },
  {
    name: 'Work ID',
    dbName: 'workTicketIds',
    query: 'workTickets',
    show: true,
    type: 'select',
    blanks: false,
    category: filterCategoryType.WORK,
  },
];

export const STATE_FILTER_CRITERION: GlobalSortAndFiltersCriterion[] = [
  {
    name: 'State',
    show: true,
    type: 'customValueSelect',
    blanks: false,
    dbName: 'states',
    category: filterCategoryType.ALARMS,
    dbProperty: 'name',
    multi: true,
    selectValues: [
      {
        label: AlarmState.ALARM,
        value: AlarmState.ALARM.toUpperCase(),
      },
      {
        label: AlarmState.NORMAL,
        value: AlarmState.NORMAL.toUpperCase(),
      },
      {
        label: VfiWarnState,
        value: AlarmState.WARN.toUpperCase(),
      },
    ],
  },
];

export const ALARM_SORTS_CRITERION: GlobalSortAndFiltersCriterion[] = [
  {
    name: 'Alarm Status',
    show: true,
    type: 'select',
    dbName: CoreAlarmsOrderSortField.alarmStatus,
    category: filterCategoryType.ALARMS,
  },
  {
    name: 'Alarm Time',
    show: true,
    type: 'dateTime',
    dbName: CoreAlarmsOrderSortField.alarmTime,
    category: filterCategoryType.ALARMS,
  },
  {
    name: 'Alarm Priority',
    show: true,
    type: 'priority',
    dbName: CoreAlarmsOrderSortField.priority,
    category: filterCategoryType.ALARMS,
    min: 0,
    max: 5,
  },
  {
    name: 'Pinned',
    show: true,
    type: 'bool',
    dbName: CoreAlarmsOrderSortField.isPinned,
    category: filterCategoryType.ALARMS,
  },
  {
    name: DefaultNuisanceSort.name,
    dbName: DefaultNuisanceSort.dbName,
    category: filterCategoryType.ALARMS,
    show: true,
    type: 'number',
  },
  {
    name: 'Alarmed Asset Name',
    dbName: 'CONTEXTUAL_NAME',
    category: filterCategoryType.ALARMS,
    show: true,
    type: 'string',
  },
  {
    name: 'Space Name',
    dbName: 'spaceNames',
    category: filterCategoryType.ALARMS,
    show: true,
    type: 'select',
  },
];

export const ACTIVE_WORK_SORTS_CRITERION: GlobalSortAndFiltersCriterion[] = [
  {
    name: 'Associated Alarms Count',
    dbName: 'ASSOCIATED_ALARMS',
    category: filterCategoryType.WORK,
    show: true,
    type: 'number',
  },
  {
    name: 'Created Date',
    dbName: 'CREATED_AT',
    category: filterCategoryType.WORK,
    show: true,
    type: 'customWorkSelect',
  },
  {
    name: 'Due Date',
    dbName: 'DUE_AT',
    category: filterCategoryType.WORK,
    show: true,
    type: 'customWorkSelect',
  },
  {
    name: 'Work ID',
    dbName: 'ID',
    category: filterCategoryType.WORK,
    show: true,
    type: 'number',
  },
  {
    name: 'Ticket Age',
    show: true,
    type: 'minMax',
    dbName: 'AGE_DAYS',
    category: filterCategoryType.WORK,
  },
  {
    name: 'Resolution',
    dbName: 'IS_RESOLVED',
    category: filterCategoryType.WORK,
    show: true,
    type: 'bool',
  },
];

export const ALL_WORK_SORTS_CRITERION: GlobalSortAndFiltersCriterion[] = [
  ...ACTIVE_WORK_SORTS_CRITERION,
  {
    name: 'Closed Date',
    dbName: 'CLOSED_AT',
    category: filterCategoryType.WORK,
    show: true,
    type: 'customWorkSelect',
  },
];
export const LAST_DATE_SELECTION = [
  {
    label: 'Last 24 Hours',
    value: 24,
  },
  {
    label: 'Last 7 Days',
    value: 24 * 7,
  },
  {
    label: 'Last 30 Days',
    value: 24 * 30,
  },
  {
    label: 'Last 90 Days',
    value: 24 * 90,
  },
];
export const LAST_DATE_SELECTION_MAP = new Map<number, string>(
  LAST_DATE_SELECTION.map((d) => [d.value, d.label])
);

export const NEXT_DATE_SELECTION = [
  {
    label: 'Next 24 Hours',
    value: 24,
  },
  {
    label: 'Next 7 Days',
    value: 24 * 7,
  },
  {
    label: 'Next 30 Days',
    value: 24 * 30,
  },
  {
    label: 'Next 90 Days',
    value: 24 * 90,
  },
];
export const NEXT_DATE_SELECTION_MAP = new Map<number, string>(
  NEXT_DATE_SELECTION.map((d) => [d.value, d.label])
);

export const WORK_FILTERS_CRITERION: GlobalSortAndFiltersCriterion[] = [
  {
    name: 'Assignee',
    show: true,
    type: 'userSelect',
    multi: true,
    dbName: 'assignee',
    category: filterCategoryType.WORK,
  },
  {
    name: 'CMMS Assignee',
    show: true,
    type: 'select',
    multi: true,
    dbName: 'cmmsAssignee',
    query: 'cmmsAssignee',
    category: filterCategoryType.WORK,
  },
  {
    name: 'Closed Date',
    show: true,
    type: 'customValueSelect',
    blanks: false,
    dbName: 'closedAtLastXHours',
    category: filterCategoryType.WORK,
    dbProperty: 'closedAtLastXHours',
    multi: false,
    selectValues: LAST_DATE_SELECTION,
  },
  {
    name: 'Created Date',
    show: true,
    type: 'customValueSelect',
    blanks: false,
    dbName: 'createdAtLastXHours',
    category: filterCategoryType.WORK,
    dbProperty: 'createdAtLastXHours',
    multi: false,
    selectValues: LAST_DATE_SELECTION,
  },
  {
    name: 'Creator',
    show: environment.showAutomation,
    type: 'userSelect',
    multi: true,
    dbName: 'createdBy',
    category: filterCategoryType.WORK,
  },
  {
    name: 'Due Date',
    show: true,
    type: 'customValueSelect',
    blanks: false,
    dbName: 'dueAtNextXHours',
    category: filterCategoryType.WORK,
    dbProperty: 'dueAtNextXHours',
    multi: false,
    selectValues: NEXT_DATE_SELECTION,
  },
  {
    name: 'State',
    show: true,
    type: 'customValueSelect',
    blanks: false,
    dbName: 'state',
    category: filterCategoryType.WORK,
    dbProperty: 'name',
    multi: true,
    selectValues: [
      {
        label: 'Open',
        value: WorkTicketProcessState.Open,
      },
      {
        label: 'Closed',
        value: WorkTicketProcessState.Closed,
      },
    ],
  },
  {
    name: 'Work Assignee (Team)',
    show: true,
    type: 'customValueSelect',
    blanks: false,
    dbName: 'teamIds',
    category: filterCategoryType.WORK,
    dbProperty: 'name',
    multi: true,
    selectValues: [],
  },
  {
    name: 'Ticket Age',
    show: true,
    type: 'minMax',
    dbName: 'ageDays',
    minMaxType: 'days',
    category: filterCategoryType.WORK,
  },
  {
    name: 'Resolution',
    show: true,
    type: 'customValueSelect',
    blanks: false,
    dbName: 'isResolved',
    category: filterCategoryType.WORK,
    dbProperty: 'name',
    multi: true,
    selectValues: ResolutionOptions,
  },
  {
    name: 'Active Alarm Count',
    show: true,
    type: 'minMax',
    blanks: false,
    dbName: 'activeAlarmCount',
    category: filterCategoryType.WORK,
  },
  {
    name: 'Inactive Alarm Count',
    show: true,
    type: 'minMax',
    blanks: false,
    dbName: 'inactiveAlarmCount',
    category: filterCategoryType.WORK,
  },
  {
    name: 'Asset Name',
    show: true,
    type: 'select',
    multi: true,
    dbName: 'assetName',
    query: 'assetNames',
    category: filterCategoryType.WORK,
  },
  {
    name: 'Asset Type',
    show: true,
    type: 'select',
    multi: true,
    dbName: 'assetType',
    query: 'entityClass',
    category: filterCategoryType.WORK,
  },
  {
    name: 'Asset Location - Building',
    show: true,
    type: 'select',
    multi: true,
    dbName: 'assetBuilding',
    query: 'assetBuildings',
    category: filterCategoryType.WORK,
  },
  {
    name: 'Asset Location - Floor',
    show: true,
    type: 'select',
    multi: true,
    dbName: 'assetFloor',
    query: 'assetFloors',
    category: filterCategoryType.WORK,
  },
  {
    name: 'Asset Location - Room',
    show: true,
    type: 'select',
    multi: true,
    dbName: 'assetRoom',
    query: 'assetRooms',
    category: filterCategoryType.WORK,
  },
];

export const WORK_STATUS_FILTER: GlobalSortAndFiltersCriterion[] = [
  {
    name: 'Work Status',
    show: true,
    type: 'customValueSelect',
    blanks: false,
    dbName: 'associatedWorkStatus',
    category: filterCategoryType.WORK,
    multi: false,
    selectValues: [
      {
        label: 'Open Work',
        value: AlarmAssociatedWorkStatus.OPEN,
      },
      {
        label: 'No Open Work',
        value: AlarmAssociatedWorkStatus.NOT_OPEN,
      },
      {
        label: 'Recently Closed (last 7 days)',
        value: AlarmAssociatedWorkStatus.RECENTLY_CLOSED,
      },
    ],
  },
];

export const DEFAULT_ACTIVE_ASSET_CRITERION = {
  model: 'Active',
  value: true,
  detail: {
    name: 'Active',
    show: true,
    type: 'bool',
    dbName: 'isActive',
    category: filterCategoryType.ASSETS,
    selection: {
      value: 'true',
    },
  },
};

export const ASSET_FILTERS_CRITERION: GlobalSortAndFiltersCriterion[] = [
  ...ASSET_CRITICALITY_FILTER_CRITERION,
  {
    name: 'Active',
    show: true,
    type: 'bool',
    multi: false,
    dbName: 'isActive',
    category: filterCategoryType.ASSETS,
  },
  {
    name: 'Asset ID',
    show: true,
    type: 'select',
    multi: true,
    dbName: 'ids',
    query: 'assetIds',
    category: filterCategoryType.ASSETS,
  },
  {
    name: 'Asset Name',
    show: true,
    type: 'select',
    multi: true,
    dbName: 'name',
    query: 'assetNames',
    category: filterCategoryType.ASSETS,
  },
  {
    name: 'Asset Type',
    show: true,
    type: 'select',
    multi: true,
    dbName: 'type',
    query: 'entityClass',
    category: filterCategoryType.ASSETS,
  },
  {
    name: 'Asset Class',
    show: true,
    type: 'customValueSelect',
    multi: true,
    dbName: 'class',
    category: filterCategoryType.ASSETS,
    selectValues: AssetClassOptions,
  },
  {
    name: 'Location - Building',
    show: true,
    type: 'select',
    multi: true,
    dbName: 'building',
    query: 'assetBuildings',
    category: filterCategoryType.ASSETS,
  },
  {
    name: 'Location - Floor',
    show: true,
    type: 'select',
    multi: true,
    dbName: 'floor',
    query: 'assetFloors',
    category: filterCategoryType.ASSETS,
  },
  {
    name: 'Location - Room',
    show: true,
    type: 'select',
    multi: true,
    dbName: 'room',
    query: 'assetRooms',
    category: filterCategoryType.ASSETS,
  },
  {
    name: 'Space Type',
    show: true,
    type: 'select',
    blanks: true,
    dbName: 'spaceTypes',
    dbProperty: 'name',
    category: filterCategoryType.ALARMS,
  },
  {
    name: 'Asset Source',
    show: true,
    type: 'customValueSelect',
    multi: true,
    dbName: 'source',
    category: filterCategoryType.ASSETS,
    selectValues: AssetSourceOptions,
  },
  {
    name: 'Asset Label',
    show: true,
    type: 'select',
    multi: true,
    dbName: 'label',
    query: 'assetLabels',
    category: filterCategoryType.ASSETS,
  },
  {
    name: 'Asset Status',
    show: true,
    type: 'customValueSelect',
    multi: true,
    dbName: 'status',
    category: filterCategoryType.ASSETS,
    selectValues: AssetStatusOptions,
  },
];

export const USER_FILTERS_CRITERION: GlobalSortAndFiltersCriterion[] = [
  {
    name: 'Display Name',
    show: true,
    dbName: 'displayName',
    type: 'string',
    category: filterCategoryType.USERS,
  },
];

export const DEFAULT_ADD_CRITERION: CriterionSelection = {
  model: null,
};

export const DEFAULT_NONE_CRITERION: CriterionSelection = {
  model: 'none',
};

export const BOOL_OPTIONS = [
  { label: 'True', value: 'true' },
  { label: 'False', value: 'false' },
];

export const DEFAULT_ACTIVE_WORK_CRITERION = {
  model: 'State',
  detail: {
    name: 'State',
    show: true,
    type: 'customValueSelect',
    blanks: false,
    dbName: 'state',
    category: 'Work Ticket Filters',
    dbProperty: 'name',
    multi: true,
    selectValues: [
      {
        label: 'Open',
        value: 'OPEN',
      },
      {
        label: 'Closed',
        value: 'CLOSED',
      },
    ],
    selection: {
      value: ['OPEN'],
    },
  },
};

export const DEFAULT_ACTIVE_ALARM_CRITERION = {
  model: 'Alarm State',
  value: true,
  detail: {
    name: 'Alarm State',
    show: true,
    type: 'customValueSelect',
    dbName: 'isActive',
    category: filterCategoryType.ALARMS,
    selection: {
      value: true,
    },
    selectValues: [
      {
        label: 'Active',
        value: true,
      },
      {
        label: 'Inactive',
        value: false,
      },
    ],
  },
};

export const DEFAULT_CLOSED_WORK_CRITERION = {
  model: 'State',
  detail: {
    name: 'State',
    show: true,
    type: 'customValueSelect',
    blanks: false,
    dbName: 'state',
    category: filterCategoryType.WORK,
    dbProperty: 'name',
    multi: false,
    selectValues: [
      {
        label: 'Open',
        value: WorkTicketProcessState.Open,
      },
      {
        label: 'Closed',
        value: WorkTicketProcessState.Closed,
      },
    ],
    selection: {
      value: 'CLOSED',
    },
  },
};

export const AUTOMATION_RULE_CREATOR = {
  label: 'Automation Rule',
  value: 'AUTOMATION_RULE',
};

export const CLOSED_DATE_CRITERION = {
  model: {
    name: 'Closed Date',
    show: true,
    type: 'dateTime',
    dbName: 'closedAt',
    category: 'Work Ticket Filters',
    selection: {
      min: '',
      max: '',
    },
  },
  detail: {
    name: 'Closed Date',
    show: true,
    type: 'dateTime',
    dbName: 'closedAt',
    category: 'Work Ticket Filters',
    selection: {
      min: '',
      max: '',
    },
  },
  selection: ['', ''],
};

export const TEAMS_CRITERION = {
  model: {
    name: 'Work Assignee (Team)',
    show: true,
    type: 'customValueSelect',
    blanks: false,
    dbName: 'teamIds',
    category: 'Work Ticket Filters',
    dbProperty: 'name',
    multi: true,
    selectValues: [],
    selection: {
      value: [],
    },
  },
  detail: {
    name: 'Work Assignee (Team)',
    show: true,
    type: 'customValueSelect',
    blanks: false,
    dbName: 'teamIds',
    category: 'Work Ticket Filters',
    dbProperty: 'name',
    multi: true,
    selectValues: [],
    selection: {
      value: [],
    },
  },
  value: [],
};

export const BUILDING_CRITERION = {
  model: 'Location - Building',
  detail: {
    name: 'Location - Building',
    show: true,
    type: 'select',
    blanks: true,
    category: 'Alarm Filters',
    dbName: 'building',
    dbProperty: 'name',
    isExclusion: false,
    selection: {
      name: [],
      value: [],
      fullValues: [],
    },
  },
};

export const ALARM_INSANCE_FILTERS_CRITERION: GlobalSortAndFiltersCriterion[] =
  [
    ...SERVICE_FAILURE_ALARM_FILTER_CRITERION,
    {
      name: 'Alarm ID',
      dbName: 'alarmIds',
      query: 'alarmId',
      show: true,
      type: 'select',
      blanks: false,
      category: filterCategoryType.ALARMS,
    },
    {
      name: 'Alarm Source',
      dbName: 'externalSystemId',
      query: 'systemSource',
      show: true,
      type: 'alarmSource',
      blanks: false,
      category: filterCategoryType.ALARMS,
    },
    {
      name: 'End Time (RTN)',
      show: true,
      type: 'dateTime',
      blanks: false,
      dbName: 'endTime',
      category: filterCategoryType.ALARMS,
    },
    {
      name: 'Fleeting',
      show: true,
      type: 'bool',
      blanks: false,
      dbName: 'isFleeting',
      category: filterCategoryType.ALARMS,
    },
    {
      name: 'Chattering',
      show: true,
      type: 'bool',
      blanks: false,
      dbName: 'isChattering',
      category: filterCategoryType.ALARMS,
    },
    {
      name: 'Has Work',
      show: true,
      type: 'bool',
      blanks: false,
      dbName: 'isCovered',
      category: filterCategoryType.ALARMS,
    },
    {
      name: 'Work Source',
      show: true,
      type: 'customValueSelect',
      blanks: false,
      dbName: 'coveredSource',
      category: filterCategoryType.ALARMS,
      dbProperty: 'coveredSource',
      selectValues: [
        {
          label: 'User',
          value: AlarmInstanceCoveredSource.User,
        },
        {
          label: 'Automation',
          value: AlarmInstanceCoveredSource.Automation,
        },
      ],
    },
  ];

export const KPI_DASHBOARD_FILTERS_CRITERION: GlobalSortAndFiltersCriterion[] =
  [
    ...SERVICE_FAILURE_ALARM_FILTER_CRITERION,
    {
      name: 'Alarm ID',
      dbName: 'alarmIds',
      query: 'alarmId',
      show: true,
      type: 'select',
      blanks: false,
      category: filterCategoryType.ALARMS,
    },
    {
      name: 'Alarm Source',
      dbName: 'externalSystemId',
      query: 'systemSource',
      show: true,
      type: 'alarmSource',
      blanks: false,
      category: filterCategoryType.ALARMS,
    },
    {
      name: 'End Time (RTN)',
      show: true,
      type: 'dateTime',
      blanks: false,
      dbName: 'endTime',
      category: filterCategoryType.ALARMS,
    },
    {
      name: 'Fleeting',
      show: true,
      type: 'bool',
      blanks: false,
      dbName: 'isFleeting',
      category: filterCategoryType.ALARMS,
    },
    {
      name: 'Chattering',
      show: true,
      type: 'bool',
      blanks: false,
      dbName: 'isChattering',
      category: filterCategoryType.ALARMS,
    },
    {
      name: 'Has Work',
      show: true,
      type: 'bool',
      blanks: false,
      dbName: 'isCovered',
      category: filterCategoryType.ALARMS,
    },
    {
      name: 'Work Source',
      show: true,
      type: 'customValueSelect',
      blanks: false,
      dbName: 'coveredSource',
      category: filterCategoryType.ALARMS,
      dbProperty: 'coveredSource',
      selectValues: [
        {
          label: 'User',
          value: AlarmInstanceCoveredSource.User,
        },
        {
          label: 'Automation',
          value: AlarmInstanceCoveredSource.Automation,
        },
      ],
    },
  ];

export const NUISANCE_ALARM_BOOLEAN_CRITERION = {
  name: 'Nuisance',
  show: true,
  type: 'bool',
  blanks: false,
  dbName: 'minNuisance',
  category: filterCategoryType.ALARMS,
  dbProperty: 'name',
};

export const TIME_TO_DISPATCH_FILTER: GlobalSortAndFiltersCriterion[] = [
  {
    name: 'Time to Dispatch',
    show: true,
    type: 'timeMinMax',
    dbName: 'timeToDispatch',
    category: filterCategoryType.WORK,
  },
];

export const NUISANCE_CRITERION = {
  model: {
    name: 'Nuisance',
    show: true,
    type: 'bool',
    blanks: false,
    multi: true,
    dbName: 'minNuisance',
    category: 'Alarm Filters',
    dbProperty: 'name',
    selection: {
      value: 'true',
    },
  },
  detail: {
    name: 'Nuisance',
    show: true,
    type: 'bool',
    blanks: false,
    multi: true,
    dbName: 'minNuisance',
    category: 'Alarm Filters',
    dbProperty: 'name',
    selection: {
      value: 'true',
    },
  },
  value: 'true',
};

export const PRIORITY_CRITERION = {
  model: {
    name: 'Alarm Priority',
    show: true,
    type: 'priority',
    blanks: false,
    dbName: 'priority',
    category: 'Alarm Filters',
    min: 0,
    max: 5,
    selection: {
      value: ['4'],
    },
  },
  detail: {
    name: 'Alarm Priority',
    show: true,
    type: 'priority',
    blanks: false,
    dbName: 'priority',
    category: 'Alarm Filters',
    min: 0,
    max: 5,
    selection: {
      value: ['4'],
    },
  },
  value: ['4'],
};
