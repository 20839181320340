import { UiAtomsModule } from '@vfi-ui/ui/atoms';
import { CodeInputModule } from 'angular-code-input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeatureCoreModule } from '@vfi-ui/feature/core';
import { UiNucleusModule } from '@vfi-ui/ui/nucleus';
import { LoginComponent } from './login/login.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpConfigInterceptor } from './interceptors/httpconfig.interceptor';
import { RegisterComponent } from './register/register.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { CustomerSelectorComponent } from './customer-selector/customer-selector.component';
import { VersionSelectorComponent } from './version-selector/version-selector.component';
import { MfaComponent } from './mfa/mfa.component';
import { LogoutComponent } from './logout/logout.component';
import {
  CAPACITOR_CORE_PROVIDER,
  CAPACITOR_DEPLOY_PROVIDER,
} from '@vfi-ui/models';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FeatureCoreModule,
    UiNucleusModule,
    UiAtomsModule,
    CodeInputModule,
  ],
  declarations: [
    LoginComponent,
    LogoutComponent,
    RegisterComponent,
    ResetPasswordComponent,
    ChangePasswordComponent,
    CustomerSelectorComponent,
    VersionSelectorComponent,
    MfaComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true,
    },
    CAPACITOR_DEPLOY_PROVIDER,
    CAPACITOR_CORE_PROVIDER,
  ],
})
export class FeatureAuthModule {}
