import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ERROR_UPLOAD_INVENTORY,
  InventoryAuditLog,
  InventoryAuditLogWhere,
  InventoryType,
} from '@vfi-ui/models';
import { NotificationService } from './notification.service';
import { Observable, catchError, map, tap, throwError } from 'rxjs';
import { environment } from '@vfi-ui/environments/environment';
import { Apollo } from 'apollo-angular';
import { INVENTORY_AUDIT_LOG_QUERY } from '../queries';

@Injectable({
  providedIn: 'root',
})
export class InventoryDataService {
  constructor(
    private http: HttpClient,
    private notification: NotificationService,
    private apollo: Apollo
  ) {}

  /**
   * Uploads inventory to backend
   *
   * @param {number} externalSystemId
   * @param {InventoryType} type
   * @param {Blob} file
   * @return {*}  {Observable<void>}
   * @memberof InventoryDataService
   */
  uploadInventory({
    externalSystemId,
    type,
    file,
    reason,
  }: {
    externalSystemId: number;
    type: InventoryType;
    file: Blob;
    reason: string;
  }): Observable<void> {
    const formData = new FormData();
    formData.append('file', file);
    let url = `${environment.backend}/inventory/upload?type=${type}&reason=${reason}`;
    if (externalSystemId) {
      url += `&externalSystemId=${externalSystemId}`;
    }
    return this.http.post<void>(url, formData).pipe(
      catchError(({ error }) => {
        this.notification.showError(ERROR_UPLOAD_INVENTORY, error.message);
        return throwError(error);
      }),
      tap(() => {
        this.notification.showSuccess(
          'Upload Inventory',
          'Inventory Uploaded.'
        );
      })
    );
  }

  /**
   * Gets existing inventory as json
   *
   * @param {number} externalSystemId
   * @param {InventoryType} type
   * @return {*}  {Observable<any[]>}
   * @memberof InventoryDataService
   */
  downloadInventory({
    externalSystemId,
    type,
    id,
  }: {
    externalSystemId: number;
    type: InventoryType;
    id: number;
  }): Observable<any[]> {
    let url = `${environment.backend}/inventory/download?type=${type}`;
    if (externalSystemId) {
      url += `&externalSystemId=${externalSystemId}`;
    }
    if (id) {
      url += `&id=${id}`;
    }
    return this.http.get<any[]>(url, {});
  }

  /**
   * Gets the inventory audit logs
   *
   * @param {InventoryType} type
   * @return {*}
   * @memberof InventoryDataService
   */
  getAuditLogs(where: InventoryAuditLogWhere): Observable<InventoryAuditLog[]> {
    return this.apollo
      .query<{ inventoryAuditLogs: InventoryAuditLog[] }>({
        fetchPolicy: 'network-only',
        query: INVENTORY_AUDIT_LOG_QUERY,
        variables: {
          input: { where },
        },
      })
      .pipe(map((d) => d.data.inventoryAuditLogs));
  }
}
