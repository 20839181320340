import { Subject, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import {
  WorkConfiguration,
  CoreAlarm,
  BaseAlarmProperty,
  AlarmProperty,
  AlarmPropertyVoteType,
  AlarmConfiguration,
} from '@vfi-ui/models';
import { AlarmsDataService } from './alarms-data.service';
import { fastParse, isNil, keys } from '@vfi-ui/util/helpers';

@Injectable({
  providedIn: 'root',
})
export class DetailsService {
  alarm: CoreAlarm;
  woProperties: WorkConfiguration;
  alarmProperties: AlarmProperty[];
  resetForms = new Subject<any>();
  inputRef = new BehaviorSubject<string>('');
  focusedInput = new BehaviorSubject<string>('');
  pointEntityInput: any;

  constructor(
    private fb: UntypedFormBuilder,
    private alarmDataService: AlarmsDataService
  ) {}

  /**
   * returns alarm details form
   *
   * @returns
   * @memberof DetailsService
   */
  get alarmDetailsForm() {
    const values = {
      pointEntity: null,
    };
    if (this.alarmProperties) {
      this.alarmProperties.forEach((r) =>
        Object.assign(values, {
          [r.name]: null,
          [`${r.name}_verify`]: null,
          [`${r.name}_new`]: null,
        })
      );
    }
    return this.fb.group(values);
  }

  /**
   * return work details form
   *
   * @returns
   * @memberof DetailsService
   */
  getWorkDetailsForm(fields: string[]) {
    const form = this.workForm();
    fields.forEach((field) => {
      form.controls[field].setValidators(Validators.required);
    });
    return form;
  }

  /**
   * return create work form
   *
   * @readonly
   * @memberof DetailsService
   */
  getCreateWorkTicketForm(): UntypedFormGroup {
    return this.fb.group({
      objective: [null, Validators.required],
      problemDetails: [null],
      assignedTo: [null, Validators.required],
      dueBy: [null, Validators.required],
    });
  }

  /**
   * save alarm details form
   *
   * @param {UntypedFormGroup} form
   * @returns
   * @memberof DetailsService
   */
  saveDetailsForm(form: UntypedFormGroup) {
    const alarmData = this.formatFormData(form);
    const newValueData = this.formatNewValuesData(form);

    if (newValueData && newValueData.length) {
      newValueData.forEach((data) =>
        this.alarmDataService.addAlarmProperty(data).subscribe()
      );
    }
    return this.alarmDataService.saveAlarmPropertyConfiguration(
      this.alarm.id,
      alarmData
    );
  }

  /**
   * save alarm confidence
   *
   * @param {string} property
   * @param {UntypedFormGroup} form
   * @returns
   * @memberof DetailsService
   */
  saveAlarmConfidence(property: string, form: UntypedFormGroup) {
    const confidenceValue = form.controls[`${property}_verify`].value;
    const configuration = [
      {
        type: property,
        values: form.controls[property].value || [],
        confidence: confidenceValue,
        voteType:
          confidenceValue === 1
            ? AlarmPropertyVoteType.THUMBS_UP
            : AlarmPropertyVoteType.THUMBS_DOWN,
      },
    ];
    return this.alarmDataService.saveAlarmPropertyConfiguration(
      this.alarm.id,
      configuration
    );
  }

  /**
   * update alarm property confidence in existing alarm
   *
   * @param {CoreAlarm} alarm
   * @param {CoreAlarm} newAlarm
   * @param {string} property
   * @returns
   * @memberof DetailsService
   */
  updateAlarmConfidenceProperty(
    alarm: CoreAlarm,
    newAlarm: CoreAlarm,
    property: string
  ) {
    const updatedAlarm = fastParse(alarm);
    const newProp = newAlarm.properties.find((p) => p.type === property);
    const oldIndex = alarm.properties.findIndex((r) => r.type === property);
    const properties = [...updatedAlarm.properties];
    if (oldIndex > -1) {
      properties[oldIndex] = newProp;
    }
    updatedAlarm.properties = properties;
    return updatedAlarm;
  }

  /**
   * update alarm confidence value in existing form
   *
   * @param {UntypedFormGroup} form
   * @param {CoreAlarm} alarm
   * @param {string} property
   * @returns
   * @memberof DetailsService
   */
  updateAlarmConfidenceValue(
    form: UntypedFormGroup,
    alarm: CoreAlarm,
    property: string
  ) {
    const prop = alarm.properties.find((p) => p.type === property);
    const control = form.controls[property];
    const control_verify = form.controls[`${property}_verify`];
    control_verify.reset();
    control_verify.markAsPristine();
    control_verify.updateValueAndValidity();
    control.setValue(prop.values);
    control.markAsPristine();
    control.updateValueAndValidity();
    form.patchValue({ [`${property}_verify`]: null, [property]: prop.values });
    form.updateValueAndValidity();
    return form;
  }

  /**
   * format values for alarm configuration query
   *
   * @param {UntypedFormGroup} form
   * @returns
   * @memberof DetailsService
   */
  formatFormData(form: UntypedFormGroup): AlarmConfiguration[] {
    return keys(form.controls)
      .map((name) => {
        if (['priority', 'isServiceImpacting', 'pointEntity'].includes(name)) {
          return;
        }
        const control = form.controls[name];
        if (!control.pristine) {
          const values = control?.value?.length ? control.value : [];
          const confidence = isNil(form.controls[`${name}_verify`]?.value)
            ? 1
            : form.controls[`${name}_verify`]?.value;
          const voteType = AlarmPropertyVoteType.CHANGE;
          return { type: name, values, confidence, voteType };
        }
      })
      .filter((i) => i);
  }

  /**
   * create form group for additional work fields
   *
   * @private
   * @returns
   * @memberof DetailsService
   */
  private workForm() {
    return this.fb.group({
      building: [null],
      unit: [null],
      shop: [null],
      status: [null],
      category: [null],
      type: [null],
      priority: [null],
      timeScheduled: [null],
      timeRequired: [null],
      phone: [null],
      campus: [null],
      floor: [null],
      room: [null],
      equipmentType: [null],
      equipment: [null],
      maintenance: [null],
      notes: [null],
      billable: [false],
      interimMeasure: [false],
    });
  }

  /**
   * format new value data
   *
   * @private
   * @param {UntypedFormGroup} form
   * @returns {BaseAlarmProperty[]}
   * @memberof DetailsService
   */
  private formatNewValuesData(form: UntypedFormGroup): BaseAlarmProperty[] {
    const data = [];
    const props = this.alarmProperties.map((r) => r.name);
    props.forEach((name) => {
      const control = form.controls[`${name}_new`];
      if (control.value && control.value.length) {
        data.push({
          type: name,
          values: control.value,
        });
      }
    });
    return data;
  }
}
