import { gql } from 'apollo-angular';

export const GET_WORK_RESOLUTION_TREND_QUERY = gql`
  query workResolutionWorkTicketTrendCounts(
    $input: WorkResolutionDashboardOptions!
  ) {
    workResolutionWorkTicketTrendCounts(input: $input) {
      resolvedCount
      unresolvedCount
      period
    }
  }
`;

export const GET_WORK_RESOLUTION_GROUP_BY_TREND_QUERY = gql`
  query workResolutionWorkTicketTrendChartGroupBy(
    $input: WorkResolutionDashboardOptions!
    $groupBy: WorkResolutionGroupBy!
    $groupByAlarmAttribute: String
  ) {
    workResolutionWorkTicketTrendChartGroupBy(
      input: $input
      groupBy: $groupBy
      groupByAlarmAttribute: $groupByAlarmAttribute
    ) {
      resolvedTotalDuration
      unresolvedTotalDuration
      groupBy
      team {
        id
        name
      }
    }
  }
`;

export const GET_WORK_RESOLUTION_CLOSED_WORK_QUERY = gql`
  query workResolutionMetricsClosedWork(
    $resolvedInput: WorkResolutionDashboardOptions!
    $previousResolvedInput: WorkResolutionDashboardOptions!
    $unresolvedInput: WorkResolutionDashboardOptions!
    $previousUnresolvedInput: WorkResolutionDashboardOptions!
  ) {
    resolvedCount: workResolutionWorkTicketTrendCount(input: $resolvedInput)
    previousResolvedCount: workResolutionWorkTicketTrendCount(
      input: $previousResolvedInput
    )
    unresolvedCount: workResolutionWorkTicketTrendCount(input: $unresolvedInput)
    previousUnresolvedCount: workResolutionWorkTicketTrendCount(
      input: $previousUnresolvedInput
    )
    resolvedDuration: workResolutionWorkTicketTrendTotalDuration(
      input: $resolvedInput
    )
    previousResolvedDuration: workResolutionWorkTicketTrendTotalDuration(
      input: $previousResolvedInput
    )
    unresolvedDuration: workResolutionWorkTicketTrendTotalDuration(
      input: $unresolvedInput
    )
    previousUnresolvedDuration: workResolutionWorkTicketTrendTotalDuration(
      input: $previousUnresolvedInput
    )
  }
`;

export const GET_WORK_RESOLUTION_REPEAT_WORK_QUERY = gql`
  query workResolutionMetricsRepeatWork(
    $repeatInput: WorkResolutionDashboardOptions!
    $previousRepeatInput: WorkResolutionDashboardOptions!
    $totalInput: WorkResolutionDashboardOptions!
    $previousTotalInput: WorkResolutionDashboardOptions!
  ) {
    repeatCount: workResolutionWorkTicketTrendCount(input: $repeatInput)
    previousRepeatCount: workResolutionWorkTicketTrendCount(
      input: $previousRepeatInput
    )
    repeatDuration: workResolutionWorkTicketTrendTotalDuration(
      input: $repeatInput
    )
    previousRepeatDuration: workResolutionWorkTicketTrendTotalDuration(
      input: $previousRepeatInput
    )
    totalCount: workResolutionWorkTicketTrendCount(input: $totalInput)
    previousTotalCount: workResolutionWorkTicketTrendCount(
      input: $previousTotalInput
    )
    totalDuration: workResolutionWorkTicketTrendTotalDuration(
      input: $totalInput
    )
    previousTotalDuration: workResolutionWorkTicketTrendTotalDuration(
      input: $previousTotalInput
    )
  }
`;

export const GET_WORK_RESOLUTION_WORK_TICKETS_QUERY = gql`
  query workResolutionWorkTickets($input: WorkResolutionDashboardOptions!) {
    workResolutionWorkTickets(input: $input) {
      id
      externalId
      objective
      createdAt
      assigneeName
      closedAt
      isResolved
      duration
      repeatCount
      isRepeat
      state
      status
      alarmInstanceCount
      inactiveAlarmCount
      activeAlarmCount
      associatedAlarms {
        id
        displayId
        priority
        status
        nuisanceCount
      }
      associatedAlarmPriorities
      associatedAlarmStatuses
      associatedAlarmSources
      associatedAlarmTimes
      associatedAlarmStaleStatuses
      associatedAlarmHasPointEntity
      associatedAlarmNuisances
      associatedAlarmAttributes
      associatedAlarmIsServiceImpacting
      associatedAlarmAssetIds
      associatedAlarmSpaceTypes
      associatedAlarmSpaceNames
      associatedAlarmAssetTypes
      associatedAlarmAssetClasses
      associatedAlarmEquipmentNames
      isOverlapped
      isResolved
      createdBy {
        displayName
      }
      creatorTeams {
        name
      }
      assigneeTeams {
        name
      }
      isAutomationCreated
      timeToDispatch
      externalSystemName
    }
  }
`;
