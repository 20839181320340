import { AuthService } from '../services/auth.service';
import { Injectable } from '@angular/core';
import {
  GuardResult,
  RedirectCommand,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { isEmpty } from '@vfi-ui/util/helpers';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private router: Router,
    private auth: AuthService
  ) {}

  /**
   * determines if user has access to rest of the application.
   * If not logged in. redirect to login screen.
   *
   * @returns {Promise<GuardResult>}
   * @memberof AuthGuard
   */
  async canActivate(state: RouterStateSnapshot): Promise<GuardResult> {
    const firebaseUser = await firstValueFrom(this.auth.getFirebaseUser());
    if (!firebaseUser) {
      const deepLink = window.location.href;
      localStorage.setItem('vfi-deepLink', deepLink);
      if (!isEmpty(state.root.queryParams)) {
        localStorage.setItem(
          'vfi-deepLink-params',
          JSON.stringify(state.root.queryParams)
        );
      }
      return new RedirectCommand(this.router.parseUrl('login'));
    }
    const user = await firstValueFrom(
      this.auth.getUsersByUids([firebaseUser.uid])
    );
    if (!user) {
      return new RedirectCommand(this.router.parseUrl('404/user'));
    }

    return true;
  }
}
