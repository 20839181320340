export const CREATE_SSO_QUERY = `
  mutation createSso($emailDomain: String!, $ssoConfig: SsoConfigInput!, $customerId: Int) {
    setupSso(
      emailDomain: $emailDomain
      ssoConfig: $ssoConfig
      customerId: $customerId
    )
  }
`;

export const UPDATE_SSO_QUERY = `
  mutation updateSso($emailDomain: String!, $ssoConfig: SsoConfigInput!) {
    updateSsoConfig(emailDomain: $emailDomain, ssoConfig: $ssoConfig) {
      entityId
      certificate
      providerEntityId
      ssoUrl
    }
  }
`;

export const DELETE_SSO_QUERY = `
mutation deleteSsoConfig($emailDomain: String!) {
  deleteSsoConfig(emailDomain: $emailDomain)
}`;

export const GET_SSO_QUERY = `
  query getSso($emailDomain: String!) {
    getSsoConfig(emailDomain: $emailDomain) {
      entityId
      certificate
      providerEntityId
      ssoUrl
    }
  }
`;

export const GET_EMAIL_DOMAINS_QUERY = `
  query getEmailDomains($customerId: Int!) {
    getEmailDomainsPerCustomer(customerId: $customerId)
  }
`;
