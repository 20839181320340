<nz-modal
  [(nzVisible)]="isVisible"
  nzMaskClosable="false"
  (nzOnCancel)="isVisible = false"
  nzTitle="Leave page without saving?"
  [nzFooter]="footerBtn"
  [nzZIndex]="modalZIndex"
  nzClosable="true"
  [nzWidth]="460"
  [nzZIndex]="modalZIndex + 2"
  nzWrapClassName="vertical-center-modal"
  nzClassName="mobile-confirm-modal dark-modal"
>
  <ng-template nzModalContent>
    <div class="modal-body mb-2 regular-16">
      <p aria-label="discardMessage1" class="mb-0">
        If you leave this page your changes will not be saved.
      </p>
    </div>
  </ng-template>
</nz-modal>

<ng-template #footerBtn>
  <div class="d-sm-down-flex flex-sm-down-column-reverse">
    <atom-vfi-button
      [text]="'Stay on page'"
      [ariaLabel]="'stayOnPageBtn'"
      [type]="'secondary'"
      [classes]="'block-button-sm'"
      (clicked)="stay()"
    ></atom-vfi-button>
    <atom-vfi-button
      [text]="'Leave'"
      [ariaLabel]="'discardChangesBtn'"
      [type]="'danger'"
      [classes]="'iml-md-1 iml-xs-0 imb-xs-2 block-button-sm'"
      (clicked)="leave()"
    ></atom-vfi-button>
  </div>
</ng-template>
