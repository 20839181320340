import { catchError, take, tap } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { filter, map, Observable, throwError } from 'rxjs';
import { NotificationService } from './notification.service';
import {
  AlarmReportWhere,
  DatetimeRange,
  ERROR_FETCH_KPI_METRICS,
  ERROR_UPDATE_KPI_CONFIG,
  ERROR_UPDATE_KPI_NOTES,
  KpiDashboardAwarenessMetricAlarmDrillDown,
  KpiDashboardDuplicateWorkDrillDown,
  KpiDashboardMetricConfig,
  KpiDashboardMetricType,
  KpiDashboardNote,
  KpiDashboardOpenWorkInactiveAlarmDrilldown,
  KpiDashboardReportWhere,
  KpiDashboardWorkCoverageAlarmDrillDown,
  KpiDashboardWorkTimeToDispatchCreatorDrilldown,
  KpiDashboardWorkTimeToDispatchTeamDrilldown,
  KpiResult,
} from '@vfi-ui/models';
import {
  FETCH_KPI_CONFIG_QUERY,
  GET_KPI_DASHBOARD_AWARENESS_METRIC_ALARM_DRILLDOWN_QUERY,
  GET_KPI_DASHBOARD_DUPLICATE_WORK_DRILLDOWN_QUERY,
  GET_KPI_DASHBOARD_METRICS_QUERY,
  GET_KPI_DASHBOARD_METRIC_CONFIG_QUERY,
  GET_KPI_DASHBOARD_OPEN_WORK_INACTIVE_ALARM_DRILLDOWN_QUERY,
  GET_KPI_DASHBOARD_TIME_TO_DISPATCH_CREATOR_DRILLDOWN_QUERY,
  GET_KPI_DASHBOARD_TIME_TO_DISPATCH_TEAM_DRILLDOWN_QUERY,
  GET_KPI_DASHBOARD_WORK_COVERAGE_ALARM_DRILLDOWN_QUERY,
  UPDATE_KPI_CONFIG_QUERY,
  UPDATE_KPI_DASHBOARD_METRIC_CONFIG_MUTATION,
  UPDATE_KPI_DASHBOARD_NOTES_MUTATION,
} from '../queries/kpi-dashboard.query';
import { HttpClient } from '@angular/common/http';
import { environment } from '@vfi-ui/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class KpiDashboardDataService {
  constructor(
    private apollo: Apollo,
    private notification: NotificationService,
    private http: HttpClient
  ) {}

  /**
   * call api to fetch kpi dashboard metrics
   *
   * @param {KpiDashboardMetricType} metricType
   * @param {{
   *    currentPeriod: DatetimeRange;
   *   previousPeriod: DatetimeRange;
   * }} input
   * @param {KpiDashboardReportWhere} where
   * @returns {Observable<KpiResult[]>}
   * @memberof KpiDashboardDataService
   */
  getKpiDashboardMetrics(
    metricType: KpiDashboardMetricType,
    input: {
      currentPeriod: DatetimeRange;
      previousPeriod: DatetimeRange;
    },
    where?: KpiDashboardReportWhere
  ): Observable<KpiResult> {
    if (where) {
      input = {
        ...input,
        ...where,
      };
    }

    const variables = {
      metricType,
      input,
    };

    return this.apollo
      .query<{ kpi: KpiResult }>({
        fetchPolicy: 'network-only',
        query: GET_KPI_DASHBOARD_METRICS_QUERY,
        variables,
      })
      .pipe(
        filter((d) => !!d),
        map((res) => res?.data?.kpi),
        catchError((error) => {
          this.notification.showError(ERROR_FETCH_KPI_METRICS);
          return throwError(error);
        })
      );
  }

  /**
   * call api to fetch work covereage alarm drilldown
   * @param {{
   *    currentPeriod: DatetimeRange;
   *    previousPeriod: DatetimeRange;
   *    where: AlarmReportWhere
   * }} input
   * @returns {Observable<KpiDashboardWorkCoverageAlarmDrillDown[]>}
   * @memberof KpiDashboardDataService
   */
  getKpiDashboardWorkCoverageAlarmDrilldown(input: {
    currentPeriod: DatetimeRange;
    previousPeriod: DatetimeRange;
    where?: AlarmReportWhere;
  }): Observable<KpiDashboardWorkCoverageAlarmDrillDown[]> {
    const variables = {
      input,
    };

    return this.apollo
      .query<{
        kpiDashboardWorkCoverageAlarmDrilldown: KpiDashboardWorkCoverageAlarmDrillDown[];
      }>({
        fetchPolicy: 'network-only',
        query: GET_KPI_DASHBOARD_WORK_COVERAGE_ALARM_DRILLDOWN_QUERY,
        variables,
      })
      .pipe(
        filter((d) => !!d),
        map((res) => {
          const data = res?.data?.kpiDashboardWorkCoverageAlarmDrilldown;
          return data.map((d) => {
            const alarmInstanceCount = d.alarmInstanceCount;
            const alarmInstanceCoveredByUserCount =
              d?.alarmInstanceCoveredByUserCount;
            const alarmInstanceCoveredByAutomationCount =
              d?.alarmInstanceCoveredByAutomationCount;
            const coveredAlarmInstanceCount = d?.coveredAlarmInstanceCount;

            return {
              ...d,
              alarmInstanceCoveredByUserCount:
                alarmInstanceCoveredByUserCount / alarmInstanceCount,
              alarmInstanceCoveredByAutomationCount:
                alarmInstanceCoveredByAutomationCount / alarmInstanceCount,
              coveredAlarmInstanceCount:
                coveredAlarmInstanceCount / alarmInstanceCount,
              notCoveredAlarmInstanceCount:
                (alarmInstanceCount - coveredAlarmInstanceCount) /
                alarmInstanceCount,
            };
          });
        })
      );
  }

  /**
   * call api to fetch duplicate work
   * @param {{
   *    currentPeriod: DatetimeRange;
   *    previousPeriod: DatetimeRange;
   *    where: AlarmReportWhere
   * }} input
   * @returns {Observable<KpiDashboardDuplicateWorkDrillDown[]>}
   * @memberof KpiDashboardDataService
   */
  getKpiDashboardDuplicateWorkDrilldown(input: {
    currentPeriod: DatetimeRange;
    previousPeriod: DatetimeRange;
    where?: AlarmReportWhere;
  }): Observable<KpiDashboardDuplicateWorkDrillDown[]> {
    const variables = {
      input,
    };

    return this.apollo
      .query<{
        kpiDashboardDuplicateWorkDrilldown: KpiDashboardDuplicateWorkDrillDown[];
      }>({
        fetchPolicy: 'network-only',
        query: GET_KPI_DASHBOARD_DUPLICATE_WORK_DRILLDOWN_QUERY,
        variables,
      })
      .pipe(
        filter((d) => !!d),
        map((res) => {
          const data = res?.data?.kpiDashboardDuplicateWorkDrilldown;
          return data.map((d) => {
            const duplicateWorkTicketPercent =
              d?.duplicateWorkTicketCount / d?.workTicketCount;
            return {
              ...d,
              duplicateWorkTicketPercent,
            };
          });
        })
      );
  }

  /**
   * call api to fetch time to dispatch metrics team drilldown
   * @param {{
   *    currentPeriod: DatetimeRange;
   *    previousPeriod: DatetimeRange;
   *    where: AlarmReportWhere;
   * }} input
   * @returns {Observable<KpiDashboardWorkTimeToDispatchTeamDrilldown[]>}
   * @memberof KpiDashboardDataService
   */
  getKpiDashboardWorkTimeToDispatchTeamDrilldown(input: {
    currentPeriod: DatetimeRange;
    previousPeriod: DatetimeRange;
    where?: AlarmReportWhere;
  }): Observable<KpiDashboardWorkTimeToDispatchTeamDrilldown[]> {
    const variables = {
      input,
    };

    return this.apollo
      .query<{
        kpiDashboardWorkTimeToDispatchTeamDrilldown: KpiDashboardWorkTimeToDispatchTeamDrilldown[];
      }>({
        fetchPolicy: 'network-only',
        query: GET_KPI_DASHBOARD_TIME_TO_DISPATCH_TEAM_DRILLDOWN_QUERY,
        variables,
      })
      .pipe(
        filter((d) => !!d),
        map((res) => {
          const data = res?.data?.kpiDashboardWorkTimeToDispatchTeamDrilldown;

          return data.map((d) => {
            let previousAvgTimeToDispatch;
            if (d.previousAvgTimeToDispatch > d?.averageTimeToDispatch) {
              previousAvgTimeToDispatch =
                ((d?.previousAvgTimeToDispatch - d?.averageTimeToDispatch) /
                  d?.previousAvgTimeToDispatch) *
                -1;
            } else {
              previousAvgTimeToDispatch =
                (d?.averageTimeToDispatch - d?.previousAvgTimeToDispatch) /
                d?.averageTimeToDispatch;
            }
            return {
              ...d,
              previousAvgTimeToDispatch,
            };
          });
        })
      );
  }

  /**
   * call api to fetch time to dispatch metrics team drilldown
   * @param {{
   *    currentPeriod: DatetimeRange;
   *    previousPeriod: DatetimeRange;
   *    where: AlarmReportWhere;
   * }} input
   * @returns {Observable<KpiDashboardWorkTimeToDispatchTeamDrilldown[]>}
   * @memberof KpiDashboardDataService
   */
  getKpiDashboardWorkTimeToDispatchCreatorDrilldown(
    teamId: string,
    input: {
      currentPeriod: DatetimeRange;
      previousPeriod: DatetimeRange;
      where?: AlarmReportWhere;
    }
  ): Observable<KpiDashboardWorkTimeToDispatchCreatorDrilldown[]> {
    const variables = {
      teamId,
      input,
    };

    return this.apollo
      .query<{
        kpiDashboardWorkTimeToDispatchCreatorDrilldown: KpiDashboardWorkTimeToDispatchCreatorDrilldown[];
      }>({
        fetchPolicy: 'network-only',
        query: GET_KPI_DASHBOARD_TIME_TO_DISPATCH_CREATOR_DRILLDOWN_QUERY,
        variables,
      })
      .pipe(
        filter((d) => !!d),
        map((res) => {
          const data =
            res?.data?.kpiDashboardWorkTimeToDispatchCreatorDrilldown;

          return data.map((d) => {
            let previousAvgTimeToDispatch;
            if (d.previousAvgTimeToDispatch > d?.avgTimeToDispatch) {
              previousAvgTimeToDispatch =
                ((d?.previousAvgTimeToDispatch - d?.avgTimeToDispatch) /
                  d?.previousAvgTimeToDispatch) *
                -1;
            } else {
              previousAvgTimeToDispatch =
                (d?.avgTimeToDispatch - d?.previousAvgTimeToDispatch) /
                d?.avgTimeToDispatch;
            }
            return {
              ...d,
              previousAvgTimeToDispatch,
            };
          });
        })
      );
  }

  /**
   * call api to fetch awareness metrics drilldown
   * @param {{
   *    currentPeriod: DatetimeRange;
   *    previousPeriod: DatetimeRange;
   *    where: AlarmReportWhere;
   * }} input
   * @returns {Observable<KpiDashboardWorkCoverageAlarmDrillDown[]>}
   * @memberof KpiDashboardDataService
   */
  getkpiDashboardAwarenessMetricAlarmDrilldown(input: {
    currentPeriod: DatetimeRange;
    previousPeriod: DatetimeRange;
    where?: AlarmReportWhere;
  }): Observable<KpiDashboardAwarenessMetricAlarmDrillDown[]> {
    const variables = {
      input,
    };

    return this.apollo
      .query<{
        kpiDashboardAwarenessMetricAlarmDrilldown: KpiDashboardAwarenessMetricAlarmDrillDown[];
      }>({
        fetchPolicy: 'network-only',
        query: GET_KPI_DASHBOARD_AWARENESS_METRIC_ALARM_DRILLDOWN_QUERY,
        variables,
      })
      .pipe(
        filter((d) => !!d),
        map((res) => {
          const data = res?.data?.kpiDashboardAwarenessMetricAlarmDrilldown;
          const totalInstanceCount = data.find(
            (d) => d.priority === null
          ).alarmInstanceCount;

          return data.map((d) => {
            const alarmInstanceCount = d.alarmInstanceCount;
            const currentPeriodFrom = new Date(input.currentPeriod.from);
            const currentPeriodTo = new Date(input.currentPeriod.to);
            const currentPeriodHours =
              (currentPeriodTo.getTime() - currentPeriodFrom.getTime()) /
              (1000 * 3600);
            const alarmInstanceDistribution =
              d.alarmInstanceCount / totalInstanceCount;

            return {
              ...d,
              averageAlarmInstancePerHour:
                alarmInstanceCount / currentPeriodHours,
              alarmInstanceDistribution,
            };
          });
        })
      );
  }

  /**
   * call api to fetch open work inactive alarm drilldown
   * @param {{
   *    currentPeriod: DatetimeRange;
   *    previousPeriod: DatetimeRange;
   *    where: AlarmReportWhere;
   * }} input
   * @returns {Observable<KpiDashboardWorkCoverageAlarmDrillDown[]>}
   * @memberof KpiDashboardDataService
   */
  getkpiDashboardOpenWorkInactiveAlarmDrilldown(input: {
    currentPeriod: DatetimeRange;
    previousPeriod: DatetimeRange;
    where?: AlarmReportWhere;
  }): Observable<KpiDashboardOpenWorkInactiveAlarmDrilldown[]> {
    const variables = {
      input,
    };

    return this.apollo
      .query<{
        kpiDashboardOpenWorkInactiveAlarmDrilldown: KpiDashboardOpenWorkInactiveAlarmDrilldown[];
      }>({
        fetchPolicy: 'network-only',
        query: GET_KPI_DASHBOARD_OPEN_WORK_INACTIVE_ALARM_DRILLDOWN_QUERY,
        variables,
      })
      .pipe(
        filter((d) => !!d),
        map((res) => {
          const data = res?.data?.kpiDashboardOpenWorkInactiveAlarmDrilldown;

          return data.map((d) => {
            const workTicketCount = d.workTicketCount;
            const openWorkTicketCount = d.openWorkTicketCount;

            return {
              ...d,
              openWorkCount: openWorkTicketCount / workTicketCount,
            };
          });
        })
      );
  }

  /**
   * call api to fetch kpi dashboard metrics config
   *
   * @returns {Observable<KpiDashboardMetricConfig[]>}
   * @memberof KpiDashboardDataService
   */
  getKpiDashboardMetricConfig(): Observable<KpiDashboardMetricConfig[]> {
    return this.apollo
      .query<{ kpiDashboardMetricConfigs: KpiDashboardMetricConfig[] }>({
        fetchPolicy: 'network-only',
        query: GET_KPI_DASHBOARD_METRIC_CONFIG_QUERY,
      })
      .pipe(
        filter((d) => !!d),
        map((res) => res?.data?.kpiDashboardMetricConfigs)
      );
  }

  /**
   * call api to update kpi dashboard metrics config metrics
   *
   * @param {KpiDashboardMetricConfig[]} config
   * @returns {Observable<KpiDashboardMetricConfig[]>}
   * @memberof KpiDashboardDataService
   */
  updateKpiDashboardMetricsConfig(config: KpiDashboardMetricConfig[]) {
    return this.apollo
      .mutate({
        mutation: UPDATE_KPI_DASHBOARD_METRIC_CONFIG_MUTATION,
        variables: { updates: config },
      })
      .pipe(
        tap(() => {
          this.notification.showSuccess(
            'KPI Dashboard',
            `KPI Dashboard metrics config updated successfully.`
          );
        }),
        filter((d) => !!d),
        catchError((err) => throwError(err))
      );
  }

  /**
   * fetch kpi config
   *
   * @returns {Observable<boolean>}
   * @memberof KpiDashboardDataService
   */
  getKpiConfig(): Observable<boolean> {
    return this.http
      .request('POST', environment.backend, {
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          query: FETCH_KPI_CONFIG_QUERY,
        }),
      })
      .pipe(
        map(
          (res: { data: { kpiDashboardConfig: { isEnabled: boolean } } }) =>
            res?.data?.kpiDashboardConfig?.isEnabled
        )
      );
  }

  /**
   * update kpi config
   *
   * @param {boolean} isEnabled
   * @returns {Observable<boolean>}
   * @memberof KpiDashboardDataService
   */
  updateKpiConfig(isEnabled: boolean): Observable<boolean> {
    return this.apollo
      .mutate<{ data: { updateKpiDashboardConfig: { isEnabled: boolean } } }>({
        mutation: UPDATE_KPI_CONFIG_QUERY,
        variables: { input: { isEnabled } },
      })
      .pipe(
        tap(() => {
          this.notification.showSuccess(
            'KPI Dashboard',
            `KPI Dashboard is now ${isEnabled ? 'enabled' : 'disabled'}`
          );
        }),
        map((res: any) => res?.data?.updateKpiDashboardConfig?.isEnabled),
        catchError(({ error }) => {
          this.notification.showError(ERROR_UPDATE_KPI_CONFIG);
          return throwError(error);
        })
      );
  }

  /**
   * mutate alarm notes
   *
   * @param {KpiDashboardMetricType} metricType
   * @param {string} notes
   * @memberof KpiDashboardDataService
   */
  updateKpiDashboardNotes(
    metricType: KpiDashboardMetricType,
    note: string
  ): Observable<KpiDashboardNote> {
    return this.apollo
      .mutate<{ updateKpiDashboardNote: KpiDashboardNote }>({
        mutation: UPDATE_KPI_DASHBOARD_NOTES_MUTATION,
        variables: { metricType, note },
      })
      .pipe(
        take(1),
        map((res) => res?.data?.updateKpiDashboardNote),
        filter((d) => !!d),
        catchError((err) => {
          this.notification.showError(ERROR_UPDATE_KPI_NOTES);
          return throwError(err);
        })
      );
  }
}
