import { WorkTicket } from './work-order.interface';
import { OrderDirection } from '../constants/work-ticket.constant';
import {
  AlarmTimeWindow,
  BaseAlarmProperty,
  CoreAlarm,
} from './alarm-instance.interface';
import { WorkResolutionState } from './work-resolution.interface';
import { DateType } from './core.interface';

export enum AlarmInstanceCoveredSource {
  User = 'USER',
  Automation = 'AUTOMATION',
}

export enum AlarmInstanceEndTimeSource {
  Bas = 'BAS',
  Infered = 'INFERED',
  Manual = 'MANUAL',
}

export interface AlarmInstanceReport {
  id: number;
  alarm?: Partial<CoreAlarm>;
  priority: number;
  attributes?: BaseAlarmProperty[];
  attributesObj: any;
  text: string;
  contextualName: string;
  alarmTime: string;
  endTime: string;
  endTimeSource: AlarmInstanceEndTimeSource;
  duration: number;
  isFleeting: boolean;
  isChattering: boolean;
  isNuisance: boolean;
  isCovered: boolean;
  isStale: boolean;
  coveredSource: AlarmInstanceCoveredSource;
  coverdBy: WorkTicket;
  externalSystemDisplayName: string;
  associatedWorkTicketIds: number[];
  associatedWorkTickets: Partial<WorkTicket[]>;
  workResolutionState: WorkResolutionState;
  basPriority: number;
  isActive: boolean;
  ackBy: string;
  ackTime: string;
  alarmId: number;
  displayId: string;
  status: string;
}

export enum AlarmInstanceReportOrderField {
  WorkResolutionState = 'WORK_RESOLUTION_STATE',
  Bell = 'BELL',
  Id = 'ID',
  IsActive = 'IS_ACTIVE',
  AlarmId = 'ALARM_ID',
  Priority = 'PRIORITY',
  Attributes = 'ATTRIBUTES',
  AlarmAttrbute = 'ALARM_ATTRIBUTE',
  Text = 'TEXT',
  ContextualName = 'CONTEXTUAL_NAME',
  AlarmTime = 'ALARM_TIME',
  EndTime = 'END_TIME',
  EndTimeSource = 'END_TIME_SOURCE',
  Duration = 'DURATION',
  IsFleeting = 'IS_FLEETING',
  IsChattering = 'IS_CHATTERING',
  IsNuisance = 'IS_NUISANCE',
  IsCovered = 'IS_COVERED',
  CoveredSource = 'COVERED_SOURCE',
  CoveredBy = 'COVERED_BY',
  ExternalSystemDisplayName = 'EXTERNAL_SYSTEM_DISPLAY_NAME',
  AssociatedWorkTicketIds = 'ASSOCIATED_WORK_TICKET_IDS',
  IsServiceImpacting = 'IS_SERVICE_IMPACTING',
  AssetClass = 'ASSET_CLASS',
  AssetType = 'ASSET_TYPE',
  SpaceType = 'SPACE_TYPE',
  SpaceName = 'SPACE_NAME',
  AssetId = 'ASSET_ID',
  EquipmentName = 'EQUIPMENT_NAME',
}

export const ALARM_INSTANCE_REPORT_SORT_VALUE = {
  workResolutionState: AlarmInstanceReportOrderField.WorkResolutionState,
  bell: AlarmInstanceReportOrderField.Bell,
  id: AlarmInstanceReportOrderField.Id,
  alarmId: AlarmInstanceReportOrderField.AlarmId,
  alarm: AlarmInstanceReportOrderField.AlarmId,
  priority: AlarmInstanceReportOrderField.Priority,
  attributes: AlarmInstanceReportOrderField.Attributes,
  text: AlarmInstanceReportOrderField.Text,
  contextualName: AlarmInstanceReportOrderField.ContextualName,
  alarmTime: AlarmInstanceReportOrderField.AlarmTime,
  endTime: AlarmInstanceReportOrderField.EndTime,
  endTimeSource: AlarmInstanceReportOrderField.EndTimeSource,
  duration: AlarmInstanceReportOrderField.Duration,
  isFleeting: AlarmInstanceReportOrderField.IsFleeting,
  isChattering: AlarmInstanceReportOrderField.IsChattering,
  isNuisance: AlarmInstanceReportOrderField.IsNuisance,
  isCovered: AlarmInstanceReportOrderField.IsCovered,
  coveredSource: AlarmInstanceReportOrderField.CoveredSource,
  coveredBy: AlarmInstanceReportOrderField.CoveredBy,
  externalSystemDisplayName:
    AlarmInstanceReportOrderField.ExternalSystemDisplayName,
  associatedWorkTicketIds:
    AlarmInstanceReportOrderField.AssociatedWorkTicketIds,
  isActive: AlarmInstanceReportOrderField.IsActive,
  isServiceImpacting: AlarmInstanceReportOrderField.IsServiceImpacting,
  assetClass: AlarmInstanceReportOrderField.AssetClass,
  assetType: AlarmInstanceReportOrderField.AssetType,
  assetId: AlarmInstanceReportOrderField.AssetId,
  spaceType: AlarmInstanceReportOrderField.SpaceType,
  basPriority: 'BAS_PRIORITY',
  ackTime: 'ACK_TIME',
  ackBy: 'ACK_BY',
  workTicketState: 'WORK_TICKET_STATE',
  workAssignee: 'WORK_ASSIGNEE',
  workClosedTime: 'WORK_CLOSED_TIME',
  workCreatedTime: 'WORK_CREATED_TIME',
  resolution: 'RESOLUTION',
  isStale: 'IS_STALE',
  spaceName: AlarmInstanceReportOrderField.SpaceName,
  equipmentName: AlarmInstanceReportOrderField.EquipmentName,
  assetCriticality: 'ASSET_CRITICALITY',
};

export interface AlarmInstanceReportOrder {
  field: AlarmInstanceReportOrderField;
  field2: string;
  direction: OrderDirection;
}

export interface AlarmInstanceReportSort {
  field: AlarmInstanceReportOrderField;
  field2: string;
  direction: OrderDirection;
}

export interface AlarmInstanceReportWhere {
  alarmIds?: number[];
  priority?: number[];
  alarmTime?: AlarmTimeWindow;
  endTime?: AlarmTimeWindow;
  durationMax?: number;
  durationMin?: number;
  attributes?: any[];
  externalSystemId?: number[];
  isFleeting?: boolean;
  isChattering?: boolean;
  isNuisance?: boolean;
  isCovered?: boolean;
  coveredSource?: AlarmInstanceCoveredSource;
  isActive?: boolean;
  // NOTE: only for saved views
  dateSelection?: DateType;
}

export interface AlarmInstanceReportOptions {
  limit?: number;
  offset?: number;
  order?: AlarmInstanceReportSort;
  where?: AlarmInstanceReportWhere;
}

export interface AlarmInstanceReportGroupBy {
  groupBy: string;
  alarm: CoreAlarm;
  count: number;
}

export enum AlarmInstanceReportGroupValues {
  'alarm' = AlarmInstanceReportOrderField.AlarmId,
  'externalSystemDisplayName' = AlarmInstanceReportOrderField.ExternalSystemDisplayName,
  'priority' = AlarmInstanceReportOrderField.Priority,
  'coveredSource' = AlarmInstanceReportOrderField.CoveredSource,
}

export enum SavedViewGroupByValues {
  'PRIORITY' = 'Priority',
  'ALARM_ID' = 'Unique Alarm',
  'EXTERNAL_SYSTEM_DISPLAY_NAME' = 'Alarm Source',
  'COVERED_SOURCE' = 'Work Source',
}

export enum AlarmInstanceReportGroupBySort {
  GroupBy = 'GROUP_BY',
  Count = 'COUNT',
  Priority = 'priority',
}

export interface AlarmInstanceReportGroupByOrder {
  field: AlarmInstanceReportGroupBySort;
  direction: OrderDirection;
}

export interface AlarmInstanceReportGroupByQuery {
  alarmInstanceReportGroupBy: AlarmInstanceReportGroupBy[];
  alarmInstanceReportGroupByCount: number;
}

export enum EndTimeSource {
  Bas = 'BAS',
  Inferred = 'INFERRED',
  Cleared = 'CLEARED',
}

export enum EndTimeSourceValues {
  BAS = 'BAS',
  INFERRED = 'Inferred',
  CLEARED = 'Cleared',
}

export enum AlarmInstanceReportGroupByField {
  AlarmId = 'ALARM_ID',
  ExternalSystemDisplayName = 'EXTERNAL_SYSTEM_DISPLAY_NAME',
  Priority = 'PRIORITY',
  AlarmAttribute = 'ALARM_ATTRIBUTE',
  CoveredSource = 'COVERED_SOURCE',
}
