import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import {
  AlarmsState,
  AlarmsStateModel,
  LoadAlarmStatuses,
} from '@vfi-ui/state';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AlarmStatusesResolver {
  @Select(AlarmsState.getState) alarmsState$: Observable<AlarmsStateModel>;

  constructor(private store: Store) {}

  resolve(): Observable<boolean> {
    this.store.dispatch(new LoadAlarmStatuses());
    return this.loadData();
  }

  loadData() {
    return this.alarmsState$.pipe(
      filter((alarms) => alarms.alarmStatuses.length > 0),
      map((state) => !!state.alarmStatuses),
      take(1)
    );
  }
}
