import { Injectable } from '@angular/core';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AuthService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard {
  constructor(private authService: AuthService) {}
  /**
   * determines if user has access to rest of the application.
   * If yes, redirect to active alarms
   *
   * @returns {Observable<boolean>}
   * @memberof LoginGuard
   */
  canActivate(): Observable<boolean> {
    return this.authService.getFirebaseUser().pipe(
      switchMap((res) => {
        if (!res) {
          return this.authService.getRedirectResult().pipe(
            catchError((e) => {
              this.authService.ssoConfigError = e;
              return of(null);
            })
          );
        }
        return of(res);
      }),
      map((res) => {
        if (res) {
          window.location.assign(`/${window.location.search}`);
          return false;
        }
        return true;
      })
    );
  }
}
