import { AlarmsDataService } from '@vfi-ui/data-access/shared';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { SetAlarmProperties } from '@vfi-ui/state';
import { take, filter, tap } from 'rxjs/operators';
import { AlarmProperty } from '@vfi-ui/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AlarmPropertyResolver {
  constructor(
    private store: Store,
    private alarmsDataService: AlarmsDataService
  ) {}

  resolve(): Observable<AlarmProperty[]> {
    return this.alarmsDataService.getDynamicAlarmProperties().pipe(
      filter((p) => !!p),
      tap((p) => {
        this.store.dispatch(new SetAlarmProperties(p));
      }),
      take(1)
    );
  }
}
