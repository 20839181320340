export const Priority5Color = '#FF1744';
export const Priority4Color = '#FFA766';
export const Priority3Color = '#FFE077';
export const Priority2Color = '#587B90';
export const Priority1Color = '#C6D4DC';
export const Priority0Color = '#fff';

export const Priority5Font = '#fff';
export const Priority4Font = '#612500';
export const Priority3Font = '#613400';
export const Priority2Font = '#fff';
export const Priority1Font = '#fff';
export const Priority0Font = '#000';

export const Priorities = [
  {
    label: 'Highest',
    value: 5,
    fontColor: 'priority-5-font',
    bgColor: 'priority-5-bg',
  },
  {
    label: 'High',
    value: 4,
    fontColor: 'priority-4-font',
    bgColor: 'priority-4-bg',
  },
  {
    label: 'Medium',
    value: 3,
    fontColor: 'priority-3-font',
    bgColor: 'priority-3-bg',
  },
  {
    label: 'Low',
    value: 2,
    fontColor: 'priority-2-font',
    bgColor: 'priority-2-bg',
  },
  {
    label: 'Lowest',
    value: 1,
    fontColor: 'priority-1-font',
    bgColor: 'priority-1-bg',
  },
  {
    label: 'No Priority',
    value: 0,
    fontColor: 'priority-0-font',
    bgColor: 'priority-0-bg',
  },
];

export const AllPriorities = [
  {
    label: 'All Priorities',
    value: -1,
    fontColor: '',
    bgColor: '',
  },
];

export const ActionBarPriorities = [
  {
    name: 'Highest',
    value: 5,
    fontColor: 'priority-5-font',
    bgColor: 'priority-5-bg',
  },
  {
    name: 'High',
    value: 4,
    fontColor: 'priority-5-font',
    bgColor: 'priority-4-action-bar-bg',
  },
  {
    name: 'Medium',
    value: 3,
    fontColor: 'priority-0-font',
    bgColor: 'priority-3-action-bar-bg',
  },
  {
    name: 'Low',
    value: 2,
    fontColor: 'priority-2-font',
    bgColor: 'priority-2-bg',
  },
  {
    name: 'Lowest',
    value: 1,
    fontColor: 'priority-0-font',
    bgColor: 'priority-1-bg',
  },
  {
    name: 'No Priority',
    value: 0,
    fontColor: 'priority-0-font',
    bgColor: 'priority-0-bg',
  },
];

export const PRIORITY_VALUES = {
  5: 'Highest',
  4: 'High',
  3: 'Medium',
  2: 'Low',
  1: 'Lowest',
  0: 'No priority',
};
export enum AlarmPriority {
  NONE = 0,
  LOWEST = 1,
  LOW = 2,
  MEDIUM = 3,
  HIGH = 4,
  HIGHEST = 5,
}

export const PRIORITY_NUMBER_VALUES = Object.keys(PRIORITY_VALUES);

export const PriorityFontColors = {
  Highest: 'priority-5',
  High: 'priority-4',
  Medium: 'priority-3',
  Low: 'priority-2',
  Lowest: 'priority-1',
  'No Priority': 'priority-0',
};

export const AlarmDetailsPriorityOptions = [
  {
    label: 'Highest',
    value: 5,
  },
  {
    label: 'High',
    value: 4,
  },
  {
    label: 'Medium',
    value: 3,
  },
  {
    label: 'Low',
    value: 2,
  },
  {
    label: 'Lowest',
    value: 1,
  },
  {
    label: 'No Priority',
    value: 0,
  },
];
