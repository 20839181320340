import { OrderDirection } from './work-ticket.constant';

export const AG_GRID_LICENSE_KEY =
  'Using_this_AG_Grid_Enterprise_key_( AG-049679 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Virtual Facility, Inc. )_is_granted_a_( Single Application )_Developer_License_for_the_application_( Alarm Triage )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( Alarm Triage )_need_to_be_licensed___( Alarm Triage )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 23 November 2024 )____[v2]_MTczMjMyMDAwMDAwMA==dfcd01bd5988a8c653ccfbf914ee787d';
export const TIME_FORMAT_ANGULAR = 'MM/d/y h:mm a';
export const TIME_FORMAT_MEDIUM_ANGULAR = 'MMM d y';
export const TIME_FORMAT_LONG = 'MM/DD/YYYY hh:mm A';
export const MAX_TABLE_LIMIT = 10;
export const ZENDESK_BRAND_ID = 360003558211;
export const ZENDESK_Url = 'https://vfacility.zendesk.com/access/jwt';
export const CUSTOMER_ID_HEADER = 'x-vfi-customer-id';
export const TEAMS_ID_HEADER = 'x-vfi-team-id';
export const VFI_SUPPORT = 'Virtual Facility Support';
export const NEW_VALUE_TEXT = '(New Value)';
export const SELECT_ALL_VALUE = 'select_all';
export const BLANK_VALUE_TEXT = '(blanks)';
export const ALARM_ATTRIBUTE_OPTION = '{alarm-attribute}';
export const BLANK_VALUE = 'null';
export const NEW_VALUE_TAG = '(+ new value)';
export const MODAL_Z_INDEX = 1001;
export const IDLE_SECONDS = 1800;
export const FAST_WORK_TICKET_LIMIT = 10000;
export const IDLE_TIMEOUT_SECONDS = 0;
export const INFINITE_SCROLL_PERCENT_THRESHOLD = 90;
export const FIREBASE_MULTI_FACTOR_AUTH_ERROR =
  '(auth/multi-factor-auth-required)';
export const TABLE_SIZE_OPTIONS = [10, 25, 50, 100];
export enum VFI_THEME {
  DARK = 'dark',
  LIGHT = 'light',
}
export const WORK_TIME_PERIODS = [
  {
    value: 7,
    label: 'Last 7 days',
  },
];
export const OrderDirectionOptions = [
  { value: OrderDirection.ASC, label: 'Asc' },
  { value: OrderDirection.DESC, label: 'Desc' },
];
export const BooleanOptions = [
  {
    label: 'True',
    value: true,
  },
  {
    label: 'False',
    value: false,
  },
];
export const ActiveOptions = [
  {
    label: 'Active',
    value: true,
  },
  {
    label: 'Inactive',
    value: false,
  },
];
export const QuickFilterDateOptions = [
  {
    label: 'Last 7 days',
    value: 7,
  },
  {
    label: 'Last 30 days',
    value: 30,
  },
  {
    label: 'Custom',
    value: 0,
  },
];
export const MINUTE_IN_MILLISECONDS = 60000;
export const ALARM_ATTRIBUTE_COLUMN_TO_LABEL_MAP = new Map<string, string>([
  ['building', 'Location - Building'],
  ['category', 'Alarm Category'],
  ['condition', 'Alarm Condition'],
  ['flow_direction', 'Alarm Flow Direction'],
  ['instrument_type', 'Alarm Instrument Type'],
  ['medium', 'Alarm Medium'],
  ['parameter', 'Alarm Parameter'],
  ['parameter_prefix', 'Alarm Parameter Descriptor'],
  ['floor', 'Location - Floor'],
  ['room_number', 'Location - Room'],
  ['shop', 'Alarm Shop'],
  ['component', 'Alarm Component'],
]);

export const BAS_COLUMN_TO_LABEL_MAP = new Map<string, string>([
  ['POINT_DESCRIPTION', 'Source Point Description'],
  ['ALARM_NAME', 'Source Alarm Name'],
  ['ALARM_DESCRIPTION', 'Source Alarm Description'],
  ['ALARM_MESSAGE', 'Source Alarm Message'],
  ['ALARM_CATEGORY', 'Source Alarm Category'],
  ['PRIORITY', 'Source Priority'],
  ['POINT', 'Source Alarm Point'],
]);

export const ALARM_ATTRIBUTE_NAME_TO_LABEL_MAP = new Map<string, string>([
  ['Active', 'Alarm State'],
  ['Alarmed Asset Name', 'Alarm Asset Name'],
  ['Building', 'Location - Building'],
  ['Category', 'Alarm Category'],
  ['Condition', 'Alarm Condition'],
  ['Shop', 'Alarm Shop'],
  ['Floor', 'Location - Floor'],
  ['Flow Direction', 'Alarm Flow Direction'],
  ['Instrument Type', 'Alarm Instrument Type'],
  ['Keyword', 'Alarm Keyword'],
  ['Medium', 'Alarm Medium'],
  ['Parameter', 'Alarm Parameter'],
  ['Parameter Prefix', 'Alarm Parameter Descriptor'],
  ['Pinned', 'Alarm Pinned'],
  ['Status', 'Alarm Status'],
  ['Equipment', 'Equipment Name'],
]);

export const SSO_SIGN_IN = 'sso-sign-in';
export const VFI_CLAIMS = 'vfi-claims';
