import { gql } from 'apollo-angular';

export const GET_SPACE_TYPE_QUERY = gql`
  query spaceTypes($options: SpaceTypeOptions!) {
    spaceTypes(options: $options) {
      id
      name
      criticality
      associatedEntities
    }
    spaceTypeCount(options: $options)
  }
`;
