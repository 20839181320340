import { AutomationRule } from './automation-rules.interface';
import {
  Alarm,
  AssetClass,
  CoreAlarmsWhere,
  DatetimeRange,
  Integrations,
  Team,
  User,
  WorkTicketWhere,
} from '..';
import { WorkResolutionWorkTicketOrderField } from '../constants/work-resolution.constants';
import {
  OrderDirection,
  WorkTicketProcessState,
} from '../constants/work-ticket.constant';

export enum Granularity {
  HOUR = 'HOUR',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  DAY = 'DAY',
}

export const GranularityOptions = [
  Granularity.HOUR,
  Granularity.DAY,
  Granularity.WEEK,
  Granularity.MONTH,
];

export interface WorkResolutionTicketWhere {
  createdAt?: DatetimeRange;
  closedAt?: DatetimeRange;
  assignee?: string[];
  createdBy?: string[];
  ageDaysMin?: number;
  ageDaysMax?: number;
  isResolved?: boolean;
  state?: WorkTicketProcessState;
  teamIds?: string[];
  createdByTeam?: string[];
  externalSystemIds?: number[];
  isInternal?: boolean;
}

export interface WorkResolutionDashboardOptions {
  alarmInput?: any;
  workTicketInput?: WorkResolutionTicketWhere;
  granularity?: Granularity;
  timezone?: string;
  order?: WorkResolutionWorkTicketOrder;
  limit?: number;
  offset?: number;
}

export interface WorkResolutionWorkTicketOrder {
  field: WorkResolutionWorkTicketOrderField;
  direction: OrderDirection;
}

export interface WorkResolutionTrendQuery {
  resolvedCount: number;
  unresolvedCount: number;
  period: string;
}

export interface WorkResolutionDurationQuery {
  resolvedTotalDuration: number;
  unresolvedTotalDuration: number;
  groupBy: string;
  team: Team;
}

export interface WorkResolutionClosedWork {
  previousResolvedCount: number;
  previousResolvedDuration: number;
  previousUnresolvedCount: number;
  previousUnresolvedDuration: number;
  resolvedCount: number;
  resolvedDuration: number;
  unresolvedCount: number;
  unresolvedDuration: number;
}

export interface WorkResolutionRepeatWork {
  previousRepeatCount: number;
  previousRepeatDuration: number;
  previousTotalCount: number;
  previousTotalDuration: number;
  repeatCount: number;
  repeatDuration: number;
  totalCount: number;
  totalDuration: number;
}

export enum WorkResolutionGroupBy {
  Assignee = 'ASSIGNEE',
  IsResolved = 'IS_RESOLVED',
  State = 'STATE',
  Status = 'STATUS',
  AlarmAttribute = 'ALARM_ATTRIBUTE',
  Team = 'TEAM',
  CreatedBy = 'CREATED_BY',
  CreatedByTeam = 'CREATED_BY_TEAM',
  Priority = 'PRIORITY',
  AlarmSource = 'ALARM_SOURCE',
  ExternalSystemId = 'EXTERNAL_SYSTEM_ID',
}

export interface WorkResolutionDashboardGroupBy {
  groupBy: string;
  count: number;
  user: User;
  team: Team;
  automationRule: AutomationRule;
  externalSystem?: Integrations;
}

export interface WorkResolutionFiltersWhere {
  alarmWhere?: CoreAlarmsWhere;
  where?: WorkTicketWhere;
  whereNot?: WorkTicketWhere;
}

export interface WorkResolutionWorkTicket {
  id: number;
  duration?: number;
  createdAt?: Date;
  closedAt?: Date;
  resolvedAlarmCount?: number;
  associatedAlarmCount?: number;
  criticalAssociatedAlarmCount?: number;
  resolvedCriticalAssociatedAlarmCount?: number;
  repeatCount?: number;
  timeValues?: Object;
  inactiveAlarmCount?: number;
  activeAlarmCount?: number;
  associatedAlarms?: Alarm[];
  associatedAlarmPriorities?: string[];
  associatedAlarmStatuses?: string[];
  associatedAlarmSources?: string[];
  associatedAlarmTimes?: string[];
  associatedAlarmStaleStatuses?: boolean[];
  associatedAlarmHasPointEntity?: boolean[];
  associatedAlarmNuisances?: string[];
  associatedAlarmAttributes?: any[];
  associatedAlarmBuildings?: string[];
  associatedAlarmConditions?: string[];
  associatedAlarmFlowDirections?: string[];
  associatedAlarmInstrumentTypes?: string[];
  associatedAlarmMediums?: string[];
  associatedAlarmParameters?: string[];
  associatedAlarmParameterPrefixes?: string[];
  associatedAlarmSpaceTypes?: string[];
  associatedAlarmIsServiceImpacting?: boolean[];
  associatedAlarmAssetIds?: number[];
  associatedAlarmSpaceNames?: string[];
  associatedAlarmAssetTypes?: string[];
  associatedAlarmAssetClasses?: AssetClass[];
  associatedAlarmEquipmentNames?: string[];
  isOverlapped?: boolean;
  createdBy?: User;
  creatorTeams?: Team[];
  assigneeTeams?: Team[];
  isAutomationCreated?: boolean;
  isRepeat?: boolean;
  timeToDispatch?: number;
}

export interface WorkResolutionAlarmSort {
  field: string;
  direction: OrderDirection;
}

export enum WorkResolutionWorkTicketsOrderField {
  Id = 'ID',
  ExternalId = 'EXTERNAL_ID',
  CreatedAt = 'CREATED_AT',
  ClosedAt = 'CLOSED_AT',
  Assignee = 'ASSIGNEE',
  Objective = 'OBJECTIVE',
  Resolution = 'RESOLUTION',
  IsResolved = 'IS_RESOLVED',
  AssigneeId = 'ASSIGNEE_ID',
  Duration = 'DURATION',
  ResolvedAlarmCount = 'RESOLVED_ALARM_COUNT',
  AssociatedAlarmCount = 'ASSOCIATED_ALARM_COUNT',
  CriticalAssociatedAlarmCount = 'CRITICAL_ASSOCIATED_ALARM_COUNT',
  Building = 'BUILDING',
  CreatedBy = 'CREATED_BY',
  RepeatCount = 'REPEAT_COUNT',
  State = 'STATE',
  AlarmInstanceCount = 'ALARM_INSTANCE_COUNT',
  WorkIndicator = 'WORK_INDICATOR',
  IsRepeat = 'IS_REPEAT',
  TimeToDispatch = 'TIME_TO_DISPATCH',
  ExternalSystemName = 'EXTERNAL_SYSTEM_NAME',
}

export enum WorkResolutionState {
  OpenWork = 'OPEN_WORK',
  MultipleWork = 'MULTIPLE_WORK',
  GoodClosedWork = 'GOOD_CLOSED_WORK',
  BadClosedWork = 'BAD_CLOSED_WORK',
  NoWork = 'NO_WORK',
}
