import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router, ActivationStart, NavigationStart } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { HttpCancelDataService } from '../services/http-cancel-data.service';
import { get } from '@vfi-ui/util/helpers';

const cancelPathsActivationStart = ['list', 'dash', ':lens'];
@Injectable({ providedIn: 'any' })
export class CancelHttpInterceptor implements HttpInterceptor {
  constructor(
    router: Router,
    private httpCancelService: HttpCancelDataService
  ) {
    router.events
      .pipe(
        filter(
          (ev) => ev instanceof ActivationStart || ev instanceof NavigationStart
        )
      )
      .subscribe((event: ActivationStart | NavigationStart) => {
        if (event instanceof ActivationStart) {
          // An event triggered at the start of the activation part of the Resolve phase of routing.
          this.cancelActivationStartRequests(event);
        } else if (event instanceof NavigationStart) {
          this.cancelNavigationStartRequests(event, router);
        }
      });
  }

  intercept<T>(
    req: HttpRequest<T>,
    next: HttpHandler
  ): Observable<HttpEvent<T>> {
    return next
      .handle(req)
      .pipe(takeUntil(this.httpCancelService.onCancelPendingRequests()));
  }

  /**
 * cancel pending requests when activation start event is fired

 *
 * @private
 * @param {ActivationStart} event
 * @param {Router} router
 * @memberof CancelHttpInterceptor
 */
  private cancelActivationStartRequests(event: ActivationStart) {
    const paths = event.snapshot.url.map((url) => url.path);
    const routePaths = event?.snapshot?.routeConfig?.path;
    const totalSegments = get(event, 'snapshot._urlSegment.segments', []).map(
      (url) => url.path
    );
    const cancelablePath = [...paths, ...totalSegments, routePaths].find(
      (path) => cancelPathsActivationStart.includes(path)
    );
    if (cancelablePath) {
      // Cancel pending calls
      this.httpCancelService.cancelPendingRequests();
    }
  }
  /**
   * cancel pending requests when navigation start event is fired
   *
   * @private
   * @param {NavigationStart} event
   * @param {Router} router
   * @memberof CancelHttpInterceptor
   */
  private cancelNavigationStartRequests(
    event: NavigationStart,
    router: Router
  ) {
    if (router['navigationId'] > 2) {
      // Cancel pending calls
      this.httpCancelService.cancelPendingRequests();
    }
  }
}
