import {
  WorkTicketDataService,
  SocketsService,
} from '@vfi-ui/data-access/shared';
import {
  State,
  Action,
  StateContext,
  Selector,
  Store,
  Actions,
  StateOperator,
  ofActionCompleted,
} from '@ngxs/store';
import {
  LoadWorkTickets,
  WorkTileSelected,
  LoadMoreWorkTickets,
  ResetWorkTickets,
  RemoveWorkTicket,
  ToggleSubmitReivewModal,
  ToggleFeedbackModal,
  AddWorkTicketCounts,
  LiveWorkTickets,
  CleanupWorkTicketSockets,
  SetRequiredWorkTicketFields,
} from './work-tickets.actions';
import {
  CriterionSelection,
  MaxWorkTicketsLimit,
  Tile,
  Counts,
  SocketAction,
  LensEntityEvent,
  LensEventType,
  FastWorkTicket,
} from '@vfi-ui/models';
import { GlobalFiltersState } from '../global-filters/global-filters.state';
import { takeUntil, skip, take, skipWhile } from 'rxjs/operators';
import { patch, append, removeItem, insertItem } from '@ngxs/store/operators';
import { MapWorkTicketToTile, result } from '@vfi-ui/util/helpers';
import { Subscription } from 'rxjs';
import { LensState } from '../lens/lens.state';
import { Injectable } from '@angular/core';

export interface WorkTicketsStateModel {
  workTickets: FastWorkTicket[];
  tiles: Tile[];
  loading: boolean;
  offset: number;
  limit: number;
  totalCount: number;
  showSubmitReviewModal: boolean;
  showFeedbackModal: boolean;
  requiredFields: string[];
  counts: Counts;
}
export const WorkTicketsStateDefaults: WorkTicketsStateModel = {
  workTickets: [],
  tiles: [],
  loading: false,
  limit: MaxWorkTicketsLimit,
  offset: 0,
  totalCount: 0,
  showSubmitReviewModal: false,
  showFeedbackModal: false,
  requiredFields: [],
  counts: {},
};

@State<WorkTicketsStateModel>({
  name: 'workTickets',
  defaults: WorkTicketsStateDefaults,
})
@Injectable({
  providedIn: 'root',
})
export class WorkTicketsState {
  workTicketEvent$ = this.socketService.onMessage<
    LensEntityEvent<FastWorkTicket>
  >(SocketAction.LENS_EVENT);

  private workTicketSocketSub = new Subscription();

  constructor(
    private store: Store,
    private actions$: Actions,
    private workTicketData: WorkTicketDataService,
    private socketService: SocketsService
  ) {}

  /**
   * check and return if there are any filtered applied
   *
   * @readonly
   * @private
   * @type {boolean}
   * @memberof AlarmsState
   */
  private get isfiltered(): boolean {
    return this.store.selectSnapshot(GlobalFiltersState.isCriterionSet);
  }

  @Selector()
  public static getState(state: WorkTicketsStateModel) {
    return state;
  }

  @Selector()
  public static getWorkTickets(state: WorkTicketsStateModel) {
    return state.workTickets;
  }

  @Selector()
  public static getTiles(state: WorkTicketsStateModel) {
    return state.tiles;
  }

  @Selector()
  public static getLoading(state: WorkTicketsStateModel) {
    return state.loading;
  }

  @Selector()
  static getTotalCount(state: WorkTicketsStateModel) {
    return state.totalCount;
  }

  @Selector()
  static isSubmitReviewModalOpen(state: WorkTicketsStateModel) {
    return state.showSubmitReviewModal;
  }

  @Selector()
  static isFeedbackModalOpen(state: WorkTicketsStateModel) {
    return state.showFeedbackModal;
  }

  @Selector()
  static getWorkCounts(state: WorkTicketsStateModel) {
    return state.counts;
  }

  @Selector()
  static getRequiredFields(state: WorkTicketsStateModel) {
    return state.requiredFields;
  }

  @Action(LoadWorkTickets)
  public loadWorkTickets(ctx: StateContext<WorkTicketsStateModel>) {
    const state = ctx.getState();
    const searchTerm = this.store.selectSnapshot(
      GlobalFiltersState.getSearchTerm
    );
    const criterion = this.store.selectSnapshot(
      GlobalFiltersState.getCriterion
    );
    const coreCriterion = this.store.selectSnapshot(
      GlobalFiltersState.getCoreCriterion
    );
    const allCriterion: CriterionSelection[] = [...coreCriterion, ...criterion];
    const sort = this.store.selectSnapshot(GlobalFiltersState.getSort);

    ctx.setState({
      ...state,
      loading: true,
    });
    this.workTicketData
      .getWorkTickets({
        ...state,
        searchTerm,
        criterion: allCriterion,
        sort,
      })
      .pipe(
        takeUntil(
          this.actions$.pipe(
            ofActionCompleted(LoadWorkTickets),
            skip(1),
            take(1)
          )
        )
      )
      .subscribe(({ workTickets, loading, count }) => {
        ctx.setState(
          patch({
            workTickets: append(workTickets),
            tiles: append(this.mapWorkTicketTiles(workTickets)),
            loading: loading,
            totalCount: count,
          })
        );
      });
  }

  @Action(LoadMoreWorkTickets)
  public updateOffset(ctx: StateContext<WorkTicketsStateModel>, { payload }) {
    const state = ctx.getState();
    ctx.setState(
      patch({
        offset: result(payload, 'offset', state.offset + state.limit),
      })
    );
    return ctx.dispatch(new LoadWorkTickets());
  }

  @Action(WorkTileSelected)
  public selectWorkTile(ctx: StateContext<WorkTicketsStateModel>, { payload }) {
    const state = ctx.getState();
    const tiles = state.tiles.map((tile) => {
      if (tile.selected) {
        tile = { ...tile, selected: false };
      }
      if (tile.tileID === payload.id) {
        tile = { ...tile, selected: true };
      }
      return tile;
    });
    ctx.patchState({
      tiles,
    });
  }

  @Action(ToggleSubmitReivewModal)
  public toggleSubmitReivewModal(
    ctx: StateContext<WorkTicketsStateModel>,
    { payload }
  ) {
    ctx.setState(
      patch({
        showSubmitReviewModal: payload,
      })
    );
  }

  @Action(ToggleFeedbackModal)
  public toggleFeedbackModal(
    ctx: StateContext<WorkTicketsStateModel>,
    { payload }
  ) {
    ctx.setState(
      patch({
        showFeedbackModal: payload,
      })
    );
  }

  @Action(ResetWorkTickets)
  public resetState(ctx: StateContext<WorkTicketsStateModel>) {
    const state = ctx.getState();
    ctx.setState({
      ...WorkTicketsStateDefaults,
      counts: state.counts,
      requiredFields: state.requiredFields,
    });
  }

  @Action(RemoveWorkTicket)
  public alarmMoved(ctx: StateContext<WorkTicketsStateModel>, { payload }) {
    ctx.setState(
      patch({
        workTickets: removeItem((item: FastWorkTicket) => item.id === payload),
        tiles: removeItem((tiles: Tile) => tiles.tileID === payload),
      })
    );
  }

  @Action(AddWorkTicketCounts)
  addPrimaryCounts(ctx: StateContext<WorkTicketsStateModel>, { payload }) {
    ctx.setState(
      patch({
        counts: patch(payload),
      })
    );
  }

  @Action(SetRequiredWorkTicketFields)
  public setRequiredFields(ctx: StateContext<WorkTicketsStateModel>, payload) {
    ctx.setState(
      patch({
        requiredFields: payload.payload,
      })
    );
  }

  @Action(LiveWorkTickets)
  public liveWorkTickets(ctx: StateContext<WorkTicketsStateModel>) {
    const workTicketSub = this.workTicketEvent$
      .pipe(skipWhile(() => this.isfiltered))
      .subscribe((event) => {
        const { id } = this.store.selectSnapshot(
          LensState.getCurrentlySelectedLens
        );
        const workTicket = event.data.entity;
        if (id === event.lens.id) {
          if (event.data.type === LensEventType.IngressEvent) {
            const workTickets = ctx.getState().workTickets || [];
            const existingWorkTicket = workTickets.find(
              (wt) => wt.id === workTicket.id
            );
            if (!existingWorkTicket) {
              ctx.setState(this.insertItem(0, workTicket));
            }
          } else if (event.data.type === LensEventType.EgressEvent) {
            ctx.setState(this.removeWorkTicketById(workTicket.id));
          }
        }
      });
    this.workTicketSocketSub.add(workTicketSub);
  }

  @Action(CleanupWorkTicketSockets)
  public cleanupSockets() {
    this.workTicketSocketSub.unsubscribe();
    this.workTicketSocketSub = new Subscription();
  }

  /**
   * insert work tickets
   *
   * @private
   * @param {number} [index=0]
   * @param {*} entity
   * @returns
   * @memberof WorkTicketsState
   */
  private insertItem(index = 0, entity): StateOperator<WorkTicketsStateModel> {
    return patch({
      tiles: insertItem(this.mapWorkTicketTiles([entity])[0], index),
      workTickets: insertItem(entity, index),
    });
  }
  /**
   * remove work tickets by id
   *
   * @private
   * @param {(string | number)} id
   * @returns
   * @memberof WorkTicketsState
   */
  private removeWorkTicketById(
    id: string | number
  ): StateOperator<WorkTicketsStateModel> {
    return patch({
      tiles: removeItem((tile: Tile) => tile.tileID === id),
      workTickets: removeItem((item: FastWorkTicket) => item.id === id),
    });
  }

  /**
   * map work tiles by work tickets
   *
   * @private
   * @param {FastWorkTicket[]} workTickets
   * @returns {Tile[]}
   * @memberof WorkTicketState
   */
  private mapWorkTicketTiles(workTickets: FastWorkTicket[]): Tile[] {
    return workTickets.map((wt) => MapWorkTicketToTile(wt));
  }
}
