import { gql } from 'apollo-angular';

export const GET_ALARM_LOCATION_EQUIPMENT_QUERY = gql`
  query entities($options: EntityOptions!) {
    entities(options: $options) {
      id
      name
    }
    entityCount(options: $options)
  }
`;

export const SEARCH_ENTITY_CLASSES_QUERY = gql`
  query searchEntityClasses($options: SearchEntityOptions!) {
    searchEntityClasses(options: $options) {
      id
      name
      count
    }
    searchEntityClassCount(options: $options)
  }
`;

export const SEARCH_ENTITIES_QUERY = gql`
  query searchEntities($options: SearchEntityOptions!) {
    searchEntities(options: $options) {
      id
      name
      count
      floor
    }
    searchEntityCount(options: $options)
  }
`;

export const FETCH_ALARM_ENTITY_PATH_QUERY = gql`
  query alarmEntityPath($alarmId: Int!, $options: EntityOptions!) {
    alarmEntityPath(alarmId: $alarmId) {
      id
      name
      class
      baseClass
      depth
    }
    alarmEntities: entities(options: $options) {
      roomNumber
      roomName
      locationEntities {
        id
        name
        class
        roomName
        roomNumber
      }
    }
  }
`;
