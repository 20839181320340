import { gql } from 'apollo-angular';

export const GET_MENU_ITEMS_QUERY = gql`
  query lenses($options: LensOptions!) {
    lenses(options: $options) {
      name
      id
      criteria
      category
      isActive
      isCustom
      isParent
      type
      orderValue
      tableOptions
      team {
        id
        name
      }
      isSectionDefault
      isHidden
    }
  }
`;

export const UPDATE_DEFAULT_LENS_QUERY = gql`
  mutation updateDefaultLens($id: String!) {
    updateDefaultLens(id: $id)
  }
`;
