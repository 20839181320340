import { AuthService } from '@vfi-ui/data-access/shared';
import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { take, filter, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AuthState, Logout } from '@vfi-ui/state';

@Injectable({
  providedIn: 'root',
})
export class SuperUserResolver {
  @Select(AuthState.isSuper) permission$: Observable<boolean>;

  constructor(
    private store: Store,
    private authService: AuthService
  ) {}

  resolve(): Observable<boolean> {
    return this.getPermissions();
  }

  getPermissions(): Observable<boolean> {
    return this.permission$.pipe(
      map((p) => {
        if (p === false) {
          this.store.dispatch(new Logout());
          this.authService.handleError(
            'User does not have super user permission'
          );
        }
        return p;
      }),
      filter((p) => !!p),
      take(1)
    );
  }
}
