import { Observable, ReplaySubject, share, Subject } from 'rxjs';
import { environment } from '@vfi-ui/environments/environment';
import { Injectable } from '@angular/core';
import { Socket, default as socketIo } from 'socket.io-client';
import {
  SocketEvent,
  SocketAction,
  InitalSocketMessage,
  SOCKET_RETRYS,
  SOCKET_ERROR_MESSAGE,
  User,
  CUSTOMER_ID_HEADER,
} from '@vfi-ui/models';
import { Store } from '@ngxs/store';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AuthService } from './auth.service';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { UpdateUser } from '../../../../../state/src/lib/auth/auth.actions';
import { result } from '@vfi-ui/util/helpers';
@Injectable({
  providedIn: 'root',
})
export class SocketsService {
  socket: Socket;
  socketsInitialized: Subject<boolean> = new Subject<boolean>();
  private initalSocket = new ReplaySubject<InitalSocketMessage>(1);
  private countsReconnect = 0;
  constructor(
    private store: Store,
    private modalService: NzModalService,
    private authService: AuthService
  ) {}

  /**
   * connect to socket connection via token
   *
   * @param {string} token
   * @memberof SocketsService
   */
  public initSocket(token: string): void {
    if (!this.socket || !result(this.socket, 'connected')) {
      const customerId = localStorage.getItem(CUSTOMER_ID_HEADER);
      const extraHeaders = { authorization: token };
      if (customerId) {
        extraHeaders[CUSTOMER_ID_HEADER] = customerId;
      }
      this.socket = socketIo(environment.socketBackend, {
        extraHeaders,
        forceNew: true,
      });
      this.socket?.on('connect', () => {
        this.initMessages();
      });
      this.socket.on('auth', () => {
        this.socket.disconnect();
        this.reconnectSocket();
      });
      this.socket?.on(SocketAction.DISABLE_USER, () => {
        this.authService.signOut().then(() => {
          window.location.reload();
        });
      });
      this.socket?.on('connect_error', (err) => {
        console.error(`socket connection error due to ${err.message}`);
      });
      this.socketsInitialized.next(true);
    }
  }

  /**
   * get the intial messages from the socket connection
   *
   * @template T
   * @returns {Observable<InitalSocketMessage>}
   * @memberof SocketsService
   */
  public initMessages(): Observable<InitalSocketMessage> {
    this.socket?.on('init', (data: InitalSocketMessage) => {
      this.initalSocket.next(data);
      this.countsReconnect = 0;
    });

    return this.initalSocket.asObservable();
  }

  /**
   * create an Observable of updates from sockets by action type
   *
   * @template T
   * @param {SocketAction} action
   * @returns {Observable<T>}
   * @memberof SocketsService
   */
  public onMessage<T>(
    action: SocketAction,
    shouldShare?: boolean
  ): Observable<T> {
    const socketObservable = new Observable<T>((observer) => {
      this.socket?.on(action, (data: T) => observer.next(data));
      return () => {
        this.socket.off(action);
      };
    });
    if (shouldShare) {
      return socketObservable.pipe(share());
    }
    return socketObservable;
  }

  /**
   * create an Observable of events from sockets such as connect and disconnect
   *
   * @template T
   * @param {SocketEvent} event
   * @returns {Observable<T>}
   * @memberof SocketsService
   */
  public onEvent<T>(event: SocketEvent): Observable<T> {
    return new Observable<T>((observer) => {
      this.socket?.on(event, () => observer.next());
    });
  }

  /**
   * clean up socket connections and remove all listeners
   *
   * @memberof SocketsService
   */
  public cleanupSockets() {
    if (this.socket) {
      const socketTypes = Object.values(SocketAction);
      socketTypes.map((d) => this.socket.off(d));
      this.socket.close();
    }
  }

  /**
   * refresh auth token and reconnect sockets
   *
   * @private
   * @memberof SocketsService
   */
  private reconnectSocket() {
    this.authService.getRefreshedToken().then((newToken) => {
      if (!newToken) {
        return;
      }
      this.store.dispatch(new UpdateUser({ token: newToken.token } as User));
      this.countsReconnect++;
      const headers = { authorization: newToken.token };
      const customerId = localStorage.getItem(CUSTOMER_ID_HEADER);
      if (customerId) {
        headers[CUSTOMER_ID_HEADER] = customerId;
      }
      this.socket.io.opts.extraHeaders = headers;
      this.socket.connect();
      if (this.countsReconnect > SOCKET_RETRYS) {
        this.cleanupSockets();
        this.socketError().afterClose.subscribe(() => window.location.reload());
      }
    });
  }

  /**
   * show socket reconnection error
   *
   * @private
   * @returns
   * @memberof SocketsService
   */
  private socketError() {
    return this.modalService.error({
      nzTitle: 'Your session timed out',
      nzContent: SOCKET_ERROR_MESSAGE,
    });
  }
}
