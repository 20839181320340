import { DefaultAlarmSort } from './alarms.constant';
import { DefaultWorkSort, OrderDirection } from './work-ticket.constant';

export const SortDirectionLabels = {
  dateTime: {
    ASC: 'Oldest - Newest',
    DESC: 'Newest - Oldest',
  },
  string: {
    ASC: 'Value A-Z',
    DESC: 'Value Z-A',
  },
  select: {
    ASC: 'Value A-Z',
    DESC: 'Value Z-A',
  },
  number: {
    ASC: 'Lowest - Highest',
    DESC: 'Highest - Lowest',
  },
  priority: {
    ASC: 'Lowest - Highest',
    DESC: 'Highest - Lowest',
  },
  bool: {
    ASC: 'False - True',
    DESC: 'True - False',
  },
  customWorkSelect: {
    ASC: 'Oldest - Newest',
    DESC: 'Newest - Oldest',
  },
  minMax: {
    ASC: 'Lowest - Highest',
    DESC: 'Highest - Lowest',
  },
};

export const LabelToDirection = {
  [SortDirectionLabels.dateTime.ASC]: OrderDirection.ASC,
  [SortDirectionLabels.dateTime.DESC]: OrderDirection.DESC,
  [SortDirectionLabels.string.ASC]: OrderDirection.ASC,
  [SortDirectionLabels.string.DESC]: OrderDirection.DESC,
  [SortDirectionLabels.number.ASC]: OrderDirection.ASC,
  [SortDirectionLabels.number.DESC]: OrderDirection.DESC,
  [SortDirectionLabels.priority.ASC]: OrderDirection.ASC,
  [SortDirectionLabels.priority.DESC]: OrderDirection.DESC,
  [SortDirectionLabels.select.ASC]: OrderDirection.ASC,
  [SortDirectionLabels.select.DESC]: OrderDirection.DESC,
  [SortDirectionLabels.bool.ASC]: OrderDirection.ASC,
  [SortDirectionLabels.bool.DESC]: OrderDirection.DESC,
  [SortDirectionLabels.customWorkSelect.ASC]: OrderDirection.ASC,
  [SortDirectionLabels.customWorkSelect.DESC]: OrderDirection.DESC,
  [SortDirectionLabels.minMax.ASC]: OrderDirection.ASC,
  [SortDirectionLabels.minMax.DESC]: OrderDirection.DESC,
};

export const DEFAULT_WORK_SORT = [
  {
    field: DefaultWorkSort.dbName,
    direction: 'ASC',
    type: 'dateTime',
  },
];

export const WORK_SORT = [
  {
    field: DefaultWorkSort.dbName,
    direction: 'ASC',
  },
];

export const ALARM_SORT = [
  {
    field: DefaultAlarmSort.dbName,
    direction: 'DESC',
  },
];
