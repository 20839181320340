import { gql } from 'apollo-angular';

export const GET_SAVED_VIEWS_QUERY = gql`
  query savedViews($input: SavedViewOptions!) {
    savedViews(input: $input) {
      id
      name
      viewType
      criteria
      isDefault
      columns
    }
  }
`;

export const CREATE_SAVED_VIEW_MUTATION = gql`
  mutation createSavedView($input: SavedViewCreateInput!) {
    createSavedView(input: $input) {
      id
      name
      viewType
      criteria
      isDefault
      columns
    }
  }
`;

export const DELETE_SAVED_VIEW_MUTATION = gql`
  mutation deleteSavedView($id: String!) {
    deleteSavedView(id: $id)
  }
`;

export const UPDATE_SAVED_VIEW_MUTATION = gql`
  mutation updateSavedView($id: String!, $input: SavedViewUpdateInput!) {
    updateSavedView(id: $id, input: $input) {
      id
      name
      viewType
      criteria
      isDefault
      columns
    }
  }
`;
