import { User } from '@vfi-ui/models';

export class SignIn {
  static readonly type = '[Auth] SignIn';
  constructor(public payload: User) {}
}
export class LoadUser {
  static readonly type = '[Auth] Load User';
  constructor(public payload: User) {}
}

export class UpdateUser {
  static readonly type = '[Auth] Update User';
  constructor(public payload: Partial<User>) {}
}

export class Logout {
  static readonly type = '[Auth] Logout';
}
