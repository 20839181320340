export enum SavedViewsViewType {
  AlarmHistory = 'ALARM_HISTORY_REPORT',
  WorkHistoryReport = 'WORK_HISTORY_REPORT',
  Kpi = 'KPI',
  Asset = 'ASSET',
  ProblematicEquipment = 'PROBLEMATIC_EQUIPMENT',
  SpaceHistoricalFailure = 'SPACE_HISTORICAL_FAILURE',
  EquipmentHistoricalFailure = 'EQUIPMENT_HISTORICAL_FAILURE',
  Nuisance = 'NUISANCE',
  CurrentStatus = 'CURRENT_STATUS',
  AlarmHistoricalDetail = 'ALARM_HISTORICAL_DETAIL',
  WorkHistory = 'WORK_HISTORY',
  WorkResolution = 'WORK_RESOLUTION',
  ActiveAlarms = 'ACTIVE_ALARMS',
}

export interface QuickFilters {
  [key: string]: boolean[] | string[] | { from: string; to: string };
}

export enum SavedViewOrderField {
  CreatedAt = 'CREATED_AT',
}

export enum SavedViewModes {
  Create = 'CREATE',
  Edit = 'EDIT',
}

export const DEFAULT_KPI_VIEW = {
  name: 'KPI Dashboard',
  isDefault: false,
  id: null,
  criteria: {},
  columns: [],
  viewType: SavedViewsViewType.Kpi,
};

export const ALARM_INSTANCE_REPORT_SAVED_VIEW_DEFAULT_VIEW = {
  name: 'Alarm Activity (Last 14 Days)',
  isDefault: false,
  id: null,
  viewType: SavedViewsViewType.AlarmHistory,
  columns: [],
};

export const WORK_TICKETS_REPORT_SAVED_VIEW_DEFAULT_VIEW = {
  name: 'Work Activity (Last 14 Days)',
  isDefault: false,
  id: null,
  viewType: SavedViewsViewType.WorkHistoryReport,
  columns: [],
};

export const BAD_ACTORS_REPORT_SAVED_VIEW_DEFAULT_VIEW = {
  name: 'Bad Actors Report',
  isDefault: false,
  id: null,
  viewType: SavedViewsViewType.Nuisance,
  columns: [],
};

export const CURRENT_STATUS_REPORT_SAVED_VIEW_DEFAULT_VIEW = {
  name: 'Current Status Report',
  isDefault: false,
  id: null,
  viewType: SavedViewsViewType.CurrentStatus,
  columns: [],
};

export const EQUIPMENT_FAILURE_REPORT_SAVED_VIEW_DEFAULT_VIEW = {
  name: 'Equipment - Historical Failure Summary',
  isDefault: false,
  id: null,
  viewType: SavedViewsViewType.EquipmentHistoricalFailure,
  columns: [],
};

export const SPACE_IMPACT_REPORT_SAVED_VIEW_DEFAULT_VIEW = {
  name: 'Space - Historical Impact Summary',
  isDefault: false,
  id: null,
  viewType: SavedViewsViewType.SpaceHistoricalFailure,
  columns: [],
};

export const ALARM_HISTORICAL_DETAIL_DEFAULT_VIEW = {
  name: 'Alarm Historical Detail',
  isDefault: false,
  id: null,
  viewType: SavedViewsViewType.AlarmHistoricalDetail,
  columns: [],
};

export const WORK_HISTORY_DEFAULT_VIEW = {
  name: 'Work History',
  isDefault: false,
  id: null,
  viewType: SavedViewsViewType.WorkHistory,
  columns: [],
};

export const ACTIVE_ALARMS_DEFAULT_VIEW = {
  name: 'Active Alarms',
  isDefault: false,
  id: null,
  viewType: SavedViewsViewType.WorkHistory,
  columns: [],
};
