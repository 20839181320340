import { gql } from 'apollo-angular';

export const GET_KPI_DASHBOARD_WORK_COVERAGE_ALARM_DRILLDOWN_QUERY = gql`
  query kpiDashboardWorkCoverageAlarmDrilldown($input: KpiDashboardWhere) {
    kpiDashboardWorkCoverageAlarmDrilldown(input: $input) {
      priority
      workTicketCount
      alarmInstanceCount
      alarmInstanceCoveredByUserCount
      alarmInstanceCoveredByAutomationCount
      coveredAlarmInstanceCount
    }
  }
`;
export const GET_KPI_DASHBOARD_DUPLICATE_WORK_DRILLDOWN_QUERY = gql`
  query kpiDashboardDuplicateWorkDrilldown($input: KpiDashboardWhere) {
    kpiDashboardDuplicateWorkDrilldown(input: $input) {
      teamId
      teamName
      workTicketCount
      duplicateWorkTicketCount
      overlappingAlarmCount
    }
  }
`;

export const GET_KPI_DASHBOARD_AWARENESS_METRIC_ALARM_DRILLDOWN_QUERY = gql`
  query kpiDashboardAwarenessMetricAlarmDrilldown($input: KpiDashboardWhere) {
    kpiDashboardAwarenessMetricAlarmDrilldown(input: $input) {
      priority
      alarmInstanceCount
      dispatcherCount
    }
  }
`;

export const GET_KPI_DASHBOARD_OPEN_WORK_INACTIVE_ALARM_DRILLDOWN_QUERY = gql`
  query kpiDashboardOpenWorkInactiveAlarmDrilldown($input: KpiDashboardWhere) {
    kpiDashboardOpenWorkInactiveAlarmDrilldown(input: $input) {
      teamId
      teamName
      workTicketCount
      openWorkTicketCount
      alarmInactiveTimeMedian
    }
  }
`;

export const GET_KPI_DASHBOARD_TIME_TO_DISPATCH_TEAM_DRILLDOWN_QUERY = gql`
  query kpiDashboardWorkTimeToDispatchTeamDrilldown($input: KpiDashboardWhere) {
    kpiDashboardWorkTimeToDispatchTeamDrilldown(input: $input) {
      teamId
      teamName
      workTicketCount
      averageTimeToDispatch
      maxTimeToDispatch
      previousAvgTimeToDispatch
    }
  }
`;

export const GET_KPI_DASHBOARD_TIME_TO_DISPATCH_CREATOR_DRILLDOWN_QUERY = gql`
  query kpiDashboardWorkTimeToDispatchCreatorDrilldown(
    $teamId: String!
    $input: KpiDashboardWhere
  ) {
    kpiDashboardWorkTimeToDispatchCreatorDrilldown(
      teamId: $teamId
      input: $input
    ) {
      userId
      userDisplayName
      workTicketCount
      avgTimeToDispatch
      longestTimeToDispatch
      previousAvgTimeToDispatch
    }
  }
`;

export const GET_KPI_DASHBOARD_METRICS_QUERY = gql`
  query kpi($metricType: KpiDashboardMetricType!, $input: KpiDashboardWhere!) {
    kpi(metricType: $metricType, input: $input) {
      metricType
      currentPeriod {
        numerator
        denominator
      }
      previousPeriod {
        numerator
        denominator
      }
      metricConfig {
        goal
        format
        scale
        warning
      }
      note {
        metricType
        notes
      }
    }
  }
`;

export const GET_KPI_DASHBOARD_METRIC_CONFIG_QUERY = gql`
  query KpiDashboardMetricConfig {
    kpiDashboardMetricConfigs {
      id
      metricType
      metricCategory
      format
      scale
      warning
      goal
    }
  }
`;

export const UPDATE_KPI_DASHBOARD_METRIC_CONFIG_MUTATION = gql`
  mutation updateKpiDashboardMetricConfigs(
    $updates: [KpiDashboardMetricConfigInput!]!
  ) {
    updateKpiDashboardMetricConfigs(updates: $updates) {
      id
      metricType
      metricCategory
      format
      scale
      warning
      goal
    }
  }
`;

export const UPDATE_KPI_CONFIG_QUERY = gql`
  mutation updateKpiDashboardConfig($input: KpiDashboardConfigInput!) {
    updateKpiDashboardConfig(input: $input) {
      isEnabled
    }
  }
`;

export const FETCH_KPI_CONFIG_QUERY = `
  query kpiDashboardConfig {
    kpiDashboardConfig {
      isEnabled
    }
  }
`;

export const UPDATE_KPI_DASHBOARD_NOTES_MUTATION = gql`
  mutation updateKpiDashboardNote(
    $metricType: KpiDashboardMetricType!
    $note: String!
  ) {
    updateKpiDashboardNote(metricType: $metricType, note: $note) {
      notes
      metricType
    }
  }
`;
