import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { filter, map, Observable } from 'rxjs';
import { OrderDirection, SpaceTypeQuery } from '@vfi-ui/models';
import { GET_SPACE_TYPE_QUERY } from '../queries/space-management.query';

@Injectable({
  providedIn: 'root',
})
export class SpaceManagementDataService {
  constructor(private apollo: Apollo) {}

  /**
   * fetch space types
   *
   * @param {number} [offset=0]
   * @param {string} [nameLike='']
   * @returns {Observable<SpaceTypeQuery>}
   * @memberof SpaceManagementDataService
   */
  fetchSpaceTypes(offset = 0, nameLike = ''): Observable<SpaceTypeQuery> {
    return this.apollo
      .query<SpaceTypeQuery>({
        fetchPolicy: 'no-cache',
        query: GET_SPACE_TYPE_QUERY,
        variables: {
          options: {
            limit: 50,
            offset,
            order: { field: 'NAME', direction: OrderDirection.ASC },
            where: { nameLike: `%${nameLike}%` },
          },
        },
      })
      .pipe(
        filter((d) => !!d),
        map((res) => res?.data)
      );
  }
}
