import {
  WorkTicket,
  WorkTicketStatus,
  tabRoutes,
  WorkTicketProcessState,
} from '@vfi-ui/models';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { SetFirstAlarmTile } from '@vfi-ui/state';

@Injectable({
  providedIn: 'root',
})
export class LinkAlarmsService {
  constructor(
    private router: Router,
    private store: Store
  ) {}

  /**
   * link to alarm in all alarms by id
   *
   * @param {number} id
   * @memberof LinkAlarmsService
   */
  linkToAlarm(id: number) {
    this.router.navigate([tabRoutes.ALL_ALARM, id]);
    this.store.dispatch(new SetFirstAlarmTile(id));
  }

  /**
   * link to work ticket
   *
   * @param {Partial<WorkTicket>} data
   * @memberof LinkAlarmsService
   */
  linkToWorkTicket(data: Partial<WorkTicket>) {
    // TODO: Refactor to remove work status and states
    if (data.state === WorkTicketProcessState.Closed) {
      this.router.navigate([tabRoutes.ALL_WORK_LIST, data.id]);
    } else if (data.status === WorkTicketStatus.Open) {
      this.router.navigate([tabRoutes.ACTIVE_WORK_LIST, data.id]);
    }
  }
}
