/* eslint-disable @typescript-eslint/naming-convention */
import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import {
  ERROR_GET_WORK_TICKETS,
  WorkResolutionDashboardOptions,
  WorkResolutionDurationQuery,
  WorkResolutionGroupBy,
  WorkResolutionTrendQuery,
} from '@vfi-ui/models';
import { result } from '@vfi-ui/util/helpers';
import { map, filter, catchError } from 'rxjs/operators';
import { NotificationService } from './notification.service';
import { throwError } from 'rxjs';
import {
  GET_WORK_RESOLUTION_CLOSED_WORK_QUERY,
  GET_WORK_RESOLUTION_REPEAT_WORK_QUERY,
  GET_WORK_RESOLUTION_TREND_QUERY,
  GET_WORK_RESOLUTION_GROUP_BY_TREND_QUERY,
} from '../queries/work-resolution.query';

@Injectable({
  providedIn: 'root',
})
export class WorkResolutionDataService {
  constructor(
    private apollo: Apollo,
    private notification: NotificationService
  ) {}

  /**
   * call api to fetch data for work resolution trend widget
   *
   * @param {WorkResolutionDashboardOptions} input
   * @returns
   * @memberof WorkResolutionDataService
   */
  getWorkResolutionTrendData(input: WorkResolutionDashboardOptions) {
    return this.apollo
      .query<{ workResolutionWorkTicketTrendCounts: WorkResolutionTrendQuery }>(
        {
          fetchPolicy: 'no-cache',
          query: GET_WORK_RESOLUTION_TREND_QUERY,
          variables: { input },
        }
      )
      .pipe(
        filter((d) => !!d),
        map((res) => result(res, 'data.workResolutionWorkTicketTrendCounts')),
        catchError((error) => {
          this.notification.showError(ERROR_GET_WORK_TICKETS);
          return throwError(error);
        })
      );
  }

  /**
   * Gets the group by data
   *
   * @param {WorkResolutionDashboardOptions} input
   * @returns
   * @memberof WorkResolutionDataService
   */
  getWorkResolutionGroupByData({
    input,
    groupBy,
    groupByAlarmAttribute,
  }: {
    input: WorkResolutionDashboardOptions;
    groupBy: WorkResolutionGroupBy;
    groupByAlarmAttribute?: string;
  }) {
    return this.apollo
      .query<{
        workResolutionWorkTicketTrendChartGroupBy: WorkResolutionDurationQuery[];
      }>({
        fetchPolicy: 'no-cache',
        query: GET_WORK_RESOLUTION_GROUP_BY_TREND_QUERY,
        variables: { input, groupBy, groupByAlarmAttribute },
      })
      .pipe(
        filter((d) => !!d),
        map((res) =>
          result(res, 'data.workResolutionWorkTicketTrendChartGroupBy')
        ),
        catchError((error) => {
          this.notification.showError(ERROR_GET_WORK_TICKETS);
          return throwError(error);
        })
      );
  }

  /**
   * call api to fetch data for work resolution closed work widget
   *
   * @param {WorkResolutionDashboardOptions} input
   * @param {WorkResolutionDashboardOptions} previousInput
   * @returns
   * @memberof WorkResolutionDataService
   */
  getWorkResolutionClosedData(
    input: WorkResolutionDashboardOptions,
    previousInput: WorkResolutionDashboardOptions
  ) {
    const resolvedInput = {
      ...input,
      workTicketInput: { ...input.workTicketInput, isResolved: true },
    };
    const previousResolvedInput = {
      ...previousInput,
      workTicketInput: { ...previousInput.workTicketInput, isResolved: true },
    };
    const unresolvedInput = {
      ...input,
      workTicketInput: { ...input.workTicketInput, isResolved: false },
    };
    const previousUnresolvedInput = {
      ...previousInput,
      workTicketInput: { ...previousInput.workTicketInput, isResolved: false },
    };
    return this.apollo
      .query<{ WorkResolutionClosedWork }>({
        fetchPolicy: 'no-cache',
        query: GET_WORK_RESOLUTION_CLOSED_WORK_QUERY,
        variables: {
          resolvedInput,
          previousResolvedInput,
          unresolvedInput,
          previousUnresolvedInput,
        },
      })
      .pipe(
        filter((d) => !!d),
        map((res) => result(res, 'data')),
        catchError((error) => {
          this.notification.showError(ERROR_GET_WORK_TICKETS);
          return throwError(error);
        })
      );
  }

  /**
   * call api to fetch data for repeat work widget
   *
   * @param {WorkResolutionDashboardOptions} input
   * @param {WorkResolutionDashboardOptions} previousInput
   * @returns
   * @memberof WorkResolutionDataService
   */
  getWorkResolutionRepeatData(
    input: WorkResolutionDashboardOptions,
    previousInput: WorkResolutionDashboardOptions
  ) {
    const repeatInput = {
      ...input,
      workTicketInput: { ...input.workTicketInput, isRepeat: true },
    };
    const previousRepeatInput = {
      ...previousInput,
      workTicketInput: { ...previousInput.workTicketInput, isRepeat: true },
    };
    const totalInput = {
      ...input,
      workTicketInput: { ...input.workTicketInput },
    };
    const previousTotalInput = {
      ...previousInput,
      workTicketInput: { ...previousInput.workTicketInput },
    };
    return this.apollo
      .query<{ WorkResolutionClosedWork }>({
        fetchPolicy: 'no-cache',
        query: GET_WORK_RESOLUTION_REPEAT_WORK_QUERY,
        variables: {
          repeatInput,
          previousRepeatInput,
          totalInput,
          previousTotalInput,
        },
      })
      .pipe(
        filter((d) => !!d),
        map((res) => result(res, 'data')),
        catchError((error) => {
          this.notification.showError(ERROR_GET_WORK_TICKETS);
          return throwError(error);
        })
      );
  }
}
