import {
  ALARM_INSTANCE_REPORT_WORK_COVERAGE_FILTERS,
  ALARM_INSTANCE_REPORT_INSTANCE_PER_HOUR_FILTERS,
  ALARM_INSTANCE_REPORT_INSTANCE_PER_HOUR_TABLE_FILTERS,
} from './alarm-instance-report.constants';
import { AlarmPriorityValues } from './alarms.constant';
import {
  WORK_TICKETS_REPORT_DUPLICATE_WORK_BY_TEAMS_FILTERS,
  WORK_TICKETS_REPORT_DUPLICATE_WORK_BY_USER_FILTERS,
  WORK_TICKETS_REPORT_DUPLICATE_WORK_PER_TEAM_FILTERS,
  WORK_TICKETS_REPORT_INACTIVE_ALARMS_BY_ASSIGNEE_FILTERS,
  WORK_TICKETS_REPORT_INACTIVE_ALARMS_BY_TEAMS_FILTERS,
  WORK_TICKETS_REPORT_INACTIVE_ALARMS_PER_TEAM_FILTERS,
  WORK_TICKETS_REPORT_TIME_TO_DISPATCH_BY_TEAMS_FILTERS,
  WORK_TICKETS_REPORT_TIME_TO_DISPATCH_BY_USERS_FILTERS,
  WORK_TICKETS_TABLE_REPORT_TIME_TO_DISPATCH_BY_USERS_FILTERS,
} from './work-resolution.constants';
import { OrderDirection } from './work-ticket.constant';

export enum KpiDashboardMetricType {
  EngagedUsers = 'ENGAGED_USERS',
  RegisteredUsers = 'REGISTERED_USERS',
  PrioritizedAlarms = 'PRIORITIZED_ALARMS',
  AlarmsPerHour = 'CRITICAL_ALARMS_PER_HOUR_PER_ACTIVE_DISPATCHER',
  ActionedCriticalAlarms = 'ACTIONED_CRITICAL_ALARMS',
  CriticalAlarmDistribution = 'CRITICAL_ALARM_DISTRIBUTION',
  WorkCoverageCriticalAlarms = 'WORK_COVERAGE_CRITICAL_ALARMS',
  TimeToDispatch = 'TIME_TO_DISPATCH_CRITICAL_ALARMS',
  TimeToResolutionCriticalAlarms = 'TIME_TO_RESOLUTION_CRITICAL_ALARMS',
  ResolutionEffectiveness = 'RESOLUTION_EFFECTIVENESS',
  RepeatWork = 'REPEAT_WORK',
  OpenResolvedWork = 'OPEN_RESOLVED_WORK',
  OpenWorkInactiveAlarms = 'OPEN_WORK_INACTIVE_ALARMS',
  DuplicateWork = 'DUPLICATE_WORK',
}

export const KPI_DASHBOARD_BLACKLISTED_FILTERS = [
  'Active',
  'Pinned',
  'Alarm Priority',
  'Alarm Source',
  'BAS Priority',
  'Keyword',
  'Nuisance Behavior',
  'Active Work',
  'Equipment',
  'State',
  'Status',
  'Equipment Type',
  'Floor',
  'Room',
  'Room Name',
  'Room Number',
  'Time in Alarm',
  'Instrument Type',
];

export const KPI_DASHBOARD_WORK_TICKET_DRILLDOWN_BLACKLISTED_FILTERS = [
  'alarmIds',
  'isCovered',
  'coveredSource',
  'externalSystemId',
];

export const KPI_DASHBOARD_PRIORITY_OPTIONS = [
  {
    label: 'Highest',
    value: AlarmPriorityValues.Highest,
  },
  {
    label: 'High',
    value: AlarmPriorityValues.High,
  },
  {
    label: 'Medium',
    value: AlarmPriorityValues.Medium,
  },
  {
    label: 'Low',
    value: AlarmPriorityValues.Low,
  },
  {
    label: 'Lowest',
    value: AlarmPriorityValues.Lowest,
  },
  {
    label: 'No Priority',
    value: AlarmPriorityValues['No priority'],
  },
];

export const KPI_DASHBOARD_ATTRIBUTE_PROPS = [
  'building',
  'floor',
  'space_type',
  'room_number',
  'room_name',
  'flow_direction',
  'parameter_prefix',
  'parameter',
  'condition',
  'equipment',
  'equipment_type',
  'medium',
  'instrument_type',
  'category',
  'shop',
];

export const KPI_DASHBOARD_DATE_OPTIONS = [
  {
    value: 'LAST_7_DAYS',
    label: '7D',
  },
  {
    value: 'LAST_30_DAYS',
    label: '30D',
  },
];

export enum KpiDashboardMetricCategory {
  Adoption = 'ADOPTION',
  Awareness = 'AWARENESS',
  Work = 'WORK',
  Accountability = 'ACCOUNTABILITY',
}

export const KpiDashboardDays = {
  LAST_7_DAYS: 7,
  LAST_30_DAYS: 30,
};

export const KpiPriorityNames = {
  5: 'Highest',
  4: 'High',
  3: 'Medium',
  2: 'Low',
  1: 'Lowest',
  0: 'No Priority',
  '': 'All Alarms',
};

export enum KpiWorkCoverageOrderField {
  Priority = 'priority',
  CoveredAlarmInstanceCount = 'coveredAlarmInstanceCount',
  NotCoveredAlarmInstanceCount = 'notCoveredAlarmInstanceCount',
  AlarmInstanceCoveredByUserCount = 'alarmInstanceCoveredByUserCount',
  AlarmInstanceCoveredByAutomationCount = 'alarmInstanceCoveredByAutomationCount',
  WorkTicketCount = 'workTicketCount',
}

export enum KpiCriticalAlarmsOrderField {
  Priority = 'priority',
  AlarmInstanceCount = 'alarmInstanceCount',
  AverageAlarmInstancePerHour = 'averageAlarmInstancePerHour',
}

export enum KpiOpenWorkInactiveAlarmsOrderField {
  TeamName = 'teamName',
  OpenWorkCount = 'openWorkCount',
  WorkTicketCount = 'workTicketCount',
  OpenWorkTicketCount = 'openWorkTicketCount',
  AlarmInactiveTimeMedian = 'alarmInactiveTimeMedian',
}

export enum KpiDuplicateWorkOrderField {
  TeamName = 'teamName',
  WorkTicketCount = 'workTicketCount',
  DuplicateWorkTicketCount = 'duplicateWorkTicketCount',
  DuplicateWorkTicketPercent = 'duplicateWorkTicketPercent',
  OverlappingAlarmCount = 'overlappingAlarmCount',
}

export enum KpiTimeToDispatchOrderField {
  TeamName = 'teamName',
  AverageTimeToDispatch = 'averageTimeToDispatch',
  PreviousAvgTimeToDispatch = 'previousAvgTimeToDispatch',
  MaxTimeToDispatch = 'maxTimeToDispatch',
  WorkTicketCount = 'workTicketCount',
}

export enum KpiTimeToDispatchCretorOrderField {
  UserDisplayName = 'userDisplayName',
  AverageTimeToDispatch = 'avgTimeToDispatch',
  PreviousAvgTimeToDispatch = 'previousAvgTimeToDispatch',
  LongestTimeToDispatch = 'longestTimeToDispatch',
  WorkTicketCount = 'workTicketCount',
}

export const KPI_WORK_COVERAGE_DRILLDOWN_SORT_VALUE = {
  priority: KpiWorkCoverageOrderField.Priority,
  coveredAlarmInstanceCount:
    KpiWorkCoverageOrderField.CoveredAlarmInstanceCount,
  notCoveredAlarmInstanceCount:
    KpiWorkCoverageOrderField.NotCoveredAlarmInstanceCount,
  alarmInstanceCoveredByUserCount:
    KpiWorkCoverageOrderField.AlarmInstanceCoveredByUserCount,
  alarmInstanceCoveredByAutomationCount:
    KpiWorkCoverageOrderField.AlarmInstanceCoveredByAutomationCount,
  workTicketCount: KpiWorkCoverageOrderField.WorkTicketCount,
};

export const KPI_ALARMS_PER_HOUR_DRILLDOWN_SORT_VALUE = {
  priority: KpiCriticalAlarmsOrderField.Priority,
  alarmInstanceCount: KpiCriticalAlarmsOrderField.AlarmInstanceCount,
  averageAlarmInstancePerHour:
    KpiCriticalAlarmsOrderField.AverageAlarmInstancePerHour,
};

export const OPEN_WORK_INACTIVE_ALARMS_DRILLDOWN_SORT_VALUE = {
  teamName: KpiOpenWorkInactiveAlarmsOrderField.TeamName,
  openWorkCount: KpiOpenWorkInactiveAlarmsOrderField.OpenWorkCount,
  workTicketCount: KpiOpenWorkInactiveAlarmsOrderField.WorkTicketCount,
  openWorkTicketCount: KpiOpenWorkInactiveAlarmsOrderField.OpenWorkTicketCount,
  alarmInactiveTimeMedian:
    KpiOpenWorkInactiveAlarmsOrderField.AlarmInactiveTimeMedian,
};

export const KPI_TIME_TO_DISPATCH_SORT_VALUE = {
  teamName: KpiTimeToDispatchOrderField.TeamName,
  averageTimeToDispatch: KpiTimeToDispatchOrderField.AverageTimeToDispatch,
  previousAvgTimeToDispatch:
    KpiTimeToDispatchOrderField.PreviousAvgTimeToDispatch,
  maxTimeToDispatch: KpiTimeToDispatchOrderField.MaxTimeToDispatch,
  workTicketCount: KpiTimeToDispatchOrderField.WorkTicketCount,
};

export const KPI_DUPLICATE_WORK_SORT_VALUE = {
  teamName: KpiDuplicateWorkOrderField.TeamName,
  workTicketCount: KpiDuplicateWorkOrderField.WorkTicketCount,
  duplicateWorkTicketCount: KpiDuplicateWorkOrderField.DuplicateWorkTicketCount,
  duplicateWorkTicketPercent:
    KpiDuplicateWorkOrderField.DuplicateWorkTicketPercent,
  overlappingAlarmCount: KpiDuplicateWorkOrderField.OverlappingAlarmCount,
};

export const KPI_TIME_TO_DISPATCH_CREATOR_SORT_VALUE = {
  userDisplayName: KpiTimeToDispatchCretorOrderField.UserDisplayName,
  avgTimeToDispatch: KpiTimeToDispatchCretorOrderField.AverageTimeToDispatch,
  previousAvgTimeToDispatch:
    KpiTimeToDispatchCretorOrderField.PreviousAvgTimeToDispatch,
  longestTimeToDispatch:
    KpiTimeToDispatchCretorOrderField.LongestTimeToDispatch,
  workTicketCount: KpiTimeToDispatchCretorOrderField.WorkTicketCount,
};

export const KPI_WORK_COVERAGE_DRILLDOWN_PRIORITY_SORT = {
  field: KpiWorkCoverageOrderField.Priority,
  direction: OrderDirection.ASC,
};

export const KPI_ALARMS_PER_HOUR_DRILLDOWN_PRIORITY_SORT = {
  field: KpiCriticalAlarmsOrderField.Priority,
  direction: OrderDirection.ASC,
};

export const OPEN_WORK_INACTIVE_ALARMS_DRILLDOWN_TEAM_NAME_SORT = {
  field: KpiOpenWorkInactiveAlarmsOrderField.TeamName,
  direction: OrderDirection.ASC,
};

export const DUPLICATE_WORK_DRILLDOWN_TEAM_NAME_SORT = {
  field: KpiDuplicateWorkOrderField.TeamName,
  direction: OrderDirection.ASC,
};

export const KPI_TIME_TO_DISPATCH_TEAM_NAME_SORT = {
  field: KpiTimeToDispatchOrderField.TeamName,
  direction: OrderDirection.ASC,
};

export const KPI_TIME_TO_DISPATCH_CREATOR_NAME_SORT = {
  field: KpiTimeToDispatchCretorOrderField.UserDisplayName,
  direction: OrderDirection.ASC,
};

export const KpiMetricInformation = {
  [KpiDashboardMetricType.WorkCoverageCriticalAlarms]: {
    title: 'Work Coverage of Alarms',
    subtitle: 'Percentage of alarms covered by work',
    tooltipText:
      ' An alarm is considered “covered” if a work ticket is created directly from it (either manually or via automation) or if there is an existing work ticket open for that same unique alarm during the time that it occurs.',
    guidedLinkText: [
      'View a list of alarms that were not covered by work, grouped by priority',
    ],
    guidedFilters: [ALARM_INSTANCE_REPORT_WORK_COVERAGE_FILTERS],
    tableFilters: ALARM_INSTANCE_REPORT_WORK_COVERAGE_FILTERS,
    reportLink: 'alarm-history',
    storageName: 'alarm-history-filters',
    category: 'Work',
    sortValue: KPI_WORK_COVERAGE_DRILLDOWN_SORT_VALUE,
    sort: KPI_WORK_COVERAGE_DRILLDOWN_PRIORITY_SORT,
    drilldownUrl: '/analytics/kpi-dashboard/workcoverageofalarms',
    columns: [
      {
        name: 'Priority',
        prop: 'priority',
        flexGrow: 2,
        cellClass: 'regular-14 ipl-1',
        minWidth: 128,
        width: 200,
      },
      {
        name: 'Covered',
        prop: 'coveredAlarmInstanceCount',
        flexGrow: 2,
        cellClass: 'regular-14',
        minWidth: 128,
        width: 200,
        height: 'auto',
      },
      {
        name: 'Not Covered',
        prop: 'notCoveredAlarmInstanceCount',
        flexGrow: 2,
        cellClass: 'regular-14 ipl-1',
        minWidth: 128,
        width: 200,
      },
      {
        name: 'Alarm Count',
        prop: 'alarmInstanceCount',
        flexGrow: 2,
        cellClass: 'regular-14 ipl-1',
        minWidth: 128,
        width: 200,
      },
      {
        name: 'Covered by User',
        prop: 'alarmInstanceCoveredByUserCount',
        flexGrow: 2,
        cellClass: 'regular-14 ipl-1',
        minWidth: 128,
        width: 200,
      },
      {
        name: 'Covered by Automation',
        prop: 'alarmInstanceCoveredByAutomationCount',
        flexGrow: 2,
        cellClass: 'regular-14 ipl-1',
        minWidth: 128,
        width: 200,
      },
      {
        name: 'Work Tickets (Qty)',
        prop: 'workTicketCount',
        flexGrow: 2,
        cellClass: 'regular-14 ipl-1',
        minWidth: 128,
        width: 200,
      },
    ],
  },
  [KpiDashboardMetricType.TimeToDispatch]: {
    title: 'Time to Dispatch',
    subtitle: 'Time between an alarm occurring and the creation of work.',
    tooltipText:
      'Duration of time between a) start time of the oldest active alarm included in the initial work and b) the creation time of the initial work ticket.',
    tooltipInnerHTML: `<p>Duration of time between</p>
      <p>a) start time of the oldest active alarm included in the initial work.</p>
       <p>b) the creation time of the initial work ticket.</p> <a class="underline" href="https://help.vfacility.com/hc/en-us/articles/17775432776340-KPI-Time-to-Dispatch" target="_blank">Learn more</a>`,
    guidedLinkText: [
      'List of Work Tickets with a time to dispatch greater than 2-hours, grouped by the Team',
    ],
    secondaryGuidedLinkText: [
      'List of Work Tickets with a time to dispatch greater than 2-hours, grouped by the Creator',
    ],
    guidedFilters: [WORK_TICKETS_REPORT_TIME_TO_DISPATCH_BY_TEAMS_FILTERS],
    secondaryGuidedFilters: [
      WORK_TICKETS_REPORT_TIME_TO_DISPATCH_BY_USERS_FILTERS,
    ],
    tableFilters: WORK_TICKETS_TABLE_REPORT_TIME_TO_DISPATCH_BY_USERS_FILTERS,
    reportLink: 'work-history',
    storageName: 'work-history-report-filters',
    category: 'Work',
    sortValue: KPI_TIME_TO_DISPATCH_SORT_VALUE,
    sort: KPI_TIME_TO_DISPATCH_TEAM_NAME_SORT,
    drilldownUrl: '/analytics/kpi-dashboard/timetodispatch',
    columns: [
      {
        name: 'Team',
        prop: 'teamName',
        flexGrow: 2,
        cellClass: 'regular-14 ipl-1',
        minWidth: 128,
        width: 200,
      },
      {
        name: 'Time to Dispatch (AVG)',
        prop: 'averageTimeToDispatch',
        flexGrow: 2,
        cellClass: 'regular-14 ipl-1',
        minWidth: 128,
        width: 200,
        height: 'auto',
      },
      {
        name: 'Prior Period % Change',
        prop: 'previousAvgTimeToDispatch',
        flexGrow: 2,
        cellClass: 'regular-14 ipl-1',
        minWidth: 128,
        width: 200,
        height: 'auto',
      },
      {
        name: 'Longest Time to Dispatch',
        prop: 'maxTimeToDispatch',
        flexGrow: 2,
        cellClass: 'regular-14 ipl-1',
        minWidth: 128,
        width: 200,
      },
      {
        name: 'Number of Work Tickets',
        prop: 'workTicketCount',
        flexGrow: 2,
        cellClass: 'regular-14 ipl-1',
        minWidth: 128,
        width: 200,
      },
    ],
    userDrilldownColumns: [
      {
        name: 'User',
        prop: 'userDisplayName',
        flexGrow: 2,
        cellClass: 'regular-14 ipl-1',
        minWidth: 128,
        width: 200,
      },
      {
        name: 'Time to Dispatch (AVG)',
        prop: 'avgTimeToDispatch',
        flexGrow: 2,
        cellClass: 'regular-14 ipl-1',
        minWidth: 128,
        width: 200,
        height: 'auto',
      },
      {
        name: 'Prior Period % Change',
        prop: 'previousAvgTimeToDispatch',
        flexGrow: 2,
        cellClass: 'regular-14 ipl-1',
        minWidth: 128,
        width: 200,
        height: 'auto',
      },
      {
        name: 'Longest Time to Dispatch',
        prop: 'longestTimeToDispatch',
        flexGrow: 2,
        cellClass: 'regular-14 ipl-1',
        minWidth: 128,
        width: 200,
      },
      {
        name: 'Number of Work Tickets',
        prop: 'workTicketCount',
        flexGrow: 2,
        cellClass: 'regular-14 ipl-1',
        minWidth: 128,
        width: 200,
      },
    ],
  },
  [KpiDashboardMetricType.DuplicateWork]: {
    title: 'Duplicate Work',
    subtitle:
      'At time of ticket creation, percent of created work tickets that contain the same alarm as another open work ticket',
    tooltipText:
      'Duplicate work is calculated at work ticket creation. Generally, alarms should have a relationship with a single work ticket. Multiple work tickets with the same alarm can lead to multiple technicians working on the same alarm.',
    guidedLinkText: [
      'View teams ranked by the number of duplicate work tickets created',
      'View users ranked by the number of duplicate work tickets created',
    ],
    guidedFilters: [
      WORK_TICKETS_REPORT_DUPLICATE_WORK_BY_TEAMS_FILTERS,
      WORK_TICKETS_REPORT_DUPLICATE_WORK_BY_USER_FILTERS,
    ],
    tableFilters: WORK_TICKETS_REPORT_DUPLICATE_WORK_PER_TEAM_FILTERS,
    reportLink: 'work-history',
    storageName: 'work-history-report-filters',
    category: 'Work',
    sortValue: KPI_DUPLICATE_WORK_SORT_VALUE,
    sort: DUPLICATE_WORK_DRILLDOWN_TEAM_NAME_SORT,
    drilldownUrl: '/analytics/kpi-dashboard/duplicatework',
    columns: [
      {
        name: 'Team',
        prop: 'teamName',
        flexGrow: 2,
        cellClass: 'regular-14 ipl-1',
        minWidth: 128,
        width: 200,
      },
      {
        name: 'Duplicate work tickets created ( % ) ',
        prop: 'duplicateWorkTicketPercent',
        flexGrow: 2,
        cellClass: 'regular-14',
        minWidth: 128,
        width: 200,
        height: 'auto',
      },
      {
        name: 'Duplicate work tickets created',
        prop: 'duplicateWorkTicketCount',
        flexGrow: 2,
        cellClass: 'regular-14 ipl-1',
        minWidth: 128,
        width: 200,
        height: 'auto',
      },
      {
        name: 'Overlapping Alarm Count',
        prop: 'overlappingAlarmCount',
        flexGrow: 2,
        cellClass: 'regular-14 ipl-1',
        minWidth: 128,
        width: 200,
      },
      {
        name: 'Total Work Tickets Created',
        prop: 'workTicketCount',
        flexGrow: 2,
        cellClass: 'regular-14 ipl-1',
        minWidth: 128,
        width: 200,
      },
    ],
  },
  [KpiDashboardMetricType.AlarmsPerHour]: {
    title: 'Alarms per Hour',
    subtitle: 'Number of alarms per hour',
    tooltipText:
      "A facility's overall alarm response can be better managed by understanding alarm volume. This widget displays the median number of alarms per hour.",
    guidedLinkText: [
      'View nuisance alarms by most active (will not display data if nuisance filter is active)',
    ],
    reportLink: 'alarm-history',
    storageName: 'alarm-history-filters',
    guidedFilters: [ALARM_INSTANCE_REPORT_INSTANCE_PER_HOUR_FILTERS],
    tableFilters: ALARM_INSTANCE_REPORT_INSTANCE_PER_HOUR_TABLE_FILTERS,
    category: 'Awareness',
    sortValue: KPI_ALARMS_PER_HOUR_DRILLDOWN_SORT_VALUE,
    sort: KPI_ALARMS_PER_HOUR_DRILLDOWN_PRIORITY_SORT,
    drilldownUrl: '/analytics/kpi-dashboard/alarmsperhour',
    columns: [
      {
        name: 'Priority',
        prop: 'priority',
        flexGrow: 2,
        cellClass: 'regular-14 ipl-1',
        minWidth: 128,
        width: 200,
      },
      {
        name: 'Avg Alarms per Hour',
        prop: 'averageAlarmInstancePerHour',
        flexGrow: 2,
        cellClass: 'regular-14 ipl-1',
        minWidth: 128,
        width: 200,
      },
      {
        name: 'Total Alarms',
        prop: 'alarmInstanceCount',
        flexGrow: 2,
        cellClass: 'regular-14 ipl-1',
        minWidth: 128,
        width: 200,
      },
      {
        name: 'Alarm Distribution',
        prop: 'alarmInstanceDistribution',
        flexGrow: 2,
        cellClass: 'regular-14 ipl-1',
        minWidth: 128,
        width: 200,
      },
    ],
  },
  [KpiDashboardMetricType.OpenWorkInactiveAlarms]: {
    title: 'Open Work with Inactive Alarms',
    subtitle: 'Percent of open work tickets with only inactive alarms',
    tooltipText:
      'An open work ticket with all inactive alarms can lead to technicians having to investigate issues that have already been resolved. Once all alarms are resolved, the work ticket should be closed.',
    guidedLinkText: [
      'View teams ranked by number of work tickets with inactive alarms',
      'View users ranked by number of work tickets with inactive alarms',
    ],
    guidedFilters: [
      WORK_TICKETS_REPORT_INACTIVE_ALARMS_BY_TEAMS_FILTERS,
      WORK_TICKETS_REPORT_INACTIVE_ALARMS_BY_ASSIGNEE_FILTERS,
    ],
    tableFilters: WORK_TICKETS_REPORT_INACTIVE_ALARMS_PER_TEAM_FILTERS,
    reportLink: 'work-history',
    storageName: 'work-history-report-filters',
    category: 'Accountability',
    sortValue: OPEN_WORK_INACTIVE_ALARMS_DRILLDOWN_SORT_VALUE,
    sort: OPEN_WORK_INACTIVE_ALARMS_DRILLDOWN_TEAM_NAME_SORT,
    drilldownUrl: '/analytics/kpi-dashboard/openworkwithinactivealarms',
    columns: [
      {
        name: 'Team',
        prop: 'teamName',
        flexGrow: 2,
        cellClass: 'regular-14 ipl-1',
        minWidth: 128,
        width: 200,
      },
      {
        name: 'Open Work with Inactive Alarms ( % )',
        prop: 'openWorkCount',
        flexGrow: 2,
        cellClass: 'regular-14',
        minWidth: 128,
        width: 200,
      },
      {
        name: 'Open work tickets with Inactive Alarms',
        prop: 'openWorkTicketCount',
        flexGrow: 2,
        cellClass: 'regular-14 ipl-1',
        minWidth: 128,
        width: 200,
      },
      {
        name: 'Alarm Inactivity Time (Median)',
        prop: 'alarmInactiveTimeMedian',
        flexGrow: 2,
        cellClass: 'regular-14 ipl-1',
        minWidth: 128,
        width: 200,
      },
      {
        name: 'Total Open Work Count',
        prop: 'workTicketCount',
        flexGrow: 2,
        cellClass: 'regular-14 ipl-1',
        minWidth: 128,
        width: 200,
      },
    ],
  },
};

export const ERROR_FETCH_KPI_METRICS = 'Failed to fetch metrics.';
