import { Pipe, PipeTransform } from '@angular/core';
import { isBefore, startOfDay } from 'date-fns';
import { DateTimeFormat } from '@vfi-ui/models';
import { Store } from '@ngxs/store';
import { formatInTimeZone } from 'date-fns-tz';
import { CustomersState } from '@vfi-ui/state';

@Pipe({
  name: 'dateTime24Hours',
  pure: true,
})
export class DateTime24HoursPipe implements PipeTransform {
  constructor(private readonly store: Store) {}
  transform(value: string): string {
    if (!value) {
      return 'N/A';
    }
    const timezone = this.store.selectSnapshot(
      CustomersState.getCurrentCustomerTimezone
    );
    const past = startOfDay(new Date());
    if (isBefore(past, new Date(value))) {
      return formatInTimeZone(
        new Date(value),
        timezone,
        DateTimeFormat.DATE_LES_THAN_24H
      );
    } else {
      return formatInTimeZone(
        new Date(value),
        timezone,
        DateTimeFormat.DATE_GRT_THAN_24H
      );
    }
  }
}
