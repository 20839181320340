import { SocketsService } from './services/sockets.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from './services/auth.service';
import {
  AlarmsDataService,
  WorkOrderDataService,
  GlobalFiltersService,
} from './services';
import { HttpCancelDataService } from './services/http-cancel-data.service';
import { CancelHttpInterceptor } from './interceptors/http-cancel.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

@NgModule({
  imports: [CommonModule],
  providers: [
    AuthService,
    AlarmsDataService,
    WorkOrderDataService,
    GlobalFiltersService,
    SocketsService,
    HttpCancelDataService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CancelHttpInterceptor,
      multi: true,
    },
  ],
})
export class DataAccessSharedModule {}
