import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';

import {
  ERROR_CREATE_GLOBAL_CUSTOMER,
  ERROR_CREATE_GLOBAL_ENVIRONMENT_CUSTOMER,
  ERROR_GET_GLOBAL_CUSTOMERS,
  GlobalCustomer,
  GlobalCustomerQuery,
} from '@vfi-ui/models';
import {
  CREATE_ENVIRONMENT_CUSTOMER_MUTATION,
  CREATE_GLOBAL_CUSTOMER_MUTATION,
  GET_GLOBAL_CUSTOMERS_QUERY,
} from '../queries/global-customer.query';
import { map, catchError, filter } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { NotificationService } from './notification.service';

export const GET_GLOBAL_CUSTOMERS_LIMIT = 50;

@Injectable({
  providedIn: 'root',
})
export class GlobalCustomerDataService {
  constructor(
    private readonly apollo: Apollo,
    private notificationService: NotificationService
  ) {}

  /**
   * Fetches global customers from the API.
   *
   * @param {string} value
   * @returns {Observable<GlobalCustomerQuery>}
   * @memberof GlobalCustomerDataService
   */
  getGlobalCustomers(value?: string): Observable<GlobalCustomerQuery> {
    return this.apollo
      .query<GlobalCustomerQuery>({
        fetchPolicy: 'no-cache',
        query: GET_GLOBAL_CUSTOMERS_QUERY,
        variables: {
          options: {
            offset: 0,
            limit: GET_GLOBAL_CUSTOMERS_LIMIT,
            where: {
              nameLike: value ? `%${value}%` : '%%',
            },
          },
        },
      })
      .pipe(
        filter((d) => !!d),
        map((r) => r?.data),
        catchError((error) => {
          this.notificationService.showError(
            ERROR_GET_GLOBAL_CUSTOMERS,
            error.message
          );
          return throwError(() => error);
        })
      );
  }

  /**
   * Creates a new environment customer.
   *
   * @param {globalCustomerId} globalCustomerId - The id of the global customer
   * @returns {Observable<boolean>}
   * @memberof GlobalCustomerDataService
   */
  createEnvironmentCustomer(globalCustomerId: number): Observable<unknown> {
    return this.apollo
      .mutate<boolean>({
        mutation: CREATE_ENVIRONMENT_CUSTOMER_MUTATION,
        variables: {
          globalCustomerId,
        },
      })
      .pipe(
        filter((d) => !!d),
        catchError((error) => {
          this.notificationService.showError(
            ERROR_CREATE_GLOBAL_ENVIRONMENT_CUSTOMER
          );
          return throwError(() => error);
        })
      );
  }

  /**
   * Creates a new global customer.
   *
   * @param {string} name - The global customer name
   * @returns {Observable<GlobalCustomer>}
   * @memberof GlobalCustomerDataService
   */
  createGlobalCustomer(name: string): Observable<GlobalCustomer> {
    return this.apollo
      .mutate<GlobalCustomer>({
        mutation: CREATE_GLOBAL_CUSTOMER_MUTATION,
        variables: {
          input: {
            name,
          },
        },
      })
      .pipe(
        filter((d) => !!d),
        map((r) => r.data),
        catchError((error) => {
          this.notificationService.showError(ERROR_CREATE_GLOBAL_CUSTOMER);
          return throwError(() => error);
        })
      );
  }
}
