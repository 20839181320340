import {
  CoreAlarmsOptions,
  CoreTicketOptions,
} from './alarm-instance.interface';

export interface LensCreateInput {
  name: string;
  criteria: CoreAlarmsOptions | CoreTicketOptions;
  category: LensCategory;
  type: LensType;
  teamId: string;
}
export interface LensUpdateInput {
  name: string;
  criteria: CoreAlarmsOptions | CoreTicketOptions;
  isActive: boolean;
}

export enum LensCategory {
  ACTIVE_ALARMS = 'active_alarms',
  ACTIVE_WORK = 'active_work',
  ALL_ALARMS = 'all_alarms',
  ALL_WORK = 'all_work',
  RECENTLY_CLOSED_WORK = 'recently_closed_work',
  ACTIVE_ASSETS = 'active_assets',
  ALL_ASSETS = 'all_assets',
}

export const LensAlarmCategories = [
  LensCategory.ALL_ALARMS,
  LensCategory.ACTIVE_ALARMS,
];

export const LensWorkCategories = [
  LensCategory.ACTIVE_WORK,
  LensCategory.ALL_WORK,
  LensCategory.RECENTLY_CLOSED_WORK,
];

export const ExplorerLensCategory = [
  LensCategory.ALL_ALARMS,
  LensCategory.ALL_WORK,
];

export enum LensType {
  ALARM = 'alarm',
  CREATE = 'create',
  WORK = 'work',
  ASSET = 'asset',
}
