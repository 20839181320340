import { Injectable, inject } from '@angular/core';
import { User } from '@vfi-ui/models';
import { navigateToDeeplink } from '@vfi-ui/util/helpers';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs';
import { UPDATE_USER_QUERY } from '../queries';
import {
  Auth,
  multiFactor,
  MultiFactorAssertion,
  MultiFactorResolver,
  PhoneAuthProvider,
  PhoneInfoOptions,
  PhoneMultiFactorGenerator,
  TotpMultiFactorGenerator,
  TotpSecret,
  UserCredential,
} from '@angular/fire/auth';

@Injectable({
  providedIn: 'root',
})
export class MfaService {
  showSecure = true;
  showVerification = false;
  showMFASuccessToast = false;
  verificationId: string;
  verificationResolver: MultiFactorResolver;
  totpSecret: TotpSecret;
  factorId: string;
  enrollingInMFA = true;
  errorMessage = '';
  phoneInfoOptions: PhoneInfoOptions;
  public afa = inject(Auth);

  constructor(private apollo: Apollo) {}

  /**
   * Returns if MFA is enabled or not
   *
   * @param code
   * @returns {Promise<boolean>}
   * @memberof MfaService
   */
  async isMultiFactorEnabled(): Promise<boolean> {
    const user = multiFactor(this.afa.currentUser);
    return user?.enrolledFactors?.length > 0;
  }

  /**
   * Handles getting auth info when sign in with MFA
   *
   * @param {{
   *     verificationId: string;
   *     verificationCode: string;
   *     resolver: MultiFactorResolver;
   *   }} {
   *     verificationCode,
   *     verificationId,
   *     resolver,
   *   }
   * @returns {Promise<User>}
   * @memberof MfaService
   */
  async signInWithMFA({
    verificationCode,
    verificationId,
    resolver,
  }: {
    verificationId: string;
    verificationCode: string;
    resolver: MultiFactorResolver;
  }): Promise<User> {
    try {
      let multiFactorAssertion: MultiFactorAssertion = null;
      if (this.factorId === TotpMultiFactorGenerator.FACTOR_ID) {
        multiFactorAssertion = TotpMultiFactorGenerator.assertionForSignIn(
          verificationId,
          verificationCode
        );
      }
      if (this.factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
        const cred = PhoneAuthProvider.credential(
          verificationId,
          verificationCode
        );
        multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
      }
      if (multiFactorAssertion) {
        // Complete sign-in.
        const signInResult: UserCredential =
          await resolver.resolveSignIn(multiFactorAssertion);
        return await this.handleSuccess(signInResult);
      }
    } catch (err) {
      console.error(err);
      this.handleError();
    }
  }

  /**
   * Initializes enroll with totop enroll
   *
   * @return {*}  {Promise<{
   *     totpSecret: TotpSecret;
   *     url: string;
   *   }>}
   * @memberof MfaService
   */
  public async initializeTOTPEnroll(): Promise<{
    totpSecret: TotpSecret;
    url: string;
  }> {
    try {
      const multiFactorSession = await multiFactor(
        this.afa.currentUser
      ).getSession();
      const totpSecret =
        await TotpMultiFactorGenerator.generateSecret(multiFactorSession);
      const url = totpSecret.generateQrCodeUrl(
        this.afa.currentUser.email,
        'Virtual Facility'
      );
      this.showSecure = false;
      this.showVerification = true;
      return { totpSecret, url };
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * Verify totp works
   *
   * @param {{
   *     totpSecret: TotpSecret;
   *     verificationCode: string;
   *   }} {
   *     totpSecret,
   *     verificationCode,
   *   }
   * @return {*}  {Promise<void>}
   * @memberof MfaService
   */
  public async verifyEnrollWithTOTP({
    totpSecret,
    verificationCode,
  }: {
    totpSecret: TotpSecret;
    verificationCode: string;
  }): Promise<void> {
    try {
      // Ask user for the verification code.
      const multiFactorAssertion =
        TotpMultiFactorGenerator.assertionForEnrollment(
          totpSecret,
          verificationCode
        );
      // Complete enrollment.
      const user = multiFactor(this.afa.currentUser);
      await user.enroll(multiFactorAssertion, 'Authenticator App');
      this.factorId = null;
      navigateToDeeplink();
    } catch (err) {
      console.error('Error in catch:', err);
      this.handleError();
    }
  }

  /**
   * Formats user info
   *
   * @param {UserCredential} userCredential
   * @returns {Promise<User>}
   * @memberof MfaService
   */
  async handleSuccess(userCredential: UserCredential): Promise<User> {
    const user: User = {
      id: userCredential.user.uid,
      uid: userCredential.user.uid,
      email: userCredential.user.email,
      username: userCredential.user.displayName,
      phone: userCredential.user.phoneNumber,
      active: true,
    };
    return await userCredential.user
      .getIdTokenResult()
      .then((idTokenResult) => {
        const hasuraClaims = idTokenResult.claims
          ? idTokenResult.claims['https://hasura.io/jwt/claims']
          : {};

        return {
          ...user,
          token: idTokenResult.token,
          hasuraClaims,
        };
      });
  }

  /**
   * Error handler for MFA sign in
   *
   * @memberof MfaService
   */
  handleError() {
    this.errorMessage = 'The verification code used is invalid. Try again.';
  }

  /**
   * call api to update user phone verification
   *
   * @param {(string | number)} id
   * @param {boolean} isVerified
   * @param {string} [phone]
   * @returns
   * @memberof MfaService
   */
  public updateUserPhoneVerification(
    id: string | number,
    isVerified: boolean,
    phone?: string
  ) {
    const user = {
      phone: {
        number: phone ? phone : '',
        countryCode: '',
        isVerified,
        isSubscribed: false,
      },
    };
    return this.apollo
      .mutate<{ updateUser: boolean }>({
        mutation: UPDATE_USER_QUERY,
        variables: {
          id,
          user,
        },
      })
      .pipe(map((d) => d?.data?.updateUser));
  }
}
