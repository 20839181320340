import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConfirmModalService {
  showConfirmModal = new Subject<void>();
  confirmation = new Subject<boolean>();

  constructor() {}

  /**
   * emit show confirm modal
   *
   * @memberof ConfirmModalService
   */
  show() {
    this.showConfirmModal.next();
  }

  /**
   * emits false confirmation
   *
   * @memberof ConfirmModalService
   */
  stay() {
    this.confirmation.next(false);
  }

  /**
   * emits true confirmation
   *
   * @memberof ConfirmModalService
   */
  leave() {
    this.confirmation.next(true);
  }
}
