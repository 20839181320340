import { WorkResolutionWorkTicketsOrderField } from '../interfaces/work-resolution.interface';
import { OrderDirection } from './work-ticket.constant';

export const WORK_RESOLUTION_DATE_RANGES = [
  {
    value: 0,
    label: 'Custom',
  },
  {
    value: 1,
    label: 'Today',
  },
  {
    value: 7,
    label: '7d',
  },
  {
    value: 30,
    label: '30d',
  },
];

export const WorkResolutionFilters = [
  'Ticket Age',
  'State',
  'Assignee',
  'Resolution',
  'Created Date',
];

export const ResolutionKeys = {
  true: 'Resolved',
  false: 'Unresolved',
};

export enum WorkResolutionWorkTicketOrderField {
  'ID',
  'DURATION',
  'CREATED_AT',
  'CLOSED_AT',
  'RESOLVED_ALARM_COUNT',
  'ASSOCIATED_ALARM_COUNT',
  'BUILDING',
  'CREATED_BY',
  'EXTERNAL_ID',
  'ASSIGNEE',
  'OBJECTIVE',
  'IS_RESOLVED',
  'REPEAT_COUNT',
  'TIME_TO_DISPATCH',
}

export enum WorkResolutionYAxisType {
  SPLIT = 'split',
  COUNT = 'count',
}

export const WorkResolutionDrilldownDefaultColumns = [
  'createdAt',
  'objective',
  'assigneeName',
  'duration',
  'closedAt',
  'associatedAlarms',
];

export const WorkResolutionDrilldownDefaultSort = {
  field: 'CLOSED_AT',
  direction: OrderDirection.DESC,
};

export const WORK_TICKETS_CLOSED_STATE_FILTER = {
  state: {
    values: ['Closed'],
    filterType: 'set',
  },
};

export const WORK_TICKETS_RESOLVED_FILTER = {
  isResolved: {
    values: ['true'],
    filterType: 'set',
  },
};

export const WORK_TICKETS_UNRESOLVED_FILTER = {
  isResolved: {
    values: ['false'],
    filterType: 'set',
  },
};

export const WORK_TICKETS_CLOSED_RESOLVED_FILTER = {
  ...WORK_TICKETS_CLOSED_STATE_FILTER,
  ...WORK_TICKETS_RESOLVED_FILTER,
};

export const WORK_TICKETS_CLOSED_UNRESOLVED_FILTER = {
  ...WORK_TICKETS_CLOSED_STATE_FILTER,
  ...WORK_TICKETS_UNRESOLVED_FILTER,
};

export const WORK_RESOLUTION_DRILLDOWN_DICT = {
  a1: {
    filters: { ...WORK_TICKETS_CLOSED_STATE_FILTER },
    columns: ['alarmInstanceCount'],
    group: null,
  },
  b1: {
    filters: WORK_TICKETS_CLOSED_RESOLVED_FILTER,
    columns: [],
    group: null,
  },
  b2: {
    filters: WORK_TICKETS_CLOSED_RESOLVED_FILTER,
    columns: [],
    group: null,
  },
  b3: {
    filters: WORK_TICKETS_CLOSED_UNRESOLVED_FILTER,
    columns: [],
    group: null,
  },
  b4: {
    filters: WORK_TICKETS_CLOSED_UNRESOLVED_FILTER,
    columns: [],
    group: null,
  },
  c1: {
    filters: { ...WORK_TICKETS_CLOSED_STATE_FILTER },
    columns: ['isRepeat'],
    group: null,
  },
  c2: {
    filters: { ...WORK_TICKETS_CLOSED_STATE_FILTER },
    columns: ['isRepeat'],
    group: null,
  },
  c3: {
    filters: { ...WORK_TICKETS_CLOSED_STATE_FILTER },
    columns: ['isRepeat'],
    group: null,
  },
  c4: {
    filters: { ...WORK_TICKETS_CLOSED_STATE_FILTER },
    columns: ['isRepeat'],
    group: null,
  },
  d1: {
    filters: WORK_TICKETS_CLOSED_RESOLVED_FILTER,
    columns: [],
    group: 'equipment',
  },
  d2: {
    filters: WORK_TICKETS_CLOSED_UNRESOLVED_FILTER,
    columns: [],
    group: 'equipment',
  },
  e1: {
    filters: WORK_TICKETS_CLOSED_RESOLVED_FILTER,
    columns: [],
    group: 'building',
  },
  e2: {
    filters: WORK_TICKETS_CLOSED_UNRESOLVED_FILTER,
    columns: [],
    group: 'building',
  },
  f1: {
    filters: WORK_TICKETS_CLOSED_RESOLVED_FILTER,
    columns: [],
    group: 'assigneeTeams',
  },
  f2: {
    filters: WORK_TICKETS_CLOSED_UNRESOLVED_FILTER,
    columns: [],
    group: 'assigneeTeams',
  },
};

export const WORK_TICKETS_REPORT_INACTIVE_ALARMS_BY_TEAMS_FILTERS = {
  columns: ['assigneeName', 'inactiveAlarmCount'],
  filters: {
    state: {
      values: ['Open'],
      filterType: 'set',
    },
    inactiveAlarmCount: {
      filterType: 'number',
      type: 'greaterThan',
      filter: 0,
    },
  },
  group: 'assigneeTeams',
};

export const WORK_TICKETS_REPORT_INACTIVE_ALARMS_BY_ASSIGNEE_FILTERS = {
  columns: ['assigneeName', 'inactiveAlarmCount'],
  filters: {
    state: {
      values: ['Open'],
      filterType: 'set',
    },
    inactiveAlarmCount: {
      filterType: 'number',
      type: 'greaterThan',
      filter: 0,
    },
  },
  group: 'assigneeName',
};

export const WORK_TICKETS_REPORT_INACTIVE_ALARMS_PER_TEAM_FILTERS = {
  columns: ['assigneeName', 'inactiveAlarmCount'],
  filters: {
    state: {
      values: ['Open'],
      filterType: 'set',
    },
    inactiveAlarmCount: {
      filterType: 'number',
      type: 'greaterThan',
      filter: 0,
    },
  },
  group: null,
};

export const WORK_TICKETS_REPORT_DUPLICATE_WORK_BY_TEAMS_FILTERS = {
  columns: ['isOverlapped'],
  filters: {
    isOverlapped: {
      values: ['true'],
      filterType: 'set',
    },
  },
  group: 'creatorTeams',
};

export const WORK_TICKETS_REPORT_DUPLICATE_WORK_BY_USER_FILTERS = {
  columns: ['isOverlapped'],
  filters: {
    isOverlapped: {
      values: ['true'],
      filterType: 'set',
    },
  },
  group: 'createdBy',
};

export const WORK_TICKETS_REPORT_DUPLICATE_WORK_PER_TEAM_FILTERS = {
  columns: ['isOverlapped'],
  filters: {
    isOverlapped: {
      values: ['true'],
      filterType: 'set',
    },
  },
  group: null,
};

export const AUTOMATION_CREATED_GRID_FILTER = {
  isAutomationCreated: {
    // Fill values array with string boolean values
    values: ['true'],
    filterType: 'set',
  },
};

export const CREATOR_TEAM_GRID_FILTER = {
  creatorTeams: {
    // Fill values array with team name
    values: [],
    filterType: 'set',
  },
};

export const CREATOR_USER_GRID_FILTER = {
  createdBy: {
    // Fill values array with user's name
    values: [],
    filterType: 'set',
  },
};

export const WORK_TICKETS_REPORT_TIME_TO_DISPATCH_BY_TEAMS_FILTERS = {
  columns: ['creatorTeams', 'isResolved', 'timeToDispatch'],
  filters: {},
  quickFilters: {},
  sort: {
    field: WorkResolutionWorkTicketsOrderField.CreatedAt,
    direction: OrderDirection.DESC,
  },
  group: 'creatorTeams',
};

export const WORK_TICKETS_REPORT_TIME_TO_DISPATCH_BY_USERS_FILTERS = {
  columns: ['timeToDispatch'],
  filters: {},
  group: 'createdBy',
};

export const WORK_TICKETS_TABLE_REPORT_TIME_TO_DISPATCH_BY_USERS_FILTERS = {
  columns: ['timeToDispatch'],
  filters: {},
  group: null,
};
