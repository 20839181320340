import { gql } from 'apollo-angular';

export const GET_GLOBAL_CUSTOMERS_QUERY = gql`
  query globalCustomers($options: GlobalCustomerOptions!) {
    globalCustomers(options: $options) {
      globalCustomerId
      name
    }
    globalCustomerCount(options: $options)
  }
`;

export const CREATE_GLOBAL_CUSTOMER_MUTATION = gql`
  mutation createGlobalCustomer($input: GlobalCustomerCreateInput!) {
    createGlobalCustomer(input: $input) {
      globalCustomerId
      name
    }
  }
`;

export const CREATE_ENVIRONMENT_CUSTOMER_MUTATION = gql`
  mutation createEnvironmentCustomer($globalCustomerId: Int!) {
    createEnvironmentCustomer(globalCustomerId: $globalCustomerId)
  }
`;
