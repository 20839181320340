import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngxs/store';
import { CustomersState } from '@vfi-ui/state';
import { formatInTimeZone } from 'date-fns-tz';

@Pipe({
  name: 'dateTime',
  pure: true,
})
export class DateTimePipe implements PipeTransform {
  constructor(private readonly store: Store) {}
  transform(value: Date | string | number, format?: string): string | null {
    if (!value) {
      return;
    }
    const timezone = this.store.selectSnapshot(
      CustomersState.getCurrentCustomerTimezone
    );
    if (typeof value === 'string') {
      return formatInTimeZone(new Date(value), timezone, format);
    }
    if (typeof value === 'object' || typeof value === 'bigint') {
      return formatInTimeZone(value, timezone, format);
    }
  }
}
