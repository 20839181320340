import { catchError, filter, map, tap } from 'rxjs/operators';
import { environment } from '@vfi-ui/environments/environment';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ERROR_CREATE_TEAM,
  TeamCreateInput,
  TeamView,
  Team,
  TeamMember,
  TeamUpdateInput,
  ERROR_UPDATE_TEAM,
  TeamMemberRole,
  TEAMS_ID_HEADER,
  ERROR_DELETE_TEAM,
} from '@vfi-ui/models';
import { Apollo } from 'apollo-angular';
import {
  ADD_TEAM_MEMBER_QUERY,
  CREATE_TEAM_QUERY,
  DELETE_TEAM_QUERY,
  GET_TEAM_MEMBERS_QUERY,
  REMOVE_TEAM_MEMBER_QUERY,
  UDPATE_TEAM_MEMBER_QUERY,
  UDPATE_TEAM_QUERY,
  UPDATE_TEAM_FILTERS_QUERY,
} from '../queries/team.query';
import { NotificationService } from './notification.service';
import {
  GET_TEAM_QUERY,
  GET_TEAM_VIEW_COUNT_QUERY,
  GET_TEAM_VIEW_QUERY,
} from '../queries';

@Injectable({ providedIn: 'root' })
export class TeamService {
  constructor(
    private http: HttpClient,
    private apollo: Apollo,
    private notification: NotificationService
  ) {}

  /**
   * Gets the team view values
   *
   * @param {{
   *     teamLike?: string;
   *     locationIds?: string[];
   *   }} where
   * @returns {Observable<TeamView[]>}
   * @memberof TeamService
   */
  getTeamViewValues(
    {
      teamLike,
      locationIds,
    }: {
      teamLike?: string;
      locationIds?: string[];
    },
    { limit, offset }: { limit: number; offset: number }
  ): Observable<TeamView[]> {
    const contentType = { 'Content-Type': 'application/json' };
    const where = {
      ...(teamLike && { teamLike: `%${teamLike}%` }),
      ...(locationIds && { locationIds }),
    };
    return this.http
      .request<{
        data: { teamViews: TeamView[] };
      }>('POST', environment.backend, {
        headers: contentType,
        body: JSON.stringify({
          query: GET_TEAM_VIEW_QUERY,
          variables: {
            options: {
              where,
              order: { field: 'TEAM_NAME', direction: 'ASC' },
              limit,
              offset,
            },
          },
        }),
      })
      .pipe(
        filter((d) => !!d),
        map((activity) => activity?.data?.teamViews),
        catchError((error) => throwError(error))
      );
  }

  /**
   * fetch all teams
   *
   * @returns {Observable<Team[]>}
   * @memberof TeamService
   */
  getAllTeams(): Observable<Team[]> {
    const contentType = { 'Content-Type': 'application/json' };
    return this.http
      .request<{
        data: { teams: Team[] };
      }>('POST', environment.backend, {
        headers: contentType,
        body: JSON.stringify({
          query: GET_TEAM_QUERY,
          variables: {
            options: {},
          },
        }),
      })
      .pipe(
        filter((d) => !!d),
        map((activity) => activity?.data?.teams),
        catchError((error) => throwError(error))
      );
  }

  /**
   * get team by id
   *
   * @param {{ id: string }} { id }
   * @return {*}  {Observable<Team[]>}
   * @memberof TeamService
   */
  getTeamByID({ id }: { id: string }): Observable<Team[]> {
    const contentType = { 'Content-Type': 'application/json' };
    return this.http
      .request<{
        data: { teams: Team[] };
      }>('POST', environment.backend, {
        headers: contentType,
        body: JSON.stringify({
          query: GET_TEAM_QUERY,
          variables: {
            options: {
              where: {
                ids: [id],
              },
            },
          },
        }),
      })
      .pipe(
        filter((d) => !!d),
        map((activity) => activity?.data?.teams),
        catchError((error) => throwError(error))
      );
  }

  /**
   * fetch team members by team id
   *
   * @param {{ id: string }} { id }
   * @return {*}  {Observable<TeamMember[]>}
   * @memberof TeamService
   */
  getTeamMembersByTeamID({ id }: { id: string }): Observable<TeamMember[]> {
    const contentType = { 'Content-Type': 'application/json' };
    return this.http
      .request<{
        data: { teamMembers: TeamMember[] };
      }>('POST', environment.backend, {
        headers: contentType,
        body: JSON.stringify({
          query: GET_TEAM_MEMBERS_QUERY,
          variables: {
            options: {
              where: {
                teamIds: [id],
              },
            },
          },
        }),
      })
      .pipe(
        filter((d) => !!d),
        map((activity) => activity?.data?.teamMembers),
        catchError((error) => throwError(error))
      );
  }
  /**
   * Gets the team view count
   *
   * @param {{
   *     teamLike?: string;
   *     locationIds?: string[];
   *   }} {
   *     teamLike,
   *     locationIds,
   *   }
   * @returns {Observable<number>}
   * @memberof TeamService
   */
  getTeamViewCount({
    teamLike,
    locationIds,
  }: {
    teamLike?: string;
    locationIds?: string[];
  }): Observable<number> {
    const contentType = { 'Content-Type': 'application/json' };
    const where = {
      ...(teamLike && { teamLike: `%${teamLike}%` }),
      ...(locationIds && { locationIds }),
    };
    return this.http
      .request<{
        data: { teamViewCount: number };
      }>('POST', environment.backend, {
        headers: contentType,
        body: JSON.stringify({
          query: GET_TEAM_VIEW_COUNT_QUERY,
          variables: {
            options: {
              where,
            },
          },
        }),
      })
      .pipe(
        filter((d) => !!d),
        map((activity) => activity?.data?.teamViewCount),
        catchError((error) => throwError(error))
      );
  }

  /**
   * calls api to create team
   *
   * @param {TeamCreateInput} input
   * @returns
   * @memberof TeamService
   */
  createTeam(input: TeamCreateInput) {
    // TODO: temp fix to get rid of team filters
    input.isAllBuildings = true;
    return this.apollo
      .mutate({
        mutation: CREATE_TEAM_QUERY,
        variables: { input },
      })
      .pipe(
        filter((d) => !!d),
        tap(() => this.notification.showSuccess('Create team')),
        catchError((error) => {
          this.notification.showError(ERROR_CREATE_TEAM);
          return throwError(error);
        })
      );
  }

  /**
   * calls api to delete team
   *
   * @param {string} id
   * @returns
   * @memberof TeamService
   */
  deleteTeam(id: string) {
    return this.apollo
      .mutate({
        mutation: DELETE_TEAM_QUERY,
        variables: { id },
      })
      .pipe(
        filter((d) => !!d),
        tap(() => this.notification.showSuccess('Delete team')),
        catchError((error) => {
          this.notification.showError(ERROR_DELETE_TEAM);
          return throwError(error);
        })
      );
  }

  /**
   * calls api to remove team member
   *
   * @param {({
   *     teamId: string;
   *     userId: number | string;
   *   })} {
   *     teamId,
   *     userId,
   *   }
   * @return {*}
   * @memberof TeamService
   */
  removeTeamMember({
    teamId,
    userId,
  }: {
    teamId: string;
    userId: number | string;
  }) {
    return this.apollo
      .mutate({
        mutation: REMOVE_TEAM_MEMBER_QUERY,
        variables: { teamId, userId },
        context: {
          headers: {
            [TEAMS_ID_HEADER]: teamId,
          },
        },
      })
      .pipe(
        filter((d) => !!d),
        catchError((error) => {
          this.notification.showError(ERROR_UPDATE_TEAM);
          return throwError(error);
        })
      );
  }
  /**
   *  calls api to add team member
   *
   * @param {({
   *     teamId: string;
   *     userId: number | string;
   *     role: TeamMemberRole;
   *   })} {
   *     teamId,
   *     userId,
   *     role,
   *   }
   * @return {*}
   * @memberof TeamService
   */
  addTeamMember({
    teamId,
    userId,
    role,
  }: {
    teamId: string;
    userId: number | string;
    role: TeamMemberRole;
  }) {
    return this.apollo
      .mutate({
        mutation: ADD_TEAM_MEMBER_QUERY,
        variables: { teamId, input: { userId, role } },
        context: {
          headers: {
            [TEAMS_ID_HEADER]: teamId,
          },
        },
      })
      .pipe(
        filter((d) => !!d),
        catchError((error) => {
          this.notification.showError(ERROR_UPDATE_TEAM);
          return throwError(error);
        })
      );
  }
  /**
   * calls api to update team member
   *
   * @param {({
   *     teamId: string;
   *     userId: number | string;
   *     role: TeamMemberRole;
   *   })} {
   *     teamId,
   *     userId,
   *     role,
   *   }
   * @return {*}
   * @memberof TeamService
   */
  updateTeamMember({
    teamId,
    userId,
    role,
  }: {
    teamId: string;
    userId: number | string;
    role: TeamMemberRole;
  }) {
    return this.apollo
      .mutate({
        mutation: UDPATE_TEAM_MEMBER_QUERY,
        variables: {
          teamId,
          input: {
            userId,
            role,
          },
        },
        context: {
          headers: {
            [TEAMS_ID_HEADER]: teamId,
          },
        },
      })
      .pipe(
        filter((d) => !!d),
        catchError((error) => {
          this.notification.showError(ERROR_UPDATE_TEAM);
          return throwError(error);
        })
      );
  }
  /**
   * call api to update team details
   *
   * @param {{ id: string; input: Partial<TeamUpdateInput> }} { id, input }
   * @return {*}
   * @memberof TeamService
   */
  updateTeam({ id, input }: { id: string; input: Partial<TeamUpdateInput> }) {
    return this.apollo
      .mutate({
        mutation: UDPATE_TEAM_QUERY,
        variables: {
          id,
          input,
        },
        context: {
          headers: {
            [TEAMS_ID_HEADER]: id,
          },
        },
      })
      .pipe(
        filter((d) => !!d),
        catchError((error) => {
          this.notification.showError(ERROR_UPDATE_TEAM);
          return throwError(error);
        })
      );
  }

  /**
   * update team filters
   *
   * @param {string} teamId
   * @param {*} alarmOptions
   * @returns
   * @memberof TeamService
   */
  updateTeamFilters(teamId: string, alarmOptions) {
    return this.apollo
      .mutate({
        mutation: UPDATE_TEAM_FILTERS_QUERY,
        variables: {
          teamId,
          alarmOptions,
        },
        context: {
          headers: {
            [TEAMS_ID_HEADER]: teamId,
          },
        },
      })
      .pipe(
        filter((d) => !!d),
        catchError((error) => {
          this.notification.showError(ERROR_UPDATE_TEAM);
          return throwError(error);
        })
      );
  }
}
