import { InjectionToken, ValueProvider } from '@angular/core';
import { App, AppPlugin } from '@capacitor/app';
import { AppLauncher, AppLauncherPlugin } from '@capacitor/app-launcher';
import { Capacitor, CapacitorGlobal } from '@capacitor/core';
import {
  PushNotifications,
  PushNotificationsPlugin,
} from '@capacitor/push-notifications';
import { SplashScreen, SplashScreenPlugin } from '@capacitor/splash-screen';
import { Deploy, DeployClass } from 'cordova-plugin-ionic';

export const CAPACITOR_CORE_TOKEN = new InjectionToken<CapacitorGlobal>(
  'CAPACITOR_CORE_TOKEN'
);

export const CAPACITOR_CORE_PROVIDER: ValueProvider = {
  provide: CAPACITOR_CORE_TOKEN,
  useValue: Capacitor,
};

export const CAPACITOR_APP_TOKEN = new InjectionToken<AppPlugin>(
  'CAPACITOR_APP_TOKEN'
);

export const CAPACITOR_APP_PROVIDER: ValueProvider = {
  provide: CAPACITOR_APP_TOKEN,
  useValue: App,
};

export const CAPACITOR_APP_LAUNCHER_TOKEN =
  new InjectionToken<AppLauncherPlugin>('CAPACITOR_APP_LAUNCHER_TOKEN');

export const CAPACITOR_APP_LAUNCHER_PROVIDER: ValueProvider = {
  provide: CAPACITOR_APP_LAUNCHER_TOKEN,
  useValue: AppLauncher,
};

export const CAPACITOR_PUSH_NOTIFICATION_TOKEN =
  new InjectionToken<PushNotificationsPlugin>(
    'CAPACITOR_PUSH_NOTIFICATION_TOKEN'
  );

export const CAPACITOR_PUSH_NOTIFICATION_PROVIDER: ValueProvider = {
  provide: CAPACITOR_PUSH_NOTIFICATION_TOKEN,
  useValue: PushNotifications,
};

export const CAPACITOR_SPLASH_SCREEN_TOKEN =
  new InjectionToken<SplashScreenPlugin>('CAPACITOR_SPLASH_SCREEN_TOKEN');

export const CAPACITOR_SPLASH_SCREEN_PROVIDER: ValueProvider = {
  provide: CAPACITOR_SPLASH_SCREEN_TOKEN,
  useValue: SplashScreen,
};

export const CAPACITOR_DEPLOY_TOKEN = new InjectionToken<DeployClass>(
  'CAPACITOR_DEPLOY_TOKEN'
);

export const CAPACITOR_DEPLOY_PROVIDER: ValueProvider = {
  provide: CAPACITOR_DEPLOY_TOKEN,
  useValue: Deploy,
};
