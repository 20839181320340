import { AuthState, CustomersState, Logout } from '@vfi-ui/state';
import { Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { redirectSettings } from '@vfi-ui/models';

@Injectable({
  providedIn: 'root',
})
export class RedirectResolver {
  constructor(
    private router: Router,
    private store: Store
  ) {}

  resolve(routerState: RouterStateSnapshot): boolean {
    const userMFAEnabled = this.store.selectSnapshot(
      AuthState.user
    )?.isMFAEnabled;
    const customerMFAEnabled = this.store.selectSnapshot(
      CustomersState.getCurrentCustomers
    )?.isMFAEnabled;
    if (customerMFAEnabled && !userMFAEnabled) {
      this.store.dispatch(new Logout());
      return false;
    }

    const authRole = this.store.selectSnapshot(AuthState.getAuthRole);
    const noAccess = redirectSettings.includes(authRole);
    if (!routerState.url.includes('settings') && noAccess) {
      this.router.navigate(['settings']);
      return false;
    }
    return true;
  }
}
