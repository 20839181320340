import { gql } from 'apollo-angular';

export const GET_TEAM_VIEW_QUERY = `
  query teamViews($options: TeamViewOptions!) {
    teamViews(input: $options) {
      teamId
      teamName
      locationNames
      teamMemberCount
      lastTeamMemberActiveAt
      createdBy
    }
  }
`;

export const GET_TEAM_VIEW_COUNT_QUERY = `
  query teamViewCount($options: TeamViewOptions!) {
    teamViewCount(input: $options)
  }
`;

export const CREATE_TEAM_QUERY = gql`
  mutation createTeam($input: TeamCreateInput!) {
    createTeam(input: $input) {
      id
    }
  }
`;

export const DELETE_TEAM_QUERY = gql`
  mutation deleteTeam($id: String!) {
    deleteTeam(id: $id)
  }
`;

export const REMOVE_TEAM_MEMBER_QUERY = gql`
  mutation removeTeamMember($teamId: String!, $userId: String!) {
    removeTeamMember(teamId: $teamId, userId: $userId)
  }
`;
export const ADD_TEAM_MEMBER_QUERY = gql`
  mutation addTeamMember($teamId: String!, $input: TeamMemberCreateInput!) {
    addTeamMember(teamId: $teamId, input: $input)
  }
`;
export const UDPATE_TEAM_MEMBER_QUERY = gql`
  mutation updateTeamMember($teamId: String!, $input: TeamMemberCreateInput!) {
    updateTeamMember(teamId: $teamId, input: $input)
  }
`;
export const UDPATE_TEAM_QUERY = gql`
  mutation updateTeam($id: String!, $input: TeamUpdateInput!) {
    updateTeam(id: $id, input: $input) {
      name
    }
  }
`;

export const GET_TEAM_QUERY = `
  query teams($options: TeamOptions!) {
    teams(input: $options) {
      id
      name
      isSystem
      createdBy {
        id
      }
      createdAt
      lastModifiedBy {
        id
      }
      lastModifiedAt
      isAllBuildings
      displayOptions
      locations {
        id
        name
      }
      buildings {
        name
      }
    }
  }
`;

export const GET_TEAM_MEMBERS_QUERY = `
  query teamMembers($options: TeamMemberOptions!) {
    teamMembers(input: $options) {
      user {
        id
        displayName
        role
        email {
          address
        }
        lastActiveAt
      }
      team {
        name
      }
      permissions {
        role
      }
    }
  }`;

export const UPDATE_TEAM_FILTERS_QUERY = gql`
  mutation associateTeamAttributes(
    $teamId: String!
    $alarmOptions: AlarmsOptions!
  ) {
    associateTeamAttributes(teamId: $teamId, alarmOptions: $alarmOptions)
  }
`;
