export const DEFAULT_COLORS = {
  NEUTRAL1: '#FFF',
  NEUTRAL2: '#FAFAFA',
  NEUTRAL3: '#F5F5F5',
  NEUTRAL4: '#E8E8E8',
  NEUTRAL5: '#D9D9D9',
  NEUTRAL6: '#BFBFBF',
  NEUTRAL7: '#8C8C8C',
  NEUTRAL8: '#595959',
  NEUTRAL9: '#262626',
  NEUTRAL10: '#000',
  BLUE1: '#E4EEFF',
  BLUE2: '#C2D9FF',
  BLUE3: '#A0C3FF',
  BLUE4: '#7EAEFF',
  BLUE5: '#4B8EFF',
  BLUE6: '#2979FF',
  BLUE7: '#005CF5',
  BLUE8: '#0042B1',
  BLUE9: '#002F7E',
  BLUE10: '#001C4B',
  RED1: '#FFF4F6',
  RED2: '#FFD2DB',
  RED3: '#FFB0BF',
  RED4: '#FF7D96',
  RED5: '#FF4A6D',
  RED6: '#FF1744',
  RED7: '#E3002C',
  RED8: '#B00022',
  RED9: '#8E001C',
  RED10: '#6C0015',
  ALT_RED1: '#d98292',
  ALT_RED2: '#ca6878',
  ALT_RED3: '#cd5c5c',
  ALT_RED4: '#ef4352',
  ALT_RED5: '#e60026',
  ALT_RED6: '#ce2029',
  ALT_RED7: '#b31b1b',
  ALT_RED8: '#a91101',
  ALT_RED9: '#950901',
  ALT_RED10: '#800000',
  ORANGE1: '#FFF5EE',
  ORANGE2: '#FFE2CC',
  ORANGE3: '#FFC599',
  ORANGE4: '#FFA766',
  ORANGE5: '#FF8A33',
  ORANGE6: '#FF6D00',
  ORANGE7: '#DD5E00',
  ORANGE8: '#AA4900',
  ORANGE9: '#730',
  ORANGE10: '#552400',
  YELLOW1: '#FFFBEE',
  YELLOW2: '#FFF3CC',
  YELLOW3: '#FFEBAA',
  YELLOW4: '#FFE077',
  YELLOW5: '#FFD033',
  YELLOW6: '#FFC400',
  YELLOW7: '#CC9D00',
  YELLOW8: '#997600',
  YELLOW9: '#664F00',
  YELLOW10: '#443400',
  GREEN1: '#E6FFF3',
  GREEN2: '#D5FFEA',
  GREEN3: '#C4FFE2',
  GREEN4: '#91FFC9',
  GREEN5: '#2BFF98',
  GREEN6: '#00E676',
  GREEN7: '#00B35C',
  GREEN8: '#00914A',
  GREEN9: '#005E30',
  GREEN10: '#003C1F',
  GRAY1: '#E6ECF0',
  GRAY2: '#C6D4DC',
  GRAY3: '#A7BCC9',
  GRAY4: '#7D9DAF',
  GRAY5: '#587B90',
  GRAY6: '#4B697B',
  GRAY7: '#384E5B',
  GRAY8: '#2B3C46',
  GRAY9: '#1E2A31',
  GRAY10: '#121A1f',
  DM00: '#ECEEF1',
  DM600: '#262b31',
  FIELD_BACKGROUND_01: '#aecafb0a',
  BORDER_02: '#aecafb26',
};

export const DEFAULT_PRIORITY_COLORS = {
  5: '#FF1744',
  4: '#FF6D00',
  3: '#FFC400',
  2: '#587B90',
  1: '#A7BCC9',
  0: '#FFF',
};

export const DEFAULT_BUILDING_COLORS = [
  DEFAULT_COLORS.BLUE6,
  DEFAULT_COLORS.BLUE4,
  DEFAULT_COLORS.GRAY6,
  DEFAULT_COLORS.GRAY3,
  DEFAULT_COLORS.NEUTRAL4,
  DEFAULT_COLORS.YELLOW5,
];
