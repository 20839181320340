import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import {
  AlarmInstanceReport,
  AlarmInstanceReportOrderField,
  ALARM_INSTANCE_REPORT_LIMIT,
  ALARM_INSTANCE_REPORT_MAX_DAYS_RANGE,
  ERROR_GET_ALARM_INSTANCE,
  OrderDirection,
} from '@vfi-ui/models';
import { map, catchError, filter } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import { NotificationService } from './notification.service';
import { GET_ALARM_INSTANCE_REPORT_INSTANCES_QUERY } from '../queries/alarm-instance-report.query';
import { startOfDay, subDays } from 'date-fns';

@Injectable({
  providedIn: 'root',
})
export class AlarmInstanceReportDataService {
  constructor(
    private apollo: Apollo,
    private notification: NotificationService
  ) {}

  /**
   * fetch alarm instance report instances
   *
   * @returns {Observable<AlarmInstanceReport[]>}
   * @memberof AlarmInstanceReportDataService
   */
  getAlarmInstanceReportInstances(): Observable<AlarmInstanceReport[]> {
    return this.apollo
      .query<{ alarmInstanceReport: AlarmInstanceReport[] }>({
        fetchPolicy: 'no-cache',
        query: GET_ALARM_INSTANCE_REPORT_INSTANCES_QUERY,
        variables: {
          input: {
            limit: ALARM_INSTANCE_REPORT_LIMIT,
            order: {
              field: AlarmInstanceReportOrderField.AlarmTime,
              direction: OrderDirection.DESC,
            },
            where: {
              alarmTime: {
                from: startOfDay(
                  subDays(new Date(), ALARM_INSTANCE_REPORT_MAX_DAYS_RANGE)
                ),
                to: new Date(),
              },
            },
          },
        },
      })
      .pipe(
        filter((d) => !!d),
        catchError((error) => {
          this.notification.showError(ERROR_GET_ALARM_INSTANCE);
          return throwError(error);
        }),
        map((res) => res?.data?.alarmInstanceReport)
      );
  }
}
