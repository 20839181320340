import { AuthService } from '@vfi-ui/data-access/shared';
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';
import { CUSTOMER_ID_HEADER } from '@vfi-ui/models';

@Injectable({
  providedIn: 'root',
})
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(private readonly authService: AuthService) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.authService.getCurrentUserIdToken().pipe(
      take(1),
      mergeMap((refreshResponse: any) => {
        const headers = {};
        const customerId = localStorage.getItem(CUSTOMER_ID_HEADER);
        if (refreshResponse) {
          headers['Authorization'] = `Bearer ${refreshResponse.token}`;
        }
        if (customerId) {
          headers[CUSTOMER_ID_HEADER] = customerId;
        }

        request = request.clone({
          setHeaders: headers,
        });

        return next.handle(request);
      })
    );
  }
}
