import {
  CREATE_ENTITY_QUERY,
  CREATE_ENTITY_RELATIONSHIP_QUERY,
  DELETE_ENTITY_QUERY,
  DELETE_ENTITY_RELATIONSHIP_QUERY,
  GET_ASSET_ACTIVITY_COUNT_QUERY,
  GET_CREATE_ENTITY_SCHEMA_QUERY,
  GET_ENTITIES_OPTIONS_QUERY,
  GET_ENTITY_CLASSES_QUERY,
  GET_ENTITY_CLASS_OPTIONS_QUERY,
  GET_ENTITY_RELATIONSHIPS_QUERY,
  SEARCH_ENTITIES_QUERY,
  SEARCH_ENTITY_COUNT_QUERY,
  UPDATE_ENTITY_QUERY,
} from './../queries/entity-management.query';
import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { catchError, filter, map, Observable, tap, throwError } from 'rxjs';
import { GET_ENTITY_LIST_QUERY } from '../queries/entity-management.query';
import {
  Asset,
  AssetClass,
  BrickRelationshipType,
  Entity,
  EntityActivityWhere,
  EntityClass,
  EntityClassQuery,
  EntityCreateInput,
  EntityListQuery,
  EntityOptionResult,
  EntityRelationship,
  EntityType,
  ERROR_CREATE_ENTITY,
  ERROR_CREATE_ENTITY_RELATIONSHIP,
  ERROR_DELETE_ENTITY,
  ERROR_DELETE_ENTITY_RELATIONSHIP,
  ERROR_UPDATE_ENTITY,
  MaxEntityCount,
  OrderDirection,
  SearchEntityResult,
} from '@vfi-ui/models';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root',
})
export class EntityManagementDataService {
  constructor(
    private apollo: Apollo,
    private notification: NotificationService
  ) {}

  /**
   * fetch entity list
   *
   * @param {*} where
   * @param {number} [offset=0]
   * @returns {Observable<EntityListQuery>}
   * @memberof EntityManagementDataService
   */
  fetchEntityList(where, offset = 0): Observable<EntityListQuery> {
    return this.apollo
      .query<EntityListQuery>({
        fetchPolicy: 'no-cache',
        query: GET_ENTITY_LIST_QUERY,
        variables: {
          options: {
            limit: MaxEntityCount,
            offset,
            where,
            order: [
              { field: 'NAME', direction: OrderDirection.ASC },
              { field: 'CLASS', direction: OrderDirection.ASC },
            ],
          },
        },
      })
      .pipe(
        filter((d) => !!d),
        map((res) => res?.data)
      );
  }

  /**
   * fetch entity relationships
   *
   * @param {number} entityId
   * @returns {Observable<EntityRelationship[]>}
   * @memberof EntityManagementDataService
   */
  fetchEntityRelationships(
    entityId: number,
    includeUpstream?: boolean
  ): Observable<EntityRelationship[]> {
    return this.apollo
      .query<{ assetRelationships: EntityRelationship[] }>({
        fetchPolicy: 'no-cache',
        query: GET_ENTITY_RELATIONSHIPS_QUERY,
        variables: {
          entityId,
          includeUpstream,
        },
      })
      .pipe(
        filter((d) => !!d),
        map((res) => res?.data.assetRelationships)
      );
  }

  /**
   * delete entity relationship
   *
   * @param {number} baseEntityId
   * @param {EntityRelationDirection} direction
   * @param {number} relationEntityId
   * @returns
   * @memberof EntityManagementDataService
   */
  deleteEntityRelationship(entityRelationshipId: number) {
    return this.apollo
      .mutate({
        mutation: DELETE_ENTITY_RELATIONSHIP_QUERY,
        variables: { entityRelationshipId },
      })
      .pipe(
        filter((d) => !!d),
        catchError((error) => {
          this.notification.showError(ERROR_DELETE_ENTITY_RELATIONSHIP);
          return throwError(error);
        })
      );
  }

  /**
   * create entity relationship
   *
   * @param {number} baseEntityId
   * @param {EntityRelationDirection} direction
   * @param {number[]} relationEntityIds
   * @returns
   * @memberof EntityManagementDataService
   */
  createEntityRelationship({
    baseEntityId,
    relationshipType,
    relationEntityId,
  }: {
    baseEntityId: number;
    relationshipType: BrickRelationshipType;
    relationEntityId: number[];
  }) {
    return this.apollo
      .mutate({
        mutation: CREATE_ENTITY_RELATIONSHIP_QUERY,
        variables: {
          baseEntityId,
          relationshipType,
          relationEntityId,
        },
      })
      .pipe(
        filter((d) => !!d),
        catchError((error) => {
          this.notification.showError(ERROR_CREATE_ENTITY_RELATIONSHIP);
          return throwError(error);
        })
      );
  }

  /**
   * search entitiy count
   *
   * @optional {number} baseEntityId
   * @optional {string} pattern
   * @returns {Observable<number>}
   * @memberof EntityManagementDataService
   */
  searchEntityCount(
    baseEntityId?: number,
    pattern?: string,
    type?: EntityType
  ): Observable<number> {
    const options = {
      where: {
        baseEntityId: baseEntityId ? baseEntityId : null,
        pattern: pattern ? `%${pattern}%` : `%%`,
        type: type || EntityType.Space,
      },
    };
    return this.apollo
      .query({
        fetchPolicy: 'no-cache',
        query: SEARCH_ENTITY_COUNT_QUERY,
        variables: { options },
      })
      .pipe(
        filter((d) => !!d),
        map((res: any) => res?.data?.searchEntityCount)
      );
  }

  /**
   * search entities
   *
   * @optional {number} baseEntityId
   * @optional {string} pattern
   * @returns {Observable<SearchEntityResult[]>}
   * @memberof EntityManagementDataService
   */
  searchEntities(
    baseEntityId?: number,
    pattern?: string,
    type?: EntityType,
    limit: number = 50,
    offset: number = 0
  ): Observable<SearchEntityResult[]> {
    const options = {
      limit,
      offset,
      order: { field: 'NAME', direction: OrderDirection.ASC },
      where: {
        baseEntityId: baseEntityId ? baseEntityId : null,
        pattern: pattern ? `%${pattern}%` : `%%`,
        type: type || EntityType.Space,
      },
    };
    return this.apollo
      .query({
        fetchPolicy: 'no-cache',
        query: SEARCH_ENTITIES_QUERY,
        variables: { options },
      })
      .pipe(
        filter((d) => !!d),
        map((res: any) => res?.data?.searchEntities)
      );
  }

  /**
   * call api to fetch entity options
   *
   * @param {number} [baseEntityId]
   * @param {string} [pattern]
   * @param {EntityType} [type]
   * @param {number} [limit=50]
   * @param {number} [offset=0]
   * @returns {Observable<EntityOptionResult>}
   * @memberof EntityManagementDataService
   */
  getEntityOptions(
    baseEntityId?: number,
    pattern?: string,
    type?: EntityType,
    limit: number = 50,
    offset: number = 0
  ): Observable<EntityOptionResult> {
    const options = {
      limit,
      offset,
      order: { field: 'NAME', direction: OrderDirection.ASC },
      where: {
        baseEntityId: baseEntityId ? baseEntityId : null,
        pattern: pattern ? `%${pattern}%` : `%%`,
        type: type || EntityType.Space,
      },
    };
    return this.apollo
      .query({
        fetchPolicy: 'no-cache',
        query: GET_ENTITIES_OPTIONS_QUERY,
        variables: { options },
      })
      .pipe(
        filter((d) => !!d),
        map((res: any) => res?.data)
      );
  }

  /**
   * fetch entity classes
   *
   * @param {string} searchValue
   * @param {number} [offset=0]
   * @returns {Observable<EntityClassQuery>}
   * @memberof EntityManagementDataService
   */
  fetchEntityClasses(
    searchValue: string,
    offset = 0
  ): Observable<EntityClassQuery> {
    return this.apollo
      .query<EntityClassQuery>({
        fetchPolicy: 'no-cache',
        query: GET_ENTITY_CLASSES_QUERY,
        variables: {
          options: {
            limit: 50,
            offset,
            where: { nameLike: `%${searchValue}%` },
            order: { field: 'NAME', direction: OrderDirection.ASC },
          },
        },
      })
      .pipe(
        filter((d) => !!d),
        map((res) => res?.data)
      );
  }

  /**
   * fetch entity class options
   *
   * @param {AssetClass} type
   * @param {string} searchValue
   * @param {number} [offset=0]
   * @returns {Observable<EntityClassQuery>}
   * @memberof EntityManagementDataService
   */
  fetchEntityClassOptions(
    type: AssetClass,
    searchValue: string,
    offset = 0
  ): Observable<EntityClassQuery> {
    const assetType = type ? { type } : {};
    return this.apollo
      .query<EntityClassQuery>({
        fetchPolicy: 'no-cache',
        query: GET_ENTITY_CLASS_OPTIONS_QUERY,
        variables: {
          options: {
            limit: 50,
            offset,
            where: { nameLike: `%${searchValue}%`, ...assetType },
            order: { field: 'NAME', direction: OrderDirection.ASC },
          },
        },
      })
      .pipe(
        filter((d) => !!d),
        map((res) => res?.data)
      );
  }

  /**
   * update entity
   *
   * @param {number} entityId
   * @param {*} input
   * @returns {Observable<Entity>}
   * @memberof EntityManagementDataService
   */
  updateEntity(entityId: number, input): Observable<Entity> {
    return this.apollo
      .mutate<{ updateEntity: Entity }>({
        mutation: UPDATE_ENTITY_QUERY,
        variables: { entityId, input },
      })
      .pipe(
        filter((d) => !!d),
        map((d) => d?.data?.updateEntity),
        tap(() =>
          this.notification.showSuccess(
            'Update Asset',
            `Your changes are saved. Please allow them a minute to update across the app.`
          )
        ),
        catchError((error) => {
          this.notification.showError(ERROR_UPDATE_ENTITY);
          return throwError(error);
        })
      );
  }

  /**
   * delete entity by ID
   *
   * @param {number} entityId
   * @returns
   * @memberof EntityManagementDataService
   */
  deleteEntity(entityId: number) {
    return this.apollo
      .mutate({
        mutation: DELETE_ENTITY_QUERY,
        variables: { entityId },
      })
      .pipe(
        filter((d) => !!d),
        catchError((error) => {
          this.notification.showError(ERROR_DELETE_ENTITY);
          return throwError(error);
        })
      );
  }

  /**
   * create entity
   *
   * @param {Partial<EntityCreateInput>} input
   * @returns {Observable<Partial<Asset>>}
   * @memberof EntityManagementDataService
   */
  createEntity(input: Partial<EntityCreateInput>): Observable<Partial<Asset>> {
    return this.apollo
      .mutate<{ createEntity: Partial<Asset> }>({
        mutation: CREATE_ENTITY_QUERY,
        variables: { input },
      })
      .pipe(
        filter((d) => !!d),
        map((res) => res?.data?.createEntity),
        tap(() =>
          this.notification.showSuccess(
            'Create Asset',
            `Asset has been created`
          )
        ),
        catchError((error) => {
          this.notification.showError(ERROR_CREATE_ENTITY);
          return throwError(error);
        })
      );
  }

  /**
   * fetch schema for new entity based on entity class
   *
   * @param {EntityClass} entityClass
   * @returns
   * @memberof EntityManagementDataService
   */
  getCreateEntitySchema(entityClass: EntityClass) {
    return this.apollo
      .query<{ createEntitySchema: any }>({
        fetchPolicy: 'no-cache',
        query: GET_CREATE_ENTITY_SCHEMA_QUERY,
        variables: {
          class: entityClass,
        },
      })
      .pipe(
        filter((d) => !!d),
        map((res) => res?.data?.createEntitySchema)
      );
  }

  /**
   * fetch entity history activity
   *
   * @param {EntityActivityWhere} where
   * @param {number} [offset=0]
   * @param {number} [limit=50]
   * @returns
   * @memberof EntityManagementDataService
   */
  fetchEntityActivity(where: EntityActivityWhere, offset = 0, limit = 100) {
    const input = {
      where,
      offset,
      limit,
      order: { field: 'CREATED_AT', direction: OrderDirection.DESC },
    };
    return this.apollo
      .query<any>({
        fetchPolicy: 'no-cache',
        query: GET_ASSET_ACTIVITY_COUNT_QUERY,
        variables: {
          input,
        },
      })
      .pipe(
        filter((d) => !!d),
        map((res) => ({
          entityActivity: res?.data?.assetActivity,
          entityActivityCount: res?.data?.assetActivityCount,
        }))
      );
  }
}
