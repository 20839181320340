import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { map, take, filter, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AuthState } from '@vfi-ui/state';
import { AuthRoles } from '@vfi-ui/models';

@Injectable({
  providedIn: 'root',
})
export class AdminResolver {
  @Select(AuthState.getAuthRole) authRole$: Observable<AuthRoles>;

  constructor(private router: Router) {}

  resolve(): Observable<boolean> {
    return this.getPermissions();
  }

  getPermissions(): Observable<boolean> {
    return this.authRole$.pipe(
      filter((p) => !!p),
      map((p) => p === AuthRoles.admin),
      tap((p) => {
        if (!p) {
          this.router.navigate(['/404']);
        }
      }),
      take(1)
    );
  }
}
