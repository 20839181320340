import {
  ExternalSystemConnectionTypes,
  ExternalSystemIssueType,
} from './../constants/external-systems.constants';
import {
  ExternalSystemTypes,
  ExternalSystemSchema,
  DatetimeRange,
  OrderDirection,
} from '@vfi-ui/models';

export interface ExternalSystems {
  name: string;
  time: string;
  healthy: boolean;
  id?: number;
}

export interface Integrations {
  id?: number;
  name?: string;
  databaseId?: string;
  type?: ExternalSystemTypes;
  displayName?: string;
  schema?: ExternalSystemSchema;
  alarmsPerHour?: number;
  hasProcessVariables?: boolean;
  genericParserAlarmCount?: number;
  genericParserConfiguration?: ExternalSystemGenericParserConfiguration;
  lastTrainingSetUploadAt?: string;
  connectionType?: string;
  direction?: string;
  url?: string;
  username?: string;
  password?: string;
  credentials?: any;
  levenshteinConfidenceThreshold?: number;
  lastRawEmailCsvGenerationAt?: string;
  rawEmailCsvFileName?: string;
  trainingSetUploadCount?: number;
  alarmCount?: number;
  averageAccuracy?: number;
  isExternalAlertsEnabled?: boolean;
  isAutomationRuleCmmsSyncEnabled?: boolean;
  externalAlertEmails?: string[];
  isCloseWorkButtonEnabled?: boolean;
  workTicketObjectiveTemplate?: string;
  excludedAlarmTexts?: string[];
  isCmmsPollingBackSyncEnabled?: boolean;
  isActive?: boolean;
  createdAt?: string;
  normalizerVersions?: string[];
  etlNormalizeVersion?: string;
}

export interface ExternalSystemGenericParserConfiguration {
  separators: string[];
}

export interface ExternalSystemOptions {
  label: string;
  value: string;
  connectionType: string;
}

export interface ExternalSystemIssueLog {
  externalSystemId: number;
  issueType: ExternalSystemIssueType;
  isResolved: boolean;
  createdAt: string;
  workTicketId: number;
  resolvedAt: string;
  dismissedAt: string;
  timeValues?: unknown;
}

export interface ConnectionTypeFields {
  name: string;
  formName: string;
  type: string;
  required?: boolean;
  editDisabled?: boolean;
  defaultValue?: any;
  options?: { label: string; value: any }[];
  showOnlySchemas?: ExternalSystemSchema[];
  showOnlyConnectionTypes?: ExternalSystemConnectionTypes[];
}

export interface ExternalSystemReplayAlarmsInput {
  replayStartAt: Date;
  replayEndAt: Date;
}

export enum ExternalSystemCommandType {
  // BAS command types
  Historical = 'HISTORICAL',
  Config = 'CONFIG',
  Kill = 'KILL',
  GetBASEquipmentAssets = 'GET_BAS_EQUIPMENT_ASSETS',
  GetBASNetworkDeviceAssets = 'GET_BAS_NETWORK_DEVICE_ASSETS',
  GetBASSpaceAssets = 'GET_BAS_SPACE_ASSETS',
  GetBASObjectAssets = 'GET_BAS_OBJECT_ASSETS',
  GetWebcontrolNodes = 'GET_WEBCONTROL_NODES',
  GetEnvisionEventMsg = 'GET_ENVISION_EVENT_MSG',
  GetEnvisionSysXRef = 'GET_ENVISION_SYS_X_REF',
  GetEnvisionAlarmHistory = 'GET_ENVISION_ALARM_HISTORY',
  GetEnvisionAlarmMessage = 'GET_ENVISION_ALARM_MESSAGE',
  GetEnvisionDevCapability = 'GET_ENVISION_DEV_CAPABILITY',
  GetEnvisionDevice = 'GET_ENVISION_DEVICE',
  GetEnvisionOperatorActivity = 'GET_ENVISION_OPERATOR_ACTIVITY',
  // CMMS command types
  CreateCmmsWork = 'CREATE_CMMS_WORK',
  UpdateCmmsWork = 'UPDATE_CMMS_WORK',
  SyncCmmsWork = 'SYNC_CMMS_WORK',
  TestCmmsConnection = 'TEST_CMMS_CONNECTION',
  GetCmmsFields = 'GET_CMMS_FIELDS',
  GetCmmsFieldValues = 'GET_CMMS_FIELD_VALUES',
}

export enum ExternalSystemCommandStatus {
  Pending = 'PENDING',
  InProgress = 'IN_PROGRESS',
  Complete = 'COMPLETE',
  Failed = 'FAILED',
}

export enum ExternalSystemBasSourceFieldType {
  PointDescription = 'POINT_DESCRIPTION',
  AlarmName = 'ALARM_NAME',
  AlarmDescription = 'ALARM_DESCRIPTION',
  AlarmMessage = 'ALARM_MESSAGE',
  AlarmCategory = 'ALARM_CATEGORY',
  Priority = 'PRIORITY',
}

export interface ExternalSystemCommand {
  id: string;
  commandType: ExternalSystemCommandType;
  command: object;
  externalSystemId: number;
  status: ExternalSystemCommandStatus;
  response: object;
  createdAt: string;
  finishedAt: string;
  workTicketIds: number[];
}

export interface ExternalSystemCommandWhere {
  externalSystemId: number;
  commandType?: ExternalSystemCommandType;
  status?: ExternalSystemCommandStatus;
  workTicketIds?: number[];
  createdAt?: DatetimeRange;
  finishedAt?: DatetimeRange;
}

export enum ExternalSystemCommandOrderField {
  Type = 'COMMAND_TYPE',
  Status = 'STATUS',
  CreatedAt = 'CREATED_AT',
  FinishedAt = 'FINISHED_AT',
}
export interface ExternalSystemCommandSort {
  field: ExternalSystemCommandOrderField;
  direction: OrderDirection;
}

export interface ExternalSystemBasSourceMapping {
  id?: string;
  schema: ExternalSystemSchema;
  type: ExternalSystemBasSourceFieldType;
  key?: string;
}

export interface ExternalSystemBasSourceMappingUpdateInput {
  id: string;
  key: string;
}

export interface ExternalSystemBasSourceMappingCreateInput {
  schema: ExternalSystemSchema;
  type: ExternalSystemBasSourceFieldType;
  key: string;
}
