<div
  class="d-flex flex-column align-items-center justify-content-between h-100 p-5"
>
  <div
    class="d-flex flex-column align-items-center justify-content-around h-75"
  >
    <img src="/assets/vfi-logo.svg" alt="vfi-logo" class="vfi-logo mb-3" />
    <div class="d-flex flex-column align-items-center justify-content-around">
      <span class="fw-500 regular-20 neutral-2 text-center"
        >Sorry, this page is not available on mobile devices.</span
      >
      <br />
      <br />
      <span class="fw-500 regular-20 neutral-2 text-center"
        >Please use a desktop or laptop computer to access the platform.</span
      >
    </div>
    <atom-vfi-button
      class="w-100"
      id="loginBtn"
      text="Back To App"
      ariaLabel="backToApp"
      type="secondary"
      [disabled]=""
      classes="block-button"
      (clicked)="backToApp()"
    ></atom-vfi-button>
  </div>

  <p class="mb-0 gray-1 regular-12">
    &copy; {{ currentYear }} Virtual Facility, Inc. All Rights Reserved.
  </p>
</div>
