import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Logout } from '@vfi-ui/state';

@Component({
  selector: 'vfi-auth-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {
  constructor(private store: Store) {}

  /**
   * logs the user out of the apploication
   * redirects to login page
   *
   * @memberof LogoutComponent
   */
  ngOnInit() {
    this.store.dispatch(new Logout());
  }
}
