export const WORK_TICKETS_REPORT_MAX_DAYS_RANGE = 14;
export const WORK_TICKETS_REPORT_DYNAMIC_COLUMNS_BLACKLIST = [
  'equipment',
  'space_type',
  'instrument_type',
];
export const WORK_TICKETS_REPORT_DEFAULT_COLUMNS = [
  'createdAt',
  'objective',
  'assigneeName',
  'duration',
  'closedAt',
  'associatedAlarms',
];

export const WORK_TICKETS_REPORT_CONTEXT_MENU_BLACKLIST = [
  'workIndicator',
  'createdAt',
  'closedAt',
  'isResolved',
  'duration',
  'isRepeat',
  'associatedAlarms',
  'isOverlapped',
  'associatedAlarmTimes',
  'associatedAlarmHasPointEntity',
  'associatedAlarmNuisances',
  'associatedAlarmIsServiceImpacting',
  'associatedAlarmPriorities',
  'associatedAlarmStaleStatuses',
  'associatedAlarmStatuses',
  'timeToDispatch',
];

export const WORK_RESOLUTION_WORK_TICKETS_TABLE_COLUMNS = [
  {
    name: 'Work Created',
    value: 'createdAt',
    isVisible: true,
    isEditable: false,
    hasConfidence: true,
    subGroup: null,
    isAlarmAttribute: false,
  },
  {
    name: 'Work Objective',
    value: 'objective',
    isVisible: true,
    isEditable: false,
    hasConfidence: true,
    subGroup: null,
    isAlarmAttribute: false,
  },
  {
    name: 'Work Assignee (User)',
    value: 'assigneeName',
    isVisible: true,
    isEditable: false,
    hasConfidence: true,
    subGroup: null,
    isAlarmAttribute: false,
  },
  {
    name: 'Work Age',
    value: 'duration',
    isVisible: true,
    isEditable: false,
    hasConfidence: true,
    subGroup: null,
    isAlarmAttribute: false,
  },
  {
    name: 'Work Closed',
    value: 'closedAt',
    isVisible: true,
    isEditable: false,
    hasConfidence: true,
    subGroup: null,
    isAlarmAttribute: false,
  },
  {
    name: 'Alarm ID',
    value: 'associatedAlarms',
    isVisible: true,
    isEditable: false,
    hasConfidence: false,
    subGroup: null,
    isAlarmAttribute: false,
  },
  {
    name: 'Work ID',
    value: 'id',
    isVisible: false,
    isEditable: false,
    hasConfidence: true,
    subGroup: null,
    isAlarmAttribute: false,
  },
  {
    name: 'CMMS ID',
    value: 'externalId',
    isVisible: false,
    isEditable: false,
    hasConfidence: true,
    subGroup: null,
    isAlarmAttribute: false,
  },
  {
    name: 'CMMS Name',
    value: 'externalSystemName',
    isVisible: false,
    isEditable: false,
    hasConfidence: true,
    subGroup: null,
    isAlarmAttribute: false,
  },
  {
    name: 'Work Resolution',
    value: 'isResolved',
    isVisible: false,
    isEditable: false,
    hasConfidence: true,
    subGroup: null,
    isAlarmAttribute: false,
  },
  {
    name: 'Work Creator (User)',
    value: 'createdBy',
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: null,
    isAlarmAttribute: false,
  },
  {
    name: 'Alarm Count',
    value: 'alarmInstanceCount',
    isVisible: false,
    isEditable: false,
    hasConfidence: true,
    subGroup: null,
    isAlarmAttribute: false,
  },
  {
    name: 'Repeat Work Tickets',
    value: 'isRepeat',
    isVisible: false,
    isEditable: false,
    hasConfidence: true,
    subGroup: null,
    isAlarmAttribute: false,
  },
  {
    name: 'Work State',
    value: 'state',
    isVisible: false,
    isEditable: false,
    hasConfidence: true,
    subGroup: null,
    isAlarmAttribute: false,
  },
  {
    name: 'Inactive Alarm Count',
    value: 'inactiveAlarmCount',
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: null,
    isAlarmAttribute: false,
  },
  {
    name: 'Active Alarm Count',
    value: 'activeAlarmCount',
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: null,
    isAlarmAttribute: false,
  },
  {
    name: 'Duplicate Work',
    value: 'isOverlapped',
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: null,
    isAlarmAttribute: false,
  },
  {
    name: 'Work Assignee (Team)',
    value: 'assigneeTeams',
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: null,
    isAlarmAttribute: false,
  },
  {
    name: 'Work Creator (Team)',
    value: 'creatorTeams',
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: null,
    isAlarmAttribute: false,
  },
  {
    name: 'Automation Created Work',
    value: 'isAutomationCreated',
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: null,
    isAlarmAttribute: false,
  },
  {
    name: 'Alarm Source',
    value: 'associatedAlarmSources',
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: null,
    isAlarmAttribute: false,
  },
  {
    name: 'Alarm Time',
    value: 'associatedAlarmTimes',
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: null,
    isAlarmAttribute: false,
  },
  {
    name: 'Has Point Entity',
    value: 'associatedAlarmHasPointEntity',
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: null,
    isAlarmAttribute: false,
  },
  {
    name: 'Nuisance',
    value: 'associatedAlarmNuisances',
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: null,
    isAlarmAttribute: false,
  },
  {
    name: 'Alarm Priority',
    value: 'associatedAlarmPriorities',
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: null,
    isAlarmAttribute: false,
  },
  {
    name: 'Service Failure Alarm',
    value: 'associatedAlarmIsServiceImpacting',
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: null,
    isAlarmAttribute: false,
  },
  {
    name: 'Stale Status',
    value: 'associatedAlarmStaleStatuses',
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: null,
    isAlarmAttribute: false,
  },
  {
    name: 'Alarm Response State',
    value: 'associatedAlarmStatuses',
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: null,
    isAlarmAttribute: false,
  },
  {
    name: 'Time to Dispatch',
    value: 'timeToDispatch',
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: null,
    isAlarmAttribute: false,
  },
  {
    name: 'VF Asset ID',
    value: 'associatedAlarmAssetIds',
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: null,
    isAlarmAttribute: false,
  },
  {
    name: 'Asset Class',
    value: 'associatedAlarmAssetClasses',
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: null,
    isAlarmAttribute: false,
  },
  {
    name: 'Asset Type',
    value: 'associatedAlarmAssetTypes',
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: null,
    isAlarmAttribute: false,
  },
  {
    name: 'Space Type',
    value: 'associatedAlarmSpaceTypes',
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: null,
    isAlarmAttribute: false,
  },
  {
    name: 'Space Name',
    value: 'associatedAlarmSpaceNames',
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: null,
    isAlarmAttribute: false,
  },
  {
    name: 'Equipment Name',
    value: 'associatedAlarmEquipmentNames',
    isVisible: false,
    isEditable: false,
    hasConfidence: false,
    subGroup: null,
    isAlarmAttribute: false,
  },
];
