import { User } from '@vfi-ui/models';
import { environment } from '@vfi-ui/environments/environment';
import { ErrorHandler, Injectable } from '@angular/core';
import StackdriverErrorReporter from 'stackdriver-errors-js';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandler implements ErrorHandler {
  errorHandler: any;
  constructor() {
    window.addEventListener('load', () => {
      this.errorHandler = new StackdriverErrorReporter();
      this.errorHandler.start({
        key: environment.firebase.apiKey,
        projectId: environment.firebase.projectId,
        disabled: environment.env !== 'prod',
        context: {},
      });
    });
  }
  /**
   * handle error and pass it to gcp stack driver
   *
   * @param {*} error
   * @memberof GlobalErrorHandler
   */
  handleError(error: any): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    if (this.errorHandler) {
      this.errorHandler.report(error);
    }
    console.error(error);

    if (chunkFailedMessage.test(error.message)) {
      window.location.reload();
    }
  }
  /**
   * set user so we know where the error came from
   *
   * @param {User} user
   * @memberof GlobalErrorHandler
   */
  setUser(user: User) {
    if (this.errorHandler) {
      this.errorHandler.setUser(JSON.stringify(user));
    }
  }
}
