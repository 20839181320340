import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { getCurrentYear } from '@vfi-ui/util/helpers';

@Component({
  selector: 'atom-indicator-401',
  templateUrl: './indicator-401.component.html',
  styleUrls: ['./indicator-401.component.scss'],
})
export class Indicator401Component {
  currentYear = getCurrentYear();

  constructor(private router: Router) {}

  /**
   * logs user out and redirects user to login screen
   *
   * @memberof Indicator401Component
   */
  backToApp() {
    this.router.navigate(['/logout']);
  }
}
