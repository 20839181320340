import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@vfi-ui/environments/environment';
import { SsoConfig } from '@vfi-ui/models';
import { get } from '@vfi-ui/util/helpers';
import { catchError, filter, map, Observable, tap, throwError } from 'rxjs';
import {
  CREATE_SSO_QUERY,
  DELETE_SSO_QUERY,
  GET_EMAIL_DOMAINS_QUERY,
  GET_SSO_QUERY,
  UPDATE_SSO_QUERY,
} from '../queries';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root',
})
export class SsoService {
  constructor(
    private readonly http: HttpClient,
    private notification: NotificationService
  ) {}
  /**
   * Creates the sso config
   *
   * @param {string} emailDomain
   * @param {SsoConfig} ssoConfig
   * @param {number} customerId
   * @returns {Observable<boolean>}
   * @memberof SsoService
   */
  createSsoConfig(
    emailDomain: string,
    ssoConfig: SsoConfig,
    customerId: number
  ): Observable<boolean> {
    return this.http
      .request<{ setupSso: boolean }>('POST', environment.backend, {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: CREATE_SSO_QUERY,
          variables: {
            emailDomain,
            ssoConfig,
            customerId,
          },
        }),
      })
      .pipe(
        filter((d) => !!d),
        tap(() =>
          this.notification.showSuccess(
            'SSO configuration created successfully'
          )
        ),
        map((res) => get(res, 'data.setupSso'))
      );
  }

  /**
   * Updates the sso config
   *
   * @param {string} emailDomain
   * @param {SsoConfig} ssoConfig
   * @returns {Observable<SsoConfig>}
   * @memberof SsoService
   */
  updateSsoConfig(
    emailDomain: string,
    ssoConfig: SsoConfig
  ): Observable<SsoConfig> {
    return this.http
      .request<{ updateSsoConfig: SsoConfig }>('POST', environment.backend, {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: UPDATE_SSO_QUERY,
          variables: {
            emailDomain,
            ssoConfig,
          },
        }),
      })
      .pipe(
        filter((d) => !!d),
        tap(() =>
          this.notification.showSuccess(
            'SSO configuration updated successfully'
          )
        ),
        map((res) => get(res, 'data.updateSsoConfig'))
      );
  }
  /**
   * Deletes a given sso config
   *
   * @param {string} emailDomain
   * @return {*}  {Observable<SsoConfig>}
   * @memberof SsoService
   */
  deleteSsoConfig(emailDomain: string): Observable<boolean> {
    return this.http
      .request<{ deleteSsoConfig: boolean }>('POST', environment.backend, {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: DELETE_SSO_QUERY,
          variables: {
            emailDomain,
          },
        }),
      })
      .pipe(
        filter((d) => !!d),
        tap(() =>
          this.notification.showSuccess(
            'SSO configuration deleted successfully'
          )
        ),
        map((res) => get(res, 'data.deleteSsoConfig'))
      );
  }

  /**
   * Gets the sso config
   *
   * @param {string} emailDomain
   * @returns {Observable<SsoConfig>}
   * @memberof SsoService
   */
  getSsoConfig(emailDomain: string): Observable<SsoConfig> {
    return this.http
      .request<{ getSsoConfig: SsoConfig }>('POST', environment.backend, {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: GET_SSO_QUERY,
          variables: {
            emailDomain,
          },
        }),
      })
      .pipe(
        filter((d) => !!d),
        map((res) => get(res, 'data.getSsoConfig'))
      );
  }

  /**
   * Gets the email domains which have sso setup
   *
   * @param {number} customerId
   * @returns {Observable<string[]>}
   * @memberof SsoService
   */
  getEmailDomains(customerId: number): Observable<string[]> {
    return this.http
      .request<{ getEmailDomainsPerCustomer: string[] }>(
        'POST',
        environment.backend,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            query: GET_EMAIL_DOMAINS_QUERY,
            variables: {
              customerId,
            },
          }),
        }
      )
      .pipe(
        filter((d) => !!d),
        map((res) => get(res, 'data.getEmailDomainsPerCustomer'))
      );
  }

  /**
   * Uploads a file
   *
   * @param {File} file
   * @return {Observable<SsoConfig>}  {Observable<SsoConfig>}
   * @memberof SsoService
   */
  uploadMetdata(file: File): Observable<SsoConfig> {
    const formData = new FormData();
    formData.append('file', file);
    return this.http
      .post<SsoConfig>(environment.backend + '/sso/metadata/', formData)
      .pipe(
        filter((d) => !!d),
        tap(() =>
          this.notification.showSuccess('Metadata uploaded successfully')
        ),
        catchError((err) => {
          this.notification.showError(err?.error?.message);
          return throwError(() => err);
        })
      );
  }
}
