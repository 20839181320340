import { ExternalSystemsDataService } from '@vfi-ui/data-access/shared';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { take, filter, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Integrations } from '@vfi-ui/models';
import { SetExternalSystemIntegrations } from '@vfi-ui/state';

@Injectable({
  providedIn: 'root',
})
export class ExternalSystemResolver {
  constructor(
    private store: Store,
    private externalSystemsDataService: ExternalSystemsDataService
  ) {}

  resolve(): Observable<Integrations[]> {
    return this.externalSystemsDataService.getAllSourceSystemStatus().pipe(
      filter((p) => !!p),
      tap((p) => {
        this.store.dispatch(new SetExternalSystemIntegrations(p));
      }),
      take(1)
    );
  }
}
