import { adminTabRoutes } from '@vfi-ui/models';
import { CustomersState } from '@vfi-ui/state';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, from, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AllCustomersResolver {
  constructor(
    private store: Store,
    private router: Router
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const currentCustomer = this.store.selectSnapshot(
      CustomersState.getCurrentCustomers
    );
    switch (state.url) {
      case '/parser':
        return from(this.router.navigate([state.url, currentCustomer.id]));

      case '/users':
        return from(
          this.router.navigate([
            adminTabRoutes.ADMIN_USERS_LIST,
            currentCustomer.name,
            currentCustomer.id,
          ])
        );
    }

    return of(false);
  }
}
