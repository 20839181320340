import { Permissions } from '../interfaces/permission.interface';
import { AuthRoles } from './roles.constants';

export const adminPermissions: Permissions = {
  authRole: AuthRoles.admin,
  landing: 'alarms',
  triage: {
    base: true,
    Active: true,
    Intake: true,
    Snoozed: true,
    Declined: true,
    // TODO: make work product specific
    Dispatched: true,
    Nuisance: true,
    'Active Work': true,
    'My Work': false,
    Closed: false,
  },
  reporting: {
    base: true,
    counts: true,
    allAlarms: true,
    followUp: true,
    allWork: true,
  },
  settings: {
    base: true,
    profile: true,
    userGroups: true,
    roles: true,
    teams: true,
    feedback: true,
    about: true,
    objectControl: true,
    automationRules: true,
  },
  analytics: {
    base: true,
    counts: true,
    'work-resolution': true,
    'kpi-dashboard': true,
    'work-history': true,
    'advanced-analytics': true,
  },
  situationalAwareness: true,
  workOrder: true,
  alarms: true,
};

export const managerPermissions: Permissions = {
  authRole: AuthRoles.manager,
  landing: 'alarms',
  triage: {
    base: true,
    Active: true,
    Intake: true,
    Snoozed: true,
    Declined: true,
    // TODO: make work product specific
    Dispatched: true,
    Nuisance: true,
    'Active Work': true,
    'My Work': false,
    Closed: false,
  },
  reporting: {
    base: true,
    counts: true,
    allAlarms: true,
    followUp: true,
    allWork: true,
  },
  settings: {
    base: true,
    profile: true,
    userGroups: true,
    roles: true,
    teams: true,
    feedback: true,
    about: true,
    objectControl: false,
    automationRules: true,
  },
  analytics: {
    base: true,
    counts: true,
    'work-resolution': true,
    'kpi-dashboard': true,
    'work-history': true,
    'advanced-analytics': false,
  },
  situationalAwareness: true,
  workOrder: true,
  alarms: true,
};

export const standardPermissions: Permissions = {
  authRole: AuthRoles.standard,
  landing: 'alarms',
  triage: {
    base: true,
    Active: true,
    Intake: true,
    Snoozed: true,
    Declined: true,
    // TODO: make work product specific
    Dispatched: true,
    Nuisance: true,
    'Active Work': true,
    'My Work': false,
    Closed: false,
  },
  reporting: {
    base: true,
    counts: true,
    allAlarms: true,
    followUp: true,
    allWork: true,
  },
  settings: {
    base: true,
    profile: true,
    userGroups: true,
    roles: false,
    teams: false,
    feedback: true,
    about: true,
    objectControl: false,
    automationRules: false,
  },
  analytics: {
    base: true,
    counts: true,
    'work-resolution': true,
    'kpi-dashboard': true,
    'work-history': true,
    'advanced-analytics': false,
  },
  situationalAwareness: true,
  workOrder: true,
  alarms: true,
};

export const vendorPermissions: Permissions = {
  authRole: AuthRoles.standard,
  landing: 'alarms',
  triage: {
    base: true,
    Active: true,
    Intake: true,
    Snoozed: true,
    Declined: true,
    // TODO: make work product specific
    Dispatched: true,
    Nuisance: true,
    'Active Work': true,
    'My Work': false,
    Closed: false,
  },
  reporting: {
    base: true,
    counts: true,
    allAlarms: true,
    followUp: true,
    allWork: true,
  },
  settings: {
    base: true,
    profile: true,
    userGroups: false,
    roles: false,
    teams: false,
    feedback: true,
    about: true,
    objectControl: false,
    automationRules: false,
  },
  analytics: {
    base: true,
    counts: true,
    'work-resolution': true,
    'kpi-dashboard': true,
    'work-history': true,
    'advanced-analytics': false,
  },
  situationalAwareness: true,
  workOrder: true,
  alarms: true,
};

export const occupantPermissions: Permissions = {
  authRole: AuthRoles.occupant,
  landing: 'settings',
  triage: {
    base: true,
    Active: true,
    Intake: true,
    Snoozed: true,
    Declined: true,
    Dispatched: true,
    Nuisance: true,
    'Active Work': true,
    'My Work': false,
    Closed: false,
  },
  reporting: {
    base: false,
    counts: false,
    allAlarms: false,
    followUp: false,
    allWork: false,
  },
  settings: {
    base: true,
    profile: true,
    userGroups: false,
    roles: false,
    teams: false,
    feedback: true,
    about: true,
    objectControl: false,
    automationRules: false,
  },
  analytics: {
    base: false,
    counts: false,
    'work-resolution': false,
    'kpi-dashboard': true,
    'work-history': false,
    'advanced-analytics': false,
  },
  situationalAwareness: true,
  workOrder: false,
  alarms: false,
};

export const nonePermissions: Permissions = {
  authRole: AuthRoles.none,
  landing: 'settings',
  triage: {
    base: false,
    Active: false,
    Intake: false,
    Snoozed: false,
    Declined: false,
    Dispatched: false,
    Nuisance: false,
    'Active Work': false,
    'My Work': false,
    Closed: false,
  },
  reporting: {
    base: false,
    counts: false,
    allAlarms: false,
    followUp: false,
    allWork: false,
  },
  settings: {
    base: false,
    profile: true,
    userGroups: false,
    roles: false,
    teams: false,
    feedback: true,
    about: true,
    objectControl: false,
    automationRules: false,
  },
  analytics: {
    base: false,
    counts: false,
    'work-resolution': true,
    'kpi-dashboard': true,
    'work-history': true,
    'advanced-analytics': true,
  },
  situationalAwareness: true,
  workOrder: false,
  alarms: false,
};

// TODO: Temp permissions. Revist once AuthRoles has been implemented
export const permission = {
  ADMIN: adminPermissions,
  MANAGER: managerPermissions,
  STANDARD: standardPermissions,
  VENDOR: vendorPermissions,
  OCCUPANT: occupantPermissions,
  NONE: nonePermissions,
};

export const redirectSettings = [AuthRoles.none];
