import {
  ResetUsers,
  LoadUsers,
  LoadMoreUsers,
  UserDisplayNameChanged,
  UserAvatarChanged,
  UserRoleChanged,
  UserCompanyChanged,
  UserStatusChanged,
  UserAccessChanged,
  SetUsers,
  RemoveUser,
  DeletingUser,
} from './users.actions';
import {
  MaxAlarmsLimit,
  Tile,
  Users,
  Status,
  AuthRoles,
  GlobalFilterSort,
} from '@vfi-ui/models';
import {
  State,
  Action,
  Selector,
  StateContext,
  Store,
  Actions,
  ofActionCompleted,
} from '@ngxs/store';
import { patch, append, updateItem } from '@ngxs/store/operators';
import { AuthService } from '@vfi-ui/data-access/shared';
import { takeUntil, skip, take, map } from 'rxjs/operators';
import { AuthState } from '../auth/auth.state';
import { GlobalFiltersState } from './../global-filters/global-filters.state';
import { Injectable } from '@angular/core';
import { result } from '@vfi-ui/util/helpers';

export interface UsersStateModel {
  items: Users[];
  tiles: Tile[];
  loading: boolean;
  offset: number;
  limit: number;
  totalCount: number;
  deletingUser: boolean;
}
export const UserStateDefaults: UsersStateModel = {
  items: [],
  tiles: [],
  loading: false,
  offset: 0,
  limit: MaxAlarmsLimit,
  totalCount: 0,
  deletingUser: false,
};

@State<UsersStateModel>({
  name: 'users',
  defaults: UserStateDefaults,
})
@Injectable({
  providedIn: 'root',
})
export class UsersState {
  constructor(
    private authService: AuthService,
    private store: Store,
    private actions$: Actions
  ) {}

  @Selector()
  static getState(state: UsersStateModel) {
    return state;
  }

  @Selector()
  static getUsers(state: UsersStateModel) {
    return state.items;
  }

  @Selector()
  static getTiles(state: UsersStateModel) {
    return state.tiles;
  }

  @Selector()
  static getLoading(state: UsersStateModel) {
    return state.loading;
  }

  @Selector()
  static getOffset(state: UsersStateModel) {
    return state.offset;
  }

  @Selector()
  static getLimit(state: UsersStateModel) {
    return state.limit;
  }

  @Selector()
  static getUsersTotalCount(state: UsersStateModel) {
    return state.totalCount;
  }

  @Selector()
  static getDeleteUserState(state: UsersStateModel) {
    return state.deletingUser;
  }

  @Action(ResetUsers)
  public resetState(ctx: StateContext<UsersStateModel>) {
    ctx.setState(UserStateDefaults);
  }

  @Action(LoadUsers)
  public add(ctx: StateContext<UsersStateModel>) {
    const state = ctx.getState();
    const authRole = this.store.selectSnapshot(AuthState.getAuthRole);
    const showDisabled = authRole === AuthRoles.admin;
    const showVendors = authRole === AuthRoles.admin;
    const sort = this.store.selectSnapshot(
      GlobalFiltersState.getSort
    ) as GlobalFilterSort;
    ctx.setState({
      ...state,
      loading: true,
    });
    this.authService
      .getUsers({ showActive: true, sort, showVendors, showDisabled })
      .pipe(
        takeUntil(
          this.actions$.pipe(ofActionCompleted(LoadUsers), skip(1), take(1))
        ),
        map((users) => {
          if (authRole && authRole !== AuthRoles.admin) {
            return users.filter((user) => user.status !== Status.pending);
          }
          return users;
        })
      )
      .subscribe((users) => {
        ctx.setState(
          patch({
            items: append(users),
            loading: false as boolean,
            totalCount: users.length,
          })
        );
      });
  }

  @Action(LoadMoreUsers)
  public updateOffset(ctx: StateContext<UsersStateModel>, { payload }) {
    const state = ctx.getState();
    ctx.setState(
      patch({
        offset: result(payload, 'offset', state.offset + state.limit),
      })
    );
    return ctx.dispatch(new LoadUsers());
  }

  @Action(SetUsers)
  public setUsers(ctx: StateContext<UsersStateModel>, { payload }) {
    ctx.setState(
      patch({
        items: payload,
        loading: false as boolean,
        totalCount: payload.length,
      })
    );
  }

  @Action(UserDisplayNameChanged)
  public userDisplayNameChanged(
    ctx: StateContext<UsersStateModel>,
    { payload }
  ) {
    ctx.setState(
      patch({
        items: updateItem(
          (item: Users) => item.id === payload.id,
          patch({
            displayName: payload.displayName,
          })
        ),
      })
    );
  }

  @Action(UserAvatarChanged)
  public userAvatarChanged(ctx: StateContext<UsersStateModel>, { payload }) {
    ctx.setState(
      patch({
        items: updateItem(
          (item: Users) => item.id === payload.id,
          patch({
            avatarUrl: payload.avatarUrl,
          })
        ),
      })
    );
  }

  @Action(UserRoleChanged)
  public userRoleChanged(ctx: StateContext<UsersStateModel>, { payload }) {
    ctx.setState(
      patch({
        items: updateItem(
          (item: Users) => item.id === payload.id,
          patch({
            role: payload.role,
          })
        ),
      })
    );
  }

  @Action(UserCompanyChanged)
  public userCompanyChanged(ctx: StateContext<UsersStateModel>, { payload }) {
    ctx.setState(
      patch({
        items: updateItem(
          (item: Users) => item.id === payload.id,
          patch({
            company: payload.company,
          })
        ),
      })
    );
  }

  @Action(UserStatusChanged)
  public userStatusChanged(ctx: StateContext<UsersStateModel>, { payload }) {
    ctx.setState(
      patch({
        items: updateItem(
          (item: Users) => item.id === payload.id,
          patch({
            status: payload.status,
          })
        ),
      })
    );
  }

  @Action(UserAccessChanged)
  public userAccessChanged(ctx: StateContext<UsersStateModel>, { payload }) {
    ctx.setState(
      patch({
        items: updateItem(
          (item: Users) => item.id === payload.id,
          patch({
            status: payload.status,
          })
        ),
      })
    );
  }

  @Action(RemoveUser)
  public removeUser(ctx: StateContext<UsersStateModel>, { payload }) {
    const state = ctx.getState();
    ctx.setState(
      patch({
        items: state.items.filter((user) => user.id !== payload),
      })
    );
  }

  @Action(DeletingUser)
  public deleteUser(ctx: StateContext<UsersStateModel>, { payload }) {
    ctx.setState(
      patch({
        deletingUser: payload,
      })
    );
  }
}
