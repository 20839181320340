// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  frontend: 'https://dev.vfacility.co',
  backend: 'https://dev-api.vfacility.co',
  socketBackend: 'wss://dev-api-ws.vfacility.co',
  account: 'dev',
  firebase: {
    apiKey: 'AIzaSyAny83vZzikuN8IgPxtVDrbMltt_ywLfcE',
    authDomain: 'dev.vfacility.co',
    databaseURL: 'https://virtual-facility-dev.firebaseio.com',
    projectId: 'virtual-facility-dev',
    storageBucket: 'virtual-facility-dev.appspot.com',
    messagingSenderId: '216271332826',
  },
  disableLogger: true,
  showSalesSimulator: false,
  showAutomation: true,
  enablePwa: false,
  env: 'dev',
  lookerBackend: 'https://looker.vfacility.co',
  mapBoxToken:
    'pk.eyJ1IjoidmZhY2lsaXR5IiwiYSI6ImNsdXN2MDludDBnZjMyam1vZ3huYjhtbHcifQ.bgLdjCHBG2Vfzy_mtc5vwQ',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
